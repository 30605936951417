import styled from "@emotion/styled";
import * as React from "react";
import { FC, useRef, useState } from "react";
import FlexBox, { CustomWidthE } from "../../components/FlexBox";
import { useResponsive } from "../../hooks/useResponsive";
import { rem, theme } from "../../styling/theme";
import PositionWrapper from "./PositionWrapper";

const Wrapper = styled(FlexBox)({
  minWidth: "100%",
  border: `1px solid ${theme.color.greyLightColor}`,
  borderRadius: rem(8),
  backgroundColor: theme.color.whiteColor,
  transition: "box-shadow 0.2s, width 0.2s",
  transitionTimingFunction: theme.timing.default,
});

type CardWrapperT = {
  cardWidth: string;
  renderChildren: (
    wrapperWidth?: number,
    actionsVisible?: boolean
  ) => JSX.Element;
  storySizing?: boolean;
};

const CardWrapper: FC<CardWrapperT> = ({
  cardWidth,
  renderChildren,
  storySizing,
}) => {
  const { tabletVersion } = useResponsive();
  const [hover, setHover] = useState(false);

  const actionsVisible = hover || tabletVersion;

  const wrapper = useRef<HTMLDivElement>(null);
  const wrapperWidth = wrapper.current?.clientWidth;

  return (
    <PositionWrapper storySizing={storySizing} cardWidth={cardWidth}>
      <Wrapper
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        ref={wrapper}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        fullHeight={true}
        customWidth={CustomWidthE.full}
        position="relative"
      >
        {renderChildren(wrapperWidth, actionsVisible)}
      </Wrapper>
    </PositionWrapper>
  );
};

export default CardWrapper;
