import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import EntryFilesRouter from "./EntryFilesRouter";

export default ({
  id,
  props,
  loader,
  translations,
}: React.ComponentProps<typeof EntryFilesRouter>): JSX.Element => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <EntryFilesRouter
        id={id}
        props={props}
        translations={translations}
        loader={loader}
      />
    </QueryClientProvider>
  );
};
