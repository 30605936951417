import * as React from "react";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import Container from "../../../components/Container";
import { UserRoleT } from "../../types";
import { getAddedMediaGroup } from "../data-access/createMediaGroup";
import { getMediaGroupsParticipant } from "../data-access/getMediaGroupsParticipant";
import AutoContainer from "./AutoContainer";
import Header, { HeaderDataT } from "./Header";
import MediaGroupsContainer from "./MediaGoupsContainer";

type MainContainerT = {
  headerData: HeaderDataT;
  role: UserRoleT | null;
};

const MainContainer: FC<MainContainerT> = ({ headerData, role }) => {
  const { auto, reporting } = headerData;
  const [autoState, setAutoState] = useState<boolean | null>(!!auto);

  const { data: mediaGroups, isLoading } = useQuery(
    "mediaGroups",
    getMediaGroupsParticipant
  );

  const { data: addedMediaGroups } = useQuery(
    "addedMediaGroups",
    getAddedMediaGroup
  );

  return (
    <Container>
      <Header headerData={headerData} autoState={!!autoState} />
      {reporting.auto && (
        <AutoContainer
          autoState={!!autoState}
          setAutoState={setAutoState}
          headerData={headerData}
          role={role}
        />
      )}

      <MediaGroupsContainer
        mediaGroups={mediaGroups}
        addedMediaGroups={addedMediaGroups}
        loading={isLoading}
      />
    </Container>
  );
};

export default MainContainer;
