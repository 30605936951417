import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Icon from "../../../components/Icon";
import Popover from "../../../components/Popover";
import SortLink from "../../../components/SortLink";
import { SortingOptionsT } from "../../../hooks/useLocalSort";
import { useTranslate } from "../../../hooks/useTranslate";
import { theme } from "../../../styling/theme";
import TableColumn from "../../../ui/table/TableColumn";
import TableItemWrapper from "../../../ui/table/TableItemWrapper";

type TableHeadT = {
  sortingOptions: SortingOptionsT;
  setSortingOptions: Dispatch<SetStateAction<SortingOptionsT>>;
};

const TableHead: FC<TableHeadT> = ({ sortingOptions, setSortingOptions }) => {
  const { t } = useTranslate("brands.competitive_analyses.show.table");

  return (
    <TableItemWrapper
      renderChildren={() => {
        return (
          <>
            <TableColumn columnWidth="17%">
              <SortLink
                attribute={"handle"}
                sortingOptions={sortingOptions}
                setSortingOptions={setSortingOptions}
                t={t}
              />
            </TableColumn>
            <TableColumn columnWidth="17%">
              <SortLink
                attribute={"full_name"}
                sortingOptions={sortingOptions}
                setSortingOptions={setSortingOptions}
                t={t}
              />
            </TableColumn>
            <TableColumn columnWidth="15%">
              <SortLink
                attribute={"avg_post_likes"}
                sortingOptions={sortingOptions}
                setSortingOptions={setSortingOptions}
                t={t}
              />
              <Popover
                renderTrigger={() => (
                  <Icon
                    icon={IconTypeE.infoCircle}
                    color={theme.color.textGreyColor}
                    size="small"
                  />
                )}
                content={t("on_day_of_creation")}
                width={200}
              />
            </TableColumn>
            <TableColumn columnWidth="14%">
              <SortLink
                attribute={"avg_post_comments"}
                sortingOptions={sortingOptions}
                setSortingOptions={setSortingOptions}
                t={t}
              />
              <Popover
                renderTrigger={() => (
                  <Icon
                    icon={IconTypeE.infoCircle}
                    color={theme.color.textGreyColor}
                    size="small"
                  />
                )}
                content={t("on_day_of_creation")}
                width={200}
              />
            </TableColumn>
            <TableColumn columnWidth="14%">
              <SortLink
                attribute={"posts_count"}
                sortingOptions={sortingOptions}
                setSortingOptions={setSortingOptions}
                t={t}
              />
            </TableColumn>
            <TableColumn columnWidth="14%">
              <SortLink
                attribute={"stories_count"}
                sortingOptions={sortingOptions}
                setSortingOptions={setSortingOptions}
                t={t}
              />
              <Popover
                renderTrigger={() => (
                  <Icon
                    icon={IconTypeE.infoCircle}
                    color={theme.color.textGreyColor}
                    size="small"
                  />
                )}
                content={t("stories_info")}
                width={200}
              />
            </TableColumn>
            <TableColumn columnWidth="9%"></TableColumn>
          </>
        );
      }}
    />
  );
};

export default TableHead;
