import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import CheckBox from "../../../components/CheckBox";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { ReportingParticipant } from "../../reporting-media-groups/data-access/types";

const Wrapper = styled(FlexBox)({
  marginBottom: rem(12),
  paddingBottom: rem(12),
  borderBottom: `1px solid ${theme.color.greyColor}`,
});

type FilterParticipantsHandleT = {
  participants: ReportingParticipant[];
  participantsState: string[];
  setParticipantsState: Dispatch<SetStateAction<string[]>>;
};

const FilterParticipantsHandle: FC<FilterParticipantsHandleT> = ({
  participants,
  participantsState,
  setParticipantsState,
}) => {
  const { t } = useTranslate("brands.reportings.participants.filter");

  return (
    <Wrapper customWidth={CustomWidthE.full} justifyContent="flex-start">
      <CheckBox
        active={participantsState.length === participants.length}
        onClickHandle={() => {
          setParticipantsState(() => {
            if (participantsState.length === participants.length) {
              return [];
            }

            return participants.map((item) => item.id);
          });
        }}
      />
      <Spacer />
      <FlexBox>
        <Paragraph
          paragraph={t("all")}
          fontWeight={600}
          color={theme.color.textGreyColor}
        />
      </FlexBox>
      <Spacer />
    </Wrapper>
  );
};

export default FilterParticipantsHandle;
