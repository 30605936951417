import { CrossPacksConts } from "../../react/helpers/crossPacks";

const {
  allowScroll,
  disableScroll,
  _o,
  getGlobalClassState,
} = require("./helpers");

const MOBILE_VERSION_BREAKPOINT = 992;

const _ = window;
_.MOBILE_VERSION = false;

export const mobileViewHandle = () => {
  const width = document.body.clientWidth;

  if (width && width < MOBILE_VERSION_BREAKPOINT) {
    window.MOBILE_VERSION = true;
    return;
  }

  window.MOBILE_VERSION = false;
};

const mobileOverlayVisibilityHandle = (show) => {
  const mobileOverlay = document.getElementById("mobile-overlay");

  if (mobileOverlay) {
    if (show) {
      mobileOverlay.classList.add("visible");
      return;
    }

    mobileOverlay.classList.remove("visible");
  }
};

export const scrollDisableHandle = (toggleCheckboxId) => {
  const toggleCheckboxElement = document.getElementById(toggleCheckboxId);

  function menuToggle() {
    if (this.checked) {
      mobileOverlayVisibilityHandle(true);
      disableScroll();
      return;
    }

    if (this.className.includes("close-notifications-trigger")) {
      const notificationsCloseListener = new Event(
        CrossPacksConts.NOTIFICATIONS_CLOSE_LISTENER
      );

      document.dispatchEvent(notificationsCloseListener);
    }

    mobileOverlayVisibilityHandle(false);
    allowScroll();
  }

  if (toggleCheckboxElement) {
    toggleCheckboxElement.addEventListener("click", menuToggle);
  }
};

function closeAllMobileMenu() {
  const allBurgerCheckboxes =
    document.getElementsByClassName("burger-checkbox");

  if (allBurgerCheckboxes && allBurgerCheckboxes.length > 0) {
    _o(allBurgerCheckboxes).map((checkbox) => {
      mobileOverlayVisibilityHandle(false);
      checkbox.checked = false;
    });

    allowScroll();
    return;
  }
}

const mobileOverlayOnClickHandle = () => {
  const overlayElement = document.getElementById("mobile-overlay");

  if (overlayElement) {
    overlayElement.addEventListener("click", closeAllMobileMenu);
  }
};

_.addEventListener("resize", () => {
  const appClasses = document.getElementById("app").className;
  const isScrollDsiabled = appClasses.includes("overflow-hidden");
  const modalOpen = getGlobalClassState("modal-open");

  if (!_.MOBILE_VERSION && !modalOpen && isScrollDsiabled) {
    allowScroll();
    closeAllMobileMenu();
  }

  mobileViewHandle();
});

$(document).on("turbolinks:load", () => {
  scrollDisableHandle("burger-checkbox");
  mobileOverlayOnClickHandle();
  mobileViewHandle();
});
