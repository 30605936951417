import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { Line } from "react-chartjs-2";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Spacer from "../../../../components/Spacer";
import { getDateTime } from "../../../../helpers/date";
import {
  capitalizeFirstLetter,
  numberToText,
} from "../../../../helpers/formaters";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { EngagementCardDatatT } from "../../types";
import MissingChart from "../MissingChart";
import { getOptionsEngagement } from "./options";

type TabStyleT = {
  active?: boolean;
  dotColor?: string;
};

const ContentWrapper = styled(FlexBox)({
  position: "relative",
  marginTop: rem(15),
  minHeight: rem(230),
});

export const Tab = styled(FlexBox)<TabStyleT>(({ active, dotColor }) => ({
  position: "relative",
  paddingLeft: dotColor ? rem(15) : 0,
  paddingBottom: rem(7),
  fontWeight: 500,
  color: active ? theme.color.textColor : theme.color.textGreyColor,
  borderBottom: `2px solid ${active ? theme.color.yellowColor : "transparent"}`,
  transition: "border-bottom 0.3s",
  transitionTimingFunction: theme.timing.default,
  cursor: "pointer",

  [":before"]: {
    content: '""',
    display: dotColor ? "flex" : "none",
    position: "absolute",
    left: 0,
    top: rem(5),
    width: rem(8),
    height: rem(8),
    backgroundColor: dotColor,
    borderRadius: "50%",
  },
}));

export type EngagementT = {
  data: EngagementCardDatatT;
  callBackForYTicks?: (value: any, index: any) => string;
  color?: string;
};

const Engagement: FC<EngagementT> = ({
  data,
  color = "#9B51E0",
  callBackForYTicks = (val: any, index: any) => val,
}) => {
  const [currentTab, setCurrentTab] = useState<"comments" | "likes">("likes");
  const { avg_comments, avg_likes } = data;
  const { items } = avg_likes;
  const { t } = useTranslate("crm.detail.card");

  const labels =
    !!items &&
    items.map((s) => Object.keys(s)).reduce((prev, curr) => prev.concat(curr));

  const values =
    !!items &&
    items
      .map((s) => Object.values(s))
      .reduce((prev, curr) => prev.concat(curr));

  const chartData = labels &&
    values && {
      labels: labels.map((l) =>
        capitalizeFirstLetter(getDateTime(l, "month").substring(0, 3))
      ),
      datasets: [
        {
          data: values.map((v) => v),
          borderColor: color,
          pointBackgroundColor: color,
          pointBorderColor: color,
          pointBorderWidth: 0,
          pointHitRadius: 10,
        },
      ],
    };

  return (
    <FlexBox
      customWidth={CustomWidthE.full}
      fullHeight={true}
      flexDirection="column"
    >
      <FlexBox customWidth={CustomWidthE.full} justifyContent="flex-start">
        <Tab
          onClick={() => setCurrentTab("likes")}
          active={currentTab === "likes"}
          dotColor="#BB6BD9"
        >{`${t("others.avg_likes")} (${numberToText(
          Math.floor(avg_likes.total / 1000) * 1000
        )})`}</Tab>
        <Spacer size="big" />
        <Tab
          onClick={() => setCurrentTab("comments")}
          active={currentTab === "comments"}
          dotColor="#2D9CDB"
        >{`${t("others.avg_comments")} (${numberToText(
          Math.floor(avg_comments.total)
        )})`}</Tab>
      </FlexBox>
      <ContentWrapper fullHeight={true} customWidth={CustomWidthE.full}>
        {chartData && (
          <Line
            data={chartData}
            options={getOptionsEngagement(callBackForYTicks)}
          />
        )}
        {(!chartData || currentTab === "comments") && <MissingChart />}
      </ContentWrapper>
    </FlexBox>
  );
};

export default Engagement;
