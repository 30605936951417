import axios from "axios";
import uniqid from "uniqid";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { MediaGroupKindT, MediaGroupT, MediaKindT, MediaT } from "./types";

export type CreateMediaGroupPropsT = {
  participantId: string;
  payload: {
    media_group: {
      kind: MediaGroupKindT;
      temporary_id: string;
      medias_attributes: {
        kind: MediaKindT;
      }[];
    };
  };
};

export type CreateMediaGroupResponseT = {
  media_groups: MediaGroupT;
};

type createMediaGroupT = (
  props: CreateMediaGroupPropsT
) => Promise<CreateMediaGroupResponseT>;
export const createMediaGroup: createMediaGroupT = async ({
  payload,
  participantId,
}) =>
  axios
    .post(
      getApiUrl(ApiRoutesE.REPORTING_MEDIA_GROUPS_POST, participantId),
      payload
    )
    .then((res) => res.data);

export type MediaGroupEmptyPossibleT = {
  id: null;
  temporary_id: string;
  kind: null;
  origin: null;
  medias: MediaT[];
};

type getAddedMediaGroupInitT = (tempId: string) => MediaGroupEmptyPossibleT;
export const getAddedMediaGroupInit: getAddedMediaGroupInitT = (tempId) => {
  return {
    id: null,
    temporary_id: tempId,
    kind: null,
    origin: null,
    medias: [],
  };
};

export type AddedMediaGroupsStateT = Array<
  MediaGroupT | MediaGroupEmptyPossibleT
>;
export const getAddedMediaGroup = (): AddedMediaGroupsStateT => {
  return [getAddedMediaGroupInit(uniqid())];
};
