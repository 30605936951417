import styled from "@emotion/styled";
import _ from "lodash";
import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import FlexBox from "../../../components/FlexBox";
import { AntiShake } from "../../../components/GeneralStyles";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../features/Placeholders/PlaceholderLoader";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { usePagination } from "../../../hooks/usePagination";
import { rem, theme } from "../../../styling/theme";
import { UserRoleT } from "../../types";
import ChatMessage from "../components/ChatMessage";
import SystemMessage from "../components/SystemMessage";
import { MessageT, OfferStateE } from "../types";

export const SCROLL_WRAPPER_ID = "SCROLL_WRAPPER_ID";

const ScrollWrapper = styled(FlexBox)({
  width: "100%",
  height: "100%",
  overflowY: "scroll",
  overflowX: "hidden",
});

const Wrapper = styled.ul({
  width: "100%",
  minHeight: "min-content",
  display: "flex",
  flexGrow: 1,
  flexDirection: "column-reverse",
  listStyle: "none",
  marginBottom: 0,
  paddingLeft: rem(16),
  paddingRight: rem(16),
  ...AntiShake,

  [theme.media.maxSm]: {
    paddingLeft: rem(8),
    paddingRight: rem(12),
  },
});

const NoContentWrapper = styled(FlexBox)({
  width: "100%",
  height: "100%",
});

type MessagesListT = {
  offerId: number;
  offerState: OfferStateE | null;
  userId: number;
  userRole: UserRoleT;
  showedMessages: MessageT[] | null;
  setShowedMessages: Dispatch<SetStateAction<MessageT[] | null>>;
  scrollToBottom: () => void;
};

const MessagesList: FC<MessagesListT> = React.memo(
  ({
    offerId,
    offerState,
    userId,
    userRole,
    showedMessages,
    setShowedMessages,
    scrollToBottom,
  }) => {
    const [focusedMessage, setFocusedMessage] = useState<number | null>(null);
    const [scrollTop, setScrollTop] = useState<number | null>(null);
    const [scrolledToBottom, setScrolledToBottom] = useState(false);

    const MESSAGES_PER_PAGE = 25;

    const { pending, queuedItems, loadItems } = usePagination<MessageT>(
      getApiUrl(ApiRoutesE.OFFER_MESSAGES, offerId),
      MESSAGES_PER_PAGE,
      setShowedMessages,
      "messages",
      offerId
    );

    if (showedMessages) {
      const setScrollTopHandle = useCallback((e): void => {
        setScrollTop(() => e.target.scrollTop);
      }, []);

      const usersMsgs: MessageT[] = showedMessages.filter(
        (message) => !message.is_system
      );

      const systemMsgs: MessageT[] = showedMessages.filter(
        (message) => message.is_system
      );

      const systemMsgsWithLinks = systemMsgs.filter((message) => {
        const { offer } = message;

        if (!!offer) {
          const { links } = offer;

          if (links !== null) {
            return links.length > 0;
          }
        }

        return null;
      });

      useEffect(() => {
        if (showedMessages.length > 0 && !scrolledToBottom) {
          setScrolledToBottom(true);
          scrollToBottom();
        }
      }, [showedMessages]);

      useEffect(() => {
        if (
          scrollTop === 0 &&
          !pending &&
          queuedItems.length > 0 &&
          showedMessages.length > 0
        ) {
          loadItems();
        }
      }, [scrollTop]);

      const newestSystemMsgWithLink =
        systemMsgsWithLinks &&
        systemMsgsWithLinks.length > 0 &&
        systemMsgsWithLinks[0];

      return (
        <ScrollWrapper
          id={SCROLL_WRAPPER_ID}
          justifyContent="flex-start"
          flexDirection="column"
          onScroll={
            useRef(_.throttle((e) => setScrollTopHandle(e), 1000)).current
          }
        >
          <Wrapper>
            {showedMessages.length > 0 && !pending ? (
              showedMessages.map((message, index) => {
                const { is_system } = message;
                const userIsSender = message.sender.id === userId;
                const nextItem = showedMessages[index - 1];
                const lastOfGroup =
                  (nextItem && nextItem.sender.id !== message.sender.id) ||
                  index === 0;

                const links =
                  !!newestSystemMsgWithLink &&
                  !!newestSystemMsgWithLink.offer &&
                  newestSystemMsgWithLink.id === message.id &&
                  newestSystemMsgWithLink.offer.links
                    ? newestSystemMsgWithLink.offer.links
                    : null;

                if (is_system) {
                  return (
                    <SystemMessage
                      key={message.id}
                      message={message}
                      userRole={userRole}
                      links={links}
                      offerId={offerId}
                      offerState={offerState}
                    />
                  );
                }

                return (
                  <ChatMessage
                    key={message.tmp_id ? message.tmp_id : message.id}
                    message={message}
                    userIsSender={userIsSender}
                    isLastMessage={
                      userIsSender && usersMsgs[0].id === message.id
                    }
                    isLastMessageOfGroup={lastOfGroup}
                    focusedMessage={focusedMessage}
                    setFocusedMessage={setFocusedMessage}
                  >
                    {message.body_html}
                  </ChatMessage>
                );
              })
            ) : (
              <NoContentWrapper alignItems="flex-end">
                <PlaceholderLoader
                  direction="column"
                  count={5}
                  type={PlaceholdersTypeE.chat_message}
                />
              </NoContentWrapper>
            )}
          </Wrapper>
        </ScrollWrapper>
      );
    }

    return null;
  }
);

export default MessagesList;
