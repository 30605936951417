import * as React from "react";
import { FC, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Spacer from "../../../components/Spacer";
import { openExternalModal } from "../../../helpers/crossPacks";
import { useTranslate } from "../../../hooks/useTranslate";
import ActionModal from "../components/ActionModal";

type ActionButtonsT = {
  campaignId: number;
  hideFinish?: boolean;
  isAbsolutePosition?: boolean;
};

const ActionButtons: FC<ActionButtonsT> = ({
  campaignId,
  hideFinish = false,
  isAbsolutePosition,
}) => {
  const { t } = useTranslate("brand.campaign");

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onFinishClickHandle = (): void => {
    setModalOpen(true);
  };

  const buttonColor = isAbsolutePosition
    ? ButtonTypeE.darkGrey
    : ButtonTypeE.grey;

  return (
    <>
      <Button
        onClick={() => openExternalModal("modal-trigger-detail")}
        type={buttonColor}
        size={ButtonSizeE.small}
        tooltip={t("actions.show")}
      >
        <Icon icon={IconTypeE.eye} size="small" />
      </Button>
      <Spacer size="mini" />
      <Button
        onClick={() => openExternalModal("modal-trigger-share")}
        type={buttonColor}
        size={ButtonSizeE.small}
        tooltip={t("actions.share")}
      >
        <Icon icon={IconTypeE.share} size="small" />
      </Button>
      {!hideFinish && (
        <>
          <Spacer size="mini" />
          <Button
            onClick={onFinishClickHandle}
            type={buttonColor}
            size={ButtonSizeE.small}
            tooltip={t("actions.finish")}
          >
            <Icon icon={IconTypeE.cross} size="mini" />
          </Button>
        </>
      )}
      <Modal
        visible={!!modalOpen}
        close={() => setModalOpen(false)}
        heading={t("cancel_view.header")}
        renderChildren={(visible, close) => (
          <ActionModal
            campaignId={campaignId}
            visible={visible}
            closeModal={close}
            type={"finish"}
            afterModalClose={() => location.reload()}
          />
        )}
      />
    </>
  );
};

export default ActionButtons;
