import * as React from "react";
import { FC, useEffect, useState } from "react";
import Container from "../../../components/Container";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useFetch } from "../../../hooks/useFetch";
import { CampaignT } from "../../chat/types";
import { CampaignDetailDataPropsT } from "../../types";
import CampaignHeader from "../partials/CampaignHeader";
import OffersTable from "../partials/OffersTable";
import { CampaignPayloadT } from "../types";

const CampaignDetailComponent: FC<CampaignDetailDataPropsT> = ({ data }) => {
  const [campaign, setCampaign] = useState<CampaignT | null>(null);

  const { campaignId } = data;
  const { payload } = useFetch<CampaignPayloadT>(
    getApiUrl(ApiRoutesE.CAMPAIGN, campaignId),
    [],
    "GET"
  );

  useEffect(() => {
    if (payload) {
      const { campaign } = payload;
      setCampaign(campaign);
    }
  }, [payload]);

  return (
    <Container>
      <CampaignHeader campaign={campaign} />
      <OffersTable campaignId={campaignId} barter={!!campaign?.barter} />
    </Container>
  );
};

export default CampaignDetailComponent;
