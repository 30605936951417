import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Spacer from "../../../../components/Spacer";
import { rem, theme } from "../../../../styling/theme";
import { InterestsDatatT } from "../../types";

const ContentWrapper = styled(FlexBox)({
  marginTop: rem(10),
});

const LabelWrapper = styled(FlexBox)({
  padding: `${rem(4)} ${rem(10)}`,
  marginBottom: rem(12),
  marginRight: rem(12),
  borderRadius: rem(4),
  backgroundColor: theme.color.backgroundColor,
  color: theme.color.textGreyColor,
  fontSize: rem(12),
  fontWeight: 600,
});

const Label = styled.span({
  fontSize: rem(15),
  marginBottom: rem(-1),
});

type InterestsExportT = {
  data: InterestsDatatT;
};

const InterestsExport: FC<InterestsExportT> = ({ data }) => {
  const { audience } = data;

  return (
    <FlexBox
      customWidth={CustomWidthE.full}
      fullHeight={true}
      flexDirection="column"
      justifyContent="flex-start"
    >
      <ContentWrapper
        customWidth={CustomWidthE.full}
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {Object.entries(audience).map((item, index) => {
          if (index < 9) {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <LabelWrapper alignItems="flex-end" key={index}>
                <Label>{item[0]}</Label>
                <Spacer size="micro" />
                <span>
                  {item[1] ? `${(Math.round(item[1] * 10) / 10) * 100}%` : "-"}
                </span>
              </LabelWrapper>
            );
          }
          return null;
        })}
      </ContentWrapper>
    </FlexBox>
  );
};

export default InterestsExport;
