import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { InterestsDatatT } from "../../types";
import { Tab } from "../engagement/Engagement";

const ContentWrapper = styled(FlexBox)({
  marginTop: rem(10),
});

const LabelWrapper = styled(FlexBox)({
  padding: `${rem(4)} ${rem(10)}`,
  marginBottom: rem(12),
  marginRight: rem(12),
  borderRadius: rem(4),
  backgroundColor: theme.color.backgroundColor,
  color: theme.color.textGreyColor,
  fontSize: rem(12),
  fontWeight: 600,
});

const Label = styled.span({
  fontSize: rem(15),
  marginBottom: rem(-1),
});

export type InterestsT = {
  data: InterestsDatatT;
};

type InterestsTypeT = "audience" | "influencer";

const Interests: FC<InterestsT> = ({ data }) => {
  const { t } = useTranslate("crm.detail.card");

  const [currentTab, setCurrentTab] = useState<InterestsTypeT>("audience");
  const { audience, influencer } = data;

  return (
    <FlexBox
      customWidth={CustomWidthE.full}
      fullHeight={true}
      flexDirection="column"
      justifyContent="flex-start"
    >
      <FlexBox customWidth={CustomWidthE.full} justifyContent="flex-start">
        <Tab
          onClick={() => setCurrentTab("audience")}
          active={currentTab === "audience"}
        >
          {t("others.audience")}
        </Tab>
        <Spacer size="big" />
        <Tab
          onClick={() => setCurrentTab("influencer")}
          active={currentTab === "influencer"}
        >
          {t("others.influencer")}
        </Tab>
      </FlexBox>
      <ContentWrapper
        customWidth={CustomWidthE.full}
        flexWrap="wrap"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {currentTab === "audience" &&
          Object.entries(audience).map((item, index) => {
            if (index < 9) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <LabelWrapper alignItems="flex-end" key={index}>
                  <Label>{item[0]}</Label>
                  <Spacer size="micro" />
                  <span>
                    {item[1]
                      ? `${(Math.round(item[1] * 10) / 10) * 100}%`
                      : "-"}
                  </span>
                </LabelWrapper>
              );
            }
            return null;
          })}
        {currentTab === "influencer" &&
          Object.entries(influencer).map((item, index) => {
            if (index < 9) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <LabelWrapper alignItems="flex-end" key={index}>
                  <Label>{item[0]}</Label>
                </LabelWrapper>
              );
            }
            return null;
          })}
      </ContentWrapper>
    </FlexBox>
  );
};

export default Interests;
