import styled from "@emotion/styled";
import * as React from "react";
import { FC, SetStateAction, useEffect, useRef, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import { GridWrapper } from "../../../components/GridWrapper";
import Handlers from "../../../components/HorizontalScroll/Handlers";
import Panel from "../../../components/Panel";
import Paragraph from "../../../components/Paragraph";
import SectionLabel from "../../../components/SectionLabel";
import ShowMore from "../../../components/ShowMore";
import ReferencialCard from "../../../features/card/ReferencialCard";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../features/Placeholders/PlaceholderLoader";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useHoriznontalScroll } from "../../../hooks/useHorizontalScroll";
import { usePagination } from "../../../hooks/usePagination";
import { useScroll } from "../../../hooks/useScroll";
import { rem, theme } from "../../../styling/theme";
import { CampaignT } from "../../chat/types";
import { CampaignGroupKeyT, CampaignsMetaT } from "../types";
import CampaignCard from "./CampaignCard";
import CampaignCardSmall from "./CampaignCardSmall";
import { GroupScrollDataT } from "./Marketplace";

const Wrapper = styled(FlexBox)({
  marginBottom: rem(32),
});

const HorizontalWrapper = styled(Panel)({
  padding: rem(24),
  marginBottom: rem(32),
});

const HorizontalList = styled(FlexBox)({
  width: `calc(100% + ${rem(48)})`,
  margin: `0 ${rem(-24)}`,
  position: "relative",

  [theme.media.maxS]: {
    margin: 0,
  },
});

const ScrollWrapper = styled(FlexBox)({
  paddingLeft: rem(24),
  paddingBottom: rem(12),
  overflow: "scroll",
  scrollbarWidth: "none",
  scrollBehavior: "smooth",
});

const NoResultsWrapper = styled(FlexBox)({
  marginLeft: rem(16),
});

type CampaignsGroupT = {
  campaignGroupKey: CampaignGroupKeyT;
  title: string;
  setGroups: React.Dispatch<
    SetStateAction<Record<CampaignGroupKeyT, GroupScrollDataT>>
  >;
  initCampaigns?: CampaignT[];
  perPage?: number;
  popoverText?: string;
  popoverTitle?: string;
  renderAfterLoad?: boolean;
  hideAfterLoad?: boolean;
  horizontal?: boolean;
};

const CampaignsGroup: FC<CampaignsGroupT> = ({
  campaignGroupKey,
  title,
  setGroups,
  initCampaigns,
  perPage = 25,
  popoverText,
  popoverTitle,
  renderAfterLoad = false,
  hideAfterLoad = false,
  horizontal = false,
}) => {
  const CAMPAIGN_API = getApiUrl(ApiRoutesE.CAMPAIGNS);
  const apiUrl = `${CAMPAIGN_API}?type=${campaignGroupKey}`;
  const { currentOffset } = useScroll(2500);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const scrollWrapperRef = useRef<HTMLDivElement>(null);

  const [referencialCardWidth, setReferencialCardWidth] = useState<number>(0);
  const [campaigsCount, setCampaignsCount] = useState<number | null>(null);
  const [campaigns, setCampaigns] = useState<CampaignT[] | null>(
    initCampaigns ? initCampaigns : null
  );

  const { onArrowClickHandle, onScrollHandle, leftArrow, rightArrow } =
    useHoriznontalScroll(scrollWrapperRef, campaigns);

  const { firstLoad, pending, loadItems, queuedItems, metaPayload } =
    usePagination<CampaignT, CampaignsMetaT>(
      apiUrl,
      perPage,
      setCampaigns,
      "campaigns"
    );

  useEffect(() => {
    if (metaPayload) {
      const { count } = metaPayload;
      setCampaignsCount(count);
    }
  }, [metaPayload]);

  useEffect(() => {
    if (wrapperRef.current) {
      const offset = wrapperRef.current.offsetTop | 0;
      const height = wrapperRef.current.clientHeight | 0;

      setGroups((prevState) => {
        return {
          ...prevState,
          ...{
            [campaignGroupKey]: {
              offset,
              height,
              exists: height > 0,
            },
          },
        };
      });
    }
  }, [wrapperRef, campaigns, currentOffset]);

  const afterFirstLoadEmpty =
    !firstLoad && !pending && !campaigns && queuedItems.length === 0;

  const getContent = (): JSX.Element => {
    if (afterFirstLoadEmpty) {
      return (
        <NoResultsWrapper justifyContent="flex-start">
          <Paragraph
            paragraph={"V této sekci se nenachází žádná kampaň"}
            color={theme.color.textGreyColor}
          />
        </NoResultsWrapper>
      );
    }

    if (campaigns && campaigns.length > 0) {
      return (
        <>
          {campaigns.map((campaign) =>
            horizontal ? (
              <CampaignCardSmall key={campaign.id} campaign={campaign} />
            ) : (
              <CampaignCard
                key={campaign.id}
                campaign={campaign}
                cardWidth={rem(referencialCardWidth)}
                isHidden={campaignGroupKey === "hidden"}
              />
            )
          )}
        </>
      );
    }

    return (
      <PlaceholderLoader
        type={
          horizontal
            ? PlaceholdersTypeE.camapign_card_small
            : PlaceholdersTypeE.camapign_card
        }
        count={horizontal ? 5 : perPage}
        direction="row"
        justifyContent="flex-start"
      />
    );
  };

  const getCount = (): string => {
    if (campaigsCount) {
      return ` (${campaigsCount})`;
    }

    return "";
  };

  if (
    (renderAfterLoad &&
      (!campaigns || (!!campaigns && campaigns.length === 0))) ||
    (hideAfterLoad && afterFirstLoadEmpty)
  ) {
    return null;
  }

  if (horizontal) {
    return (
      <HorizontalWrapper
        id={`${campaignGroupKey}_ID`}
        ref={wrapperRef}
        flexDirection="column"
        customWidth={CustomWidthE.full}
        alignItems="flex-start"
      >
        <SectionLabel
          title={`${title}${getCount()}`}
          popoverText={popoverText}
          popoverTitle={popoverTitle}
        />

        <HorizontalList customWidth={CustomWidthE.full}>
          <ScrollWrapper
            justifyContent="stretch"
            alignItems="stretch"
            onScroll={_.debounce((e) => onScrollHandle(e), 500)}
            ref={scrollWrapperRef}
            customWidth={CustomWidthE.full}
          >
            {getContent()}
          </ScrollWrapper>
          <Handlers
            leftArrow={leftArrow}
            rightArrow={rightArrow}
            onArrowClickHandle={onArrowClickHandle}
            scrollWrapper={scrollWrapperRef}
          />
        </HorizontalList>
      </HorizontalWrapper>
    );
  }

  return (
    <Wrapper
      id={`${campaignGroupKey}_ID`}
      ref={wrapperRef}
      flexDirection="column"
      customWidth={CustomWidthE.full}
      alignItems="flex-start"
    >
      <SectionLabel
        title={`${title}${getCount()}`}
        popoverText={popoverText}
        popoverTitle={popoverTitle}
      />

      <GridWrapper>
        <ReferencialCard setReferencialCardWidth={setReferencialCardWidth} />
        {getContent()}
      </GridWrapper>

      <FlexBox customWidth={CustomWidthE.full}>
        <ShowMore loadItems={loadItems} queuedItems={queuedItems} />
      </FlexBox>
    </Wrapper>
  );
};

export default CampaignsGroup;
