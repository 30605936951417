import styled from "@emotion/styled";
import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import Switch from "../../../components/Switch";
import { useScroll } from "../../../hooks/useScroll";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { ActionModalTypeT } from "../components/ActionModal";
import { InboxItemT } from "../types";
import MassActions from "./MassActions";

const HEADER_OFFSET = 72;

const Wrapper = styled(FlexBox)<{ wrapperPosition: "static" | "sticky" }>(
  ({ wrapperPosition }) => ({
    position: wrapperPosition,
    top: rem(HEADER_OFFSET),
    paddingLeft: rem(16),
    paddingRight: rem(16),
    backgroundColor: theme.color.whiteColor,
    border: `1px solid ${theme.color.greyColor}`,
    borderTop:
      wrapperPosition === "static"
        ? "none"
        : `1px solid ${theme.color.greyColor}`,
    borderTopLeftRadius: wrapperPosition === "static" ? rem(8) : 0,
    borderTopRightRadius: wrapperPosition === "static" ? rem(8) : 0,
    zIndex: 3,
  })
);

type TemporaryRowT = {
  selectedItems: InboxItemT[];
  firstLoad: boolean;
  pending: boolean;
  campaignId: number;
  tableView: boolean;
  setReFetchHelper: Dispatch<SetStateAction<number>>;
  setTableView: Dispatch<SetStateAction<boolean>>;
  barter: boolean;
};

const TemporaryRow: FC<TemporaryRowT> = ({
  selectedItems,
  firstLoad,
  pending,
  campaignId,
  tableView,
  setReFetchHelper,
  setTableView,
  barter,
}) => {
  const [position, setPosition] = useState<"static" | "sticky">("static");
  const [offsetTop, setOffsetTop] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<ActionModalTypeT | undefined>(
    undefined
  );

  const { t } = useTranslate("influencer.market.switch");
  const { currentOffset } = useScroll(0);
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (offsetTop < currentOffset && position === "static") {
      setPosition("sticky");
      return;
    }

    if (offsetTop < currentOffset && position === "sticky") {
      return;
    }

    setPosition("static");
  }, [currentOffset]);

  useEffect(() => {
    setTimeout(() => {
      if (wrapper.current && !offsetTop) {
        setOffsetTop(wrapper.current.offsetTop - HEADER_OFFSET);
      }
    }, 300);
  }, [wrapper.current]);

  return (
    <Wrapper
      customWidth={CustomWidthE.full}
      justifyContent="space-between"
      ref={wrapper}
      wrapperPosition={position}
    >
      <MassActions
        firstLoad={firstLoad}
        campaignId={campaignId}
        selectedItems={selectedItems}
        setReFetchHelper={setReFetchHelper}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        barter={barter}
      />
      <Switch
        leftOption={
          <FlexBox cursor="pointer">
            <Icon icon={IconTypeE.list} />
            <Spacer size="micro" />
            <Paragraph
              paragraph={t("table")}
              paragraphSize="small"
              fontWeight={600}
              cursor="inherit"
            />
          </FlexBox>
        }
        rightOption={
          <FlexBox cursor="pointer">
            <Icon icon={IconTypeE.tiles} />
            <Spacer size="micro" />
            <Paragraph
              paragraph={t("tiles")}
              paragraphSize="small"
              fontWeight={600}
              cursor="inherit"
            />
          </FlexBox>
        }
        state={tableView}
        setState={setTableView}
        sideWidth={100}
        disable={pending}
      />
    </Wrapper>
  );
};

export default TemporaryRow;
