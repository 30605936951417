import { _o } from "./helpers";

type GraphDirectionT = "height" | "width";

type updateGraphElementT = (
  element: HTMLDivElement,
  direction: GraphDirectionT
) => void;
const updateGraphElement: updateGraphElementT = (element, direction) => {
  const value = element.dataset.value ? parseFloat(element.dataset.value) : 0;
  const iterationValue = parseFloat((value / 10).toFixed(2));

  const timer = setInterval(() => {
    const current = element.dataset.current
      ? parseFloat(element.dataset.current)
      : 0;
    const newValue = current + iterationValue;
    const child = element.firstElementChild as HTMLElement;

    element.dataset.current = newValue.toFixed(2);
    element.dataset.viewed = newValue.toFixed(1);
    child.style[direction] = `${newValue}%`;

    if (parseFloat(element.dataset.current) >= value) {
      clearInterval(timer);
    }
  }, 100);
};

const graphOnLoadHandle = (): void => {
  const graphSizeOnLoadElements =
    document.getElementsByClassName("size-onload");

  _o(graphSizeOnLoadElements).forEach((graphItem) => {
    const graph = graphItem as HTMLDivElement;
    const { direction } = graph.dataset;

    updateGraphElement(graph, direction as GraphDirectionT);
  });
};

type graphOnLoadT = () => void;
export const graphOnLoad: graphOnLoadT = () => {
  graphOnLoadHandle();

  document.addEventListener("GRAPH_ONLOAD", graphOnLoadHandle);
};
