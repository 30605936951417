const infoMessageHandle = (input) => {
  const productPriceInfo = document.getElementById("campaign-product-price-info");
  const productPriceNumber = document.getElementById("campaign-product-price-number");
  const currency = window.CAMPAIGN_CURRENCY;
  const value = parseInt(input.value);
  const currencyValue = currency == "EUR" ? value*26 : value
  const commision = getCommision(currencyValue);

  productPriceNumber.innerText = commision;

  productPriceInfo.classList.remove("hidden");

  if(isNaN(currencyValue) || currencyValue < 2_000) {
    productPriceInfo.classList.add("hidden");
  };
};

const getCommision = (value) => {
  switch(true) {
    case value >= 2_000 && value <= 10_000:
      return 25;
    case value > 10_000 && value <= 20_000:
      return 20;
    case value > 20_000 && value <= 40_000:
      return 18;
    case value > 40_000 && value <= 80_000:
      return 15;
    case value > 80_000:
      return 10;
    default:
      return "";
  };
};

export const productPriceOnChange = () => {
  const productPriceWrapper = document.getElementById("campaign-product-price");

  if(productPriceWrapper) {
    const input = productPriceWrapper.getElementsByTagName("INPUT")[0];
    
    input.addEventListener("input", () => infoMessageHandle(input));
    infoMessageHandle(input);
  }
}
