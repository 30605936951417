import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { rem, theme } from "../styling/theme";
import FlexBox from "./FlexBox";
import Icon, { IconRotateT, IconSizeT } from "./Icon";
import Paragraph, { ParagraphSizeT } from "./Paragraph";
import Spacer from "./Spacer";

type BlueLinkStyleT = {
  marginTop: string;
  hideBorder: boolean;
};

const StyledBlueLink = styled(FlexBox)<BlueLinkStyleT>(
  ({ marginTop, hideBorder }) => ({
    display: "inline-block",
    width: "max-content",
    marginTop,
    color: theme.color.primaryColor,
    cursor: "pointer",
    borderBottom: hideBorder ? "none" : "1px solid",
    borderColor: "transparent",
    transition: "border 0.4s",
    transitionTimingFunction: theme.timing.default,

    ["&:hover"]: {
      borderColor: "inherit",
    },
  })
);

type BlueLinkT = {
  text: string;
  onClick?: () => any;
  icon?: IconTypeE;
  iconSize?: IconSizeT;
  iconPosition?: "before" | "after";
  iconColor?: string;
  rotate?: IconRotateT;
  href?: string;
  target?: "_blank" | "_self";
  marginTop?: string;
  paragraphSize?: ParagraphSizeT;
  hideBorder?: boolean;
  color?: string;
};

const BlueLink: FC<BlueLinkT> = ({
  text,
  onClick,
  icon,
  iconSize = "small",
  iconPosition = "before",
  iconColor = theme.color.primaryColor,
  rotate,
  href,
  target = "_self",
  marginTop = rem(4),
  paragraphSize = "default",
  hideBorder = false,
  color = theme.color.primaryColor,
}) => {
  const getContent = (): JSX.Element => (
    <FlexBox>
      {icon && iconPosition === "before" && (
        <>
          <Icon icon={icon} size={iconSize} color={iconColor} rotate={rotate} />
          <Spacer size="micro" />
        </>
      )}
      <Paragraph
        paragraph={text}
        paragraphSize={paragraphSize}
        color={color}
        fontWeight={500}
        cursor="pointer"
      />
      {icon && iconPosition === "after" && (
        <>
          <Spacer size="micro" />
          <Icon icon={icon} size={iconSize} color={iconColor} rotate={rotate} />
        </>
      )}
    </FlexBox>
  );

  if (href) {
    return (
      <StyledBlueLink hideBorder={hideBorder} marginTop={marginTop}>
        <a href={href} target={target}>
          {getContent()}
        </a>
      </StyledBlueLink>
    );
  }

  return (
    <StyledBlueLink
      hideBorder={hideBorder}
      marginTop={marginTop}
      onClick={onClick}
    >
      {getContent()}
    </StyledBlueLink>
  );
};

export default BlueLink;
