import styled from "@emotion/styled";
import * as React from "react";
import { FC, ReactNode, useEffect } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { useResponsive } from "../hooks/useResponsive";
import { useScrollBlock } from "../hooks/useScrollBlock";
import { breakpoints, HEADER_HEIGHT, rem, theme } from "../styling/theme";
import CircleButton from "./CircleButton";
import FlexBox from "./FlexBox";
import Heading, { HeadingTypeT } from "./Heading";
import Icon from "./Icon";
import LoaderComponent from "./Loader";
import Paragraph from "./Paragraph";
import Spacer from "./Spacer";

type VisibleStyleT = {
  visible: boolean;
};

type TypeStyleT = {
  side: boolean;
};

type SlimStyleT = {
  slim: boolean;
};

type ModalContentStyleT = {
  withHeading?: boolean;
  padding?: string;
};

const Wrapper = styled.div<VisibleStyleT>(({ visible }) => ({
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 25,
  overflow: "scroll",
  scrollbarWidth: "none",
  visibility: visible ? "visible" : "hidden",
  opacity: visible ? 1 : 0,
  transition: "opacity 0.4s, visibility 0.4s",
  transitionTimingFunction: theme.timing.default,

  [theme.media.maxMd]: {
    paddingTop: rem(HEADER_HEIGHT),
  },
}));

const CloseWrapper = styled.div<TypeStyleT>(({ side }) => ({
  position: "absolute",
  right: side ? rem(30) : rem(-65),
  top: side ? rem(25) : 0,
  width: rem(50),
  height: side ? "auto" : "100%",

  [theme.media.maxMd]: {
    right: rem(8),
    top: rem(8),
    height: "auto",
  },
}));

const CloseButton = styled(FlexBox)({
  position: "sticky",
  top: rem(30),
  backgroundColor: theme.color.whiteColor,
  width: rem(50),
  height: rem(50),
  borderRadius: rem(8),
  cursor: "pointer",
});

const Overlay = styled.div({
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  backgroundColor: theme.color.overlayColor,
  zIndex: 20,
});

const ModalBody = styled.div<TypeStyleT & SlimStyleT>(({ side, slim }) => ({
  position: side ? "absolute" : "relative",
  width: "100%",
  top: side ? 0 : "auto",
  right: side ? 0 : "auto",
  bottom: side ? 0 : "auto",
  maxWidth: side ? rem(550) : slim ? rem(460) : rem(750),
  margin: side ? 0 : `${rem(50)} auto ${rem(32)} auto`,
  backgroundColor: theme.color.whiteColor,
  borderTopLeftRadius: rem(8),
  borderBottomLeftRadius: rem(8),
  borderTopRightRadius: side ? 0 : rem(8),
  borderBottomRightRadius: side ? 0 : rem(8),
  overflow: side ? "scroll" : "visible",
  zIndex: 21,

  [theme.media.maxMd]: {
    position: "absolute",
    margin: 0,
    maxWidth: "100%",
    width: "100%",
    height: "auto",
    minHeight: "30%",
    maxHeight: "80%",
    bottom: 0,
    left: 0,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    overflow: "scroll",
  },
}));

const ModalHead = styled.div({
  padding: `${rem(40)} ${rem(45)} ${rem(22)}`,

  [theme.media.maxMd]: {
    padding: `${rem(32)} ${rem(32)} ${rem(20)} ${rem(32)}`,
  },
});

const ModalContent = styled.div<ModalContentStyleT>(
  ({ withHeading = true, padding }) => ({
    padding: padding
      ? padding
      : `${rem(withHeading ? 22 : 40)} ${rem(45)} ${rem(40)}`,
    borderTop: withHeading ? `1px solid ${theme.color.greyColor}` : "none",

    [theme.media.maxMd]: {
      padding: padding ? padding : `${rem(20)} ${rem(32)} ${rem(52)}`,
    },
  })
);

const MessageWrapper = styled(FlexBox)({
  minHeight: rem(250),
});

export const getPendingModalContent = (): JSX.Element => {
  return (
    <MessageWrapper flexDirection="column">
      <LoaderComponent size={90} />
      <Spacer size="big" direction="vertical" />
      <Paragraph
        paragraph={"Chvíli počkejte..."}
        color={theme.color.textGreyColor}
      />
    </MessageWrapper>
  );
};

type getAfterActionModalContentT = (
  payload: Response | undefined,
  error: {} | undefined,
  successMessage: string,
  errorMessage: string,
  errorSubMessage?: string
) => ReactNode;
export const getAfterActionModalContent: getAfterActionModalContentT = (
  payload,
  error,
  successMessage,
  errorMessage,
  errorSubMessage
) => {
  if (error) {
    return (
      <MessageWrapper flexDirection="column">
        <CircleButton
          icon={IconTypeE.sadDoc}
          iconSize="monster"
          iconColor={theme.color.whiteColor}
          background={theme.color.greyColor}
          disabled={true}
        />
        <Spacer size="big" direction="vertical" />
        <Paragraph paragraphSize="large" paragraph={errorMessage} />
        {errorSubMessage && (
          <>
            <Spacer size="small" direction="vertical" />
            <Paragraph
              paragraphSize="big"
              paragraph={errorSubMessage}
              color={theme.color.textGreyColor}
            />
          </>
        )}
      </MessageWrapper>
    );
  }

  if (payload) {
    return (
      <MessageWrapper flexDirection="column">
        <CircleButton
          icon={IconTypeE.checkCircle}
          iconColor={theme.color.whiteColor}
          iconSize="monster"
          disabled={true}
        />
        <Spacer size="big" direction="vertical" />
        <Paragraph paragraphSize="large" paragraph={successMessage} />
      </MessageWrapper>
    );
  }

  return getPendingModalContent();
};

type ModalTypeT = "center" | "right";
type ModalT = {
  visible: boolean;
  close: () => void;
  renderChildren: (visible: boolean, close: () => void) => ReactNode;
  heading?: string;
  subHeading?: string;
  contentPadding?: string;
  side?: ModalTypeT;
  slim?: boolean;
  showCloseButton?: boolean;
};

const Modal: FC<ModalT> = ({
  visible,
  close,
  renderChildren,
  heading,
  subHeading,
  contentPadding,
  side = "center",
  slim = false,
  showCloseButton = true,
}) => {
  const { blockScroll, allowScroll } = useScrollBlock();
  const { breakePointCondition } = useResponsive(breakpoints.md);

  type onKeyDownT = (e: KeyboardEvent) => void;
  const onKeyDown: onKeyDownT = (e) => {
    const { key } = e;

    if (key == "Escape") {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  useEffect(() => {
    if (!visible) {
      allowScroll();

      return;
    }

    blockScroll();
  }, [visible]);

  return (
    <Wrapper visible={visible}>
      <Overlay onClick={() => close()} />
      <ModalBody side={side === "right"} slim={slim}>
        {heading && (
          <ModalHead>
            <Heading
              heading={heading}
              headingType={HeadingTypeT.h2}
              spaceBottom="micro"
            />
            {subHeading && (
              <Paragraph
                paragraph={subHeading}
                color={theme.color.textLightGreyColor}
                fontWeight={400}
              />
            )}
          </ModalHead>
        )}
        <ModalContent padding={contentPadding} withHeading={!!heading}>
          {renderChildren(visible, close)}
        </ModalContent>
        {showCloseButton && (
          <CloseWrapper side={side === "right"}>
            <CloseButton onClick={close}>
              <Icon
                icon={IconTypeE.cross}
                size={breakePointCondition ? "small" : "mini"}
              />
            </CloseButton>
          </CloseWrapper>
        )}
      </ModalBody>
    </Wrapper>
  );
};

export default Modal;
