import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  getIllustration,
  IllustrationTypeE,
} from "../../../assets/illustrations/Illustrations";
import Button from "../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Input from "../../../components/Input";
import { getPendingModalContent } from "../../../components/Modal";
import { IllustrationWrapper } from "../../../components/NoResults";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useFetch } from "../../../hooks/useFetch";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { IgHandlePayloadT } from "../types";
import { NewBrandResponseT } from "./AddBrand";

const StyledInput = styled(Input)({
  width: "100%",
  backgroundColor: theme.color.backgroundColor,
  borderRadius: rem(3),
});

const ResponseWrapper = styled(FlexBox)({
  padding: rem(20),
});

type BrandModalT = {
  status: NewBrandResponseT;
  setStatus: Dispatch<SetStateAction<NewBrandResponseT>>;
  closeModal: () => void;
};

const BrandModal: FC<BrandModalT> = ({ status, setStatus, closeModal }) => {
  const [brandText, setBrandText] = useState<string>("");

  const { t } = useTranslate("crm.detail.brand_affinity.add");

  const { call, pending, payload } = useFetch<IgHandlePayloadT>(
    `${getApiUrl(ApiRoutesE.IG_HANDLE)}?name=${brandText}`,
    undefined,
    "POST"
  );

  useEffect(() => {
    if (payload) {
      const { success } = payload;

      setStatus(success ? "done" : "isTracking");

      setTimeout(() => {
        closeModal();
        setBrandText("");
      }, 3000);
    }
  }, [payload]);

  const getContent = (): JSX.Element => {
    if (!status) {
      return (
        <FlexBox flexDirection="column" alignItems="stretch">
          <Heading heading={t("action")} headingType={HeadingTypeT.h2} />
          <Paragraph
            paragraph={t("modal_text")}
            color={theme.color.textGreyColor}
          />
          <Spacer direction="vertical" size="big" />
          <Paragraph paragraph={t("brand_name")} fontWeight={600} />
          <Spacer direction="vertical" size="micro" />
          <FlexBox>
            {"@"}
            <Spacer size="micro" />
            <StyledInput
              value={brandText}
              setValue={setBrandText}
              placeholder={"lafluenceapp"}
              clearButton={true}
            />
          </FlexBox>
          <Spacer direction="vertical" size="micro" />
          <Paragraph paragraphSize="small" paragraph={t("instagram_text")} />
          <Spacer direction="vertical" size="big" />
          <Button
            disabled={pending || brandText.length < 3}
            onClick={call}
            customWidth={CustomWidthE.full}
          >
            {t("button")}
          </Button>
        </FlexBox>
      );
    }

    if (status === "isTracking") {
      return (
        <ResponseWrapper flexDirection="column">
          <IllustrationWrapper size={rem(180)}>
            {getIllustration(IllustrationTypeE.yellow_books)}
          </IllustrationWrapper>
          <Paragraph
            paragraph={t("already_tracking.heading")}
            align="center"
            paragraphSize="large"
            fontWeight={600}
          />
          <Spacer direction="vertical" />
          <Paragraph align="center" paragraph={t("already_tracking.text")} />
        </ResponseWrapper>
      );
    }

    if (status === "done") {
      return (
        <ResponseWrapper flexDirection="column">
          <IllustrationWrapper size={rem(180)}>
            {getIllustration(IllustrationTypeE.success_books)}
          </IllustrationWrapper>
          <Paragraph
            paragraph={t("success.heading")}
            align="center"
            paragraphSize="large"
            fontWeight={600}
          />
          <Spacer direction="vertical" />
          <Paragraph align="center" paragraph={t("success.text")} />
        </ResponseWrapper>
      );
    }

    return getPendingModalContent();
  };

  return getContent();
};

export default BrandModal;
