import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Avatar from "../../../components/Avatar";
import FlexBox from "../../../components/FlexBox";
import { AntiShake } from "../../../components/GeneralStyles";
import Icon from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { rem, theme } from "../../../styling/theme";
import { MessageT } from "../types";
import MessageBubbleWrapper from "./MessageBubbleWrapper";
import MessageFiles from "./MessageFiles";

type OwnMessageStyleT = {
  ownMessage: boolean;
};

type WrapperStyleT = {
  lastOfGroup: boolean;
} & OwnMessageStyleT;

const Wrapper = styled(FlexBox)<WrapperStyleT>(
  ({ ownMessage, lastOfGroup }) => ({
    position: "relative",
    maxWidth: "75%",
    width: "75%",
    alignSelf: ownMessage ? "flex-end" : "flex-start",
    marginBottom: lastOfGroup ? rem(16) : 0,
    ...AntiShake,

    [theme.media.maxSm]: {
      maxWidth: "85%",
      width: "85%",
    },
  })
);

const AntiShakeFlexBox = styled(FlexBox)({
  ...AntiShake,
});

const Message = styled.div<OwnMessageStyleT>(({ ownMessage }) => ({
  padding: `${rem(8)} ${rem(12)}`,
  backgroundColor: ownMessage
    ? theme.color.primaryColor
    : theme.color.lighterBlueColor,
  wordBreak: "break-word",
  zIndex: 2,
  borderRadius: rem(16),
  ...AntiShake,

  p: {
    margin: `0 0 ${rem(4)} 0`,

    "&:last-child": {
      marginBottom: 0,
    },

    "&:empty": {
      display: "none",
    },
  },
}));

const AvatarWrapper = styled.div({
  marginBottom: rem(3),
});

const SentIndicator = styled(FlexBox)<{ filled: boolean }>(({ filled }) => ({
  position: "absolute",
  right: rem(-12),
  bottom: rem(3),
  borderRadius: "50%",
  border: `2px solid ${theme.color.primaryColor}`,
  backgroundColor: filled ? theme.color.primaryColor : "transparent",

  [theme.media.maxSm]: {
    right: rem(-10),
    bottom: rem(3),
    width: rem(14),
    height: rem(14),
  },
}));

type ChatMessageT = {
  message: MessageT;
  userIsSender: boolean;
  isLastMessage: boolean;
  isLastMessageOfGroup: boolean;
  focusedMessage: number | null;
  setFocusedMessage: Dispatch<SetStateAction<number | null>>;
};

const ChatMessage: FC<ChatMessageT> = React.memo(
  ({
    message,
    userIsSender,
    isLastMessage,
    isLastMessageOfGroup,
    focusedMessage,
    setFocusedMessage,
  }) => {
    const { body, body_html, created_at, read_at, id, message_files, sender } =
      message;
    const { avatar_url, nick_or_name } = sender;

    const messageIsEmpty = body_html == "<p></p>";
    const messageFilesExists =
      message_files.filter((file) => file.file_url).length > 0;

    if (messageIsEmpty && !messageFilesExists) {
      return null;
    }

    const paragraphs =
      body && body.split("\n").filter((str) => !!str && str.length > 0);

    return (
      <Wrapper
        ownMessage={userIsSender}
        alignItems="flex-end"
        justifyContent="flex-start"
        flexDirection={userIsSender ? "row-reverse" : "row"}
        lastOfGroup={isLastMessageOfGroup}
      >
        {!userIsSender &&
          (isLastMessageOfGroup && !!nick_or_name ? (
            <AvatarWrapper>
              <Avatar src={avatar_url} name={nick_or_name} />
            </AvatarWrapper>
          ) : (
            <Spacer size={28} />
          ))}
        {userIsSender && isLastMessage && !read_at && (
          <SentIndicator filled={!!created_at}>
            <Icon
              icon={IconTypeE.check}
              size="micro"
              color={created_at ? theme.color.whiteColor : "transparent"}
            />
          </SentIndicator>
        )}
        <Spacer size="micro" />
        <MessageBubbleWrapper
          read={read_at}
          created={created_at}
          ownMessage={userIsSender}
          isLastMessage={isLastMessage}
          focused={focusedMessage === id}
        >
          <AntiShakeFlexBox
            flexDirection="column"
            alignItems={userIsSender ? "flex-end" : "flex-start"}
          >
            {!messageIsEmpty && (
              <Message
                ownMessage={userIsSender}
                onClick={() => {
                  setFocusedMessage((prevState) =>
                    prevState === id ? null : id
                  );
                }}
              >
                {Array.isArray(paragraphs) &&
                  paragraphs.map((paragraph, i) => (
                    <Paragraph
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                      paragraph={paragraph}
                      color={
                        userIsSender
                          ? theme.color.whiteColor
                          : theme.color.textColor
                      }
                    />
                  ))}
              </Message>
            )}
            {messageFilesExists && <MessageFiles files={message_files} />}
          </AntiShakeFlexBox>
        </MessageBubbleWrapper>
      </Wrapper>
    );
  }
);

export default ChatMessage;
