import styled from "@emotion/styled";
import { cs } from "date-fns/locale";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import VisibleWrapper from "../../../components/VisibleWrapper";
import {
  getDateForRangeDayFromToday,
  getDateTime,
} from "../../../helpers/date";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { CalendarWrapper } from "../../../ui/CalendarWrapper";
import FilterSection from "../ui/FilterSection";

const IconWrapper = styled(FlexBox)<{ left?: boolean; right?: boolean }>(
  ({ left, right }) => ({
    position: "absolute",
    left: left ? rem(12) : "auto",
    right: right ? rem(12) : "auto",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
  })
);

const DateWrapper = styled(FlexBox)({
  borderRadius: rem(4),
  backgroundColor: theme.color.backgroundColor,
});

const DateTrigger = styled(FlexBox)({
  padding: `${rem(12)} ${rem(44)}`,
  cursor: "pointer",
});

const StyledCalendarWrapper = styled(CalendarWrapper)({
  top: rem(-4),
  border: `1px solid ${theme.color.backgroundColor}`,
  borderTopWidth: rem(4),
  borderRadius: rem(4),
});

export type DateStateT = {
  startDate: Date;
  endDate: Date;
};

type FilterDateT = {
  dateState: DateStateT | undefined;
  setDateState: Dispatch<SetStateAction<DateStateT | undefined>>;
};

const FilterDate: FC<FilterDateT> = ({ dateState, setDateState }) => {
  const [pickerVisible, setPickerVisible] = useState(false);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [pickedDaysCount, setPickedDaysCount] = useState(0);

  const { t } = useTranslate("brands.reportings.participants.filter");

  const setStateHandle = (startDate?: Date, endDate?: Date): void => {
    if (startDate) {
      setStartDate(startDate);
    }

    if (endDate) {
      setEndDate(endDate);
    }
  };

  const clearPickerOptions = (): void => {
    setPickedDaysCount(0);
    setPickerVisible(false);
  };

  const clearLocalState = (): void => {
    setStartDate(undefined);
    setEndDate(undefined);
  };

  useEffect(() => {
    if (dateState) {
      setStartDate(new Date(dateState.startDate));
      setEndDate(new Date(dateState.endDate));
    }
  }, [dateState]);

  useEffect(() => {
    if (startDate && endDate && pickedDaysCount === 2) {
      clearPickerOptions();
      setDateState({ startDate, endDate });
    }
  }, [pickedDaysCount]);

  const dateRange = {
    startDate: new Date(startDate || getDateForRangeDayFromToday(-3)),
    endDate: new Date(endDate || getDateForRangeDayFromToday(3)),
    key: "selection",
  };

  return (
    <FilterSection label={t("date")} labelDescription={`- ${t("date_after")}`}>
      <FlexBox
        customWidth="100%"
        justifyContent="space-between"
        flexDirection="column"
      >
        <DateWrapper
          customWidth={CustomWidthE.full}
          position="relative"
          justifyContent="flex-start"
        >
          <DateTrigger
            onClick={() => setPickerVisible((prevState) => !prevState)}
          >
            <IconWrapper left={true}>
              <Icon icon={IconTypeE.calendar} />
            </IconWrapper>
            {startDate && endDate ? (
              <Paragraph
                paragraph={`${getDateTime(
                  `${startDate}`,
                  "shortDate"
                )} - ${getDateTime(`${endDate}`, "shortDate")}`}
              />
            ) : (
              <Paragraph
                color={theme.color.textGreyColor}
                paragraph={t("date_range")}
              />
            )}
          </DateTrigger>
          <IconWrapper
            right={true}
            onClick={() => {
              clearPickerOptions();
              clearLocalState();
              setDateState(undefined);
            }}
          >
            <Icon
              visible={!!(startDate && endDate)}
              icon={IconTypeE.crossCircle}
              color={theme.color.textGreyColor}
            />
          </IconWrapper>
        </DateWrapper>
        <VisibleWrapper visible={pickerVisible} type="roll">
          <StyledCalendarWrapper
            position="relative"
            customWidth={CustomWidthE.full}
          >
            <DateRange
              locale={cs}
              showDateDisplay={false}
              ranges={[dateRange]}
              onChange={(dateRange) => {
                setPickedDaysCount((prevState) => (prevState === 0 ? 1 : 2));
                setStateHandle(
                  dateRange.selection.startDate,
                  dateRange.selection.endDate
                );
              }}
            />
          </StyledCalendarWrapper>
        </VisibleWrapper>
      </FlexBox>
    </FilterSection>
  );
};

export default FilterDate;
