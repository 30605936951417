import styled from "@emotion/styled";
import * as React from "react";
import { FC, ReactNode, useState } from "react";
import FlexBox, { CustomWidthE } from "../../components/FlexBox";
import { rem, theme } from "../../styling/theme";

type WrapperStyleT = {
  selected: boolean;
  hover: boolean;
  last: boolean;
  temporaryHide: boolean;
};

const Wrapper = styled(FlexBox)<WrapperStyleT>(
  ({ selected, hover, temporaryHide, last }) => ({
    backgroundColor: selected
      ? theme.color.lighterBlueColor
      : hover
      ? theme.color.backgroundColor
      : "transparent",
    borderBottom:
      temporaryHide || last ? "none" : `1px solid ${theme.color.greyColor}`,
    borderBottomLeftRadius: last ? rem(8) : 0,
    borderBottomRightRadius: last ? rem(8) : 0,
    transition: "all 0.3s",
    transitionTimingFunction: theme.timing.default,
    visibility: temporaryHide ? "hidden" : "visible",
    height: rem(70),
    maxHeight: temporaryHide ? 0 : rem(70),
    overflow: temporaryHide ? "hidden" : "visible",
  })
);

type TableItemWrapperT = {
  renderChildren: (hover: boolean) => ReactNode;
  selected?: boolean;
  temporaryHide?: boolean;
  first?: boolean;
  last?: boolean;
};

const TableItemWrapper: FC<TableItemWrapperT> = React.memo(
  ({
    selected = false,
    temporaryHide = false,
    last = false,
    renderChildren,
  }) => {
    const [hover, setHover] = useState(false);

    return (
      <Wrapper
        justifyContent="flex-start"
        alignItems="stretch"
        customWidth={CustomWidthE.full}
        selected={selected}
        last={last}
        hover={hover}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        temporaryHide={temporaryHide}
      >
        {renderChildren(hover)}
      </Wrapper>
    );
  }
);

export default TableItemWrapper;
