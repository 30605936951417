import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import Button from "../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import { getAfterActionModalContent } from "../../../components/Modal";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useFetch } from "../../../hooks/useFetch";
import { rem, theme } from "../../../styling/theme";
import MessageInput from "../../chat/components/MessageInput";
import { InboxItemT } from "../types";

const StyledMessageInput = styled(MessageInput)({
  border: `1px solid ${theme.color.greyColor}`,
  padding: rem(8),
  borderRadius: rem(8),
});

type ContentOptionObjectT = {
  apiUrlKey: ApiRoutesE;
  initMessage: string;
  button: string;
  successMessage: string;
};

export type ActionModalTypeT = "message" | "decline" | "approve" | "finish";

const contentOption: Record<ActionModalTypeT, ContentOptionObjectT> = {
  message: {
    apiUrlKey: ApiRoutesE.MASS_MESSAGE,
    initMessage: "",
    button: "Odoslat hromadnou zprávu",
    successMessage: "Hromadná zpráva úspěšně odeslána",
  },
  approve: {
    apiUrlKey: ApiRoutesE.MASS_APPROVE,
    initMessage: "",
    button: "Odeslat přijmout nabídky",
    successMessage:
      "Hromadné přijetí nabídek úspěšně dokončeno, zpráva byla odeslána.",
  },
  decline: {
    apiUrlKey: ApiRoutesE.MASS_DECLINE,
    initMessage:
      "Děkujeme za Vaši nabídku, ale pro tuhle kampaň jsme se rozhodli pro jiné spolupráce. Hezký den a budeme se těšit na budoucí případnou spolupráci!",
    button: "Odeslat a odmítnout nabídky",
    successMessage:
      "Hromadné odmítnutí nabídek úspěšně dokončeno, zpráva byla odeslána.",
  },
  finish: {
    apiUrlKey: ApiRoutesE.CAMPAIGN_FINISH,
    initMessage:
      "Děkujeme za Vaši nabídku, ale pro tuhle kampaň jsme se rozhodli pro jiné spolupráce. Hezký den a budeme se těšit na budoucí případnou spolupráci!",
    button: "Ukončit a odeslat hromadně všem aktivním návrhům",
    successMessage:
      "Hromadné odmítnutí nabídek úspěšně dokončeno, zpráva byla odeslána a kampaň ukončena.",
  },
};

type ActionModalT = {
  campaignId: number;
  visible: boolean;
  selectedItems?: InboxItemT[];
  closeModal: () => void;
  type?: ActionModalTypeT;
  afterModalClose?: () => void;
};

const ActionModal: FC<ActionModalT> = React.memo(
  ({
    campaignId,
    selectedItems,
    closeModal,
    visible,
    type = "message",
    afterModalClose,
  }) => {
    const [message, setMessage] = useState<string>(
      contentOption[type].initMessage
    );
    const [messageInputHeight, setMessageInputHeight] = useState<number>(150);
    const [sentMessage, setSentMessage] = useState(false);

    const selectedNames =
      selectedItems && selectedItems.map((item) => item.user.nick_or_name);
    const selectedIds = selectedItems && selectedItems.map((item) => item.id);

    const body = {
      offer_ids: selectedIds && selectedIds.join(),
      message,
    };

    const { call, pending, payload, error } = useFetch<Response>(
      getApiUrl(contentOption[type].apiUrlKey, campaignId),
      undefined,
      "PUT",
      body
    );

    const sendDisable = !(message.length > 0);

    useEffect(() => {
      if (payload || error) {
        setTimeout(() => {
          closeModal();

          if (afterModalClose) {
            afterModalClose();
          }
        }, 3000);
      }
    }, [payload, error]);

    useEffect(() => {
      // removing modal state after modal reopen
      if (!visible) {
        setMessage(contentOption[type].initMessage);
        setSentMessage(false);
      }
    }, [visible]);

    useEffect(() => {
      // removing modal state after modal reopen
      if (type) {
        setMessage(contentOption[type].initMessage);
        setSentMessage(false);
      }
    }, [type]);

    const sendMessage = (): void => {
      call();
      setSentMessage(true);
    };

    return (
      <FlexBox
        flexDirection="column"
        justifyContent="stretch"
        alignItems="stretch"
      >
        {sentMessage ? (
          getAfterActionModalContent(
            payload,
            error,
            contentOption[type].successMessage,
            "Vyskytla se chyba, skuste to prosím znovu.",
            "Skuste to pozdějc prosím znovu."
          )
        ) : (
          <>
            {selectedNames && (
              <Paragraph
                color={theme.color.textGreyColor}
                paragraph={`Zpráva bude odoslána: (${
                  selectedNames.length
                }) - ${selectedNames.join(", ")}`}
                truncate={180}
              />
            )}
            <Spacer size="big" direction="vertical" />
            <StyledMessageInput
              newMessage={message}
              setNewMessage={setMessage}
              height={messageInputHeight}
              setHeight={setMessageInputHeight}
            />
            <Spacer size="big" direction="vertical" />
            <Button
              customWidth={CustomWidthE.full}
              disabled={pending || sendDisable}
              onClick={sendMessage}
            >
              {contentOption[type].button}
            </Button>
          </>
        )}
      </FlexBox>
    );
  }
);

export default ActionModal;
