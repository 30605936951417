import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Icon from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import ColumnsBar from "../../../features/columns-bar/ColumnsBar";
import { useResponsive } from "../../../hooks/useResponsive";
import { useTranslate } from "../../../hooks/useTranslate";
import { breakpoints, rem, theme } from "../../../styling/theme";
import { Column } from "../../../ui/columns-bar/Column";
import TruncateColumn from "../../../ui/columns-bar/TruncateColumn";
import { ParticipantsHeadingDataT } from "../../types";
import ActionButtons from "./ActionButtons";

type HeaderDataT = {
  reportingId: number;
  headerData: ParticipantsHeadingDataT;
};

const Header: FC<HeaderDataT> = ({ reportingId, headerData }) => {
  const {
    name,
    duration_from,
    duration_to,
    auto,
    hashtags,
    mentions,
    back_url,
  } = headerData;

  const { t } = useTranslate("brands.reportings.participants");
  const { breakePointCondition } = useResponsive(breakpoints.lg);

  const onBackButtonClick = (): void => {
    window.location = back_url as any as Location;
  };

  return (
    <ColumnsBar
      renderActions={(isAbsolutePosition) => (
        <ActionButtons
          basePath={`/brands/reportings/${reportingId}`}
          reportingId={reportingId}
          isAbsolutePosition={isAbsolutePosition}
        />
      )}
      renderContent={(mobilePosition) => (
        <>
          <Column
            onClick={onBackButtonClick}
            cursor="pointer"
            border={false}
            mobile={mobilePosition}
            fullHeight={true}
          >
            <Icon icon={IconTypeE.arrowLeft} size="small" />
          </Column>
          <Column
            fullHeight={true}
            paddingLeft={false}
            mobile={mobilePosition}
            maxWidth={rem(180)}
          >
            <FlexBox flexDirection="column" alignItems="stretch">
              <Heading
                headingType={HeadingTypeT.h4}
                heading={name}
                spaceBottom={0}
              />
            </FlexBox>
          </Column>
          <Column fullHeight={true} mobile={mobilePosition}>
            <FlexBox flexDirection="column" alignItems="stretch">
              <Heading
                headingType={HeadingTypeT.h4}
                heading={t("header.date")}
                spaceBottom="mini"
              />
              <Paragraph
                paragraph={`${duration_from} - ${duration_to}`}
                paragraphSize="small"
                color={theme.color.textGreyColor}
              />
            </FlexBox>
          </Column>
          <Column fullHeight={true} border={false} mobile={mobilePosition}>
            <FlexBox flexDirection="column" alignItems="stretch">
              <Heading
                headingType={HeadingTypeT.h4}
                heading={t("header.autoreporting")}
                spaceBottom="mini"
              />
              <Paragraph
                paragraph={auto ? t("header.auto_on") : t("header.auto_off")}
                paragraphSize="small"
                color={theme.color.textGreyColor}
              />
            </FlexBox>
          </Column>
          {hashtags && (
            <TruncateColumn
              string={hashtags}
              mobile={mobilePosition}
              label={t("header.hashtags")}
            />
          )}
          {mentions && (
            <TruncateColumn
              string={mentions}
              label={t("header.mentions")}
              mobile={mobilePosition}
              border={breakePointCondition}
            />
          )}
        </>
      )}
    />
  );
};

export default Header;
