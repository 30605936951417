import { debounce, putSpacesToThousand, _o } from "../helpers";

const isNetworkActive = (id) => {
  const network = document.getElementById(id);
  return network.className.includes("active");
};

const reverseValueCalculation = (id) => {
  const inputCount = parseInt(document.getElementById(`${id}-count`).value);
  const inputTotalElement = document.getElementById(`${id}-total`);
  const inputTotal = parseInt(inputTotalElement.value);

  const inputPrice = document.getElementById(`${id}-price`);

  if (inputCount === 0) {
    inputPrice.value = 0;
    inputTotalElement.value = 0;

    return;
  }

  inputPrice.value = parseInt(inputTotal / inputCount);
};

const updateTotalPrices = (total) => {
  const totalPrice = document.getElementById("offer-price-total");
  const paymentFee = document.getElementById("offer-price-fee");
  const influencerPrice = document.getElementById("offer-price-influencer");
  const totalPriceInput = document.getElementById("offer-price-total-input");

  const paymentFeeNum = parseFloat(paymentFee.dataset.fee) * 100;
  const commissionPrice = (total * paymentFeeNum) / 100;

  totalPrice.innerHTML = putSpacesToThousand(total);
  paymentFee.innerHTML = putSpacesToThousand(commissionPrice);
  influencerPrice.innerHTML = putSpacesToThousand(total - commissionPrice);

  if (totalPriceInput) {
    totalPriceInput.value = total;
  }
};

const sumarizeNetworkPrices = (ids) => {
  let totalPriceState = 0;

  ids.forEach((id) => {
    const input = parseInt(document.getElementById(`${id}-total`).value);

    if (!isNaN(input) && isNetworkActive(id)) {
      totalPriceState = totalPriceState + input;
    }
  });

  updateTotalPrices(totalPriceState);
};

const handleTotalOnChange = debounce((ids) => {
  sumarizeNetworkPrices(ids);
}, 100);

const valueOnChange = (id) => {
  const inputCount = parseInt(document.getElementById(`${id}-count`).value);
  const inputPrice = parseInt(document.getElementById(`${id}-price`).value);
  const inputTotal = document.getElementById(`${id}-total`);

  if (!isNaN(inputCount) && !isNaN(inputPrice) && inputTotal) {
    const inputEvent = new Event("input");

    const price = inputCount * inputPrice;
    inputTotal.value = price;
    inputTotal.dispatchEvent(inputEvent);
  }
};

const handleValueOnChange = debounce((id) => {
  valueOnChange(id);
}, 100);

const handleNetworkState = (network, barter) => {
  const id = network.id;
  const destroyPackageInputId = `${id}-package-destroy`;
  const destroyPackageInput = document.getElementById(destroyPackageInputId);
  const inputCount = document.getElementById(`${id}-count`);

  if (destroyPackageInput) {
    destroyPackageInput.value = Math.abs(
      parseInt(destroyPackageInput.value) - 1
    );

    if (!barter) {
      const inputTotal = document.getElementById(`${id}-total`);
      const inputEvent = new Event("input");

      inputTotal.dispatchEvent(inputEvent);
    }

    if (isNetworkActive(id)) {
      network.classList.remove("active");
      return;
    }

    if (inputCount && inputCount.value == 0) {
      inputCount.value = 1;
    }

    network.classList.add("active");
    return;
  }

  console.warn(
    `Hidden input /${destroyPackageInputId}/ for network /${network.id}/ was not found!`
  );
};

export const offerPriceCalcInit = () => {
  const networkSection = document.getElementById("network-offer-section");
  const networks = _o(networkSection.getElementsByClassName("network-offer"));
  const barterCampaign = networkSection.dataset.barter == "true";

  if (networks) {
    if (barterCampaign) {
      networks.forEach((network) => {
        const id = network.id;

        const networkTriggers = document.getElementsByClassName(
          `trigger-${id}`
        );

        if (networkTriggers) {
          _o(networkTriggers).forEach((trigger) => {
            trigger.addEventListener("click", () =>
              handleNetworkState(network, barterCampaign)
            );
          });
        }
      });

      return;
    }

    const totalPriceInput = document.getElementById("offer-price-total-input");
    const existedTotalValue = totalPriceInput.value;

    const allNetworksIDs = networks.map((network) => network.id);

    networks.forEach((network) => {
      const id = network.id;
      const allInputs = network.getElementsByTagName("INPUT");
      const filteredInputs = _o(allInputs).filter(
        (input) => input.type !== "hidden"
      );
      const networkTriggers = document.getElementsByClassName(`trigger-${id}`);

      _o(networkTriggers).forEach((trigger) => {
        trigger.addEventListener("click", () =>
          handleNetworkState(network, barterCampaign)
        );
      });

      filteredInputs.forEach((input) => {
        let inputState;

        if (input.id.includes("price")) {
          input.addEventListener("input", (e) => {
            if (isNaN(e.data)) {
              input.value = inputState;

              return;
            }

            inputState = input.value;
            handleValueOnChange(id);
          });
        }

        if (input.id.includes("count")) {
          input.addEventListener("input", () => handleValueOnChange(id));
        }

        if (input.id.includes("total")) {
          input.addEventListener("input", (e) => {
            if (e.data || e.inputType === "deleteContentBackward") {
              if (isNaN(e.data)) {
                input.value = inputState;

                return;
              }

              reverseValueCalculation(id);
            }

            inputState = input.value;
            handleTotalOnChange(allNetworksIDs);
          });
        }

        valueOnChange(id);
      });
    });

    if (!existedTotalValue || isNaN(existedTotalValue)) {
      updateTotalPrices(0);

      return;
    }

    updateTotalPrices(existedTotalValue);
  }
};
