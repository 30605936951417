import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import {
  getIllustration,
  IllustrationTypeE,
} from "../assets/illustrations/Illustrations";
import { rem, theme } from "../styling/theme";
import FlexBox, { CustomWidthE } from "./FlexBox";
import Paragraph from "./Paragraph";

type IllustrationStyleT = {
  size?: string;
};

export const IllustrationWrapper = styled.div<IllustrationStyleT>(
  ({ size = "100%" }) => ({
    width: size,
    maxWidth: "100%",
    marginBottom: rem(16),
  })
);

type NoResultsT = {
  message: string;
  illustration?: IllustrationTypeE;
  illustrationSize?: number;
};

const NoResults: FC<NoResultsT> = ({
  message,
  illustration = IllustrationTypeE.search_fighting,
  illustrationSize = 300,
}) => (
  <FlexBox
    flexDirection="column"
    customWidth={CustomWidthE.full}
    fullHeight={true}
  >
    <IllustrationWrapper size={rem(illustrationSize)}>
      {getIllustration(illustration)}
    </IllustrationWrapper>
    <Paragraph
      paragraph={message}
      align="center"
      color={theme.color.textGreyColor}
    />
  </FlexBox>
);

export default NoResults;
