import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { rem, theme } from "../styling/theme";
import FlexBox from "./FlexBox";
import Icon from "./Icon";
import VisibleWrapper from "./VisibleWrapper";

type WrapperStyleT = {
  active: boolean;
  size: number;
  hover?: boolean;
};

const Wrapper = styled(FlexBox)<WrapperStyleT>(
  ({ active, hover, size = 20 }) => ({
    width: rem(size),
    height: rem(size),
    padding: rem(2),
    border: `2px solid`,
    borderColor:
      active || hover ? theme.color.primaryColor : theme.color.greyColor,
    backgroundColor: active ? theme.color.primaryColor : "transparent",
    borderRadius: rem(2),
    transition: "all 0.3s",
    transitionTimingFunction: theme.timing.default,
    cursor: "pointer",
    userSelect: "none",

    ["&:hover"]: {
      borderColor: theme.color.primaryColor,
    },
  })
);

type CheckBoxT = {
  active: boolean;
  onClickHandle: () => void;
  size?: "mini" | "micro";
  hover?: boolean;
};

const CheckBox: FC<CheckBoxT> = ({
  active,
  onClickHandle,
  size = "mini",
  hover = false,
}) => {
  return (
    <Wrapper
      active={active}
      onClick={onClickHandle}
      size={size === "mini" ? 20 : 16}
      hover={hover}
    >
      <VisibleWrapper visible={active}>
        <Icon
          icon={IconTypeE.check}
          size={size}
          color={theme.color.whiteColor}
        />
      </VisibleWrapper>
    </Wrapper>
  );
};

export default CheckBox;
