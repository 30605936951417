import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Popover from "../../../components/Popover";
import { IconWrapper } from "../../../components/SectionLabel";
import Spacer from "../../../components/Spacer";
import { rem, theme } from "../../../styling/theme";

type ActiveStyleT = {
  active: boolean;
  disable: boolean;
};

const Wrapper = styled(FlexBox)({
  height: rem(56),
});

const Content = styled(FlexBox)<ActiveStyleT>(({ active, disable }) => ({
  position: "relative",
  margin: `0 ${rem(1)}`,
  height: active && !disable ? rem(55) : rem(50),
  bottom: "-1px",
  backgroundColor:
    active && !disable ? theme.color.whiteColor : theme.color.greyLightColor,
  borderColor: theme.color.greyColor,
  borderWidth: "1px",
  borderTopLeftRadius: rem(8),
  borderTopRightRadius: rem(8),
  borderStyle: "solid",
  borderBottom: "none",
  transition: "all 0.2s",
  transitionTimingFunction: theme.timing.default,
  cursor: disable ? "default" : "pointer",
  zIndex: active ? 2 : 1,

  ["&::after"]: {
    content: '" "',
    position: "absolute",
    display: "block",
    top: 0,
    width: "100%",
    height: active && !disable ? rem(5) : 0,
    backgroundColor: theme.color.yellowColor,
    transition: "all 0.2s",
    transitionTimingFunction: theme.timing.default,
    borderTopLeftRadius: rem(8),
    borderTopRightRadius: rem(8),
  },
}));

const TabLabel = styled(FlexBox)<ActiveStyleT>(({ active, disable }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  color: disable ? theme.color.textGreyColor : theme.color.textColor,
  fontWeight: active ? (disable ? 400 : 600) : 400,
  fontSize: rem(14),
  transition: "all 0.2s",
  transitionTimingFunction: theme.timing.default,
}));

const WidthAssurance = styled.span({
  padding: `0 ${rem(12)}`,
  fontWeight: 600,
  fontSize: rem(14),
  whiteSpace: "nowrap",
  color: "transparent",
  opacity: 0,
  visibility: "hidden",
});

const Label = styled.span({
  width: "max-content",
  cursor: "pointer",
});

type TabT = {
  label: string;
  count?: number | null;
  onClick: () => void;
  active: boolean;
  disable?: boolean;
  popover?: string;
};

const Tab: FC<TabT> = ({
  label,
  onClick,
  active,
  count,
  disable = false,
  popover,
}) => {
  const [hover, setHover] = useState(false);

  const activeState = active || hover;

  const getFullLabel = (): JSX.Element => (
    <Label>{`${label}` + (count ? `(${count})` : "")}</Label>
  );

  return (
    <Wrapper alignItems="flex-end">
      <Content
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        active={activeState}
        onClick={onClick}
        disable={disable}
      >
        <WidthAssurance>
          {popover && <Spacer size={active ? 110 : 80} />}
          {getFullLabel()}
        </WidthAssurance>
        <TabLabel disable={disable} active={activeState}>
          {popover && (
            <Popover
              renderTrigger={(active) => (
                <IconWrapper hover={active} margin="right">
                  <Icon icon={IconTypeE.info} size="mini" />
                </IconWrapper>
              )}
              content={popover}
            />
          )}
          {getFullLabel()}
        </TabLabel>
      </Content>
    </Wrapper>
  );
};

export default Tab;
