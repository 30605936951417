import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import CircleButton from "../../../components/CircleButton";
import FlexBox from "../../../components/FlexBox";
import { AntiShake } from "../../../components/GeneralStyles";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { rem, theme } from "../../../styling/theme";
import { UserRoleT } from "../../types";
import { LinkT, MessageT, MessageTranslationsT, OfferStateE } from "../types";
import ChatBubbleWrapper from "./MessageBubbleWrapper";
import ApproveReport from "./report/ApproveReport";
import SystemMessageLinks from "./SystemMessageLinks";

type MessageStyleT = {
  bigWidth: boolean;
  backgroundColor: string;
  borderColor: string;
};

const Wrapper = styled(FlexBox)({
  position: "relative",
  maxWidth: "90%",
  width: "auto",
  alignSelf: "flex-start",
  padding: `${rem(30)} 0`,
  ...AntiShake,
});

const IconWrapper = styled.div({
  marginBottom: rem(3),
});

const Message = styled(FlexBox)<MessageStyleT>(
  ({ bigWidth, backgroundColor, borderColor }) => ({
    padding: `${rem(10)} ${rem(16)}`,
    width: bigWidth ? rem(450) : "100%",
    maxWidth: bigWidth ? rem(450) : rem(300),
    zIndex: 2,
    border: `1px solid ${borderColor}`,
    backgroundColor,
    color: theme.color.textColor,
    borderRadius: rem(16),

    [theme.media.maxSm]: {
      maxWidth: "100%",
      width: "100%",
    },

    p: {
      margin: `0 0 ${rem(4)} 0`,

      "&:last-child": {
        marginBottom: 0,
      },

      "&:empty": {
        display: "none",
      },
    },
  })
);

type getMessageColorsT = (state: string) => {
  borderColor: string;
  backgroundColor: string;
  icon: IconTypeE;
  iconColor?: string;
};
const getMessageColors: getMessageColorsT = (state) => {
  if (state === "links_accepted") {
    return {
      borderColor: theme.color.successColor,
      backgroundColor: theme.color.successColorLight,
      icon: IconTypeE.check,
      iconColor: theme.color.whiteColor,
    };
  }

  return {
    borderColor: theme.color.yellowColor,
    backgroundColor: theme.color.yellowLightHoverColor,
    icon: IconTypeE.bell,
  };
};

type SystemMessageT = {
  offerId: number;
  offerState: OfferStateE | null;
  message: MessageT;
  links: LinkT[] | null;
  userRole: UserRoleT;
};

const SystemMessage: FC<SystemMessageT> = React.memo(
  ({ message, links, userRole, offerId, offerState }) => {
    const { body, created_at, translations } = message;
    const { borderColor, backgroundColor, iconColor, icon } =
      getMessageColors(body);

    const allowApproveReport =
      userRole === "brand" &&
      links &&
      links.length > 0 &&
      offerState &&
      offerState === OfferStateE.in_progress;

    const messageText = (translations as MessageTranslationsT)[userRole];

    return (
      <Wrapper alignItems="flex-end" justifyContent="flex-start">
        <IconWrapper>
          <CircleButton
            background={borderColor}
            icon={icon}
            iconSize={14}
            disabled={true}
            iconColor={iconColor}
          />
        </IconWrapper>
        <Spacer size="micro" />
        <ChatBubbleWrapper
          read={null}
          created={created_at}
          ownMessage={false}
          isLastMessage={false}
          focused={true}
        >
          <Message
            alignItems="flex-start"
            flexDirection="column"
            bigWidth={!!(links && links.length > 0)}
            borderColor={borderColor}
            backgroundColor={backgroundColor}
          >
            <FlexBox justifyContent="flex-start" alignItems="flex-start">
              <Spacer size="micro" direction="vertical" />
              <Paragraph paragraph={messageText} />
            </FlexBox>
            {links && links.length > 0 && (
              <SystemMessageLinks
                links={links}
                backgroundColor={backgroundColor}
              />
            )}
          </Message>
          {allowApproveReport && <ApproveReport offerId={offerId} />}
        </ChatBubbleWrapper>
      </Wrapper>
    );
  }
);

export default SystemMessage;
