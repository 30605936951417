import styled from "@emotion/styled";
import * as React from "react";
import { useEffect, useState } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import FlexBox from "../components/FlexBox";
import Icon from "../components/Icon";
import Spacer from "../components/Spacer";
import VisibleWrapper from "../components/VisibleWrapper";
import { rem, theme } from "../styling/theme";
import Paragraph from "./Paragraph";

const LoadMoreButton = styled(FlexBox)({
  padding: `${rem(20)} ${rem(25)}`,
  color: theme.color.textGreyDarkColor,
  fontWeight: 600,
  cursor: "pointer",
});

type ShowMoreT<T extends any> = {
  loadItems: () => void;
  queuedItems: T[];
};

const ShowMore = <T extends any>({
  loadItems,
  queuedItems,
}: ShowMoreT<T>): JSX.Element => {
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    if (queuedItems.length > 0) {
      setTimeout(() => {
        setShowMore(() => true);
      }, 500);
      return;
    }

    setShowMore(() => false);
  }, [queuedItems, showMore]);

  return (
    <VisibleWrapper visible={showMore}>
      <LoadMoreButton onClick={() => loadItems()}>
        <Paragraph
          color={theme.color.primaryColor}
          fontWeight={500}
          paragraph={"Načíst více"}
        />
        <Spacer size="micro" />
        <Icon
          icon={IconTypeE.arrowBottom}
          size={12}
          color={theme.color.primaryColor}
        />
      </LoadMoreButton>
    </VisibleWrapper>
  );
};

export default ShowMore;
