import pptxgen from "pptxgenjs";
import {
  addPBLText,
  createNumberColumn,
  createTable,
  formatTableRows,
  getAudience,
  getStringValue,
} from "./helpers";
import { InfluencerT, PPTMediaT, SummaryDataT } from "./types";

export const createCoverSlide: createSlideT = (pptx, data): void => {
  const slide = pptx.addSlide();

  slide.addShape("rect", {
    x: 0,
    y: 0,
    w: 10,
    h: 0.1,
    fill: { color: "3846A2" },
  });

  slide.addImage({
    path: data.logo_path,
    x: 0.8,
    y: 0.7,
    w: 1,
    h: 1,
  });

  slide.addText(data.name, {
    x: 0.7,
    y: 1.8,
    w: 8,
    h: 0.7,
    fontSize: 32,
    bold: true,
    color: "333333",
    align: "left",
  });

  slide.addText(data.duration, {
    x: 0.7,
    y: 2.6,
    w: 4,
    h: 0.4,
    fontSize: 16,
    color: "828282",
    align: "left",
  });

  slide.addShape("rect", {
    x: 0.8,
    y: 3.2,
    w: 0.4,
    h: 0.03,
    fill: { color: "3846A2" },
  });

  slide.addText(
    "Campaign description and purpose of this report. Text can be replaced and you can edit this section",
    {
      x: 0.7,
      y: 3.4,
      w: 4,
      h: 0.6,
      fontSize: 11,
      color: "333333",
      align: "left",
    }
  );

  slide.addImage({
    path: data.logo_path,
    x: 0.78,
    y: 4,
    w: 0.4,
    h: 0.4,
  });

  slide.addText("Agency name (optional)", {
    x: 1.2,
    y: 4.05,
    w: 4,
    h: 0.3,
    fontSize: 11,
    color: "333333",
    align: "left",
  });

  addPBLText(slide, 0.7);
};

type createSlideT = (pptx: pptxgen, data: SummaryDataT) => void;
export const createSummarySlide: createSlideT = (pptx, data): void => {
  const slide = pptx.addSlide();
  const { medias, influencers, total } = data.metrics;

  const tableData1 = [["Influencers:", `${influencers}`]];

  const tableData2 = [
    ["Posts:", getStringValue(medias.posts)],
    ["Stories:", getStringValue(medias.stories)],
    ["Reels:", getStringValue(medias.reels)],
    ["IGTV:", getStringValue(medias.igtvs)],
    ["All media", getStringValue(medias.total)],
  ];

  const tableData3 = [
    ["Reach", getStringValue(total.reach)],
    ["Impressions", getStringValue(total.impressions)],
    ["Likes", getStringValue(total.likes)],
    ["Comments", getStringValue(total.comments)],
    ["Engagement ", getStringValue(total.engagement)],
  ];

  const tableRows1 = formatTableRows(tableData1);
  const tableRows2 = formatTableRows(tableData2);
  const tableRows3 = formatTableRows(tableData3);

  slide.addText(data.name.toUpperCase(), {
    x: 0.2,
    y: 0.2,
    w: 2,
    h: 0.4,
    fontSize: 12,
    bold: true,
    color: "3846A2",
    align: "left",
  });

  slide.addText("Summary for campaign.", {
    x: 0.2,
    y: 0.6,
    w: 4,
    h: 1.3,
    fontSize: 48,
    lineSpacing: 48,
    bold: true,
    color: "333333",
    align: "left",
  });

  slide.addText(
    "Campaign description and purpose of this report. Text can be replaced and you can edit this section",
    {
      x: 0.2,
      y: 4,
      w: 3.3,
      h: 0.6,
      fontSize: 11,
      color: "333333",
      align: "left",
    }
  );

  slide.addImage({
    path: data.logo_path,
    x: 0.3,
    y: 4.6,
    w: 0.4,
    h: 0.4,
  });

  slide.addText("Agency name (optional)", {
    x: 0.7,
    y: 4.65,
    w: 4,
    h: 0.3,
    fontSize: 11,
    color: "333333",
    align: "left",
  });

  addPBLText(slide);

  slide.addShape("rect", {
    x: 5,
    y: 0.1,
    w: 4.9,
    h: 5.4,
    fill: { color: "3846A2" },
  });

  slide.addText("TOTAL", {
    x: 5.3,
    y: 0.3,
    w: 2,
    h: 0.2,
    fontSize: 8,
    bold: true,
    color: "F2C94C",
    align: "left",
  });

  slide.addTable(tableRows1, {
    x: 5.3,
    y: 0.45,
    w: 4.3,
    h: 0.4,
    fontSize: 24,
    color: "ffffff",
  });

  slide.addShape("rect", {
    x: 5.35,
    y: 1,
    w: 4.3,
    h: 0.01,
    fill: { color: "ffffff", transparency: 65 },
  });

  createTable(slide, tableRows2, 5.3, 1.2, 2.1, 2.4, 1.58, 0.48, "ffffff");
  createTable(slide, tableRows3, 7.5, 1.2, 2.1, 2.4, 1.58, 0.48, "ffffff");

  slide.addText("Likes + comments", {
    x: 7.55,
    y: 3.35,
    w: 2,
    h: 0.2,
    fontSize: 7,
    color: "ffffff",
    align: "left",
    margin: 0,
  });
};

type createInfluencerSlideT = (
  pptx: pptxgen,
  data: InfluencerT,
  campaignName: string
) => void;
export const createInfluencerSummarySlide: createInfluencerSlideT = (
  pptx,
  data,
  name
): void => {
  const slide = pptx.addSlide();
  const { total, medias } = data.metrics;

  const tableData1 = [
    ["Posts", getStringValue(medias.posts)],
    ["Stories", getStringValue(medias.stories)],
    ["Reels", getStringValue(medias.reels)],
    ["IGTV", getStringValue(medias.igtvs)],
    ["All media", getStringValue(medias.total)],
  ];

  const tableData2 = [
    ["Reach", getStringValue(total.reach)],
    ["Impressions", getStringValue(total.impressions)],
    ["Likes", getStringValue(total.likes)],
    ["Comments", getStringValue(total.comments)],
    ["Swipeups", getStringValue(total.swipeups)],
    ["Stickertaps", getStringValue(total.stickertaps)],
  ];

  const tableRows1 = formatTableRows(tableData1);
  const tableRows2 = formatTableRows(tableData2);

  slide.addText(name.toUpperCase(), {
    x: 0.2,
    y: 0.2,
    w: 2,
    h: 0.4,
    fontSize: 12,
    bold: true,
    color: "3846A2",
    align: "left",
  });

  slide.addShape("rect", {
    x: 0.2,
    y: 0.7,
    w: 4.5,
    h: 4.3,
    fill: { color: "FAFAFA" },
  });

  if (data.avatar_data) {
    slide.addImage({
      data: data.avatar_data,
      x: 2.05,
      y: 0.9,
      w: 1,
      h: 1,
      rounding: true,
    });
  }

  slide.addText(data.name, {
    x: 0.2,
    y: 2,
    w: 4.5,
    h: 0.3,
    fontSize: 18,
    bold: true,
    color: "333333",
    align: "center",
  });

  slide.addText(`@${data.handle}`, {
    x: 0.2,
    y: 2.3,
    w: 4.5,
    h: 0.2,
    fontSize: 12,
    color: "828282",
    align: "center",
  });

  slide.addText(_.truncate(data.bio, { length: 110 }), {
    x: 0.2,
    y: 2.6,
    w: 4.5,
    h: 0.6,
    fontSize: 10,
    color: "828282",
    align: "center",
  });

  slide.addShape("rect", {
    x: 2,
    y: 3.4,
    w: 1,
    h: 0.03,
    fill: { color: "E0E0E0" },
  });

  createNumberColumn(slide, data.followers, "Followers", 0.2, 3.55);
  createNumberColumn(slide, data.avg_reach, "Reach", 1.7, 3.55);
  createNumberColumn(slide, data.engagement_rate, "Engagement rate", 3.2, 3.55);

  addPBLText(slide);

  slide.addText("Main audience:", {
    x: 0.4,
    y: 4.4,
    w: 1.2,
    h: 0.4,
    fontSize: 12,
    color: "BDBDBD",
    align: "left",
  });

  slide.addText(
    getAudience(
      data.main_audience.age,
      data.main_audience.gender,
      data.main_audience.location
    ),
    {
      x: 1.6,
      y: 4.4,
      w: 4,
      h: 0.4,
      fontSize: 12,
      bold: true,
      color: "828282",
      align: "left",
    }
  );

  slide.addText("TOTAL (FOR EACH TYPE OF CONTENT)", {
    x: 5,
    y: 0.3,
    w: 2,
    h: 0.2,
    fontSize: 8,
    color: "F2C94C",
    align: "left",
  });

  createTable(slide, tableRows1, 5, 0.6, 2.1, 2.35, 0.98, 0.47, "333333");
  createTable(slide, tableRows2, 7.5, 0.6, 2.1, 2.85, 0.98, 0.47, "333333");
};

type createInfluencerMediaSlidesT = (
  pptx: pptxgen,
  data: PPTMediaT[],
  influencer: InfluencerT
) => void;
export const createInfluencerMediaSlides: createInfluencerMediaSlidesT = (
  pptx,
  data,
  influencer
): void => {
  const numberOfSlides = Math.ceil(data.length / 4);

  type sliceDataToSlidesT = (
    itemsNum: number,
    times: number
  ) => Array<PPTMediaT[]>;
  const sliceDataToSlides: sliceDataToSlidesT = (itemsNum, times) => {
    return [...Array(times)].map((_, index) => {
      const position = index * itemsNum;

      return data.slice(position, position + itemsNum);
    });
  };

  sliceDataToSlides(4, numberOfSlides).forEach((item) => {
    const slide = pptx.addSlide();

    if (influencer.avatar_data) {
      slide.addImage({
        data: influencer.avatar_data,
        x: 0.35,
        y: 0.2,
        w: 0.4,
        h: 0.4,
        rounding: true,
      });
    }

    slide.addText(influencer.name, {
      x: 0.75,
      y: 0.23,
      w: 4,
      h: 0.3,
      fontSize: 11,
      color: "333333",
      align: "left",
    });

    createNumberColumn(
      slide,
      influencer.followers,
      "Followers",
      7,
      0.16,
      0.2,
      1.4,
      12
    );
    createNumberColumn(
      slide,
      influencer.avg_reach,
      "Reach",
      7.8,
      0.16,
      0.2,
      1.4,
      12
    );
    createNumberColumn(
      slide,
      influencer.engagement_rate,
      "Engagement rate",
      8.6,
      0.16,
      0.2,
      1.4,
      12
    );

    slide.addShape("rect", {
      x: 0.3,
      y: 0.68,
      w: 9.5,
      h: 0.01,
      fill: { color: "828282" },
    });

    item.forEach((item, index) => {
      const positionX = index * 2.2 + index * 0.2 + 0.35;

      const tableData = [
        ["Reach", getStringValue(item.reach)],
        ["Impressions", getStringValue(item.impressions)],
        ["Likes", getStringValue(item.likes)],
        ["Comments", item.comments],
        ["Total Engagement", getStringValue(item.total_engagement)],
        ["Engagement Rate", item.engagement_rate],
      ];

      const filteredTableData = tableData
        .filter((item) => item[0] !== "Likes")
        .filter((item) => item[0] !== "Comments");
      filteredTableData.push(["", ""], ["", ""]);

      const tableRows = formatTableRows(
        item.kind === "story" ? filteredTableData : tableData
      );

      if (item.file_data) {
        slide.addImage({
          data: item.file_data,
          x: positionX,
          y: 0.8,
          w: item.file_dimensions[0] / 1000,
          h: item.file_dimensions[1] / 1000,
          sizing: {
            type: "cover",
            w: 2.2,
            h: 2.2,
          },
        });
      }

      slide.addShape("rect", {
        x: positionX,
        y: 3,
        w: 2.2,
        h: 2.1,
        fill: { color: "FAFAFA" },
      });

      slide.addText(item.kind, {
        x: positionX + 0.01,
        y: 3.1,
        w: 2,
        h: 0.2,
        fontSize: 9,
        color: "333333",
        align: "left",
      });

      slide.addText(`Posted on: ${item.published_at}`, {
        x: positionX + 0.01,
        y: 3.3,
        w: 2,
        h: 0.2,
        fontSize: 7,
        color: "828282",
        align: "left",
      });

      slide.addShape("rect", {
        x: positionX,
        y: 3.53,
        w: 2.2,
        h: 0.01,
        fill: { color: "BDBDBD" },
      });

      createTable(
        slide,
        tableRows,
        positionX + 0.05,
        3.6,
        2.1,
        1.3,
        0,
        0,
        "333333",
        7
      );
    });

    addPBLText(slide);
  });
};
