import { QueryClient } from "react-query";
import uniqid from "uniqid";
import {
  AddedMediaGroupsStateT,
  CreateMediaGroupResponseT,
  getAddedMediaGroupInit,
} from "../createMediaGroup";

type createMediaGroupMutationT = (
  queryClient: QueryClient,
  data: CreateMediaGroupResponseT
) => void;

export const createMediaGroupMutation: createMediaGroupMutationT = (
  queryClient,
  data
) => {
  queryClient.setQueryData<AddedMediaGroupsStateT | undefined>(
    "addedMediaGroups",
    (prevState) => {
      if (prevState) {
        const { id, kind, origin, medias, participant } = data.media_groups;
        const newState: AddedMediaGroupsStateT = [
          getAddedMediaGroupInit(uniqid()),
          {
            id,
            temporary_id: prevState[0].temporary_id,
            kind,
            origin,
            medias,
            participant,
          },
        ];

        return prevState.length > 1
          ? [...newState, ...prevState.slice(1)]
          : newState;
      }

      return prevState;
    }
  );
};
