import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox from "../../../components/FlexBox";
import { AntiShake } from "../../../components/GeneralStyles";
import Icon from "../../../components/Icon";
import Spacer from "../../../components/Spacer";
import { LightBoxContext } from "../../../context-providers/LightBoxContextProvider";
import {
  FileTypeE,
  getFileExtension,
  getFileName,
  getFileType,
} from "../../../helpers/fileType";
import { rem, theme } from "../../../styling/theme";
import { MessageFileT } from "../types";
import { ImagePreview, VideoPreview } from "./files-update/PendingFile";

const MediaFilesWrapper = styled(FlexBox)({
  borderRadius: rem(16),
  overflow: "hidden",
  marginTop: rem(3),
  ...AntiShake,
});

const OtherFilesWrapper = styled(FlexBox)({
  marginTop: rem(3),
});

const MediaFile = styled.a({
  maxWidth: rem(150),
  maxHeight: rem(150),
  minHeight: rem(150),
  border: `2px solid ${theme.color.whiteColor}`,
  margin: "-2px",
  cursor: "pointer !important",
});

const File = styled.div({
  padding: `${rem(4)} ${rem(10)}`,
  backgroundColor: theme.color.greyLightColor,
  color: theme.color.textColor,
  borderRadius: rem(16),
  marginTop: rem(2),
});

const FileContent = styled(FlexBox)({
  ...AntiShake,
});

const Name = styled.a({
  textDecoration: "underline",
});

type MessageFilesT = {
  files: MessageFileT[];
};

const MessageFiles: FC<MessageFilesT> = React.memo(({ files }) => {
  const { setLightBoxState } = useContext(LightBoxContext);

  const filesWithUrl = files.filter((file) => file.file_url);
  const mediaFiles: MessageFileT[] = [];
  const otherFiles: MessageFileT[] = [];

  filesWithUrl.forEach((file) => {
    const fileType = getFileType(getFileExtension(file.file_url));
    if (fileType === FileTypeE.file) {
      otherFiles.push(file);
      return;
    }

    mediaFiles.push(file);
  });

  const onClickHandle = (current_url: string): void => {
    const file_urls = mediaFiles.map((file) => file.file_url);
    const index = file_urls.indexOf(current_url);

    setLightBoxState({ file_urls: file_urls, currentIndex: index });
  };

  return (
    <>
      {mediaFiles.length > 0 && (
        <MediaFilesWrapper justifyContent="stretch" alignItems="stretch">
          {mediaFiles.map((file) => {
            const fileUrl = file.file_url;
            const fileType = getFileType(getFileExtension(fileUrl));

            if (fileType === FileTypeE.video) {
              return (
                <MediaFile key={file.id} href={file.file_url} target="_blank">
                  <VideoPreview src={fileUrl + "#t=0.1"} />
                </MediaFile>
              );
            }

            return (
              <MediaFile
                key={file.id}
                onClick={() => onClickHandle(file.file_url)}
              >
                <ImagePreview src={fileUrl} />
              </MediaFile>
            );
          })}
        </MediaFilesWrapper>
      )}
      {otherFiles.length > 0 && (
        <OtherFilesWrapper
          flexDirection="column"
          justifyContent="stretch"
          alignItems="stretch"
        >
          {otherFiles.map((file) => {
            return (
              <File key={file.id}>
                <FileContent justifyContent="stretch">
                  <Icon
                    icon={IconTypeE.document}
                    size="default"
                    color={theme.color.textGreyDarkColor}
                  />
                  <Spacer size="mini" />
                  <Name href={file.file_url} target="_blank">
                    {getFileName(file.file_url)}
                  </Name>
                </FileContent>
              </File>
            );
          })}
        </OtherFilesWrapper>
      )}
    </>
  );
});

export default MessageFiles;
