$(document).on("focus", ".datepicker", function () {
    var that = $(this);
    that.datepicker(
        $.extend($.datepicker.regional["cs"], {
            dateFormat: that.data('format') || 'dd.mm.yy',
            autoclose: true,
            changeMonth: true,
            changeYear: true,
            yearRange: "c-100:c+100"
        }, that.attr("id") === "user_birthday" ? {
            minDate: new Date(1920, 1, 1),
            maxDate: "+1Y"
        } : {})
    );
});