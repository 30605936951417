import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import AvatarTile, { AvatarSizeE } from "../../../components/AvatarTile";
import CheckBox from "../../../components/CheckBox";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Spacer from "../../../components/Spacer";
import { rem } from "../../../styling/theme";

const Wrapper = styled(FlexBox)({
  marginBottom: rem(4),
});

type FilterParticipantT = {
  profileUrl: string;
  handle: string;
  active: boolean;
  onClickHandle: () => void;
};

const FilterParticipant: FC<FilterParticipantT> = ({
  profileUrl,
  handle,
  active,
  onClickHandle,
}) => {
  return (
    <Wrapper customWidth={CustomWidthE.full} justifyContent="flex-start">
      <CheckBox active={active} onClickHandle={onClickHandle} />
      <Spacer />
      <AvatarTile
        imageUrl={profileUrl}
        name={handle}
        avatarSize={AvatarSizeE.small}
      />
    </Wrapper>
  );
};

export default FilterParticipant;
