import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { CustomWidthE } from "../../../components/FlexBox";
import PositionWrapper from "../../../ui/cards-ui/PositionWrapper";
import { ClearCarouselWrapper } from "../../../ui/ClearCarouselWrapper";
import { MediaGroupsHelperContext } from "../context-providers/MediaGroupsHelperContextProvider";
import { MediaGroupT } from "../data-access/types";
import MediaCard from "./card/MediaCard";
import StorySetHandles from "./card/StorySetHandles";
import { MediaToRemoveStateT } from "./MediaGoupsContainer";

type MediaGroupStorySetT = {
  mediaGroup: MediaGroupT;
  mediaToRemove: MediaToRemoveStateT | undefined;
  liveAddedMediaGroup?: boolean;
};

const MediaGroupStorySet: FC<MediaGroupStorySetT> = ({
  mediaGroup,
  mediaToRemove,
  liveAddedMediaGroup,
}) => {
  const { cardWidth } = useContext(MediaGroupsHelperContext);

  const { medias, id, origin, participant } = mediaGroup;
  const [mediaToRemoveInSet, setMediaToRemoveInSet] = useState(false);
  const [currentCard, setCurrentCard] = useState({
    index: 0,
    id: medias[0].id,
  });

  useEffect(() => {
    if (mediaToRemove) {
      const inSet =
        medias.findIndex((item) => item.id === mediaToRemove.media.id) > -1;
      if (inSet) {
        setMediaToRemoveInSet(true);
        return;
      }

      setMediaToRemoveInSet(false);
    }
  }, [mediaToRemove]);

  useEffect(() => {
    if (!mediaToRemove && mediaToRemoveInSet) {
      if (medias.find((item) => item.id !== currentCard.id)) {
        setCurrentCard((prevState) => {
          const newIndex = prevState.index > 0 ? prevState.index - 1 : 0;
          return {
            index: newIndex,
            id: medias[newIndex].id,
          };
        });

        return;
      }

      setCurrentCard({
        index: 0,
        id: medias[0].id,
      });
    }
  }, [mediaToRemove]);

  return (
    <PositionWrapper cardWidth={cardWidth} position="relative">
      <StorySetHandles
        medias={medias}
        mediaGroupId={id}
        mediaGroupParticipantId={participant.id}
        currentCard={currentCard}
        liveAddedMediaGroup={liveAddedMediaGroup}
        setCurrentCard={setCurrentCard}
        addNewStoryAllow={origin === "manual"}
      />
      <ClearCarouselWrapper customWidth={CustomWidthE.full}>
        <Carousel
          showArrows={false}
          showThumbs={false}
          emulateTouch={false}
          selectedItem={currentCard.index}
          onChange={(index) => setCurrentCard({ index, id: medias[index].id })}
        >
          {medias.map((media, index) => (
            <MediaCard
              key={media.id}
              media={media}
              mediaGroup={mediaGroup}
              cardStorySizing={true}
              liveAddedMedia={liveAddedMediaGroup}
              storyCounter={`${index + 1}/${medias.length}`}
              lastStory={medias.length === 1}
            />
          ))}
        </Carousel>
      </ClearCarouselWrapper>
    </PositionWrapper>
  );
};
export default MediaGroupStorySet;
