import _ from "lodash";
import { serializeArray, _o } from "../helpers";

type inputOnChangeT = (form: HTMLFormElement) => void;
const inputOnChange: inputOnChangeT = (form) => {
  const idElement = document.getElementById("campaing-id") as HTMLInputElement;
  const formData = serializeArray(form);
  const rawData = formData.filter(
    (input) => !(input.name === "id" && input.value === "")
  );

  // filter attributes about campaign packages and moodboard files
  const data = rawData
    .filter((input) => !input.name.includes("campaign_packages_attributes"))
    .filter((input) => !input.name.includes("campaign_posts_attributes"))
    .filter((input) => !input.name.includes("file_urls"));
  const saveDraftObject = { name: "save_draft", value: "true" };
  data.push(saveDraftObject);

  setTimeout(() => {
    $.ajax({
      url: "/brands/campaigns/check",
      type: "POST",
      dataType: "json",
      data,
    }).done((response) => {
      if (!idElement.value) {
        idElement.value = response.campaign_id;
      }
    });
  }, 250);
};

type saveDraftAutomaticallyT = () => void;
export const saveDraftAutomatically: saveDraftAutomaticallyT = () => {
  const idElement = document.getElementById("campaing-id") as HTMLInputElement;
  const form = document.getElementById("create_campaign") as HTMLFormElement;
  const draftButton = _o(document.getElementsByClassName("save-draft-button"));

  if (form && idElement && draftButton.length > 0) {
    const allInputs = form.getElementsByTagName("INPUT");
    const allTextAreas = form.getElementsByTagName("TEXTAREA");

    const filteredInputs = _o(allInputs).filter((input) => {
      const element = input as HTMLInputElement;
      return element.type !== "hidden";
    });

    const allElements = filteredInputs.concat(_o(allTextAreas));

    allElements.forEach((input) => {
      input.addEventListener(
        "input",
        _.throttle(() => inputOnChange(form), 5000) // 5sec interval
      );
    });
  }
};
