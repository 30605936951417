import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import { PendingFileT } from "../../../../hooks/usePendingFile";
import CardCover from "../../../../ui/cards-ui/CardCover";
import { ClearCarouselWrapper } from "../../../../ui/ClearCarouselWrapper";
import { CardOriginT, MediaFileT, MediaGroupT } from "../../data-access/types";
import CarouselArrow from "../../ui/CarouselArrow";
import ImagesOptions from "../../ui/ImagesOptions";
import EmptyState from "./EmptyState";
import { LightBoxStateT } from "./LightBoxModal";
import UploadInProgress from "./UploadInProgress";

const COVER_OPTIONS = {
  defaltHeight: 300,
  aspectRatio: [1, 1],
};

type CoverImagesT = {
  mediaGroup: MediaGroupT;
  mediaId: number;
  existedImages: MediaFileT[];
  origin: CardOriginT;
  wrapperWidth?: number;
  setLightBoxState: Dispatch<SetStateAction<LightBoxStateT | undefined>>;
  liveAddedMedia?: boolean;
};

const CoverImages: FC<CoverImagesT> = ({
  mediaGroup,
  mediaId,
  existedImages,
  origin,
  wrapperWidth,
  setLightBoxState,
  liveAddedMedia,
}) => {
  const [pendingFiles, setPendingFiles] = useState<PendingFileT[]>([]);
  const [currentImage, setCurrentImage] = useState<number>(0);

  const setLightBoxStateHandle = (): void => {
    setLightBoxState({
      files: existedImages,
      mediaId,
      mediaGroup,
      parentCurrentImage: currentImage,
      setParentCurrentImage: setCurrentImage,
    });
  };

  return (
    <FlexBox
      position="relative"
      customWidth={CustomWidthE.full}
      fullHeight={true}
    >
      <UploadInProgress
        visible={pendingFiles.length > 0}
        pendingFiles={pendingFiles}
        setPendingFiles={setPendingFiles}
        mediaId={mediaId}
        participantId={mediaGroup.participant.id}
        liveAddedMedia={liveAddedMedia}
      />

      {existedImages.length > 0 ? (
        <ClearCarouselWrapper
          position="relative"
          customWidth={CustomWidthE.full}
        >
          <ImagesOptions
            imagesCount={existedImages.length}
            setPendingFiles={setPendingFiles}
            uploadDisabled={origin === "private" || origin === "public"}
          />
          <FlexBox cursor="pointer" customWidth={CustomWidthE.full}>
            <Carousel
              showThumbs={false}
              emulateTouch={true}
              selectedItem={currentImage}
              onChange={(index) => setCurrentImage(index)}
              renderArrowPrev={(clickHandler, hasPrev) => (
                <CarouselArrow
                  disable={!hasPrev}
                  visible={existedImages.length > 1}
                  onClickHandle={clickHandler}
                  side="left"
                />
              )}
              renderArrowNext={(clickHandler, hasNext) => (
                <CarouselArrow
                  disable={!hasNext}
                  visible={existedImages.length > 1}
                  onClickHandle={clickHandler}
                  side="right"
                />
              )}
            >
              {existedImages.map((file) => {
                const { thumbnail_url, file_url } = file;

                return (
                  <CardCover
                    key={file.id}
                    image={!!thumbnail_url ? thumbnail_url : file_url}
                    wrapperWidth={wrapperWidth}
                    defaultHeight={COVER_OPTIONS.defaltHeight}
                    aspectRatio={COVER_OPTIONS.aspectRatio}
                    onClick={setLightBoxStateHandle}
                  />
                );
              })}
            </Carousel>
          </FlexBox>
        </ClearCarouselWrapper>
      ) : (
        <EmptyState
          setPendingFiles={setPendingFiles}
          wrapperWidth={wrapperWidth}
          options={COVER_OPTIONS}
          origin={origin}
        />
      )}
    </FlexBox>
  );
};

export default CoverImages;
