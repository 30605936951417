import { _o } from "../helpers";

type networkTabOnClickT = (networkId: string) => void;
const networkTabOnClick: networkTabOnClickT = (networkId) => {
  const tab = document.getElementById(`${networkId}-network-tab`);
  if (tab) {
    if (tab.className.includes("open")) {
      tab.classList.remove("open");

      return;
    }

    tab.classList.add("open");
  }
};

export const networkTabsInit = (): void => {
  const networksTabTriggers = _o(
    document.getElementsByClassName("network-tab-trigger")
  );

  if (networksTabTriggers) {
    networksTabTriggers.forEach((trigger) => {
      const htmlTrigger = trigger as HTMLElement;

      if (htmlTrigger.dataset.mounted) {
        return;
      }
      htmlTrigger.addEventListener("click", () => {
        const { network } = (htmlTrigger as HTMLElement).dataset;

        if (network) {
          networkTabOnClick(network);
        }
      });

      htmlTrigger.dataset.mounted = "true";
    });
  }
};

const that = window as any;
that.reInitNetworkTabs = () => {
  networkTabsInit();
};
