import * as React from "react";
import { FC } from "react";
import { useQuery } from "react-query";
import FlexBox from "./components/FlexBox";
import LoaderComponent from "./components/Loader";
import Spacer from "./components/Spacer";
import TranslationsContextProvider from "./context-providers/TranslationsContextProvider";
import { fetchSessionInfo } from "./data-access/fetchSessionInfo";
import CampaignDetail from "./entry-files/campaign-detail";
import ChatComponent from "./entry-files/chat";
import CompetitiveAnalysisDetail from "./entry-files/competitive-analysis";
import DatabaseDetail from "./entry-files/database-detail";
import Marketplace from "./entry-files/marketplace";
import MarketplaceRestricted from "./entry-files/marketplace/components/MarketplaceRestricted";
import Notifications from "./entry-files/notifications/";
import ReportingMediaGroups from "./entry-files/reporting-media-groups";
import ReportMonitoring from "./entry-files/reporting-monitoring";
import {
  CampaignDetailDataT,
  ChatDataT,
  CompetitiveAnalysisT,
  DatabaseDetailDataT,
  EntryFilesRouterPropsT,
  MarketplaceDataT,
  MediaGroupsDataT,
  NotificationsDataT,
  ReportMonitoringDataT,
  SessionEmptyPossibleT,
  SessionT,
} from "./entry-files/types";

const EntryFilesRouter: FC<EntryFilesRouterPropsT> = ({
  id,
  props,
  loader,
  translations,
}) => {
  const { data, isError } = useQuery("session", () => fetchSessionInfo());

  const getEntryFile = (session: SessionT): JSX.Element => {
    const { role } = session;

    if (id == "chat" && role) {
      return (
        <ChatComponent
          lightVersion={role === "brand"}
          data={{ ...(props as ChatDataT), session }}
        />
      );
    }

    if (id == "campaign_detail") {
      return (
        <CampaignDetail data={{ ...(props as CampaignDetailDataT), session }} />
      );
    }

    if (id == "notifications") {
      return (
        <Notifications data={{ ...(props as NotificationsDataT), session }} />
      );
    }

    if (id == "marketplace") {
      return <Marketplace data={{ ...(props as MarketplaceDataT), session }} />;
    }

    if (id == "marketplace_restricted") {
      return (
        <MarketplaceRestricted
          data={{ ...(props as MarketplaceDataT), session }}
        />
      );
    }

    if (id == "database_detail") {
      return (
        <DatabaseDetail data={{ ...(props as DatabaseDetailDataT), session }} />
      );
    }

    if (id == "reporting_media_groups") {
      return (
        <ReportingMediaGroups
          props={{ ...(props as MediaGroupsDataT) }}
          session={session}
        />
      );
    }

    if (id == "reporting_monitoring") {
      return (
        <ReportMonitoring
          data={{ ...(props as ReportMonitoringDataT), session }}
        />
      );
    }

    if (id == "competitive_analysis") {
      return (
        <CompetitiveAnalysisDetail
          data={{ ...(props as CompetitiveAnalysisT) }}
        />
      );
    }

    return <p>{`Entry file NOT FOUND. Data: ${JSON.stringify(data)}`}</p>;
  };

  const getEntryFileEmptySession = (
    session: SessionEmptyPossibleT
  ): JSX.Element => {
    if (id == "reporting_media_groups") {
      return (
        <ReportingMediaGroups
          props={{ ...(props as MediaGroupsDataT) }}
          session={session}
        />
      );
    }

    return <p>{`Entry file NOT FOUND. Data: ${JSON.stringify(data)}`}</p>;
  };

  if (isError) {
    // TODO: Error initial data data fetch
    console.error("There was an error!", isError);

    return <div>{"Error! Check the console."}</div>;
  }

  return (
    <TranslationsContextProvider
      translations={translations}
      renderChildren={(ready) => {
        if (ready && data) {
          const { id, role, email, nick_or_name, roles } = data;

          if (id && role && email && nick_or_name && roles) {
            return getEntryFile({
              ...data,
              id,
              role,
              email,
              nick_or_name,
              roles,
            });
          }

          return getEntryFileEmptySession(data);
        }

        return (
          <FlexBox fullHeight={true} flexDirection="column">
            {loader === "none" ? null : (
              <>
                <Spacer size="big" direction="vertical" />
                <LoaderComponent />
                <Spacer size="big" direction="vertical" />
              </>
            )}
          </FlexBox>
        );
      }}
    />
  );
};

export default EntryFilesRouter;
