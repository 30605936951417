import styled from "@emotion/styled";
import * as React from "react";
import { useState } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import { rem, theme } from "../../../styling/theme";

type WrapperStyleT = {
  width?: string;
};

type ContentStyleT = {
  focus: boolean;
  hover: boolean;
  disable: boolean;
  clear: boolean;
};

const Wrapper = styled(FlexBox)<WrapperStyleT>(({ width = "33.33%" }) => ({
  maxWidth: "100%",
  width,
  padding: rem(2),
}));

const getBorderColor = (
  clear: boolean,
  disable: boolean,
  hover: boolean,
  focus: boolean
): string => {
  if (clear || disable) {
    return "transparent";
  }

  if (focus) {
    return theme.color.yellowColor;
  }

  if (hover) {
    return theme.color.greyColor;
  }

  return "transparent";
};

const Content = styled(FlexBox)<ContentStyleT>(
  ({ disable, focus, hover, clear }) => ({
    padding: rem(4),
    borderRadius: rem(4),
    borderColor: getBorderColor(clear, disable, hover, focus),
    borderWidth: rem(1),
    borderStyle: "solid",
    backgroundColor: disable
      ? theme.color.backgroundColor
      : theme.color.whiteColor,
    transition: "all 0.4s",
    transitionTimingFunction: theme.timing.default,
  })
);

const StyledParagraph = styled(Paragraph)({
  cursor: "default",
});

type InsightItemWrapperT = {
  disabled: boolean;
  label: string;
  clear?: boolean;
  width?: string;
  onClick?: () => void;
};

const InsightItemWrapper: React.FC<InsightItemWrapperT> = ({
  label,
  width,
  disabled,
  children,
  clear = false,
  onClick,
}) => {
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <Wrapper
      width={width}
      onClick={onClick}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Content
        flexDirection="column"
        justifyContent="stretch"
        alignItems="stretch"
        disable={disabled}
        customWidth={CustomWidthE.full}
        focus={focus}
        clear={clear}
        hover={hover}
      >
        <StyledParagraph
          paragraph={label}
          paragraphSize="micro"
          color={theme.color.textGreyColor}
        />
        {children}
      </Content>
    </Wrapper>
  );
};

export default InsightItemWrapper;
