import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import FlexBox, { CustomWidthE } from "../components/FlexBox";
import FilesUploadArea from "../features/files-upload/FilesUploadArea";
import { PendingFileT } from "../hooks/usePendingFile";
import { rem, theme } from "../styling/theme";

type EmptyStateWrapperStyleT = {
  height: number;
};

const EmptyStateWrapper = styled(FlexBox)<EmptyStateWrapperStyleT>(
  ({ height }) => ({
    height: rem(height),
    backgroundColor: theme.color.yellowLightHoverColor,
    border: `2px dashed ${theme.color.yellowColor}`,
    borderRadius: rem(4),
  })
);

type UploadEmptyStateAreaT = {
  height: number;
  setPendingFiles: Dispatch<SetStateAction<PendingFileT[]>>;
};

const UploadEmptyStateArea: FC<UploadEmptyStateAreaT> = ({
  height,
  setPendingFiles,
  children,
}) => {
  return (
    <FlexBox position="relative" customWidth={CustomWidthE.full}>
      <FilesUploadArea setPendingFiles={setPendingFiles} />
      <EmptyStateWrapper
        customWidth={CustomWidthE.full}
        height={height}
        flexDirection="column"
      >
        {children}
      </EmptyStateWrapper>
    </FlexBox>
  );
};

export default UploadEmptyStateArea;
