import styled from "@emotion/styled";
import * as React from "react";
import { FC, useRef } from "react";
import FlexBox from "../../components/FlexBox";
import { rem, theme } from "../../styling/theme";

const Wrapper = styled(FlexBox)({
  width: rem(220),
  minWidth: rem(220),
  marginRight: rem(16),
  border: `1px solid ${theme.color.greyLightColor}`,
  borderRadius: rem(8),
  backgroundColor: theme.color.whiteColor,
  transition: "box-shadow 0.2s, width 0.2s",
  transitionTimingFunction: theme.timing.default,
});

type CardSmallWrapperT = {
  renderChildren: (wrapperWidth?: number) => JSX.Element;
};

const CardSmallWrapper: FC<CardSmallWrapperT> = ({ renderChildren }) => {
  const wrapper = useRef<HTMLDivElement>(null);
  const wrapperWidth = wrapper.current?.clientWidth;

  return (
    <Wrapper
      ref={wrapper}
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      position="relative"
    >
      {renderChildren(wrapperWidth)}
    </Wrapper>
  );
};

export default CardSmallWrapper;
