import styled from "@emotion/styled";
import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Spacer from "../../../components/Spacer";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { FilterItemT } from "../types";
import Tab from "./Tab";

const PADDING_SPACING = 12;

type ActiveStyleT = {
  active: boolean;
};

type SingleStyleT = {
  single: boolean;
};

type WidthStyleT = {
  width: number | "auto";
};

const Wrapper = styled(FlexBox)<ActiveStyleT & WidthStyleT>(
  ({ width, active }) => ({
    position: "relative",
    width,
    minWidth: "fit-content",
    height: "89px",
    bottom: "-1px",
    paddingBottom: "1px",
    overflow: active ? "visible" : "hidden",
  })
);

const Content = styled(FlexBox)<ActiveStyleT & SingleStyleT>(
  ({ active, single }) => ({
    position: "relative",
    borderTopLeftRadius: rem(8),
    borderTopRightRadius: rem(8),
    backgroundColor: active ? theme.color.whiteColor : "#F2F2F2",
    height: active ? rem(88) : rem(68),
    width: "100%",
    borderWidth: "1px",
    borderColor: single && active ? theme.color.greyColor : "#F2F2F2",
    borderStyle: "solid",
    borderBottomWidth: 0,
    transition: "all 0.3s",
    transitionTimingFunction: "ease",
    cursor: "pointer",
  })
);

const TableItems = styled(FlexBox)<ActiveStyleT>(({ active }) => ({
  position: "absolute",
  padding: `0 ${rem(PADDING_SPACING)}`,
  bottom: 0,
  opacity: active ? 1 : 0,
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,
}));

const TabLabel = styled.div<ActiveStyleT>(({ active }) => ({
  position: "absolute",
  width: "100%",
  left: rem(PADDING_SPACING),
  top: active ? "20%" : "50%",
  transform: "translateY(-50%)",
  fontWeight: active ? 400 : 600,
  fontSize: active ? rem(12) : rem(16),
  transition: "all 0.2s",
  transitionTimingFunction: theme.timing.default,
}));

const TabLabelSingle = styled(FlexBox)<ActiveStyleT>(({ active }) => ({
  position: "relative",
  height: "100%",
  padding: `0 ${rem(PADDING_SPACING)}`,
  fontWeight: 600,
  fontSize: rem(16),

  ["&::after"]: {
    content: '" "',
    position: "absolute",
    display: "block",
    bottom: "-1px",
    width: "100%",
    height: active ? rem(2) : 0,
    backgroundColor: theme.color.whiteColor,
    transition: "all 0.2s",
    transitionTimingFunction: theme.timing.default,
  },
}));

type ParentTabT = {
  tabTitle: string;
  current: boolean;
  currentFilter: FilterItemT;
  defaultItem: FilterItemT;
  setCurrentFilter: Dispatch<SetStateAction<FilterItemT>>;
  items: Partial<Record<FilterItemT, number | null>>;
  icon?: IconTypeE;
};

const ParentTab: FC<ParentTabT> = ({
  tabTitle,
  currentFilter,
  setCurrentFilter,
  current,
  items,
  icon,
  defaultItem,
}) => {
  const { t } = useTranslate("influencer.market");
  const tableTabsElement = useRef<HTMLDivElement>(null);
  const itemsCount = Object.keys(items).length;
  const singleStyle = itemsCount === 1;

  const [hover, setHover] = useState(false);
  const [tabWidth, setTabWidth] = useState<number | "auto">(
    !singleStyle ? 300 : "auto"
  );

  const active = current || hover;

  const getOffersCount = (): number => {
    const obj = { ...items };

    Object.keys(items).forEach((key) => {
      if (key === "starred" || key === "open") delete obj[key];
    });

    return (Object.values(obj).filter((item) => !!item) as number[]).reduce(
      (acc, value) => acc + value,
      0
    );
  };

  const onTabClickHandle = (): void => {
    const defaultCount = items[defaultItem];

    if (getOffersCount() >= 1 && !!defaultCount && defaultCount >= 1) {
      setCurrentFilter(defaultItem);
    }
  };

  const getFullLabel = (): string => {
    const count = getOffersCount();

    if (count || count === 0) {
      return `${tabTitle} (${count})`;
    }

    return tabTitle;
  };

  useEffect(() => {
    if (tableTabsElement.current) {
      setTabWidth(tableTabsElement.current.clientWidth);
    }
  }, [tableTabsElement, items]);

  return (
    <Wrapper width={tabWidth} alignItems="flex-end" active={active}>
      <Content
        active={active}
        flexDirection="column"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        single={singleStyle}
      >
        {!singleStyle ? (
          <>
            <TabLabel onClick={onTabClickHandle} active={active}>
              {getFullLabel()}
            </TabLabel>
            <TableItems ref={tableTabsElement} active={active}>
              {Object.keys(items).map((key) => {
                const item = key as FilterItemT;
                const active = item === currentFilter;
                const count = items[item];

                const setCurrentFilterHandle = (): void | null => {
                  setCurrentFilter(item);
                };

                return (
                  <Tab
                    key={key}
                    label={t(`filter.${key}`)}
                    active={active}
                    onClick={() => setCurrentFilterHandle()}
                    count={count}
                    popover={
                      key === "targeted" ? t("targeted_info") : undefined
                    }
                  />
                );
              })}
            </TableItems>
          </>
        ) : (
          <TabLabelSingle
            active={current}
            onClick={onTabClickHandle}
            cursor="pointer"
          >
            {icon && (
              <>
                <Icon size="small" icon={icon} />
                <Spacer size="micro" />
              </>
            )}
            {getFullLabel()}
          </TabLabelSingle>
        )}
      </Content>
    </Wrapper>
  );
};

export default ParentTab;
