import styled from "@emotion/styled";
import FlexBox from "../../components/FlexBox";
import { rem, theme } from "../../styling/theme";

type ColumntStyleT = {
  border?: boolean;
  cursor?: "pointer" | "auto";
  paddingLeft?: boolean;
  maxWidth?: string;

  mobile?: boolean;
};

export const Column = styled(FlexBox)<ColumntStyleT>(
  ({
    border = true,
    cursor = "auto",
    paddingLeft = true,
    maxWidth = "none",
    mobile = false,
  }) => ({
    paddingLeft: paddingLeft || mobile ? rem(14) : 0,
    paddingRight: rem(14),
    borderRight:
      border && !mobile ? `1px solid ${theme.color.greyColor}` : "none",
    minWidth: maxWidth != "none" ? "auto" : "fit-content",
    maxWidth,
    minHeight: mobile ? rem(60) : rem(78),
    cursor,
  })
);
