import { addToInnerHTML, numberToText, serializeArray, _o } from "../helpers";

const influencerCountOnChange = (count) => {
  const valueElement = document.getElementById("influencers-value");

  if (valueElement) {
    valueElement.dataset.count = count;
  }
};

const getAvailableNetworksInputs = (serializedData) => {
  const availableInputs = [];
  const regExp = /\d+/;

  const serializedNetworksInputs = serializedData.filter((input) =>
    input["name"].includes("campaign_packages")
  );

  const availableIndexes = serializedNetworksInputs
    .filter((input) => {
      const name = input["name"];
      return name.includes("_destroy") && input["value"] == 0;
    })
    .map((input) => {
      const name = input["name"];
      return name[name.search(regExp)];
    });

  availableIndexes.forEach((index) => {
    serializedNetworksInputs.forEach(
      (input) => input["name"].includes(index) && availableInputs.push(input)
    );
  });

  return availableInputs;
};

const influencerCountOnChangeHandle = () => {
  setTimeout(() => {
    const form = document.getElementById("create_campaign");
    const serializedFormData = serializeArray(form);
    const networksData = getAvailableNetworksInputs(serializedFormData);
    const targetingData = serializedFormData.filter((value) =>
      value.name.includes("campaign_targeting")
    );

    const ajaxData = targetingData.concat(networksData);

    $.ajax({
      url: "/brands/campaigns/influencers_count.json",
      type: "POST",
      dataType: "json",
      data: ajaxData,
    }).done((response) => {
      const { registered } = response;

      influencerCountOnChange(registered);
    });
  }, 10);
};

const createNetworksList = (data) => {
  const outcomeWrapper = document.getElementById("estimate-outcome");
  const networkList = document.getElementById("estimate-outcome-list");

  if (networkList.firstChild) {
    networkList.innerHTML = "";
  }

  data.forEach((network) => {
    const item = document.createElement("LI");
    item.classList = "estimate-outcome-item";

    const networkId = Object.keys(network)[0];

    if (networkId) {
      const headingElement = document.createElement("div");
      const countsElement = document.createElement("div");
      headingElement.className = "estimate-outcome-network-heading";
      countsElement.className = "estimate-outcome-network-counts";

      headingElement.innerText = networkId;
      item.innerHTML = headingElement.outerHTML;

      network[networkId].forEach((input) => {
        const name = input.name;

        if (name.includes("posts_count")) {
          addToInnerHTML(countsElement, input.value + " x posts, ");
          return;
        }

        if (name.includes("stories_count")) {
          addToInnerHTML(countsElement, input.value + " x stories, ");
          return;
        }

        if (name.includes("min_fans")) {
          addToInnerHTML(countsElement, numberToText(input.value) + " - ");
          return;
        }

        if (name.includes("max_fans")) {
          addToInnerHTML(countsElement, numberToText(input.value));
          return;
        }
      });

      addToInnerHTML(item, countsElement.outerHTML);
      addToInnerHTML(networkList, item.outerHTML);
    }
  });

  if (networkList.firstChild) {
    outcomeWrapper.classList.remove("hidden");

    return;
  }

  outcomeWrapper.classList.add("hidden");
};

const networksOnChange = () => {
  const campaignNetworks = _o(document.getElementsByClassName("network-item"));
  const networksData = _o(campaignNetworks)
    .map((network) => {
      const inputs = _o(network.getElementsByTagName("input"));
      const destroyInputs = inputs.filter((input) =>
        input.id.includes("destroy")
      );

      if (destroyInputs.length > 0 && destroyInputs[0].value == "0") {
        const networkId = network.dataset.network;
        const networkDataInputs = _o(inputs).filter(
          (input) => input.type === "number"
        );

        return { [networkId]: networkDataInputs };
      }
    })
    .filter((obj) => obj);

  if (networksData) {
    createNetworksList(networksData);
  }
};

export const influencerTargetingInit = () => {
  const influencerTargetingWrapper = document.getElementById(
    "influencer-targeting"
  );
  const campaignNetworksWrapper = document.getElementById("campaigns-networks");

  if (influencerTargetingWrapper) {
    _o(influencerTargetingWrapper.getElementsByTagName("INPUT")).forEach(
      (input) => {
        if (!input.className.includes("targeting-ignore")) {
          input.addEventListener("input", influencerCountOnChangeHandle);
        }
      }
    );

    influencerTargetingWrapper.addEventListener("click", (e) => {
      if (e.target.className.includes("select2")) {
        const interval = setInterval(() => {
          const openSelect = document.getElementsByClassName(
            "select2-container--open"
          );

          if (_o(openSelect).length == 0) {
            influencerCountOnChangeHandle();
            clearInterval(interval);

            return;
          }
        }, 1000);
      }
    });

    influencerCountOnChangeHandle();
  }

  if (campaignNetworksWrapper) {
    const campaignNetworksInputs = _o(
      campaignNetworksWrapper.getElementsByTagName("INPUT")
    );
    const campaignNetworksTriggers = _o(
      campaignNetworksWrapper.getElementsByClassName("button--network")
    );

    campaignNetworksInputs.forEach((element) =>
      element.addEventListener("input", networksOnChange)
    );
    campaignNetworksTriggers.forEach((element) => {
      element.addEventListener("click", networksOnChange);
      element.addEventListener("click", influencerCountOnChangeHandle);
    });
  }

  networksOnChange();
};
