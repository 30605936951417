import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../components/Heading";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import CopyLinkButton from "../../../reporting-media-groups/features/CopyLinkButton";

type ShareModalT = {
  shareLink: string | undefined;
  setShareLink: Dispatch<SetStateAction<string | undefined>>;
};

const ShareModal: FC<ShareModalT> = ({ shareLink, setShareLink }) => {
  const { t } = useTranslate("brands.reportings.participants.share_modal");

  return (
    <FlexBox flexDirection="column" alignItems="stretch">
      <Heading heading={t("title")} headingType={HeadingTypeT.h2} />
      <Paragraph paragraph={t("description")} />
      <Spacer size="big" direction="vertical" />
      <CopyLinkButton link={shareLink ? shareLink : ""} linkText={true} />
      <Spacer size="big" direction="vertical" />
      <FlexBox customWidth={CustomWidthE.full} justifyContent="flex-end">
        <Button
          onClick={() => setShareLink(undefined)}
          size={ButtonSizeE.small}
          type={ButtonTypeE.grey}
        >
          {t("close")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default ShareModal;
