import styled from "@emotion/styled";
import FlexBox from "../components/FlexBox";
import { rem } from "../styling/theme";

export const VerticalScrollView = styled(FlexBox)<{ customHeight: number }>(
  ({ customHeight }) => ({
    maxHeight: rem(customHeight),
    overflow: "scroll",
  })
);
