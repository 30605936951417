import * as React from "react";
import { FC, useEffect, useState } from "react";
import FlexBox from "../../../components/FlexBox";
import LightBoxModal, {
  LightBoxStateT,
} from "../../../components/LightBoxModal";
import LoaderComponent from "../../../components/Loader";
import Modal from "../../../components/Modal";
import { LightBoxContextProvider } from "../../../context-providers/LightBoxContextProvider";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useFetch } from "../../../hooks/useFetch";
import { useResponsive } from "../../../hooks/useResponsive";
import { ChatDataPropsT, UserT } from "../../types";
import CampaignDetail from "../components/CampaignDetail";
import MessagesContainerWrapper from "../components/MessagesContainerWrapper";
import { OfferPayloadT, OfferT } from "../types";
import ConversationsContainer from "./ConversationsContainer";
import MessagesContainer from "./MessagesContainer";

const Chat: FC<ChatDataPropsT> = ({ data, lightVersion }) => {
  const { offerId, openConversation, session } = data;
  const { tabletVersion } = useResponsive();
  const { id, role, email, avatar_url, nick_or_name } = session;

  const [activeOffer, setActiveOffer] = useState<OfferT | null>(null);
  const [offerDetailVisible, setOfferDetailVisible] =
    useState(openConversation);
  const [lightBoxState, setLightBoxState] = useState<
    LightBoxStateT | undefined
  >(undefined);

  const { payload } = useFetch<OfferPayloadT>(
    getApiUrl(ApiRoutesE.OFFER, offerId),
    [],
    "GET"
  );

  const userData: UserT = {
    id,
    email,
    avatar_url,
    nick_or_name,
  };

  useEffect(() => {
    if (payload) {
      const { offer } = payload;

      if (offer) {
        setActiveOffer(() => offer);
      }
    }
  }, [payload]);

  useEffect(() => {
    if (tabletVersion && offerDetailVisible) {
      document.body.style.overflow = "hidden";
      return;
    }

    document.body.style.overflow = "visible";
  }, [tabletVersion, offerDetailVisible]);

  const getContent = (): JSX.Element => {
    // Stripper version for brand side where is only container with list for single offer
    if (lightVersion) {
      return (
        <MessagesContainerWrapper fullWidth={lightVersion}>
          <MessagesContainer
            activeOfferId={offerId}
            activeOfferState={activeOffer?.state}
            activeOfferRating={activeOffer?.ratings}
            userId={id}
            userRole={role}
            userData={userData}
          />
        </MessagesContainerWrapper>
      );
    }

    return (
      <>
        {!!activeOffer ? (
          <FlexBox fullHeight={true}>
            <ConversationsContainer
              activeOffer={activeOffer}
              setActiveOffer={setActiveOffer}
              setOfferDetailVisible={setOfferDetailVisible}
              userId={id}
              userRole={role}
            />
            <MessagesContainerWrapper offerDetailVisible={offerDetailVisible}>
              <CampaignDetail
                offerId={activeOffer.id || offerId}
                offerState={activeOffer.state}
                campaign={activeOffer.campaign}
                setOfferDetailVisible={setOfferDetailVisible}
              />

              <MessagesContainer
                activeOfferId={activeOffer.id || offerId}
                activeOfferState={activeOffer.state}
                activeOfferRating={activeOffer?.ratings}
                campaign={activeOffer.campaign}
                userId={id}
                userRole={role}
                userData={userData}
              />
            </MessagesContainerWrapper>
          </FlexBox>
        ) : (
          <FlexBox fullHeight={true}>
            <LoaderComponent />
          </FlexBox>
        )}
      </>
    );
  };

  return (
    <LightBoxContextProvider setLightBoxState={setLightBoxState}>
      {getContent()}

      <Modal
        visible={!!lightBoxState}
        close={() => setLightBoxState(undefined)}
        // contentPadding={rem(4)}
        showCloseButton={false}
        renderChildren={(_, close) => (
          <>
            {!!lightBoxState && (
              <LightBoxModal lightBoxState={lightBoxState} close={close} />
            )}
          </>
        )}
      />
    </LightBoxContextProvider>
  );
};

export default Chat;
