import styled from "@emotion/styled";
import * as React from "react";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import FlexBox from "../../../components/FlexBox";
import Panel from "../../../components/Panel";
import { getSpacerSize } from "../../../components/Spacer";
import { useResponsive } from "../../../hooks/useResponsive";
import { useScroll } from "../../../hooks/useScroll";
import { breakpoints, rem, theme } from "../../../styling/theme";

const PanelWrapper = styled(FlexBox)({
  height: rem(170),
  marginBottom: rem(getSpacerSize("big")),

  [theme.media.maxMd]: {
    height: "auto",
  },
});

const StickyPanel = styled(Panel)<{ isSticky: boolean }>(({ isSticky }) => ({
  position: isSticky ? "fixed" : "relative",
  top: isSticky ? rem(75) : 0,
  transform: "translateZ(1)",
  zIndex: 3,
}));

type StickyHeaderT = {
  renderChildren: (fixed: boolean) => ReactNode;
};

const StickyHeader: FC<StickyHeaderT> = ({ renderChildren }) => {
  const [headerFixed, setHeaderFixed] = useState<boolean>(false);
  const [headerWidth, setHeaderWidth] = useState(0);

  const headerWrapper = useRef<HTMLDivElement>(null);
  const { currentOffset } = useScroll(0);
  const { windowWidth, breakePointCondition } = useResponsive(breakpoints.sm);

  useEffect(() => {
    if (headerWrapper.current) {
      const topOffset = headerWrapper.current.offsetTop;
      const topHeaderOffset = 75;

      setHeaderWidth(headerWrapper.current.clientWidth);

      if (currentOffset > topOffset - topHeaderOffset && breakePointCondition) {
        setHeaderFixed(true);
        return;
      }

      setHeaderFixed(false);
    }
  }, [currentOffset, windowWidth]);

  return (
    <PanelWrapper ref={headerWrapper} alignItems="flex-end">
      <StickyPanel
        isSticky={headerFixed}
        fullHeight={headerFixed ? false : true}
        marginBottom={0}
        customWidth={rem(headerWidth)}
      >
        {renderChildren(headerFixed)}
      </StickyPanel>
    </PanelWrapper>
  );
};

export default StickyHeader;
