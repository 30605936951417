import { getListOfNetworks } from "./helpers";

export const CAMPAIGNS_SLIDERS = [
  {
    id: "influencer-age",
    connect: true,
    step: 1,
    range: {
      min: 13,
      max: 55,
    },
    pipsValues: [13, 55],
    pipsSymbols: {
      55: "+",
    },
    tooltips: true,
  },
  {
    id: "fans-age",
    connect: true,
    step: 5,
    range: {
      min: [13],
      "16%": [18, 7],
      "33%": [25, 10],
      "49%": [35, 10],
      "66%": [45, 10],
      "83%": [55, 10],
      max: 65,
    },
    pipsValues: [13, 18, 25, 35, 45, 55, 65],
    pipsSymbols: {
      65: "+",
    },
    tooltips: true,
  },
  {
    id: "expiration-date",
    connect: "lower",
    step: 1,
    range: {
      min: 1,
      max: 90,
    },
    pipsValues: [1, 7, 14, 30, 60, 90],
    tooltips: true,
    updateLabel: false,
  },
];

export const BUDGET_SLIDER = {
  CZK: [
    {
      id: "campaign-budget",
      connect: "lower",
      step: 5000,
      range: {
        min: [0],
        "15%": [5000, 1000],
        "35%": [30000, 5000],
        "65%": [100000, 20000],
        max: [1000000],
      },
      pipsValues: [5000, 30000, 100000, 1000000],
      pipsAlias: {
        30000: "30",
        100000: "100",
        1000000: "1M",
      },
      pipsSymbols: {
        30000: "k",
        100000: "k",
        1000000: "+",
      },
      tooltips: false,
      updateLabel: "update",
    },
  ],
  EUR: [
    {
      id: "campaign-budget",
      connect: "lower",
      step: 200,
      range: {
        min: [0],
        "15%": [200, 50],
        "35%": [1000, 100],
        "65%": [4000, 500],
        max: [40000],
      },
      pipsValues: [200, 1000, 4000, 40000],
      pipsAlias: {
        4000: "4",
        40000: "40",
      },
      pipsSymbols: {
        4000: "k",
        40000: "k",
      },
      tooltips: false,
      updateLabel: "update",
    },
  ],
};

export const getNetworksMinFansSliders = (): object[] | false | undefined => {
  const networks = getListOfNetworks() as string[];
  const networksSliders: object[] = [];

  if (networks.length > 1) {
    networks.map((network) => {
      networksSliders.push({
        id: `network-${network}-fans`,
        connect: true,
        step: 5000,
        range: {
          min: [3000],
          "25%": [50000, 10000],
          "55%": [100000, 50000],
          max: [500000],
        },
        pipsValues: [3000, 50000, 100000, 500000],
        pipsAlias: {
          3000: "3",
          50000: "50",
          100000: "100",
          500000: "500",
        },
        pipsSymbols: {
          3000: "k",
          50000: "k",
          100000: "k",
          500000: "k+",
        },
        tooltips: true,
        updateLabel: false,
      });
    });

    if (networksSliders.length === 0) {
      console.error("WARNING: Any slider wasn`t found");
      return;
    }

    return networksSliders;
  }

  return false;
};
