import styled, { CSSObject } from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, ReactNode, SetStateAction, useState } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import CircleButton from "../../../../components/CircleButton";
import FlexBox from "../../../../components/FlexBox";
import Icon from "../../../../components/Icon";
import Loader from "../../../../components/Loader";
import { FileTypeE } from "../../../../helpers/fileType";
import usePendingFile, { PendingFileT } from "../../../../hooks/usePendingFile";
import { useResponsive } from "../../../../hooks/useResponsive";
import { rem, theme } from "../../../../styling/theme";
import { FILES_UPLOAD_HEIGHT } from "../../partials/CreateMessageBar";
import { FileStatusTypeE } from "../../types";

const Wrapper = styled.div<{ isLast: boolean }>(({ isLast }) => ({
  position: "relative",
  flex: `0 0 ${rem(FILES_UPLOAD_HEIGHT)}`,
  marginRight: isLast ? 0 : rem(10),
  height: rem(100),
  textAlign: "center",
}));

const File = styled(FlexBox)<{ background?: string }>(
  ({ background = theme.color.whiteColor }) => ({
    position: "relative",
    background,
    borderRadius: rem(4),
    overflow: "hidden",
  })
);

const ImageWrapper = styled(FlexBox)({
  height: rem(80),
});

const Name = styled.span({
  fontSize: rem(11),
  display: "inline-block",
  padding: `${rem(2)} ${rem(5)}`,
  height: rem(20),
});

const IndicatorBase: CSSObject = {
  position: "absolute",
  width: rem(20),
  height: rem(20),
  top: rem(-2),
  zIndex: 1,
};

const CloseButton = styled(CircleButton)<{ visible: boolean }>(
  ({ visible }) => ({
    ...IndicatorBase,
    right: rem(-3),
    minWidth: 0,
    visibility: visible ? "visible" : "hidden",
    opacity: visible ? 1 : 0,
    transition: "opacity 0.4s, visibility 0.4s",
    transitionTimingFunction: theme.timing.default,
  })
);

const IndicatorWrapper = styled(FlexBox)<{
  color?: string;
  side?: "left" | "right";
}>(({ color = theme.color.errorColor, side = "left" }) => ({
  ...IndicatorBase,
  left: side === "left" ? rem(-3) : "auto",
  right: side === "right" ? rem(-3) : "auto",
  backgroundColor: color,
  color: theme.color.whiteColor,
  borderRadius: "50%",
  fontWeight: "bold",
  fontSize: rem(13),
}));

export const ImagePreview = styled.img({
  objectFit: "cover",
  width: "100%",
  height: "100%",
});

export const VideoPreview = styled.video({
  objectFit: "cover",
  width: "100%",
  height: "100%",
});

const BlurWrapper = styled(FlexBox)({
  position: "absolute",
  backdropFilter: "blur(5px)",
  filter: "grayscale(40%)",
  width: "100%",
  height: rem(80),
});

type getPreviewElementT = (type: FileTypeE, link: string) => ReactNode;
const getPreviewElement: getPreviewElementT = (type, link) => {
  switch (type) {
    case FileTypeE.file:
      return <Icon icon={IconTypeE.document} size="large" />;
    case FileTypeE.image:
      return <ImagePreview src={link} />;
    case FileTypeE.video:
      return <VideoPreview src={link + "#t=0.1"} />;
  }
};

type PendingFilePropsT = {
  pendingFile: PendingFileT;
  setPendingFiles: Dispatch<SetStateAction<PendingFileT[]>>;
  isLast?: boolean;
  background?: string;
};

const PendingFile: FC<PendingFilePropsT> = React.memo(
  ({ pendingFile, setPendingFiles, isLast = false, background }) => {
    const [hover, setHover] = useState<boolean>(false);
    const { tabletVersion } = useResponsive();
    const {
      type,
      temporaryUrl,
      uploadedUrl,
      name,
      pending,
      failure,
      localState,
      showFile,
      deleteFileHandle,
    } = usePendingFile(pendingFile, setPendingFiles);

    if (!showFile) {
      return null;
    }

    return (
      <Wrapper
        isLast={isLast}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <CloseButton
          visible={
            hover || (localState === FileStatusTypeE.uploaded && tabletVersion)
          }
          onClickHandle={deleteFileHandle}
          background={theme.color.errorColor}
          hoverBackground={theme.color.errorHoverColor}
          icon={IconTypeE.minus}
          iconColor={theme.color.whiteColor}
          iconSize="mini"
        />
        {failure && (
          <IndicatorWrapper title="Nahrávání souboru selhalo">
            {"!"}
          </IndicatorWrapper>
        )}
        {pending && (
          <IndicatorWrapper
            side="right"
            color={theme.color.textGreyColor}
            title="Nahrávám..."
          >
            <Loader size={14} color={theme.color.whiteColor} />
          </IndicatorWrapper>
        )}
        <File
          background={background}
          flexDirection="column"
          justifyContent="stretch"
        >
          {pending && <BlurWrapper />}
          <ImageWrapper>
            {getPreviewElement(type, uploadedUrl ? uploadedUrl : temporaryUrl)}
          </ImageWrapper>
          <Name>{name}</Name>
        </File>
      </Wrapper>
    );
  }
);

export default PendingFile;
