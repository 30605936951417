import styled, { CSSObject } from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { rem, theme } from "../styling/theme";
import FlexBox, { CustomWidthE } from "./FlexBox";
import Loader from "./Loader";
import Tooltip, { TooltipPosition } from "./Tooltip";

type getButtonSizeT = (size: ButtonSizeE) => CSSObject;
const getButtonSize: getButtonSizeT = (size) => {
  switch (size) {
    case ButtonSizeE.normal:
      return {
        height: rem(60),
        padding: `0 ${rem(24)}`,
      };
    case ButtonSizeE.small:
      return {
        fontSize: rem(14),
        height: rem(40),
        padding: `0 ${rem(10)}`,
      };
    case ButtonSizeE.micro:
      return {
        fontSize: rem(13),
        height: rem(32),
        padding: `0 ${rem(6)}`,
        borderRadius: rem(4),
      };
  }
};

//TODO: Split hover and solid colors to two diffrent atributes
type getButtonTypeT = (type: ButtonTypeE) => CSSObject;
const getButtonType: getButtonTypeT = (type) => {
  switch (type) {
    case ButtonTypeE.default:
      return {
        backgroundColor: theme.color.yellowColor,

        ["&:hover"]: {
          backgroundColor: theme.color.yellowHoverColor,
          borderColor: theme.color.yellowHoverColor,
        },

        ["&:active"]: {
          color: theme.color.textGreyDarkColor,
          backgroundColor: theme.color.yellowPressedColor,
          borderColor: theme.color.yellowPressedColor,
        },

        ["&:focus"]: {
          color: theme.color.textGreyDarkColor,
          backgroundColor: theme.color.yellowPressedColor,
          borderColor: theme.color.yellowPressedColor,
        },
      };
    case ButtonTypeE.outline:
      return {
        backgroundColor: "transparent",

        ["&:hover"]: {
          borderColor: theme.color.yellowHoverColor,
        },

        ["&:active"]: {
          color: theme.color.textGreyDarkColor,
          backgroundColor: theme.color.yellowLightHoverColor,
          borderColor: theme.color.yellowPressedColor,
        },

        ["&:focus"]: {
          color: theme.color.textGreyDarkColor,
          backgroundColor: theme.color.yellowLightHoverColor,
          borderColor: theme.color.yellowPressedColor,
        },
      };
    case ButtonTypeE.disabled:
      return {
        color: theme.color.textLightGreyColor,
        backgroundColor: theme.color.greyLightColor,
        borderColor: theme.color.greyColor,
        cursor: "auto",

        ["&:hover"]: {
          color: theme.color.greyColor,
        },
      };
    case ButtonTypeE.grey:
      return {
        color: theme.color.textGreyDarkColor,
        backgroundColor: theme.color.greyLightColor,
        borderColor: "transparent",
        ["&:hover"]: {
          backgroundColor: theme.color.greyColor,
        },
      };
    case ButtonTypeE.darkGrey:
      return {
        color: theme.color.textGreyDarkColor,
        backgroundColor: theme.color.greyColor,
        borderColor: "transparent",
        ["&:hover"]: {
          backgroundColor: theme.color.textLightGreyColor,
        },
      };
    case ButtonTypeE.error:
      return {
        color: theme.color.whiteColor,
        backgroundColor: theme.color.errorColor,
        borderColor: theme.color.errorColor,

        ["&:hover"]: {
          color: theme.color.errorLightColor,
        },

        ["&:active"]: {
          color: theme.color.errorLightHoverColor,
        },

        ["&:focus"]: {
          color: theme.color.errorLightHoverColor,
        },
      };
    case ButtonTypeE.clear:
      return {
        color: theme.color.textColor,
        backgroundColor: "transparent",
        borderColor: "transparent",
      };
    case ButtonTypeE.clearWithHover:
      return {
        color: theme.color.textColor,
        backgroundColor: "transparent",
        borderColor: "transparent",

        ["&:hover"]: {
          backgroundColor: theme.color.greyColor,
        },
      };
    case ButtonTypeE.dummy:
      return {
        backgroundColor: theme.color.yellowColor,
        cursor: "auto",
      };
  }
};

type ButtonStyleT = {
  size: ButtonSizeE;
  buttonType: ButtonTypeE;
  customWidth?: CustomWidthE;
  disabled?: boolean;
};

const StyledButton = styled(FlexBox)<ButtonStyleT>(
  ({ size, buttonType, customWidth, disabled = false }) => ({
    position: "relative",
    width: customWidth ? customWidth : "max-content",
    color: theme.color.textColor,
    fontSize: rem(15),
    fontWeight: "bold",
    lineHeight: 0, // anti shake effect during animation
    border: `2px solid ${theme.color.yellowColor}`,
    borderRadius: rem(8),
    cursor: disabled ? "default" : "pointer",
    WebkitAppearance: "none",
    userSelect: "none",
    transition: "all 0.4s",
    transitionTimingFunction: theme.timing.default,

    ...getButtonSize(size),
    ...getButtonType(buttonType),
  })
);

export enum ButtonSizeE {
  normal = "normal",
  small = "small",
  micro = "micro",
}

export enum ButtonTypeE {
  default = "default",
  outline = "outline",
  disabled = "disabled",
  error = "error",
  dummy = "dummy",
  grey = "grey",
  darkGrey = "darkGrey",
  clear = "clear",
  clearWithHover = "clearWithHover",
}

type ButtonT = {
  onClick?: () => void;
  href?: string;
  target?: "_blank" | "_self";
  size?: ButtonSizeE;
  type?: ButtonTypeE;
  loading?: boolean;
  disabled?: boolean;
  customWidth?: CustomWidthE;
  withoutDisableStyle?: boolean;
  tooltip?: string;
  tooltipPosition?: TooltipPosition;
  tooltipVisibility?: "hover" | "solid";
  whiteSpace?: "nowrap" | "normal";
};

const Button: FC<ButtonT> = ({
  children,
  href,
  target = "_self",
  size = ButtonSizeE.normal,
  type = ButtonTypeE.default,
  loading,
  disabled,
  onClick,
  customWidth,
  withoutDisableStyle = false,
  tooltip,
  tooltipPosition = "top",
  tooltipVisibility = "hover",
  whiteSpace,
}) => {
  const [hover, setHover] = useState(false);

  const handleOnClick = (): void => {
    const disable = disabled || loading;

    if (disable || href) {
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  const buttonType =
    disabled && !withoutDisableStyle ? ButtonTypeE.disabled : type;

  const renderButton = (): JSX.Element => (
    <StyledButton
      onClick={handleOnClick}
      size={size}
      buttonType={buttonType}
      customWidth={customWidth}
      disabled={disabled}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {loading ? <Loader size={20} color={theme.color.textColor} /> : children}
      {tooltip && (
        <Tooltip
          visible={tooltipVisibility === "hover" ? hover : !!tooltip}
          bouncing={false}
          position={tooltipPosition}
          tooltip={tooltip}
          whiteSpace={whiteSpace}
        />
      )}
    </StyledButton>
  );

  if (href) {
    return (
      <a href={href} target={target}>
        {renderButton()}
      </a>
    );
  }

  return renderButton();
};

export default Button;
