import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import Container from "../../../components/Container";
import { getMediaGroups } from "../../reporting-media-groups/data-access/getMediaGroups";
import { getPartcipants } from "../../reporting-media-groups/data-access/getParticipants";
import MediaGroupsContainer from "../../reporting-media-groups/features/MediaGoupsContainer";
import { ParticipantsHeadingDataT } from "../../types";
import { getUpdatedUrl } from "../utils/helpers";
import Header from "./Header";
import OptionsSection from "./OptionsSection";
import Table from "./participants-table/Table";
import { FilterStateT, SortingStateT } from "./SortAndFilter";

type MainContainerT = {
  reportingId: number;
  headerData: ParticipantsHeadingDataT;
};

const MainContainer: FC<MainContainerT> = ({ reportingId, headerData }) => {
  const [firstLoad, setFirstLoad] = useState(true);
  const [section, setSection] = useState<"participants" | "outputs">(
    "participants"
  );
  const [sortingState, setSortingState] = useState<SortingStateT>({
    sort: "created_at:desc",
  });
  const [filterState, setFilterState] = useState<FilterStateT | undefined>(
    undefined
  );

  const { data: participants, isLoading: participantsAreLoading } = useQuery(
    "participants",
    () => getPartcipants(reportingId)
  );

  const {
    data: mediaGroups,
    isLoading: mediaGroupsAreLoading,
    refetch,
  } = useQuery(
    "mediaGroups",
    () =>
      getMediaGroups({
        apiUrl: getUpdatedUrl(reportingId, { ...filterState, ...sortingState }),
      }),
    { retry: false }
  );

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      refetch();
    }
  }, [filterState, sortingState]);

  return (
    <Container>
      <Header headerData={headerData} reportingId={reportingId} />

      <OptionsSection
        section={section}
        setSection={setSection}
        filterState={filterState}
        setFilterState={setFilterState}
        sortingState={sortingState}
        setSortingState={setSortingState}
        participants={participants}
      />

      {section === "participants" && (
        <Table
          participantsState={participants}
          dataIsLoading={participantsAreLoading}
          reportingId={reportingId}
        />
      )}

      {section === "outputs" && (
        <MediaGroupsContainer
          loading={mediaGroupsAreLoading}
          mediaGroups={mediaGroups}
          withParticipant={true}
        />
      )}
    </Container>
  );
};

export default MainContainer;
