import * as React from "react";
import { FC, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import BlueLink from "../../../components/BlueLink";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Icon from "../../../components/Icon";
import Panel from "../../../components/Panel";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../features/Placeholders/PlaceholderLoader";
import { useResponsive } from "../../../hooks/useResponsive";
import { useTranslate } from "../../../hooks/useTranslate";
import { breakpoints, rem } from "../../../styling/theme";
import AvatarWithCircles from "../../../ui/AvatarWithCircles";
import StickyHeader from "../features/StickyHeader";
import { InfluencerProfileT, NetworkProviderT } from "../types";
import InfluencerThumbnails from "../ui/InfluencerThumbnails";
import Actions from "./Actions";
import ExportContainer from "./ExportContainer";

type InfluencerProfilePropsT = {
  provider: NetworkProviderT;
  adminRole: boolean;
  data?: InfluencerProfileT;
};

const InfluencerProfile: FC<InfluencerProfilePropsT> = ({
  data,
  provider,
  adminRole,
}) => {
  const [exportType, setExportType] = useState<"copy" | "save" | null>(null);
  const [exportPrice, setExportPrice] = useState<string>("");
  const [exportDescription, setExportDescription] = useState<string>("");

  const { t } = useTranslate("crm.detail");

  const MAX_BIO_LENGTH = 150;
  const { breakePointCondition, tabletVersion } = useResponsive(breakpoints.sm);

  const onBackButtonClick = (): void => {
    const backUrl = `/crm/influencers?q%5Bnetwork_profiles_provider_is%5D=${provider}`;
    window.location = backUrl as any as Location;
  };

  const getContent = (fixed: boolean): JSX.Element => {
    if (!data) {
      return (
        <PlaceholderLoader type={PlaceholdersTypeE.header_panel} count={1} />
      );
    }

    const { handle, avatar_url, name, profile_url, bio } = data;

    return (
      <FlexBox
        justifyContent="space-between"
        alignItems="stretch"
        customWidth={CustomWidthE.full}
        flexDirection={breakePointCondition ? "row" : "column"}
      >
        <FlexBox customWidth={CustomWidthE.full}>
          <FlexBox onClick={onBackButtonClick} cursor="pointer">
            <Spacer />
            <Icon icon={IconTypeE.arrowLeft} size="small" />
          </FlexBox>
          <AvatarWithCircles
            handle={handle}
            profileImageUrl={avatar_url}
            provider={provider}
            avatarSize={fixed ? "mini" : "default"}
          />
          <Spacer />
          <FlexBox
            flexDirection="column"
            customWidth={CustomWidthE.full}
            alignItems="flex-start"
          >
            <Heading
              spaceBottom={0}
              heading={name ? name : handle}
              headingType={HeadingTypeT.h2}
            />
            <BlueLink
              text={`@${handle}`}
              href={profile_url}
              icon={IconTypeE.linkExternal}
              target="_blank"
              iconPosition={"after"}
            />
            <Spacer direction="vertical" size="mini" />

            {!fixed && (
              <>
                <Paragraph truncate={MAX_BIO_LENGTH} paragraph={bio} />
                {bio && bio.length > MAX_BIO_LENGTH && (
                  <BlueLink
                    text={t("view_more")}
                    paragraphSize="small"
                    target="_blank"
                    href={profile_url}
                  />
                )}
              </>
            )}
          </FlexBox>
        </FlexBox>
        <Spacer />
        <Actions
          setExportType={setExportType}
          setExportPrice={setExportPrice}
          setExportDescription={setExportDescription}
          exportPrice={exportPrice}
          exportDescription={exportDescription}
          adminRole={adminRole}
        />
      </FlexBox>
    );
  };

  return (
    <>
      <StickyHeader
        renderChildren={(fixed) => getContent(fixed || tabletVersion)}
      />

      {data && data.top_posts.length > 0 && (
        <Panel padding={rem(16)}>
          <InfluencerThumbnails
            items={data.top_posts}
            thumbnailWidth={"20%"}
            thumbnailHeight={rem(250)}
          />
        </Panel>
      )}

      {data && (
        <ExportContainer
          influencerData={data}
          exportType={exportType}
          setExportType={setExportType}
          exportPrice={exportPrice}
          exportDescription={exportDescription}
        />
      )}
    </>
  );
};

export default InfluencerProfile;
