import * as React from "react";
import { FC, useEffect, useState } from "react";
import uniqid from "uniqid";
import Button from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import { getAfterActionModalContent } from "../../../../components/Modal";
import Spacer from "../../../../components/Spacer";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { useFetch } from "../../../../hooks/useFetch";
import { LinkCheckT } from "../../types";
import Files from "./Files";
import Links, { LinkItemT } from "./Links";
import Stories from "./Stories";

type ReportModal = {
  offerId: number;
  visible: boolean;
  closeModal: () => void;
  linksCheckState: LinkCheckT[];
};

const ReportModal: FC<ReportModal> = React.memo(
  ({ offerId, closeModal, visible, linksCheckState }) => {
    const [links, setLinks] = useState<LinkItemT[]>([]);
    const [fileURLs, setFileURLs] = useState<string[]>([]);
    const [stories, setStories] = useState<string[]>([]);
    const [reportSent, setReportSend] = useState(false);

    const body = {
      links: {
        urls: links.map((link) => link.link).filter((link) => link),
        file_urls: fileURLs,
        ig_story_ids: stories || [],
      },
    };

    const { call, pending, payload, error } = useFetch<Response>(
      getApiUrl(ApiRoutesE.OFFER_LINKS, offerId),
      undefined,
      "POST",
      body
    );

    const {
      links: { file_urls, urls, ig_story_ids },
    } = body;

    const sendDisable =
      file_urls.length < 1 && urls.length < 1 && ig_story_ids.length < 1;

    useEffect(() => {
      if (payload || error) {
        setTimeout(() => {
          closeModal();
        }, 3000);
      }
    }, [payload, error]);

    useEffect(() => {
      // removing modal state after modal reopen
      if (!visible) {
        setLinks([]);
        addLink();

        setFileURLs([]);
        setStories([]);
        setReportSend(false);
      }
    }, [visible]);

    const sendReport = (): void => {
      call();
      setReportSend(true);
    };

    // init first empty link
    type addLinkT = () => void;
    const addLink: addLinkT = (): void => {
      setLinks((prevState) => {
        prevState.push({
          id: uniqid(),
          link: null,
        });

        return [...prevState];
      });
    };

    return (
      <FlexBox
        flexDirection="column"
        justifyContent="stretch"
        alignItems="stretch"
      >
        {reportSent ? (
          getAfterActionModalContent(
            payload,
            error,
            "Výstupy úspěšne přidané k výslednímu reportu",
            "Vyskytla se chyba při pokusu o vytvoření reportu.",
            "Skuste to pozdějc prosím znovu."
          )
        ) : (
          <>
            <Links
              offerId={offerId}
              links={links}
              setLinks={setLinks}
              addLink={addLink}
              linksCheckState={linksCheckState}
            />
            <Spacer size="big" direction="vertical" />
            <Files
              offerId={offerId}
              fileURLs={fileURLs}
              setFileURLs={setFileURLs}
            />
            <Spacer size="big" direction="vertical" />
            {visible && <Stories setStories={setStories} />}
            <Spacer size="big" direction="vertical" />
            <Button
              customWidth={CustomWidthE.full}
              disabled={pending || sendDisable}
              onClick={sendReport}
            >
              {"Odeslat ke schválení"}
            </Button>
          </>
        )}
      </FlexBox>
    );
  }
);

export default ReportModal;
