import * as React from "react";
import { createContext, Dispatch, FC, SetStateAction } from "react";
import { DateCalendarData } from "../features/CalendarContainer";
import { LightBoxStateT } from "../features/card-cover/LightBoxModal";
import { MediaToRemoveStateT } from "../features/MediaGoupsContainer";

type MediaGroupsHelperContextT = {
  cardWidth: string;
  withParticipant: boolean;
  setMediaToRemove: Dispatch<SetStateAction<MediaToRemoveStateT | undefined>>;
  setLightBoxState: Dispatch<SetStateAction<LightBoxStateT | undefined>>;
  dateCalendar: DateCalendarData | undefined;
  setDateCalendar: Dispatch<SetStateAction<DateCalendarData | undefined>>;
};

export const MediaGroupsHelperContext =
  createContext<MediaGroupsHelperContextT>({
    cardWidth: "",
    withParticipant: false,
    setMediaToRemove: () => {},
    setLightBoxState: () => {},
    dateCalendar: undefined,
    setDateCalendar: () => {},
  });

export const MediaGroupsHelperContextProvider: FC<
  MediaGroupsHelperContextT
> = ({
  cardWidth,
  withParticipant,
  setMediaToRemove,
  setLightBoxState,
  dateCalendar,
  setDateCalendar,
  children,
}) => (
  <MediaGroupsHelperContext.Provider
    value={{
      cardWidth,
      withParticipant,
      setMediaToRemove,
      setLightBoxState,
      dateCalendar,
      setDateCalendar,
    }}
  >
    {children}
  </MediaGroupsHelperContext.Provider>
);
