import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import CircleButton from "../../../components/CircleButton";
import FlexBox from "../../../components/FlexBox";
import Spacer from "../../../components/Spacer";
import FilesUploadArea from "../../../features/files-upload/FilesUploadArea";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useFetch } from "../../../hooks/useFetch";
import { PendingFileT } from "../../../hooks/usePendingFile";
import { rem, theme } from "../../../styling/theme";
import { UserRoleT, UserT } from "../../types";
import FilesUpload from "../components/files-update/FilesUpload";
import MessageInput, { MESSAGE_INPUT_MARGIN } from "../components/MessageInput";
import Rating from "../components/rating/Rating";
import Report from "../components/report/Report";
import {
  FileStatusTypeE,
  LinkCheckT,
  MessageFileT,
  MessagePayloadT,
  MessageT,
  OfferStateE,
  RatingsT,
} from "../types";

export const FILES_UPLOAD_HEIGHT = 100;

type WrapperStyleT = {
  customHeight: number;
};

const Wrapper = styled(FlexBox)<WrapperStyleT>(({ customHeight }) => ({
  width: "100%",
  minHeight: rem(customHeight + MESSAGE_INPUT_MARGIN * 3),
  padding: `${rem(MESSAGE_INPUT_MARGIN)} ${rem(16)} 0 ${rem(16)}`,
  transition: "min-height 0.4s",
  transitionTimingFunction: theme.timing.default,
  zIndex: 5,

  [theme.media.maxSm]: {
    padding: `${rem(MESSAGE_INPUT_MARGIN)} ${rem(8)} 0 ${rem(8)}`,
  },
}));

const Side = styled(FlexBox)({});

const FilesUploadButtonWrapper = styled(FlexBox)({
  position: "relative",
  marginRight: rem(8),

  [theme.media.maxSm]: {
    marginRight: rem(2),
  },
});

const SPACE_WIDTH = 8;
const ICON_WIDTH = 40;

type MessageWrapperStyleT = {
  approveButton: boolean;
};

const MessageWrapper = styled(FlexBox)<MessageWrapperStyleT>(
  ({ approveButton }) => ({
    maxWidth: `calc(100% - ${
      approveButton ? 3 : 2 * (SPACE_WIDTH + ICON_WIDTH)
    }px)`,
    flex: 1,
    minHeight: "100%",
    margin: 0,
    padding: `${rem(MESSAGE_INPUT_MARGIN)} ${rem(16)}`,
    borderRadius: rem(16),
    backgroundColor: theme.color.backgroundColor,
  })
);

type CreateMessageBarT = {
  offerId: number;
  offerState: OfferStateE | null;
  offerRating?: RatingsT[];
  setShowedMessages: Dispatch<SetStateAction<MessageT[] | null>>;
  scrollToBottom: () => void;
  campaignName?: string;
  linksCheckState: LinkCheckT[];
  userRole: UserRoleT;
  userData: UserT;
};

const CreateMessageBar: FC<CreateMessageBarT> = React.memo(
  ({
    offerId,
    offerState,
    offerRating,
    campaignName,
    linksCheckState,
    userRole,
    userData,
    scrollToBottom,
    setShowedMessages,
  }) => {
    const [pendingFiles, setPendingFiles] = useState<PendingFileT[]>([]);
    const [fileURLs, setFileURLs] = useState<string[]>([]);
    const [newMessage, setNewMessage] = useState<string>("");
    const [temporaryIdState, setTemporaryIdState] = useState<number>(() =>
      Date.now()
    );

    const CHATMESSAGE_INPUT_HEIGHT = 25;
    const [messageInputHeight, setMessageInputHeight] = useState<number>(
      CHATMESSAGE_INPUT_HEIGHT
    );

    const pendingFilesExist = pendingFiles && pendingFiles.length > 0;

    const body = {
      message: {
        body: newMessage,
        file_urls: fileURLs,
        tmp_id: temporaryIdState,
      },
    };

    const { call, payload } = useFetch<MessagePayloadT>(
      getApiUrl(ApiRoutesE.OFFER_MESSAGES, offerId),
      undefined,
      "POST",
      body
    );

    type isUploadingInProgressT = (files: PendingFileT[]) => boolean;
    const isUploadingInProgress: isUploadingInProgressT = (files) =>
      files && files.some((file) => file.status === FileStatusTypeE.pending);

    type createLocalMessageT = (tempId: number) => void;
    const createLocalMessage: createLocalMessageT = (tempId) => {
      const localMessageFiles: MessageFileT[] = fileURLs.map((file, index) => {
        return {
          id: temporaryIdState + index,
          file_url: file,
        };
      });

      const localMessage: MessageT = {
        id: tempId,
        tmp_id: tempId,
        sender: userData,
        recipient: null,
        body: newMessage,
        body_html: `<p>${newMessage}</p>`,
        read_at: null,
        created_at: null,
        is_system: false,
        message_files: localMessageFiles,
        offer: null,
        translations: null,
      };

      setShowedMessages((prevState) =>
        prevState ? [localMessage, ...prevState] : [localMessage]
      );
      scrollToBottom();
    };

    type clearMessageInputT = () => void;
    const clearMessageInput: clearMessageInputT = () => {
      setNewMessage(() => "");
      setPendingFiles(() => []);
      setFileURLs(() => []);
    };

    type handleSendMessageT = () => void;
    const handleSendMessage: handleSendMessageT = () => {
      const temporaryId = Date.now();

      createLocalMessage(temporaryId);
      setTemporaryIdState(() => temporaryId);
    };

    useEffect(() => {
      if (payload) {
        const responseMessage = payload.message;

        setShowedMessages((prevState) => {
          if (prevState) {
            const existedMessageId = prevState.findIndex(
              (item) => responseMessage.tmp_id === item.tmp_id
            );

            if (existedMessageId > -1) {
              prevState.splice(existedMessageId, 1);
            }

            return [responseMessage, ...prevState];
          }

          return [responseMessage];
        });
      }
    }, [payload]);

    useEffect(() => {
      const messageIsNotEmpty =
        body.message.body.length > 0 || body.message.file_urls.length > 0;

      if (temporaryIdState && messageIsNotEmpty) {
        call();
        clearMessageInput();
      }
    }, [temporaryIdState]);

    useEffect(() => {
      clearMessageInput();
    }, [offerId]);

    const disableSending =
      (newMessage.length < 1 && fileURLs && fileURLs.length < 1) ||
      isUploadingInProgress(pendingFiles);

    const influencerRole = userRole === "influencer";

    const allowReporting =
      offerState &&
      (offerState === OfferStateE.in_progress ||
        offerState === OfferStateE.accepted);

    return (
      <Wrapper
        customHeight={
          pendingFilesExist
            ? messageInputHeight + FILES_UPLOAD_HEIGHT
            : messageInputHeight
        }
        alignItems="flex-end"
      >
        <Side justifyContent="flex-start">
          <Report
            offerId={offerId}
            offerState={offerState}
            campaignName={campaignName}
            influencerRole={influencerRole}
            linksCheckState={linksCheckState}
          />
          <Rating
            offerId={offerId}
            offerState={offerState}
            offerRating={offerRating}
            influencerRole={influencerRole}
          />
          <FilesUploadButtonWrapper>
            <FilesUploadArea
              setPendingFiles={setPendingFiles}
              allowCopyPaste={true}
            />
            <CircleButton
              onClickHandle={() => {}}
              icon={IconTypeE.attachment}
              background="transparent"
            />
          </FilesUploadButtonWrapper>
        </Side>
        <MessageWrapper
          approveButton={!!allowReporting}
          flexDirection="column"
          alignItems="stretch"
        >
          <FilesUpload
            customHeight={pendingFilesExist ? FILES_UPLOAD_HEIGHT : 0}
            pendingFiles={pendingFiles}
            setPendingFiles={setPendingFiles}
            setFileURLs={setFileURLs}
          />
          <MessageInput
            height={messageInputHeight}
            setHeight={setMessageInputHeight}
            newMessage={newMessage}
            setNewMessage={setNewMessage}
          />
        </MessageWrapper>
        <Side justifyContent="flex-end">
          <Spacer size="mini" />
          <CircleButton
            onClickHandle={handleSendMessage}
            hoverBackground={theme.color.yellowHoverColor}
            disabledBackground="transparent"
            disabled={disableSending}
            icon={IconTypeE.send}
          />
        </Side>
      </Wrapper>
    );
  }
);

export default CreateMessageBar;
