import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useFetch } from "./useFetch";

type getUrlStringT = (url: string) => string;
const getUrlString: getUrlStringT = (url) => {
  if (url.includes("?")) {
    return `${url}&`;
  }

  return `${url}?`;
};

const PAGINATED_TYPE = {
  campaigns: "campaigns",
  inbox_items: "inbox_items",
  messages: "messages",
  notifications: "notifications",
  offers: "offers",
  stories: "stories",
} as const;

type PaginatedTypeT = keyof typeof PAGINATED_TYPE;
type PaginationPayloadT<T> = Record<PaginatedTypeT, T[]>;

export const usePagination = <T, P = undefined>(
  apiUrl: string,
  perPage: number,
  setShowedItems: Dispatch<SetStateAction<T[] | null>>,
  payloadKey: PaginatedTypeT,
  resetDependency?: number | string | boolean
): {
  firstLoad: boolean;
  pending: boolean;
  queuedItems: T[];
  loadItems: () => void;
  metaPayload: P | undefined;
} => {
  const [firstLoad, setFirstLoad] = useState(true); // for init loader
  const [paginationIndex, setPaginationIndex] = useState<number>(1);
  const [queuedItems, setQueuedItems] = useState<T[]>([]);
  const [countHelper, setCountHelper] = useState(0);
  const [metaPayload, setMetaPayload] = useState<P>();

  const { pending, payload, call } = useFetch<PaginationPayloadT<T>>(
    `${getUrlString(apiUrl)}page=${paginationIndex}&per_page=${perPage}`,
    [paginationIndex],
    "GET"
  );

  // clear queuedItems state and increase page index by 1
  const prepareQueue = (): void => {
    setQueuedItems(() => []);
    setPaginationIndex((prevState) => prevState + 1);
  };

  // reset whole pagination and call first load automatically
  const resetState = (): void => {
    setFirstLoad(() => true);
    setCountHelper(() => 0);

    if (paginationIndex === 1) {
      call();
      return;
    }

    setPaginationIndex(() => 1);
  };

  // update showedStories state and prepare queue
  const loadItems = (): void => {
    setShowedItems((prevState) => {
      if (Array.isArray(prevState) && prevState.length > 0) {
        return [...prevState, ...queuedItems];
      }

      return [];
    });

    prepareQueue();
  };

  useEffect(() => {
    if (payload) {
      const payloadData = payload[payloadKey];

      // when the response from the server contains also metadata with extra data
      // those data will be pass back to the place of hook call by `metaPayload` state
      if (payload["meta"]) {
        setMetaPayload(payload["meta"]);
      }

      setQueuedItems(() => {
        if (payloadData) {
          return payloadData;
        }

        return [];
      });

      if (paginationIndex === 1 && payloadData && payloadData.length === 0) {
        setShowedItems(() => null);
        setFirstLoad(false);
      }
    }
  }, [payload]);

  useEffect(() => {
    // automatic set showedStories state and prepare queue
    // happend during first iteration and only once
    if (paginationIndex === 1 && queuedItems.length > 0) {
      setShowedItems(() => queuedItems);
      prepareQueue();
      return;
    }
  }, [queuedItems]);

  useEffect(() => {
    // set firstLoad to false after first filling of showedItems
    if (paginationIndex === 2) {
      setFirstLoad(false);
    }
  }, [paginationIndex]);

  useEffect(() => {
    if (countHelper > 0) {
      resetState();
    }

    setCountHelper((prevState) => prevState + 1);
  }, [resetDependency]);

  return {
    firstLoad,
    pending: firstLoad ? pending : firstLoad,
    queuedItems,
    loadItems,
    metaPayload,
  };
};
