import * as React from "react";
import { FC, RefObject } from "react";
import { onArrowClickHandleT } from "../../hooks/useHorizontalScroll";
import Arrow from "./Arrow";

type HandlersT = {
  leftArrow: boolean;
  rightArrow: boolean;
  scrollWrapper: RefObject<HTMLDivElement>;
  onArrowClickHandle: onArrowClickHandleT;
};

const Handlers: FC<HandlersT> = ({
  leftArrow,
  rightArrow,
  scrollWrapper,
  onArrowClickHandle,
}) => {
  return (
    <>
      <Arrow
        side={"left"}
        visible={leftArrow}
        onClickHandle={() => onArrowClickHandle(scrollWrapper, "left")}
      />

      <Arrow
        side={"right"}
        visible={rightArrow}
        onClickHandle={() => onArrowClickHandle(scrollWrapper, "right")}
      />
    </>
  );
};

export default Handlers;
