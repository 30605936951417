import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Note from "../../../components/Note";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { useTranslate } from "../../../hooks/useTranslate";
import { theme } from "../../../styling/theme";
import { GetParticipantsReponseT } from "../../reporting-media-groups/data-access/getParticipants";
import SectionsSwitcher from "./SectionsSwitcher";
import SortAndFilter, { FilterStateT, SortingStateT } from "./SortAndFilter";

type OptionsSectionT = {
  section: "participants" | "outputs";
  setSection: Dispatch<SetStateAction<"participants" | "outputs">>;
  filterState: FilterStateT | undefined;
  setFilterState: Dispatch<SetStateAction<FilterStateT | undefined>>;
  sortingState: SortingStateT;
  setSortingState: Dispatch<SetStateAction<SortingStateT>>;
  participants: GetParticipantsReponseT | undefined;
};

const OptionsSection: FC<OptionsSectionT> = ({
  section,
  setSection,
  filterState,
  setFilterState,
  sortingState,
  setSortingState,
  participants,
}) => {
  const { t } = useTranslate("brands.reportings.participants.index");

  return (
    <>
      <Note storageKey="DATA_REFRESH_INFO">
        <FlexBox customWidth={CustomWidthE.full}>
          <Icon
            icon={IconTypeE.arrowRefresh}
            color={theme.color.textGreyDarkColor}
            size="small"
          />
          <Spacer size="mini" />
          <Paragraph
            color={theme.color.textGreyDarkColor}
            paragraph={t("data_refresh_info")}
          />
        </FlexBox>
      </Note>
      <FlexBox
        customWidth={CustomWidthE.full}
        justifyContent="space-between"
        position="relative"
        zIndex={2}
      >
        <SectionsSwitcher section={section} setSection={setSection} />
        <Spacer />
        {section === "outputs" && (
          <SortAndFilter
            filterState={filterState}
            setFiliterState={setFilterState}
            sortingState={sortingState}
            setSortingState={setSortingState}
            participants={participants}
          />
        )}
      </FlexBox>
    </>
  );
};

export default OptionsSection;
