import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Spacer from "../../../components/Spacer";
import { rem } from "../../../styling/theme";
import { AnimationOverlay, Avatar, Button, Line } from "../PlaceholderLoader";

const Wrapper = styled(FlexBox)({
  position: "relative",
  alignItems: "normal",
  width: "100%",
  minHeight: rem(70),
  padding: rem(16),
  borderRadius: rem(16),
  overflow: "hidden",
});

type HeaderPanelPlaceholderT = {
  withAvatar?: boolean;
};

const HeaderPanelPlaceholder: FC<HeaderPanelPlaceholderT> = ({
  withAvatar = true,
}) => (
  <Wrapper customWidth={CustomWidthE.full} fullHeight={true}>
    <AnimationOverlay />
    <FlexBox customWidth={CustomWidthE.full} alignItems="center">
      {withAvatar && (
        <>
          <Avatar size={100} />
          <Spacer size="big" />
        </>
      )}
      <FlexBox
        customWidth={CustomWidthE.full}
        alignItems="space-between"
        flexDirection="column"
      >
        <Line />
        <Line />
        <Line last={true} />
      </FlexBox>
      <Spacer size="big" />
      <FlexBox
        customWidth={CustomWidthE.custom28}
        alignItems="flex-start"
        fullHeight={true}
      >
        <Button />
        <Button isSquare={true} />
        <Button isSquare={true} />
        <Button isSquare={true} />
      </FlexBox>
    </FlexBox>
  </Wrapper>
);

export default HeaderPanelPlaceholder;
