import { QueryClient } from "react-query";
import { GetParticipantsReponseT } from "../getParticipants";

type removeParticipantMutationT = (
  queryClient: QueryClient,
  participantToRemoveId: string,
  afterMutationHandle: () => void
) => void;

export const removeParticipantMutation: removeParticipantMutationT = (
  queryClient,
  participantToRemoveId,
  afterMutationHandle
) => {
  queryClient.setQueryData<GetParticipantsReponseT | undefined>(
    "participants",
    (prevState) => {
      if (prevState) {
        return {
          reporting_participants: prevState.reporting_participants.filter(
            (item) => item.id !== participantToRemoveId
          ),
        };
      }

      return prevState;
    }
  );
  afterMutationHandle();
};
