import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { Doughnut } from "react-chartjs-2";
import FlexBox from "../../../../components/FlexBox";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { CreditableFollowersCardDataT } from "../../types";
import { optionsCredibleFollowers } from "./options";

const Wrapper = styled(FlexBox)({
  maxHeight: rem(210),
  minHeight: rem(210),
});

type CreditableFollowersT = {
  data: CreditableFollowersCardDataT;
};

const CreditableFollowers: FC<CreditableFollowersT> = ({ data }) => {
  const { t } = useTranslate("crm.detail.card.others");
  const { credible, not_credible } = data;
  const values = [credible, not_credible];

  const chartData = {
    labels: [t("credible"), t("not_credible")],
    datasets: [
      {
        data: values,
        backgroundColor: ["#3846A2", "#EB5757"],
        borderWidth: 0,
        hoverOffset: 10,
      },
    ],
  };

  return (
    <Wrapper>
      <Doughnut data={chartData} options={optionsCredibleFollowers} />
    </Wrapper>
  );
};

export default CreditableFollowers;
