import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import CircleButton from "../../../../components/CircleButton";
import Modal from "../../../../components/Modal";
import Tooltip from "../../../../components/Tooltip";
import { rem, theme } from "../../../../styling/theme";
import { LinkCheckT, OfferStateE } from "../../types";
import ReportModal from "./ReportModal";

type WrapperStyleT = {
  visible: boolean;
};

const Wrapper = styled.div<WrapperStyleT>(({ visible }) => ({
  position: "relative",
  marginRight: visible ? rem(8) : 0,
  maxWidth: visible ? rem(100) : 0,
  overflow: visible ? "visible" : "hidden",
  transition: "max-width 0.4s",
  transitionTimingFunction: theme.timing.default,
  [theme.media.maxSm]: {
    marginRight: visible ? rem(2) : 0,
  },
}));

type ReportT = {
  offerId: number;
  offerState: OfferStateE | null;
  campaignName?: string;
  linksCheckState: LinkCheckT[];
  influencerRole: boolean;
};

const Report: FC<ReportT> = React.memo(
  ({ offerId, offerState, campaignName, linksCheckState, influencerRole }) => {
    const [modalOpen, setModalOpen] = useState(false);

    const REPORT_LOCAL_STORAGE_KEY = "REPORT_TOOLTIP";
    const reportTooltipStorage = localStorage.getItem(REPORT_LOCAL_STORAGE_KEY);

    const allowReporting =
      offerState &&
      (offerState === OfferStateE.in_progress ||
        offerState === OfferStateE.accepted);

    type handleOpenReportT = () => void;
    const handleOpenReport: handleOpenReportT = () => {
      if (!reportTooltipStorage) {
        localStorage.setItem(REPORT_LOCAL_STORAGE_KEY, "true");
      }
      setModalOpen(true);
    };

    return (
      <Wrapper visible={!!allowReporting && influencerRole}>
        <CircleButton
          onClickHandle={handleOpenReport}
          background={allowReporting ? theme.color.yellowColor : "transparent"}
          iconColor={
            allowReporting ? theme.color.textColor : theme.color.textGreyColor
          }
          hoverBackground={theme.color.yellowHoverColor}
          disabled={!allowReporting}
          icon={IconTypeE.plusSquare}
          tooltip={
            <Tooltip
              visible={!reportTooltipStorage}
              bouncing={true}
              position="top"
              tooltip={`Pošli své výstupy&nbsp;ke schválení`}
              whiteSpace="normal"
            />
          }
        />
        <Modal
          visible={modalOpen}
          close={() => setModalOpen(false)}
          heading={"Poslat výstupy ke schválení"}
          subHeading={campaignName}
          renderChildren={(visible, close) => (
            <ReportModal
              offerId={offerId}
              visible={visible}
              closeModal={close}
              linksCheckState={linksCheckState}
            />
          )}
        />
      </Wrapper>
    );
  }
);

export default Report;
