import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import Avatar from "../../../components/Avatar";
import FlexBox from "../../../components/FlexBox";
import OfferLabel from "../../../components/OfferLabel";
import Paragraph from "../../../components/Paragraph";
import { getDateTimeDynamicOption } from "../../../helpers/date";
import { rem, theme } from "../../../styling/theme";
import { UnreadIndicatior } from "../../../ui/UnreadIndicator";
import { UserRoleT } from "../../types";
import { OfferT } from "../types";

type WrapperStyleT = {
  active: boolean;
};

const Wrapper = styled(FlexBox)<WrapperStyleT>(({ active }) => ({
  backgroundColor: active ? theme.color.backgroundColor : "transparent",
  padding: rem(16),

  [theme.media.maxSm]: {
    padding: `${rem(16)} ${rem(8)}`,
  },
}));

const InfoWrapper = styled(FlexBox)({
  width: "100%",
  marginTop: rem(15),
});

const ContentWrapper = styled(FlexBox)({
  flexGrow: 1,
  paddingLeft: rem(16),

  [theme.media.maxSm]: {
    paddingLeft: rem(8),
  },
});

const Header = styled.div({
  position: "relative",
  width: "100%",
  paddingRight: rem(14),
  marginBottom: rem(4),
});

type ConverstionCardT = {
  offer: OfferT;
  activeOfferId: number;
  userId: number;
  userRole: UserRoleT;
};

const ConverstionCard: FC<ConverstionCardT> = ({
  offer,
  activeOfferId,
  userId,
  userRole,
}) => {
  const { proposed_price, campaign, state, last_message, currency } = offer;
  const { sender, read_at, created_at, translations } = last_message;
  const { name, campaign_brand } = campaign;

  const unreadMessageCondition = !read_at && sender.id !== userId;

  const [unread, setUnread] = useState<boolean>(unreadMessageCondition);
  const [onTouch, setOnTouch] = useState<boolean>(false);

  const mesage = !!translations ? translations[userRole] : last_message.body;

  useEffect(() => {
    if (activeOfferId === offer.id && unread) {
      setUnread(false);
    }
  }, [activeOfferId]);

  useEffect(() => {
    if (activeOfferId !== offer.id && !unread && unreadMessageCondition) {
      setUnread(true);
    }
  }, [read_at, mesage]);

  const getCardTitle = (): string => {
    const information: string[] = [];
    information.push(
      _.truncate(name, {
        length: 55,
      })
    );

    if (campaign_brand) {
      information.push(campaign_brand.name);
    }

    if (proposed_price) {
      information.push(`${proposed_price}\xa0${currency}`);
    }

    return information.join(" • ");
  };

  return (
    <Wrapper
      alignItems="flex-start"
      onTouchStart={() => setOnTouch(true)}
      onTouchEnd={() => setOnTouch(false)}
      active={onTouch}
    >
      <Avatar src={campaign.image_url} name={campaign.name} size={44} />
      <ContentWrapper flexDirection="column" alignItems="flex-start">
        <Header>
          <Paragraph
            paragraph={getCardTitle()}
            color={unread ? theme.color.textColor : theme.color.textGreyColor}
            fontWeight={unread ? 600 : 400}
          />
          {unread && <UnreadIndicatior />}
        </Header>
        <Paragraph
          paragraph={mesage}
          paragraphSize="small"
          color={unread ? theme.color.textColor : theme.color.textGreyColor}
          truncate={90}
        />
        <InfoWrapper justifyContent="space-between">
          <OfferLabel state={state} />
          {created_at && (
            <Paragraph
              paragraph={getDateTimeDynamicOption(created_at)}
              paragraphSize="small"
              color={theme.color.textGreyColor}
            />
          )}
        </InfoWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default ConverstionCard;
