import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import Avatar from "../../../components/Avatar";
import BlueLink from "../../../components/BlueLink";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Panel from "../../../components/Panel";
import Spacer from "../../../components/Spacer";
import { useLocalSort } from "../../../hooks/useLocalSort";
import { useTranslate } from "../../../hooks/useTranslate";
import TableColumn from "../../../ui/table/TableColumn";
import TableItemWrapper from "../../../ui/table/TableItemWrapper";
import { CompetitiveAnalysisResultT } from "../../types";
import TableHead from "./TableHead";

type TableT = {
  results: CompetitiveAnalysisResultT[];
  setHandle: Dispatch<SetStateAction<string | undefined>>;
};

const Table: FC<TableT> = ({ results, setHandle }) => {
  const { t } = useTranslate("brands.competitive_analyses.show.table");

  const { sortedCollection, setSortingOptions, sortingOptions } =
    useLocalSort<CompetitiveAnalysisResultT>("avg_post_likes", results);

  return (
    <>
      <Panel flexDirection="column">
        <TableHead
          sortingOptions={sortingOptions}
          setSortingOptions={setSortingOptions}
        />
        {sortedCollection &&
          sortedCollection.map((result) => (
            <FlexBox
              key={result.id}
              flexDirection="column"
              customWidth={CustomWidthE.full}
            >
              <TableItemWrapper
                renderChildren={() => {
                  return (
                    <>
                      <TableColumn columnWidth="17%">
                        <Avatar name={result.handle} src={result.avatar_url} />
                        <Spacer size="small" />
                        <BlueLink
                          text={`@${result.handle}`}
                          target="_blank"
                          href={`https://instagram.com/${result.handle}`}
                        />
                      </TableColumn>
                      <TableColumn columnWidth="17%">
                        {result.full_name}
                      </TableColumn>
                      <TableColumn columnWidth="15%">
                        {Math.round(result.avg_post_likes)}
                      </TableColumn>
                      <TableColumn columnWidth="14%">
                        {Math.round(result.avg_post_comments)}
                      </TableColumn>
                      <TableColumn columnWidth="14%">
                        {result.posts_count}
                      </TableColumn>
                      <TableColumn columnWidth="14%">
                        {result.stories_count}
                      </TableColumn>
                      <TableColumn columnWidth="9%">
                        <Button
                          size={ButtonSizeE.small}
                          type={ButtonTypeE.grey}
                          onClick={() => setHandle(result.handle)}
                        >
                          {t("view")}
                        </Button>
                      </TableColumn>
                    </>
                  );
                }}
              />
            </FlexBox>
          ))}
      </Panel>
    </>
  );
};

export default Table;
