import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../components/FlexBox";
import { AntiShake } from "../../../components/GeneralStyles";
import { getDateTime } from "../../../helpers/date";
import { rem, theme } from "../../../styling/theme";

type VisibleStyleT = {
  visible: boolean;
};

const Wrapper = styled(FlexBox)({
  marginBottom: rem(3),
  width: "100%",
  ...AntiShake,
});

const TimeBase = styled.div<VisibleStyleT>(({ visible }) => ({
  color: theme.color.textGreyColor,
  opacity: visible ? 1 : 0,
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,
}));

const CreatedTime = styled(TimeBase)<VisibleStyleT>(({ visible }) => ({
  marginBottom: visible ? rem(5) : 0,
  width: "100%",
  maxHeight: visible ? rem(12) : 0,
  fontSize: rem(12),
  textAlign: "center",
}));

const ReadTime = styled(TimeBase)<VisibleStyleT>(({ visible }) => ({
  marginTop: visible ? rem(2) : 0,
  maxHeight: visible ? rem(10) : 0,
  fontSize: rem(10),
  textAlign: "right",
}));

type MessageBubbleWrapperT = {
  created: string | null;
  read: string | null;
  ownMessage: boolean;
  isLastMessage: boolean;
  focused: boolean;
};

const MessageBubbleWrapper: FC<MessageBubbleWrapperT> = React.memo(
  ({ created, read, children, ownMessage, isLastMessage, focused }) => {
    const showCreatedTime = created && focused;
    const showReadTime =
      (read && isLastMessage) || (read && ownMessage && focused);

    return (
      <Wrapper
        flexDirection="column"
        alignItems={ownMessage ? "flex-end" : "flex-start"}
      >
        <CreatedTime visible={!!showCreatedTime}>
          {created !== null &&
            getDateTime(created, "fullNumeric").toUpperCase()}
        </CreatedTime>
        {children}
        <ReadTime visible={!!showReadTime}>{`Přečteno ${
          read !== null && getDateTime(read, "fullDate")
        }`}</ReadTime>
      </Wrapper>
    );
  }
);

export default MessageBubbleWrapper;
