import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import FlexBox from "../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../components/Heading";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { PendingFileT } from "../../../../hooks/usePendingFile";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { FileStatusTypeE } from "../../../chat/types";
import { useMutateMedia } from "../../hooks/useMutateMedia";
import LineLoadingIndicator from "../../ui/LineLoadingIndicator";
import PendingCoverImage from "./PendingCoverImage";

const Wrapper = styled(FlexBox)({
  position: "absolute",
  padding: rem(48),
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: theme.color.whiteColor,
  opacity: 0.9,
  zIndex: 2,
});

type UploadInProgressT = {
  mediaId: number;
  participantId: string;
  visible: boolean;
  pendingFiles: PendingFileT[];
  setPendingFiles: Dispatch<SetStateAction<PendingFileT[]>>;
  liveAddedMedia?: boolean;
};

const UploadInProgress: FC<UploadInProgressT> = ({
  mediaId,
  participantId,
  visible,
  pendingFiles,
  setPendingFiles,
  liveAddedMedia,
}) => {
  const [firtsLoad, setFirstLoad] = useState(true);

  const { t } = useTranslate("brands.reportings.media_groups");
  const { mutateMedia } = useMutateMedia(
    mediaId,
    "image_urls",
    participantId,
    () => {
      setPendingFiles([]);
    },
    liveAddedMedia ? "addedMediaGroups" : "mediaGroups"
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (!firtsLoad && pendingFiles.length > 0) {
        const uploaded = pendingFiles
          .map((file) => file.status === FileStatusTypeE.uploaded && file.link)
          .filter((url) => url) as string[];

        if (pendingFiles.length === uploaded.length) {
          mutateMedia(uploaded);
        }
      }

      setFirstLoad(false);
    }

    return () => {
      mounted = false;
    };
  }, [pendingFiles]);

  if (!visible) {
    return null;
  }

  return (
    <Wrapper
      justifyContent="center"
      flexDirection="column"
      alignItems="flex-start"
    >
      <Heading
        heading={t("upload")}
        headingType={HeadingTypeT.h4}
        spaceBottom="small"
      />
      <LineLoadingIndicator visible={true} />
      <Spacer size={"mini"} direction="vertical" />
      <Paragraph
        paragraph={t("upload_description")}
        color={theme.color.textGreyDarkColor}
      />
      {pendingFiles.map((file) => (
        <PendingCoverImage
          key={file.id}
          pendingFile={file}
          setPendingFiles={setPendingFiles}
        />
      ))}
    </Wrapper>
  );
};

export default UploadInProgress;
