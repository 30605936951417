import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-scroll";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import Container from "../../../components/Container";
import FlexBox from "../../../components/FlexBox";
import { useResponsive } from "../../../hooks/useResponsive";
import { useScroll } from "../../../hooks/useScroll";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { CampaignGroupKeyT } from "../types";
import { GroupsOffsetsT } from "./Marketplace";

type WrapperStyleT = {
  active: boolean;
};

const Wrapper = styled(FlexBox)<WrapperStyleT>(({ active }) => ({
  position: "fixed",
  top: active ? rem(72) : 0,
  width: "100%",
  minWidth: "100%",
  height: rem(60),
  marginPadding: rem(8),
  backgroundColor: theme.color.whiteColor,
  zIndex: 2,
  boxShadow: `0px 4px 8px rgb(0 0 0 / 0.1)`,
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,
  overflow: "scroll",
}));

const ScrollWrapper = styled(FlexBox)({
  width: "100%",
  overflow: "scroll",
  scrollbarWidth: "none",
  scrollBehavior: "smooth",

  ["&::-webkit-scrollbar"]: {
    display: "none",
  },
});

const Item = styled(FlexBox)({
  marginRight: rem(8),
});

type CampaignsHeaderT = {
  groups: GroupsOffsetsT;
  scrolledGroup: CampaignGroupKeyT;
};

type ItemsOffsetsT = Record<CampaignGroupKeyT, number>;

const CampaignsHeader: FC<CampaignsHeaderT> = ({ groups, scrolledGroup }) => {
  const { t } = useTranslate("influencer.market");
  const { currentOffset } = useScroll();
  const { windowWidth } = useResponsive();
  const [itemsOffsets, setItemsOffsets] = useState<ItemsOffsetsT | {}>({});

  const wrapper = useRef<HTMLDivElement>(null);

  const activeGroupsKeys = Object.keys(groups).filter(
    (key) => groups[key].exists
  );

  const moveToItem = (key: CampaignGroupKeyT): void => {
    if (wrapper.current) {
      const scrollLeft = itemsOffsets[key];
      wrapper.current.scrollLeft = scrollLeft;
    }
  };

  useEffect(() => {
    moveToItem(scrolledGroup);
  }, [scrolledGroup]);

  useEffect(() => {
    const parent = wrapper.current as HTMLDivElement;

    if (!!parent) {
      Object.values(parent.children).forEach((item) => {
        const child = item as HTMLDivElement;

        setItemsOffsets((prevState) => {
          return {
            ...prevState,
            ...{
              [child.id]:
                child.offsetLeft - windowWidth / 2 + child.clientWidth / 2,
            },
          };
        });
      });
    }
  }, [groups]);

  return (
    <Wrapper active={currentOffset > 10}>
      <Container noMargin={true}>
        <ScrollWrapper ref={wrapper} justifyContent="flex-start">
          {activeGroupsKeys.map((item) => {
            const key = item as CampaignGroupKeyT;

            return (
              <Item id={`${key}`} key={key}>
                <Link to={`${key}_ID`} offset={-150}>
                  <Button
                    onClick={() => moveToItem(key)}
                    size={ButtonSizeE.micro}
                    type={
                      key === scrolledGroup
                        ? ButtonTypeE.default
                        : ButtonTypeE.outline
                    }
                  >
                    {t(`${key}.title`)}
                  </Button>
                </Link>
              </Item>
            );
          })}
        </ScrollWrapper>
      </Container>
    </Wrapper>
  );
};

export default CampaignsHeader;
