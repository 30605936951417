import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import { GridWrapper } from "../../../components/GridWrapper";
import ReferencialCard from "../../../features/card/ReferencialCard";
import { rem } from "../../../styling/theme";
import { CampaignT } from "../../chat/types";
import CampaignCard from "./CampaignCard";

const Wrapper = styled(FlexBox)({
  marginBottom: rem(32),
});

type CampaignsGroupStripperT = {
  campaigns: CampaignT[];
};

const CampaignsGroupStripper: FC<CampaignsGroupStripperT> = ({ campaigns }) => {
  const [referencialCardWidth, setReferencialCardWidth] = useState<number>(0);

  return (
    <Wrapper
      flexDirection="column"
      customWidth={CustomWidthE.full}
      alignItems="flex-start"
    >
      <GridWrapper>
        <ReferencialCard setReferencialCardWidth={setReferencialCardWidth} />
        {campaigns.map((campaign) => (
          <CampaignCard
            key={campaign.id}
            campaign={campaign}
            cardWidth={rem(referencialCardWidth)}
            hideOption={false}
          />
        ))}
      </GridWrapper>
    </Wrapper>
  );
};

export default CampaignsGroupStripper;
