import { useMutation, useQueryClient } from "react-query";
import { FieldTypeT, MediaGroupT, QueryStackT } from "../data-access/types";
import { updateMedia } from "../data-access/updateMedia";

type mutateMediaT = (value: string | number | string[] | null) => void;

type useMutateMediaT = (
  id: number,
  valueKey: FieldTypeT | "published_at" | "image_urls" | "url",
  participantId: string,
  onSuccessHandle?: () => void,
  queryStack?: QueryStackT
) => { disabled: boolean; mutateMedia: mutateMediaT };

export const useMutateMedia: useMutateMediaT = (
  id,
  valueKey,
  participantId,
  onSuccessHandle,
  queryStack = "mediaGroups"
) => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(updateMedia, {
    onSuccess: (data) => {
      queryClient.setQueryData<MediaGroupT[] | undefined>(
        queryStack,
        (prevState) => {
          if (prevState) {
            const currentMediaGroupIndex = prevState.findIndex(
              (mediaGroup) =>
                mediaGroup.id === data.media.reporting_media_group_id
            );

            if (currentMediaGroupIndex > -1) {
              const currendMediaIndex = prevState[
                currentMediaGroupIndex
              ].medias.findIndex((media) => media.id === data.media.id);

              if (currendMediaIndex > -1) {
                prevState[currentMediaGroupIndex].medias[currendMediaIndex] =
                  data.media;
              }
            }
          }

          return prevState;
        }
      );

      onSuccessHandle && onSuccessHandle();
    },
  });

  const mutateMedia: mutateMediaT = (value) => {
    mutate({
      id,
      participantId: participantId,
      updatedMediaData: {
        media: {
          id: id,
          [valueKey]: value,
        },
      },
    });
  };

  return {
    disabled: isLoading,
    mutateMedia,
  };
};
