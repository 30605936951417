import { SocialNetworkProviderT } from "../../helpers/socialMediaProviders";
import { UserT } from "../types";

export type LinkValidationPayloadT = {
  success: "async" | boolean;
  errors: string[] | null;
};

export type MessagesPayloadT = {
  messages: MessageT[];
};

export type MessagePayloadT = {
  message: MessageT;
};

export type OffersPayloadT = {
  offers: OfferT[];
};

export type OfferPayloadT = {
  offer: OfferT;
};

export type StoriesPayloadT = {
  stories: StoryT[];
};

export enum OfferStateE {
  created = "created",
  in_progress = "in_progress",
  accepted = "accepted",
  declined = "declined",
}

export enum CampaignStateE {
  draft = "draft",
  published = "published",
  approved = "approved",
  disapproved = "disapproved",
  finished = "finished",
  expired = "expired",
}

type BrandT = {
  id: number;
  name: string;
  logo_url: string | null;
};

export type CampaignT = {
  barter: boolean;
  campaign_brand: BrandT | null;
  id: number;
  image_url: string;
  name: string;
  state: CampaignStateE;
  short_description: string;
  user: UserT;

  expiration_text: string;
  invited: boolean | null;
  offer_path: string | null;
  offered: boolean;
  budget: string;
  budget_spent: string;
  launch_date_from: string;
};

type LinkDataStaticDataT = {
  media_url: string;
  permalink: string;
  real_url: string;
  timestamp: string;
};

type LinkDataT = {
  report?: object;
  static_data?: LinkDataStaticDataT;
  error?: string;
};

export type LinkT = {
  id: number;
  data: LinkDataT;
  valid_class: "has-error" | "has-ok";
  engagement: number;
  file_url: string;
  ig_story: StoryT | null;
  provider: SocialNetworkProviderT | null;
  rate: null;
  url: string | null;
  view_count: number;
};

export enum LinkTypeE {
  post = "post",
  story = "story",
  file = "file",
}

export type RatingsT = {
  created_at: string;
  feedback: string;
  id: number;
  offer_id: number;
  stars: number;
  user: UserT;
};

export type OfferT = {
  id: number;
  last_message: LastMessageT;
  proposed_price: number;
  links: LinkT[] | null;
  campaign: CampaignT;
  state: OfferStateE;
  user: UserT;
  ratings: RatingsT[];
  currency: string;
};

export type MessageFileT = {
  id: number;
  file_url: string;
};

export type MessageTranslationsT = {
  brand: string;
  influencer: string;
};

export type LastMessageT = {
  id: number;
  tmp_id: number | null;
  sender: UserT;
  recipient: UserT | null;
  body: string;
  body_html: string;
  read_at: string | null;
  created_at: string | null;
  is_system: boolean;
  message_files: MessageFileT[];
  translations: MessageTranslationsT | null;
};

export type LocalMessageT = {
  id: number;
  tmp_id: number | null;
  sender: UserT;
  recipient: UserT | null;
  body: string;
  body_html: string;
  read_at: string | null;
  created_at: string | null;
  is_system: boolean;
  message_files: MessageFileT[];
  offer: OfferT;
  translations: MessageTranslationsT | null;
};

export type MessageT = {
  id: number;
  tmp_id: number | null;
  sender: UserT;
  recipient: UserT | null;
  body: string;
  body_html: string;
  read_at: string | null;
  created_at: string | null;
  is_system: boolean;
  message_files: MessageFileT[];
  offer: OfferT | null;
  translations: MessageTranslationsT | null;
};

export type RecivedMessageT = {
  html: any;
  json: MessageT;
};

export type LinkCheckT = {
  html: "string";
  json: LinkT;
  object_id: string;
};

export type RecivedDataT = {
  message?: RecivedMessageT;
  message_id?: number;
  sender_id?: number;
  link_check?: boolean;
  link?: LinkCheckT;
};

export type StoryT = {
  id: string;
  media_type: "IMAGE" | "VIDEO";
  media_url: string | null;
  thumbnail_url: string | null;
  timestamp: string;
  permalink: string;
  caption: string;
  insights: string;
  owner: string;
};

export enum FileStatusTypeE {
  pending = "pending",
  willErase = "willErase",
  erased = "erased",
  uploaded = "uploaded",
  failed = "failed",
}
