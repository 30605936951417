import { _o } from "./helpers";

export const tooltipsInit = () => {
  const tooltips = document.getElementsByClassName("tooltip");
  const collections = [];

  const toggleTooltip = (tooltip, show) => {
    if (show) {
      tooltip.classList.add("visible");

      return;
    }

    tooltip.classList.remove("visible");
  };

  if (tooltips) {
    _o(tooltips).forEach((tooltip) => {
      const inputElement = document.getElementById(tooltip.id);

      if (inputElement) {
        collections.push([tooltip, inputElement]);
      }
    });

    if (collections && collections.length > 0) {
      collections.forEach((collection) => {
        const [tooltip, inputElement] = collection;

        inputElement.addEventListener("focusin", () =>
          toggleTooltip(tooltip, true)
        );
        inputElement.addEventListener("focusout", () =>
          toggleTooltip(tooltip, false)
        );
      });
    }
  }
};
