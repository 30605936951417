type infoMessageHandleT = (input: HTMLFormElement) => void;
const infoMessageHandle: infoMessageHandleT = (input) => {
  const productBudgetInfo = document.getElementById("campaign-budget-info");
  const value = parseInt(input.value);

  if (productBudgetInfo) {
    productBudgetInfo.classList.add("hidden");

    if (isNaN(value) || value === 0) {
      productBudgetInfo.classList.remove("hidden");
    }
  }
};

export const budgetOnChange = (): void => {
  const input = document.getElementById("campaign-budget-from");

  if (input) {
    input.addEventListener("input", () =>
      infoMessageHandle(input as HTMLFormElement)
    );
    infoMessageHandle(input as HTMLFormElement);
  }
};
