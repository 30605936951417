import { putSpacesToThousand, serializeArray, _o } from "../helpers";

const _ = window;
_.CAMPAIGN_CURRENCY = "CZK";

export const setCurrency = (withReset = true) => {
  _.CAMPAIGN_CURRENCY = getInputValue("create_campaign", "campaign[currency]");

  if (withReset) {
    resetBudgetValue();
    updateLabels();
  }
};

export const updateCurrencyLabels = () => {
  const currencyLabels = document.getElementsByClassName(
    "campaign-currency-label"
  );

  if (currencyLabels) {
    _o(currencyLabels).map((label) => {
      label.innerHTML = _.CAMPAIGN_CURRENCY;
    });
  }
};

export const setBudgetToLabel = (prefix, value = false) => {
  const budgetLabelElement = document.getElementById(`${prefix}-label`);

  if (budgetLabelElement && !isNaN(value)) {
    budgetLabelElement.innerHTML = putSpacesToThousand(value);
  }
};

export const updateLabels = () => {
  updateCurrencyLabels();

  const currenBudget = getInputValue("create_campaign", "campaign[budget]");
  setBudgetToLabel("campaign-budget", currenBudget || false);
};

export const getInputValue = (formId, valueName) => {
  const formElement = document.getElementById(formId);

  if (formElement) {
    const formInputs = serializeArray(formElement);
    const input = formInputs.filter(
      (input) => input.name === valueName && !!input.value
    );

    if (!input || input.length < 1) {
      console.warn(`WARNING: Value ${valueName} does not exist!`);
      return 0;
    }

    return input[0].value;
  }

  console.error(`ERROR: Form ${formId} does not exist!`);
};

export const setInputValue = (inputId, value) => {
  document.getElementById(inputId).value = value;
};

const convertBudget = (budget, toCurrency) => {
  const CONVERSE_RATE = 26;
  return toCurrency === "EUR" ? budget / CONVERSE_RATE : budget * CONVERSE_RATE;
};

export const resetBudgetValue = () => {
  const currentCurrency = _.CAMPAIGN_CURRENCY;
  const currentBudget = getInputValue("create_campaign", "campaign[budget]");

  const convertedBudget =
    Math.round(convertBudget(currentBudget, currentCurrency) / 10) * 10;

  setInputValue("campaign-budget-from", convertedBudget);
};

export const getListOfNetworks = () => {
  const campaignNetworks = document.getElementById("campaigns-networks");
  const networks = [];

  if (campaignNetworks && campaignNetworks.children) {
    const networksElements = _o(campaignNetworks.children);

    networksElements.map(
      (network) =>
        networks &&
        network.dataset.network &&
        networks.push(network.dataset.network)
    );
  }

  return networks;
};

export const hotUpdate = (source, value) => {
  source.textContent = value;
};
