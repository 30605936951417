import styled from "@emotion/styled";

export enum CustomWidthE {
  full = "100%",
  half = "50%",
  quarter = "25%",
  custom72 = "72%",
  custom40 = "40%",
  custom28 = "28%",
}

export type FlexDirectionT = "row" | "column" | "row-reverse";
export type FlexWrapT = "nowrap" | "wrap";
export type AlignT =
  | "stretch"
  | "center"
  | "flex-start"
  | "space-around"
  | "flex-end"
  | "space-between"
  | "auto";

type FlexBoxT = {
  flexDirection?: FlexDirectionT;
  justifyContent?: AlignT;
  alignItems?: AlignT;
  alignSelf?: AlignT;
  flexWrap?: FlexWrapT;
  title?: string;
  fullHeight?: boolean;
  customWidth?: CustomWidthE | string;
  position?: "static" | "relative" | "absolute";
  zIndex?: number | "auto";
  cursor?: "auto" | "pointer";
};

const FlexBox = styled.div<FlexBoxT>(
  ({
    flexDirection = "row",
    justifyContent = "center",
    alignItems = "center",
    alignSelf = "auto",
    flexWrap = "nowrap",
    fullHeight = false,
    customWidth,
    position = "static",
    zIndex = "auto",
    cursor = "auto",
  }) => ({
    position,
    display: "flex",
    flexDirection,
    justifyContent,
    alignItems,
    alignSelf,
    flexWrap,
    height: fullHeight ? "100%" : "auto",
    width: customWidth ? customWidth : "auto",
    zIndex,
    cursor,
  })
);

export default FlexBox;
