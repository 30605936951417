import * as ActionCable from "@rails/actioncable";
import * as React from "react";
import { createContext } from "react";
import { ChatDataPropsT } from "../types";
import Chat from "./partials/Chat";

const CableApp = { cable: null };
CableApp.cable = ActionCable.createConsumer();
export const ActionCableContext = createContext(null);

export default ({ data, lightVersion }: ChatDataPropsT): JSX.Element => {
  return (
    <ActionCableContext.Provider value={CableApp.cable}>
      <Chat lightVersion={lightVersion} data={data} />
    </ActionCableContext.Provider>
  );
};
