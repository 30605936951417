import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Avatar from "../../../components/Avatar";
import BlueLink from "../../../components/BlueLink";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import CircleButton from "../../../components/CircleButton";
import FlexBox from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { useResponsive } from "../../../hooks/useResponsive";
import { rem, theme } from "../../../styling/theme";
import { CampaignT, OfferStateE } from "../types";

export const CHAT_HEADER_HEIGHT = 70;

const Wrapper = styled(FlexBox)({
  width: "100%",
  padding: `0 ${rem(16)}`,
  minHeight: rem(CHAT_HEADER_HEIGHT),
  borderBottom: `1px solid ${theme.color.greyColor}`,

  [theme.media.maxSm]: {
    padding: `0 ${rem(8)}`,
  },
});

const CampaignInfoWrapper = styled(FlexBox)({
  [theme.media.maxMd]: {
    width: "100%",
  },
  [theme.media.maxMi]: {
    fontSize: rem(13),
  },
});

const ActionDesktopWrapper = styled(FlexBox)({
  [theme.media.maxMd]: {
    display: "none",
  },
});

const ActionDevicesWrapper = styled(FlexBox)({
  [theme.media.md]: {
    display: "none",
  },
});

type CampaignDetailT = {
  offerId: number;
  offerState: OfferStateE | null;
  campaign: CampaignT;
  setOfferDetailVisible: Dispatch<SetStateAction<boolean>>;
};

const CampaignDetail: FC<CampaignDetailT> = ({
  campaign,
  offerState,
  offerId,
  setOfferDetailVisible,
}) => {
  const { image_url, name, campaign_brand, id } = campaign;

  const { tabletVersion, mobileVersion } = useResponsive();

  return (
    <Wrapper
      flexDirection={tabletVersion ? "column" : "row"}
      alignItems="center"
      justifyContent={tabletVersion ? "center" : "space-between"}
    >
      <CampaignInfoWrapper
        justifyContent="space-between"
        flexDirection={tabletVersion ? "row-reverse" : "row"}
      >
        <Avatar src={image_url} name={name} size={40} />
        <Spacer />
        <FlexBox
          flexDirection="column"
          alignItems="stretch"
          justifyContent={tabletVersion ? "space-between" : "center"}
        >
          <Paragraph
            paragraph={name}
            paragraphSize={mobileVersion ? "small" : "default"}
            fontWeight={600}
            align="left"
            display="inline-block"
            truncate={mobileVersion ? 30 : 50}
          />
          {!tabletVersion && campaign_brand && (
            <Paragraph
              paragraph={campaign_brand.name}
              paragraphSize="small"
              display="inline-block"
            />
          )}

          <ActionDevicesWrapper>
            {offerState === OfferStateE.created && (
              <>
                <BlueLink
                  href={`/influencers/offers/${offerId}/edit`}
                  text={"Upravit nabídku"}
                />
                <Spacer size={mobileVersion ? "mini" : "small"} />
              </>
            )}
            <BlueLink
              href={`/influencers/campaigns/${id}`}
              text={"Zobrazit kampaň"}
            />
          </ActionDevicesWrapper>
        </FlexBox>
        {tabletVersion && (
          <>
            <Spacer />
            <CircleButton
              onClickHandle={() => setOfferDetailVisible(false)}
              icon={IconTypeE.arrowLeft}
              background={theme.color.backgroundColor}
              iconSize="small"
            />
          </>
        )}
      </CampaignInfoWrapper>
      <ActionDesktopWrapper>
        {offerState === OfferStateE.created && (
          <Button
            type={ButtonTypeE.outline}
            size={ButtonSizeE.micro}
            href={`/influencers/offers/${offerId}/edit`}
          >
            {"Upravit nabídku"}
          </Button>
        )}
        <Spacer size="small" />
        <Button href={`/influencers/campaigns/${id}`} size={ButtonSizeE.micro}>
          {"Zobrazit kampaň"}
        </Button>
      </ActionDesktopWrapper>
    </Wrapper>
  );
};

export default CampaignDetail;
