/* eslint-disable react/jsx-no-undef */
import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox from "../../../components/FlexBox";
import Spacer from "../../../components/Spacer";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import ParentTab from "../components/ParentTab";
import { FilterCountsT, FilterItemT } from "../types";

const Wrapper = styled(FlexBox)({
  position: "relative",
  width: `calc(100% - ${rem(16)})`,
  margin: "0 auto",
  bottom: "-1px",
  borderBottom: `1px solid ${theme.color.greyColor}`,
  zIndex: 3,
});

type OffersHeaderT = {
  filterCounts: FilterCountsT | null;
  currentFilter: FilterItemT;
  setCurrentFilter: Dispatch<SetStateAction<FilterItemT>>;
};

const OffersHeader: FC<OffersHeaderT> = ({
  filterCounts,
  currentFilter,
  setCurrentFilter,
}) => {
  const WAITING_LIST_GROUP: FilterItemT[] = [
    "choose",
    "targeted",
    "starred",
    "invited",
    "other",
  ];
  const WAITING_LIST_GROUP_FOR_COUNT = WAITING_LIST_GROUP.filter(
    (item) => item != "choose"
  );
  const OPEN_DEALS_GROUP: FilterItemT[] = ["open", "in_progress", "accepted"];
  const ARCHIVED: FilterItemT[] = ["declined"];

  const { t } = useTranslate("influencer.market.filter");

  type getCountsSubsetT = (
    group: FilterItemT[]
  ) => Partial<Record<FilterItemT, number | null>>;
  const getCountsSubset: getCountsSubsetT = (group) => {
    const obj = {};

    group.forEach((item) => {
      obj[item] =
        filterCounts && (filterCounts[item] || filterCounts[item] === 0)
          ? filterCounts[item]
          : null;
    });

    return obj;
  };

  return (
    <Wrapper justifyContent="space-between">
      <FlexBox>
        <ParentTab
          tabTitle={t("waiting_list")}
          items={getCountsSubset(WAITING_LIST_GROUP_FOR_COUNT)}
          currentFilter={currentFilter}
          defaultItem="targeted"
          setCurrentFilter={setCurrentFilter}
          current={WAITING_LIST_GROUP.includes(currentFilter)}
        />
        <Spacer size="mini" />
        <ParentTab
          tabTitle={t("open_deals")}
          items={getCountsSubset(OPEN_DEALS_GROUP)}
          currentFilter={currentFilter}
          defaultItem="open"
          setCurrentFilter={setCurrentFilter}
          current={OPEN_DEALS_GROUP.includes(currentFilter)}
        />
      </FlexBox>
      <ParentTab
        tabTitle={t("declined")}
        items={getCountsSubset(ARCHIVED)}
        currentFilter={currentFilter}
        defaultItem="declined"
        setCurrentFilter={setCurrentFilter}
        current={ARCHIVED.includes(currentFilter)}
        icon={IconTypeE.archived}
      />
    </Wrapper>
  );
};

export default OffersHeader;
