import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../components/Heading";
import Icon from "../../../../components/Icon";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { useCardCoverHeight } from "../../../../hooks/useCardCoverHeight";
import { PendingFileT } from "../../../../hooks/usePendingFile";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import UploadEmptyStateArea from "../../../../ui/UploadEmptyStateArea";
import { CardOriginT } from "../../data-access/types";

type NoImageWrapperStyleT = {
  customHeight: string;
};

const NoImageWrapper = styled(FlexBox)<NoImageWrapperStyleT>(
  ({ customHeight }) => ({
    height: customHeight,
    margin: `0 ${rem(4)}`,
    width: `calc(100% - ${rem(8)})`,
    borderRadius: rem(6),
    backgroundColor: theme.color.greyLightColor,
  })
);

const NoPreview = styled(FlexBox)({
  padding: rem(24),
});

const Wrapper = styled(FlexBox)({
  position: "relative",
  margin: `0 ${rem(4)}`,
  width: `calc(100% - ${rem(8)})`,
});

const Content = styled(FlexBox)({
  padding: `0 ${rem(24)}`,
});

type CoverOptionsT = {
  defaltHeight: number;
  aspectRatio: number[];
};

type EmptyStateT = {
  setPendingFiles: Dispatch<SetStateAction<PendingFileT[]>>;
  options: CoverOptionsT;
  origin: CardOriginT;
  wrapperWidth?: number;
};

const EmptyState: FC<EmptyStateT> = ({
  setPendingFiles,
  options,
  origin,
  wrapperWidth,
}) => {
  const { t } = useTranslate("brands.reportings.media_groups.empty_state");
  const { coverHeight } = useCardCoverHeight(
    wrapperWidth,
    options.defaltHeight,
    options.aspectRatio
  );

  if (origin === "private" || origin === "public") {
    return (
      <NoImageWrapper
        customWidth={CustomWidthE.full}
        customHeight={rem(coverHeight)}
      >
        <NoPreview flexDirection="column">
          <Icon
            icon={IconTypeE.eyeStrike}
            color={theme.color.textLightGreyColor}
            size="big"
          />
          <Spacer direction="vertical" size="small" />
          <Paragraph
            paragraph={t("missing_image")}
            color={theme.color.textLightGreyColor}
            fontWeight={500}
            align="center"
          />
        </NoPreview>
      </NoImageWrapper>
    );
  }

  return (
    <Wrapper>
      <UploadEmptyStateArea
        setPendingFiles={setPendingFiles}
        height={coverHeight}
      >
        <Content flexDirection="column">
          <Icon
            icon={IconTypeE.upload}
            size={"monster"}
            color={theme.color.textGreyDarkColor}
          />
          <Spacer direction="vertical" />
          <Heading
            heading={t("add_files")}
            headingType={HeadingTypeT.h4}
            spaceBottom={10}
            color={theme.color.textGreyDarkColor}
          />
          <Paragraph
            paragraph={t("description")}
            align="center"
            paragraphSize="small"
          />
          <Spacer direction="vertical" />
          <Paragraph
            paragraph={t("supported_formats")}
            align="center"
            paragraphSize="micro"
            color={theme.color.textGreyColor}
          />
          <Paragraph
            paragraph={t("formats")}
            align="center"
            paragraphSize="micro"
            color={theme.color.textGreyColor}
          />
          <Spacer direction="vertical" size="big" />
          <Button type={ButtonTypeE.outline} size={ButtonSizeE.small}>
            {t("browse")}
          </Button>
        </Content>
      </UploadEmptyStateArea>
    </Wrapper>
  );
};

export default EmptyState;
