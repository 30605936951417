import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import Container from "../../../components/Container";
import FlexBox from "../../../components/FlexBox";
import { MarketplaceDataPropsT } from "../../../entry-files/types";
import CampaignsGroupStripper from "../components/CampaignsGroupStripper";

const Wrapper = styled(FlexBox)({
  position: "relative",
});

const MarketplaceRestricted: FC<MarketplaceDataPropsT> = ({ data }) => {
  const { campaigns } = data;

  return (
    <Container>
      <Wrapper flexDirection="column">
        <CampaignsGroupStripper campaigns={campaigns} />
      </Wrapper>
    </Container>
  );
};

export default MarketplaceRestricted;
