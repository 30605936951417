import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import CircleButton from "../../../components/CircleButton";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Note, { NoteContent } from "../../../components/Note";
import Panel from "../../../components/Panel";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import VisibleWrapper from "../../../components/VisibleWrapper";
import { useResponsive } from "../../../hooks/useResponsive";
import { useTranslate } from "../../../hooks/useTranslate";
import { breakpoints, theme } from "../../../styling/theme";
import { UserRoleT } from "../../types";
import CopyLinkButton from "./CopyLinkButton";

type AutoMessageT = {
  auto: boolean;
  visible: boolean;
  noteKey: string;
  role: UserRoleT | null;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

const AutoMessage: FC<AutoMessageT> = ({
  auto,
  visible,
  noteKey,
  role,
  setShowModal,
}) => {
  const { breakePointCondition } = useResponsive(breakpoints.sm);
  const { t } = useTranslate("brands.reportings.media_groups.autoreporting");

  const getMessageContent = (): JSX.Element => {
    if (role && role === "brand") {
      return (
        <Panel justifyContent="flex-start" marginBottom={20}>
          <NoteContent alignItems="flex-start" customWidth={CustomWidthE.full}>
            <CircleButton icon={IconTypeE.megaphone} iconSize="small" />
            <Spacer />
            <FlexBox
              flexDirection={breakePointCondition ? "row" : "column"}
              justifyContent="space-between"
              customWidth={CustomWidthE.full}
            >
              <FlexBox flexDirection="column" alignItems="flex-start">
                <Paragraph paragraph={t("brand.title")} fontWeight={600} />
                <Spacer direction="vertical" size="micro" />
                <Paragraph
                  paragraph={t("brand.text")}
                  color={theme.color.textGreyColor}
                  paragraphSize="small"
                />
              </FlexBox>
              <Spacer
                direction={breakePointCondition ? "horizontal" : "vertical"}
              />
              <FlexBox
                alignSelf="center"
                justifyContent="flex-end"
                customWidth={
                  !breakePointCondition ? CustomWidthE.full : undefined
                }
              >
                <CopyLinkButton link={window.location.href} />
              </FlexBox>
            </FlexBox>
          </NoteContent>
        </Panel>
      );
    }

    return (
      <Panel justifyContent="flex-start" marginBottom={20}>
        <NoteContent alignItems="flex-start" customWidth={CustomWidthE.full}>
          <CircleButton icon={IconTypeE.megaphone} iconSize="small" />
          <Spacer />
          <FlexBox
            flexDirection={breakePointCondition ? "row" : "column"}
            justifyContent="space-between"
            customWidth={CustomWidthE.full}
          >
            <FlexBox flexDirection="column" alignItems="flex-start">
              <Paragraph paragraph={t("influencer.title")} fontWeight={600} />
              <Spacer direction="vertical" size="micro" />
              <Paragraph
                paragraph={t("influencer.text")}
                color={theme.color.textGreyColor}
                paragraphSize="small"
              />
            </FlexBox>
            <Spacer
              direction={breakePointCondition ? "horizontal" : "vertical"}
            />
            <FlexBox
              alignSelf="center"
              justifyContent="flex-end"
              customWidth={
                !breakePointCondition ? CustomWidthE.full : undefined
              }
            >
              <Button
                onClick={() => setShowModal(true)}
                size={ButtonSizeE.micro}
                type={ButtonTypeE.outline}
              >
                {t("influencer.allow")}
              </Button>
            </FlexBox>
          </FlexBox>
        </NoteContent>
      </Panel>
    );
  };

  if (auto) {
    return (
      <Note
        withIcon={IconTypeE.check}
        iconBackground={theme.color.successColor}
        iconColor={theme.color.whiteColor}
        marginBottom={20}
        storageKey={`${_.snakeCase(noteKey)}_AUTOREPORTING`}
      >
        <FlexBox flexDirection="column" alignItems="flex-start">
          <Paragraph
            paragraph={
              role === "brand"
                ? t("brand.approved_title")
                : t("influencer.approved_title")
            }
            fontWeight={600}
          />
          <Spacer direction="vertical" size="micro" />
          <Paragraph
            paragraph={
              role === "brand"
                ? t("brand.approved_text")
                : t("influencer.approved_text")
            }
            color={theme.color.textGreyColor}
            paragraphSize="small"
          />
        </FlexBox>
      </Note>
    );
  }

  return (
    <VisibleWrapper visible={visible} type="roll">
      {getMessageContent()}
    </VisibleWrapper>
  );
};

export default AutoMessage;
