import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Carousel } from "react-responsive-carousel";
import { IconTypeE } from "../../../../assets/icons/Icons";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Icon from "../../../../components/Icon";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import { theme } from "../../../../styling/theme";
import { ClearCarouselWrapper } from "../../../../ui/ClearCarouselWrapper";
import { removeMediaImage } from "../../data-access/removeMediaImage";
import { MediaFileT, MediaGroupT } from "../../data-access/types";
import CarouselLightBoxArrow from "../../ui/CarouselLightBoxArrow";
import LightBoxItem from "./LightBoxItem";

export type LightBoxStateT = {
  files: MediaFileT[];
  mediaId: number;
  mediaGroup: MediaGroupT;
  parentCurrentImage: number;
  setParentCurrentImage: Dispatch<SetStateAction<number>>;
};

type LightBoxModalT = {
  lightBoxState: LightBoxStateT;
  close: () => void;
};

const LightBoxModal: FC<LightBoxModalT> = ({ lightBoxState, close }) => {
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [removeConfirm, setRemoveConfirm] = useState(false);

  const { t } = useTranslate("brands.reportings.media_groups.buttons");
  const { mediaGroup, files, parentCurrentImage, setParentCurrentImage } =
    lightBoxState;

  const { origin, participant } = mediaGroup;

  const { mutate, isLoading } = useMutation(removeMediaImage, {
    onSuccess: () => {
      if (files.length === 1 || currentImage === 0) {
        close();
      }

      files.splice(currentImage, 1);

      if (parentCurrentImage === currentImage) {
        setParentCurrentImage(0);
      }

      setCurrentImage((prevState) => (prevState < 0 ? prevState - 1 : 0));
    },
    onSettled: () => {
      setRemoveConfirm(false);
    },
  });

  useEffect(() => {
    if (parentCurrentImage !== currentImage) {
      setCurrentImage(parentCurrentImage);
    }
  }, [parentCurrentImage]);

  const removeOnClickHandle = (): void => {
    if (removeConfirm) {
      mutate({ id: files[currentImage].id, participantId: participant.id });
      return;
    }

    setRemoveConfirm(true);
  };

  return (
    <FlexBox flexDirection="column">
      <FlexBox justifyContent="space-between" customWidth={CustomWidthE.full}>
        <FlexBox customWidth={CustomWidthE.quarter} justifyContent="flex-start">
          {origin === "manual" && (
            <Button
              onClick={removeOnClickHandle}
              type={removeConfirm ? ButtonTypeE.error : ButtonTypeE.grey}
              size={ButtonSizeE.micro}
              disabled={isLoading}
            >
              <Icon
                icon={IconTypeE.bin}
                size="small"
                color={
                  removeConfirm ? theme.color.whiteColor : theme.color.textColor
                }
              />
              <Spacer size="mini" />
              {t(removeConfirm ? "confirm_remove_image" : "remove_image")}
            </Button>
          )}
        </FlexBox>
        <Paragraph paragraph={`${currentImage + 1} / ${files.length}`} />
        <FlexBox customWidth={CustomWidthE.quarter} justifyContent="flex-end">
          <Button
            onClick={() => {
              removeConfirm && setRemoveConfirm(false);
              close();
            }}
            type={ButtonTypeE.clear}
            size={ButtonSizeE.micro}
          >
            <Icon icon={IconTypeE.cross} size="mini" />
          </Button>
        </FlexBox>
      </FlexBox>
      <Spacer direction="vertical" size="micro" />
      <ClearCarouselWrapper flexDirection="column">
        <Carousel
          showArrows={files.length > 1}
          showThumbs={false}
          emulateTouch={true}
          useKeyboardArrows={true}
          selectedItem={currentImage}
          onChange={(index) => setCurrentImage(index)}
          renderArrowPrev={(clickHandler, hasPrev) => (
            <CarouselLightBoxArrow
              disable={hasPrev}
              visible={files.length > 1}
              onClickHandle={() => {
                removeConfirm && setRemoveConfirm(false);
                clickHandler();
              }}
              side="left"
            />
          )}
          renderArrowNext={(clickHandler, hasNext) => (
            <CarouselLightBoxArrow
              disable={hasNext}
              visible={files.length > 1}
              onClickHandle={() => {
                removeConfirm && setRemoveConfirm(false);
                clickHandler();
              }}
              side="right"
            />
          )}
        >
          {files.map((file) => (
            <LightBoxItem key={file.id} url={file.file_url} />
          ))}
        </Carousel>
      </ClearCarouselWrapper>
    </FlexBox>
  );
};

export default LightBoxModal;
