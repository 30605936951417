import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Icon from "../../../../components/Icon";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { TopBar } from "../../../../ui/cards-ui/styles";
import Dropdown from "../../../../ui/Dropdown";
import { mediaGroupLabelsIcons } from "../../data-access/dataConstants";
import { CardOriginT, MediaGroupKindT, MediaT } from "../../data-access/types";
import { MediaToRemoveStateT } from "../MediaGoupsContainer";

const IconWrapper = styled(FlexBox)({
  cursor: "pointer",
});

const DropdownWrapper = styled(FlexBox)({
  padding: `${rem(16)} ${rem(12)}`,
  cursor: "pointer",
});

type CardHeaderT = {
  media: MediaT;
  mediaGroupKind: MediaGroupKindT;
  mediaGroupParticipantId: string;
  setMediaToRemove: Dispatch<SetStateAction<MediaToRemoveStateT | undefined>>;
  origin: CardOriginT;
  liveAdded?: boolean;
  lastStory?: boolean;
  storyCounter?: string;
};

const CardHeader: FC<CardHeaderT> = React.memo(
  ({
    media,
    mediaGroupKind,
    mediaGroupParticipantId,
    setMediaToRemove,
    origin,
    liveAdded = false,
    lastStory = false,
    storyCounter = undefined,
  }) => {
    const [active, setActive] = useState(false);

    const { t } = useTranslate("brands.reportings");
    const label = t(`labels.${mediaGroupKind}`);

    const onRemoveClickHandle = (): void => {
      // lastStory flag is for the use case when media card is the last in StorySet carousel
      // card act same as a simple card without carousel

      setMediaToRemove({
        media,
        participantId: mediaGroupParticipantId,
        removed: false,
        story: media.kind === "story" && !lastStory,
        queryStack: liveAdded ? "addedMediaGroups" : "mediaGroups",
      });
    };

    return (
      <TopBar customWidth={CustomWidthE.full} justifyContent="space-between">
        <FlexBox>
          <Icon icon={mediaGroupLabelsIcons[mediaGroupKind]} size="small" />
          <Spacer size="micro" />
          <Paragraph
            paragraph={`${label} ${storyCounter ? storyCounter : ""}`}
            fontWeight={500}
          />
        </FlexBox>
        <Spacer />
        {origin === "manual" && (
          <FlexBox position="relative">
            <IconWrapper onClick={() => setActive(!active)}>
              <Icon icon={IconTypeE.dots} size="small" />
            </IconWrapper>
            <Dropdown
              active={active}
              width={rem(180)}
              setInactive={() => setActive(false)}
            >
              <DropdownWrapper onClick={onRemoveClickHandle}>
                <Icon icon={IconTypeE.bin} size={"small"} />
                <Spacer size="mini" />
                <Paragraph
                  paragraph={t("media_groups.buttons.remove_post")}
                  fontWeight={600}
                  cursor="pointer"
                />
              </DropdownWrapper>
            </Dropdown>
          </FlexBox>
        )}
      </TopBar>
    );
  }
);

export default CardHeader;
