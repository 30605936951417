import * as React from "react";
import { FC, useState } from "react";
import { IllustrationTypeE } from "../../../assets/illustrations/Illustrations";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Modal from "../../../components/Modal";
import NoResults from "../../../components/NoResults";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../features/Placeholders/PlaceholderLoader";
import { useTranslate } from "../../../hooks/useTranslate";
import TableContentWrapper from "../../campaign-detail/components/TableContentWrapper";
import { RecordMediaT } from "../../types";
import { BrandRecordT } from "../types";
import AddBrand from "./AddBrand";
import MediaModal from "./MediaModal";
import TableHead from "./TableHead";
import TableItem from "./TableItem";

type AffintyTableT = {
  brands: BrandRecordT[] | null;
};

const AffintyTable: FC<AffintyTableT> = ({ brands }) => {
  const [openModal, setOpenModal] = useState<RecordMediaT[] | null>(null);

  const { t } = useTranslate("crm.detail.brand_affinity");

  const getContent = (): JSX.Element => {
    if (brands && brands.length > 0) {
      return (
        <>
          {brands?.map((item, index) => (
            <TableItem
              key={item.id}
              item={item}
              last={index === brands.length - 1}
              setCurrentMedias={setOpenModal}
            />
          ))}
        </>
      );
    }

    return (
      <TableContentWrapper height={350}>
        <NoResults
          illustrationSize={300}
          illustration={IllustrationTypeE.scissors}
          message={t("no_results")}
        />
      </TableContentWrapper>
    );
  };

  return (
    <FlexBox flexDirection="column" customWidth={CustomWidthE.full}>
      <TableHead />
      {brands ? (
        <>
          {getContent()}
          <AddBrand />
        </>
      ) : (
        <PlaceholderLoader
          type={PlaceholdersTypeE.table_item_no_avatar}
          count={3}
          direction="column"
        />
      )}
      <Modal
        visible={!!openModal}
        close={() => setOpenModal(null)}
        heading={t("all_mentions")}
        side="right"
        renderChildren={() => {
          return <MediaModal medias={openModal} />;
        }}
      />
    </FlexBox>
  );
};

export default AffintyTable;
