import * as React from "react";
import { FC, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { openExternalModal } from "../../../helpers/crossPacks";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem } from "../../../styling/theme";
import Dropdown, {
  DropdownAnchorWrapper,
  DropdownItem,
} from "../../../ui/Dropdown";
import SpinLoader from "../../../ui/SpinLoader";
import PPTExport from "../ppt-export/PPTExport";

type ActionButtonsT = {
  basePath: string;
  reportingId: number;
  isAbsolutePosition?: boolean;
};

const ActionButtons: FC<ActionButtonsT> = ({
  basePath,
  reportingId,
  isAbsolutePosition,
}) => {
  const [downloadHover, setDownloadHover] = useState(false);
  const [pptxIsGenerating, setPptxIsGenerating] = useState(false);
  const { t } = useTranslate("brands.reportings.participants.index");

  const smallButtonColor = isAbsolutePosition
    ? ButtonTypeE.darkGrey
    : ButtonTypeE.grey;

  return (
    <>
      <Button
        onClick={() => {
          openExternalModal("modal-trigger-add-influencer");
        }}
        size={ButtonSizeE.small}
        customWidth={isAbsolutePosition ? CustomWidthE.full : undefined}
      >
        <Icon icon={IconTypeE.plus} size="mini" />
        <Spacer size="micro" />
        {t("new")}
      </Button>
      <Spacer size="mini" />
      <Button
        onClick={() => {
          openExternalModal("modal-trigger-edit");
        }}
        type={smallButtonColor}
        size={ButtonSizeE.small}
      >
        <Icon icon={IconTypeE.edit} size="small" />
      </Button>
      <Spacer size="mini" />
      <FlexBox
        position="relative"
        onMouseEnter={() => setDownloadHover(true)}
        onMouseLeave={() => setDownloadHover(false)}
      >
        <Button type={smallButtonColor} size={ButtonSizeE.small}>
          {pptxIsGenerating ? (
            <SpinLoader />
          ) : (
            <Icon icon={IconTypeE.download} size="small" />
          )}
        </Button>
        <Dropdown active={downloadHover} width={rem(100)}>
          <FlexBox flexDirection="column" customWidth={CustomWidthE.full}>
            <DropdownAnchorWrapper href={`${basePath}.csv`}>
              <DropdownItem>
                <Icon icon={IconTypeE.csv} color={"#1E7145"} />
                <Spacer size="mini" />
                <Paragraph
                  paragraph={".csv"}
                  paragraphSize="small"
                  fontWeight={600}
                />
              </DropdownItem>
            </DropdownAnchorWrapper>
            <DropdownAnchorWrapper href={`${basePath}.xlsx`}>
              <DropdownItem>
                <Icon icon={IconTypeE.xlsx} color={"#1E7145"} />
                <Spacer size="mini" />
                <Paragraph
                  paragraph={".xlsx"}
                  paragraphSize="small"
                  fontWeight={600}
                />
              </DropdownItem>
            </DropdownAnchorWrapper>
            <DropdownItem>
              <PPTExport
                setPptxIsGenerating={setPptxIsGenerating}
                reportingId={reportingId}
              >
                <Icon icon={IconTypeE.pptx} color={"#D24826"} />
                <Spacer size="mini" />
                <Paragraph
                  paragraph={".pptx"}
                  paragraphSize="small"
                  fontWeight={600}
                />
              </PPTExport>
            </DropdownItem>
          </FlexBox>
        </Dropdown>
      </FlexBox>
    </>
  );
};

export default ActionButtons;
