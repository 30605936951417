import { Dispatch, FC, SetStateAction, useEffect } from "react";
import usePendingFile, { PendingFileT } from "../../../../hooks/usePendingFile";

type PendingCoverImageT = {
  pendingFile: PendingFileT;
  setPendingFiles: Dispatch<SetStateAction<PendingFileT[]>>;
};

const PendingCoverImage: FC<PendingCoverImageT> = ({
  pendingFile,
  setPendingFiles,
}) => {
  const { failure } = usePendingFile(pendingFile, setPendingFiles);

  useEffect(() => {
    if (failure) {
      console.warn("Image upload failed. Please try it again.");
      return;
    }
  }, [failure]);

  return null;
};

export default PendingCoverImage;
