import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Input from "../../../components/Input";
import Spacer from "../../../components/Spacer";
import { useTranslate } from "../../../hooks/useTranslate";
import { theme } from "../../../styling/theme";

const TextWrapper = styled(FlexBox)({
  height: "100%",
  whiteSpace: "nowrap",
  overflow: "hidden",
  cursor: "pointer",
});

const StyledInput = styled(Input)({
  width: "100%",
  padding: 0,
  color: theme.color.textGreyDarkColor,
  direction: "rtl",
  cursor: "pointer",
});

type CopyLinkButtonT = {
  link: string;
  buttonSize?: ButtonSizeE;
  linkText?: boolean;
};

const CopyLinkButton: FC<CopyLinkButtonT> = ({
  link,
  buttonSize = ButtonSizeE.small,
  linkText = false,
}) => {
  const [copiedMessage, setCopiedMessage] = useState<string | undefined>(
    undefined
  );

  const { t } = useTranslate("brands.reportings.media_groups.autoreporting");

  const showCopiedText = (): void => {
    setCopiedMessage(t("brand.tooltip"));

    setTimeout(() => {
      setCopiedMessage(undefined);
    }, 1500);
  };

  const onCopyLinkClickHandle = (): void => {
    navigator.clipboard.writeText(link).then(showCopiedText);
  };

  return (
    <Button
      onClick={onCopyLinkClickHandle}
      type={ButtonTypeE.grey}
      size={buttonSize}
      customWidth={linkText ? CustomWidthE.full : undefined}
      tooltip={copiedMessage}
      tooltipVisibility="solid"
    >
      <Icon icon={IconTypeE.link} size="small" />
      <Spacer size="mini" />
      {linkText ? (
        <TextWrapper customWidth={CustomWidthE.full}>
          <StyledInput disabled={true} value={link} />
        </TextWrapper>
      ) : (
        t("brand.copy")
      )}
    </Button>
  );
};

export default CopyLinkButton;
