import * as React from "react";
import { FC, useContext } from "react";
import AvatarTile from "../../../../components/AvatarTile";
import { CustomWidthE } from "../../../../components/FlexBox";
import { rem } from "../../../../styling/theme";
import CardWrapper from "../../../../ui/cards-ui/CardWrapper";
import { Content } from "../../../../ui/cards-ui/styles";
import { MediaGroupsHelperContext } from "../../context-providers/MediaGroupsHelperContextProvider";
import { MediaGroupT, MediaT } from "../../data-access/types";
import CoverImages from "../card-cover/CoverImages";
import CardFooter from "./CardFooter";
import CardHeader from "./CardHeader";
import InsightsForm from "./InsightsForm";

type MediaCardT = {
  media: MediaT;
  mediaGroup: MediaGroupT;
  liveAddedMedia?: boolean;
  cardStorySizing?: boolean;
  lastStory?: boolean;
  storyCounter?: string;
};

const MediaCard: FC<MediaCardT> = ({
  media,
  mediaGroup,
  liveAddedMedia,
  cardStorySizing,
  lastStory,
  storyCounter,
}) => {
  const { origin, kind, participant } = mediaGroup;
  const { files, id, updated_at } = media;
  const { handle, profile_url } = participant;

  const { cardWidth, setMediaToRemove, setLightBoxState, withParticipant } =
    useContext(MediaGroupsHelperContext);

  return (
    <CardWrapper
      cardWidth={cardWidth}
      storySizing={cardStorySizing}
      renderChildren={(wrapperWidth) => (
        <>
          <CardHeader
            media={media}
            mediaGroupKind={kind}
            mediaGroupParticipantId={participant.id}
            origin={origin}
            setMediaToRemove={setMediaToRemove}
            liveAdded={liveAddedMedia}
            lastStory={lastStory}
            storyCounter={storyCounter}
          />
          <CoverImages
            mediaId={id}
            mediaGroup={mediaGroup}
            existedImages={files}
            origin={origin}
            wrapperWidth={wrapperWidth}
            setLightBoxState={setLightBoxState}
            liveAddedMedia={liveAddedMedia}
          />
          <Content
            flexDirection="column"
            alignItems="flex-start"
            customWidth={CustomWidthE.full}
            padding={`${rem(12)} ${rem(16)}`}
          >
            {withParticipant && (
              <AvatarTile name={handle} imageUrl={profile_url} />
            )}
            <InsightsForm
              media={media}
              origin={origin}
              liveAddedMedia={liveAddedMedia}
              mediaGroupParticipantId={participant.id}
            />
          </Content>
          <CardFooter cardOrigin={origin} updatedTime={updated_at} />
        </>
      )}
    />
  );
};

export default MediaCard;
