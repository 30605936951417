import _ from "lodash";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  MOBILE_VERSION_BREAKPOINT,
  TABLET_VERSION_BREAKPOINT,
} from "../styling/theme";

type useResponsiveT = {
  mobileVersion: boolean;
  tabletVersion: boolean;
  windowWidth: number;
  windowHeight: number;
  breakePointCondition?: boolean;
};

type deviceCheckT = (
  width: number,
  deviceBreakePoint: number,
  setState: Dispatch<SetStateAction<boolean>>
) => void;
const deviceCheck: deviceCheckT = (width, deviceBreakePoint, setState) => {
  if (width < deviceBreakePoint) {
    setState(true);
    return;
  }

  setState(false);
};

export const useResponsive = (breakePoint?: number): useResponsiveT => {
  const WINDOW_CONST = typeof window !== "undefined" && window;

  const [tabletVersion, setTabletVersion] = useState(false);
  const [mobileVersion, setMobileVersion] = useState(false);
  const [dimensions, setDimensions] = useState({
    windowWidth: 0,
    windowHeight: 0,
  });

  const { windowWidth, windowHeight } = dimensions;

  const breakePointCondition = !!(breakePoint && breakePoint < windowWidth);

  useEffect(() => {
    const handleResize = (): void => {
      setDimensions({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });

      deviceCheck(
        window && window.innerWidth,
        MOBILE_VERSION_BREAKPOINT,
        setMobileVersion
      );
      deviceCheck(
        window && window.innerWidth,
        TABLET_VERSION_BREAKPOINT,
        setTabletVersion
      );
    };
    handleResize();

    WINDOW_CONST &&
      WINDOW_CONST.addEventListener("resize", _.throttle(handleResize, 300));
    return (): void => {
      WINDOW_CONST &&
        WINDOW_CONST.removeEventListener(
          "resize",
          _.throttle(handleResize, 300)
        );
    };
  }, [WINDOW_CONST]);

  return {
    mobileVersion,
    tabletVersion,
    windowWidth,
    windowHeight,
    breakePointCondition,
  };
};
