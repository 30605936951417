import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { rem, theme } from "../styling/theme";
import FlexBox from "./FlexBox";
import Icon from "./Icon";
import Paragraph from "./Paragraph";
import Spacer from "./Spacer";

const Wrapper = styled(FlexBox)<{ active: boolean }>(({ active }) => ({
  width: rem(64),
  height: rem(64),
  padding: rem(2),
  border: "2px solid",
  borderColor: active ? theme.color.yellowColor : theme.color.greyLightColor,
  backgroundColor: active ? theme.color.yellowLightHoverColor : "transparent",
  borderRadius: rem(8),
  transition: "all 0.3s",
  transitionTimingFunction: theme.timing.default,
  cursor: "pointer",
  userSelect: "none",

  ["&:hover"]: {
    backgroundColor: theme.color.yellowLightHoverColor,
    borderColor: theme.color.yellowColor,
  },
}));

type CheckBigIconT = {
  active: boolean;
  onClickHandle: () => void;
  icon: IconTypeE;
  label?: string;
};

const CheckBigIcon: FC<CheckBigIconT> = ({
  active,
  onClickHandle,
  icon,
  label,
}) => {
  const color = active
    ? theme.color.textGreyDarkColor
    : theme.color.textLightGreyColor;

  return (
    <Wrapper active={active} onClick={onClickHandle} flexDirection="column">
      <Icon icon={icon} color={color} />
      {label && (
        <>
          <Spacer direction="vertical" size="micro" />
          <Paragraph
            paragraphSize={"micro"}
            fontWeight={600}
            paragraph={label}
            color={color}
          />
        </>
      )}
    </Wrapper>
  );
};

export default CheckBigIcon;
