import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { useResponsive } from "../hooks/useResponsive";
import { rem, theme } from "../styling/theme";
import FlexBox, { CustomWidthE } from "./FlexBox";
import Modal from "./Modal";
import Paragraph from "./Paragraph";
import Spacer from "./Spacer";
import VisibleWrapper from "./VisibleWrapper";

const CONTENT_GAP = 8;

type ContentStyleT = {
  width: number;
  padding: number;
  transform?: string;
} & CustomPositionT;

type WrapperStyleT = {
  isActive: boolean;
};

const Wrapper = styled(FlexBox)<WrapperStyleT>(({ isActive }) => ({
  zIndex: isActive ? 2 : 1,

  [theme.media.maxMd]: {
    zIndex: "auto",
  },
}));

const TriggerWrapper = styled(FlexBox)({
  marginBottom: rem(-CONTENT_GAP),
  paddingBottom: rem(CONTENT_GAP),
});

const Position = styled(VisibleWrapper)({
  position: "absolute",
  top: rem(CONTENT_GAP),
  left: rem(0),
});

const Content = styled(FlexBox)<ContentStyleT>(
  ({
    width,
    padding,
    transform = "none",
    left = rem(0),
    top = "100%",
    bottom = "auto",
    right = "auto",
  }) => ({
    position: "absolute",
    left,
    top,
    bottom,
    right,
    padding: rem(padding),
    width: rem(width),
    backgroundColor: theme.color.whiteColor,
    borderRadius: rem(8),
    boxShadow: `0px 12px 60px rgb(0 0 0 / 0.1)`,
    transform,
  })
);

type CustomPositionT = {
  top?: string;
  left?: string;
  right?: string;
  bottom?: string;
};

type PopoverT = {
  renderTrigger: (active: boolean) => JSX.Element;
  content: string | JSX.Element;
  title?: string;
  width?: number;
  forceActive?: "open" | "close";
  padding?: number;
  customPosition?: CustomPositionT;
};

const Popover: FC<PopoverT> = ({
  renderTrigger,
  content,
  title,
  width = 340,
  forceActive = undefined,
  padding = 16,
  customPosition,
}) => {
  const [active, setActive] = useState(false);
  const { tabletVersion } = useResponsive();

  type setActiveStateHandleT = (state: boolean) => void;
  const setActiveStateHandle: setActiveStateHandleT = (state) => {
    if (!forceActive) {
      setActive(state);
    }
  };

  useEffect(() => {
    if (forceActive) {
      setActive(forceActive === "open" ? true : false);
    }
  }, [forceActive]);

  if (tabletVersion) {
    return (
      <Wrapper
        position="relative"
        isActive={active}
        onTouchStart={() => setActiveStateHandle(!active)}
      >
        <TriggerWrapper customWidth={CustomWidthE.full}>
          {renderTrigger(active)}
        </TriggerWrapper>
        <Modal
          visible={active}
          close={() => setActive(false)}
          heading={title}
          renderChildren={() => (
            <>
              {typeof content === "string" ? (
                <Paragraph
                  paragraph={content}
                  color={theme.color.textGreyDarkColor}
                />
              ) : (
                content
              )}
            </>
          )}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper
      onMouseEnter={() => setActiveStateHandle(true)}
      onMouseLeave={() => setActiveStateHandle(false)}
      isActive={active}
      position="relative"
    >
      <TriggerWrapper customWidth={CustomWidthE.full}>
        {renderTrigger(active)}
      </TriggerWrapper>

      <Position visible={active} transitionTime={0.3}>
        <Content
          padding={padding}
          width={width}
          flexDirection="column"
          alignItems="flex-start"
          top={customPosition?.top}
          bottom={customPosition?.bottom}
          left={customPosition?.left}
          right={customPosition?.right}
        >
          {title && (
            <>
              <Paragraph
                paragraph={title}
                color={
                  tabletVersion
                    ? theme.color.textColor
                    : theme.color.textGreyDarkColor
                }
                fontWeight={600}
                paragraphSize={"small"}
              />
              <Spacer direction="vertical" size={"mini"} />
            </>
          )}
          <>
            {typeof content === "string" ? (
              <Paragraph
                paragraph={content}
                color={theme.color.textGreyDarkColor}
                paragraphSize={"small"}
              />
            ) : (
              content
            )}
          </>
        </Content>
      </Position>
    </Wrapper>
  );
};

export default Popover;
