import * as React from "react";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { IconTypeE } from "../../../../assets/icons/Icons";
import Button from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";
import NoResults from "../../../../components/NoResults";
import Panel from "../../../../components/Panel";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../features/Placeholders/PlaceholderLoader";
import { openExternalModal } from "../../../../helpers/crossPacks";
import { useTranslate } from "../../../../hooks/useTranslate";
import TableContentWrapper from "../../../campaign-detail/components/TableContentWrapper";
import { GetParticipantsReponseT } from "../../../reporting-media-groups/data-access/getParticipants";
import { removeParticipantMutation } from "../../../reporting-media-groups/data-access/mutations/removeParticipantMutation";
import { removeParticipant } from "../../../reporting-media-groups/data-access/removeParticipant";
import RemoveParticipantModal from "./RemoveParticipantModal";
import ShareModal from "./ShareModal";
import TableHead from "./TableHead";
import TableRow from "./TableRow";

type TableT = {
  reportingId: number;
  dataIsLoading: boolean;
  participantsState?: GetParticipantsReponseT;
};

export type ParticipantToRemove = {
  id: string;
  reportingId: number;
  removed: boolean;
};

const Table: FC<TableT> = ({
  reportingId,
  dataIsLoading,
  participantsState,
}) => {
  const [shareLink, setShareLink] = useState<string | undefined>();
  const [removeParticipantDisable, setRemoveParticipantDsiable] =
    useState<boolean>(false);
  const [participantToRemove, setParticipantToRemove] = useState<
    ParticipantToRemove | undefined
  >(undefined);

  const { t } = useTranslate("brands.reportings.participants.index");

  const queryClient = useQueryClient();
  const { mutate } = useMutation(removeParticipant, {
    onMutate: () => {
      setRemoveParticipantDsiable(true);
      setParticipantToRemove((prevState) => {
        if (prevState) {
          return { ...prevState, removed: true };
        }

        return prevState;
      });
    },
    onSuccess: () => {
      if (participantToRemove) {
        setTimeout(() => {
          removeParticipantMutation(
            queryClient,
            participantToRemove.id,
            clearStatesAfterRemoving
          );
        }, 300);
      }
    },

    onError: () => {
      clearStatesAfterRemoving();
    },
  });

  const clearStatesAfterRemoving = (): void => {
    setParticipantToRemove(undefined);
    setRemoveParticipantDsiable(false);
  };

  const removeParticipantHandle = (): void => {
    if (participantToRemove) {
      const data = {
        id: participantToRemove.id,
        reportingId,
      };

      mutate(data);
    }
  };

  const getTableContent = (): JSX.Element => {
    const participants =
      participantsState && participantsState.reporting_participants;

    if (participants && participants.length > 0) {
      return (
        <>
          <TableHead />
          {participants.map((item, index) => (
            <TableRow
              key={item.id}
              participant={item}
              reportingId={reportingId}
              last={participants.length - 1 === index}
              setShareLink={setShareLink}
              hide={
                !!(
                  participantToRemove &&
                  participantToRemove.id === item.id &&
                  participantToRemove.removed
                )
              }
              setParticipantToRemove={setParticipantToRemove}
            />
          ))}
        </>
      );
    }

    if (dataIsLoading) {
      return (
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          <PlaceholderLoader
            type={PlaceholdersTypeE.table_item}
            count={5}
            direction="column"
          />
        </FlexBox>
      );
    }

    return (
      <TableContentWrapper blocksAreRows={true}>
        <NoResults
          illustrationSize={350}
          message={t("dashboard.empty_title")}
        />
        <Spacer direction="vertical" />
        <Paragraph align="center" paragraph={t("dashboard.empty_text")} />
        <Spacer direction="vertical" />
        <Button
          onClick={() => {
            openExternalModal("modal-trigger-add-influencer");
          }}
        >
          <Icon icon={IconTypeE.plus} size="mini" />
          <Spacer size="micro" />
          {t("new")}
        </Button>
      </TableContentWrapper>
    );
  };

  return (
    <>
      <Panel flexDirection="column" position="relative">
        {getTableContent()}
      </Panel>

      <Modal
        visible={!!participantToRemove}
        close={() => setParticipantToRemove(undefined)}
        slim={true}
        renderChildren={() => (
          <RemoveParticipantModal
            disabled={removeParticipantDisable}
            removeParticipantHandle={removeParticipantHandle}
            setParticipantToRemove={setParticipantToRemove}
          />
        )}
      />

      <Modal
        visible={!!shareLink}
        close={() => setShareLink(undefined)}
        slim={true}
        renderChildren={() => (
          <ShareModal shareLink={shareLink} setShareLink={setShareLink} />
        )}
      />
    </>
  );
};

export default Table;
