import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import FlexBox, { CustomWidthE } from "../../components/FlexBox";
import Panel from "../../components/Panel";
import Spacer from "../../components/Spacer";
import { useResponsive } from "../../hooks/useResponsive";
import { breakpoints, rem } from "../../styling/theme";

const BOTTOM_MARGIN = 20;
const ACTIONS_HEIGHT = 40;

type ActionPositionStyleT = {
  actionsAbsolute: boolean;
};

const Wrapper = styled(FlexBox)<ActionPositionStyleT>(
  ({ actionsAbsolute }) => ({
    paddingBottom: actionsAbsolute ? rem(ACTIONS_HEIGHT + BOTTOM_MARGIN) : 0,
  })
);

const StyledPanel = styled(Panel)<ActionPositionStyleT>(
  ({ actionsAbsolute }) => ({
    minHeight: actionsAbsolute ? rem(60) : rem(78),
  })
);

const ActionButtons = styled(FlexBox)<ActionPositionStyleT>(
  ({ actionsAbsolute }) => ({
    position: actionsAbsolute ? "absolute" : "relative",
    bottom: actionsAbsolute ? rem(BOTTOM_MARGIN + 4) : "auto",
    width: actionsAbsolute ? "100%" : "auto",
    marginRight: actionsAbsolute ? 0 : rem(16),
    maxHeight: rem(ACTIONS_HEIGHT),
  })
);

const ColumnsWrapper = styled(FlexBox)<ActionPositionStyleT>(
  ({ actionsAbsolute }) => ({
    width: actionsAbsolute ? "100%" : "auto",
    maxWidth: actionsAbsolute ? "none" : "max-content",
    minWidth: actionsAbsolute ? 0 : "max-content",
    flexWrap: actionsAbsolute ? "wrap" : "nowrap",
    justifyContent: "flex-start",
  })
);

type ColumnsBarT = {
  renderContent?: (mobilePosition: boolean) => JSX.Element;
  renderActions?: (isAbsolutePosition: boolean) => JSX.Element;
};

const ColumnsBar: FC<ColumnsBarT> = ({
  renderActions,
  renderContent,
  children,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const actionsRef = useRef<HTMLDivElement>(null);

  const { windowWidth } = useResponsive();

  type LayoutT = "default" | "mobile";

  const [wrapperWidth, setWrapperWidth] = useState<number | undefined>();
  const [contentWidth, setContentWidth] = useState<number | undefined>();
  const [actionsWidth, setActionsWidth] = useState<number | undefined>();

  const [layout, setLayout] = useState<LayoutT>("default");

  useLayoutEffect(() => {
    setWrapperWidth(wrapperRef.current?.clientWidth);
  }, [windowWidth]);

  useLayoutEffect(() => {
    setContentWidth(contentRef.current?.clientWidth);
    setActionsWidth(actionsRef.current?.clientWidth);
  }, []);

  useEffect(() => {
    if (wrapperWidth && contentWidth && actionsWidth) {
      if (
        wrapperWidth < breakpoints.sm ||
        wrapperWidth <= contentWidth + actionsWidth + 20
      ) {
        setLayout("mobile");
        return;
      }

      setLayout("default");
    }
  }, [wrapperWidth]);

  const mobileCondition = layout === "mobile";

  return (
    <Wrapper
      actionsAbsolute={mobileCondition}
      customWidth={CustomWidthE.full}
      flexDirection="column"
      position="relative"
    >
      <StyledPanel
        ref={wrapperRef}
        justifyContent="space-between"
        marginBottom={BOTTOM_MARGIN}
        actionsAbsolute={mobileCondition}
      >
        <ColumnsWrapper
          ref={contentRef}
          fullHeight={true}
          actionsAbsolute={mobileCondition}
        >
          {children && children}
          {renderContent && renderContent(layout === "mobile")}
        </ColumnsWrapper>
        {renderActions && (
          <ActionButtons ref={actionsRef} actionsAbsolute={mobileCondition}>
            {renderActions(mobileCondition)}
            <Spacer direction="vertical" />
          </ActionButtons>
        )}
      </StyledPanel>
    </Wrapper>
  );
};

export default ColumnsBar;
