import styled from "@emotion/styled";
import FlexBox from "../../components/FlexBox";
import { rem, theme } from "../../styling/theme";

const TableHeadWrapper = styled(FlexBox)({
  padding: `${rem(8)} 0`,
  width: "100%",
  borderBottom: `1px solid ${theme.color.greyColor}`,
  zIndex: 0,
});

export default TableHeadWrapper;
