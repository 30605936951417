import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { SocialNetworkProviderT } from "../helpers/socialMediaProviders";
import { rem, theme } from "../styling/theme";
import FlexBox from "./FlexBox";
import Icon, { getIconSize, IconSizeT } from "./Icon";

type SocialIconT = {
  socialNetwork: SocialNetworkProviderT;
  size?: IconSizeT;
};

type CircleWrapperStyleT = {
  size: number;
};

const CircleWrapper = styled(FlexBox)<CircleWrapperStyleT>(({ size }) => ({
  width: rem(size),
  height: rem(size),
  borderRadius: "50%",
  background: theme.color.yellowColor,
}));

type getSocialIconTypeT = (
  socialNetwork: SocialNetworkProviderT,
  size: number
) => JSX.Element;

const getSocialIconType: getSocialIconTypeT = (socialNetwork, size) => {
  if (socialNetwork === "facebook") {
    return <Icon icon={IconTypeE.socFacebook} size={size} />;
  }
  if (socialNetwork === "instagram") {
    return <Icon icon={IconTypeE.socInstagram} size={size} />;
  }
  if (socialNetwork === "youtube") {
    return <Icon icon={IconTypeE.socYoutube} size={size} />;
  }
  if (socialNetwork === "tiktok") {
    return <Icon icon={IconTypeE.socTiktok} size={size} />;
  }

  return (
    <CircleWrapper size={size}>
      <Icon icon={IconTypeE.socDefault} size={size / 2} />
    </CircleWrapper>
  );
};

const SocialIcon: FC<SocialIconT> = ({ size = "default", socialNetwork }) => {
  const iconSize = getIconSize(size);

  return getSocialIconType(socialNetwork, iconSize * 2);
};

export default SocialIcon;
