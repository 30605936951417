import { wizardInit } from "./wizard";

$(document).on("turbolinks:load", () => {
  const filterFormId = "filter-form";
  const filterForm = document.getElementById(filterFormId);

  if (filterForm) {
    wizardInit();
  }
});
