import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import CircleButton from "../../../components/CircleButton";
import FlexBox from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import SocialIcon from "../../../components/SocialIcon";
import Spacer from "../../../components/Spacer";
import {
  FileTypeE,
  getFileExtension,
  getFileName,
  getFileType,
} from "../../../helpers/fileType";
import { getRGBaColor, rem, theme } from "../../../styling/theme";
import { LinkT } from "../types";
import { ImagePreview } from "./files-update/PendingFile";

type GradientOverlayStyleT = {
  backgroundColor: string;
};

const Wrapper = styled(FlexBox)({
  width: "100%",
});

const LinksWrapper = styled(FlexBox)({
  position: "relative",
  width: "100%",
  paddingBottom: rem(8),
  flexWrap: "wrap",
  overflow: "hidden",
});

const ImageWrapper = styled(FlexBox)({
  height: "100%",
});

const PostWrapper = styled(FlexBox)({
  marginBottom: rem(4),
});

const PostLink = styled.a({
  display: "block",
  fontSize: rem(13),
  fontWeight: 500,
});

const MediaLink = styled.a({
  position: "relative",
  width: "24%",
  maxHeight: rem(110),
  minHeight: rem(110),
  overflow: "hidden",
  marginRight: "1%",
  marginBottom: rem(5),
  borderRadius: rem(4),
  backgroundColor: theme.color.backgroundColor,
  border: `1px solid ${theme.color.greyColor}`,

  [theme.media.maxSm]: {
    width: "32%",
    maxWidth: rem(120),
  },

  [theme.media.maxMi]: {
    width: "49%",
  },
});

const GradientOverlay = styled.div<GradientOverlayStyleT>(
  ({ backgroundColor }) => ({
    position: "absolute",
    width: rem(20),
    height: "100%",
    top: 0,
    right: 0,
    zIndex: 1,
    background: `linear-gradient(90deg, ${getRGBaColor(
      backgroundColor,
      0
    )} 0%, ${getRGBaColor(backgroundColor, 1)} 100%)`,
  })
);

const MediaIcon = styled(CircleButton)({
  position: "absolute",
  right: rem(5),
  top: rem(5),
});

type SystemMessageLinksT = {
  links: LinkT[];
  backgroundColor: string;
};

type getFileTypeCollectionT = (link: LinkT) => {
  type: FileTypeE;
  fileUrl: string;
};
const getFileTypeCollection: getFileTypeCollectionT = (link) => {
  const { file_url, ig_story } = link;

  if (ig_story) {
    const { media_type, media_url, thumbnail_url } = ig_story;
    return {
      type: FileTypeE.image,
      fileUrl:
        media_type === "IMAGE" && media_url
          ? media_url
          : thumbnail_url
          ? thumbnail_url
          : "",
    };
  }

  const extension = getFileExtension(file_url);

  return {
    type: getFileType(extension),
    fileUrl: file_url,
  };
};

const SystemMessageLinks: FC<SystemMessageLinksT> = React.memo(
  ({ links, backgroundColor }) => {
    const postLinks = links && links.filter((link) => link.url);
    const mediaLinks =
      links && links.filter((link) => link.file_url || link.ig_story);

    return (
      <Wrapper flexDirection="column" alignItems="stretch">
        <Spacer size="default" direction="vertical" />
        {postLinks.length > 0 && (
          <>
            <Paragraph
              paragraph={"Odkazy:"}
              fontWeight={600}
              paragraphSize="small"
            />

            <LinksWrapper alignItems="stretch" justifyContent="flex-start">
              <GradientOverlay backgroundColor={backgroundColor} />
              {postLinks.map((link) => (
                <PostWrapper key={link.id}>
                  {link.provider && (
                    <SocialIcon socialNetwork={link.provider} size="mini" />
                  )}
                  <Spacer size="micro" />
                  {link.url && (
                    <PostLink target="_blank" href={link.url}>
                      {link.url.length > 50
                        ? link.url.substring(0, 50) + "..."
                        : link.url}
                    </PostLink>
                  )}
                </PostWrapper>
              ))}
            </LinksWrapper>
          </>
        )}
        {mediaLinks.length > 0 && (
          <>
            <Paragraph
              paragraph={"Soubory a InstaStories:"}
              paragraphSize="small"
              fontWeight={600}
            />
            <LinksWrapper alignItems="stretch" justifyContent="flex-start">
              {mediaLinks.map((link) => {
                const { type, fileUrl } = getFileTypeCollection(link);

                return (
                  <MediaLink
                    target="_blank"
                    href={fileUrl}
                    key={link.id}
                    title={link.ig_story ? "InstaStory" : "Soubor"}
                  >
                    <MediaIcon
                      icon={
                        link.ig_story ? IconTypeE.story : IconTypeE.document
                      }
                      background="rgba(0,0,0,0.6)"
                      iconColor={theme.color.whiteColor}
                    />
                    <ImageWrapper>
                      {type === FileTypeE.image ? (
                        <ImagePreview src={fileUrl} />
                      ) : (
                        <Paragraph
                          paragraph={getFileName(fileUrl).substring(0, 10)}
                          paragraphSize="small"
                          fontWeight={600}
                        />
                      )}
                    </ImageWrapper>
                  </MediaLink>
                );
              })}
            </LinksWrapper>
          </>
        )}
      </Wrapper>
    );
  }
);

export default SystemMessageLinks;
