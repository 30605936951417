import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import FlexBox from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Panel from "../../../components/Panel";
import Spacer from "../../../components/Spacer";
import VisibleWrapper from "../../../components/VisibleWrapper";
import { getStringDate } from "../../../helpers/date";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { GetParticipantsReponseT } from "../../reporting-media-groups/data-access/getParticipants";
import { MediaGroupKindT } from "../../reporting-media-groups/data-access/types";
import FilterDate, { DateStateT } from "./FilterDate";
import FilterParticipants from "./FilterParticipants";
import FilterPostType from "./FilterPostType";

const FilterWrapper = styled(VisibleWrapper)({
  position: "absolute",
  top: rem(50),
  height: "auto",
  right: 0,
  width: rem(320),
  zIndex: 2,
});

const Close = styled(Icon)({
  position: "absolute",
  right: rem(10),
  top: rem(10),
  cursor: "pointer",
});

const Overlay = styled.div({
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
});

const Devider = styled(FlexBox)({
  marginLeft: rem(8),
  paddingLeft: rem(8),
  height: rem(16),
  borderLeft: `1px solid ${theme.color.greyColor}`,
});

export type FilterStateT = {
  from?: string;
  to?: string;
  kinds?: MediaGroupKindT[];
  participant_ids?: string[];
};

export type SortingStateT = {
  sort?: "created_at:desc" | "created_at:asc";
};

type SortAndFilterT = {
  participants?: GetParticipantsReponseT;
  filterState: FilterStateT | undefined;
  setFiliterState: Dispatch<SetStateAction<FilterStateT | undefined>>;
  sortingState: SortingStateT;
  setSortingState: React.Dispatch<React.SetStateAction<SortingStateT>>;
};

const SortAndFilter: FC<SortAndFilterT> = ({
  filterState,
  setFiliterState,
  sortingState,
  setSortingState,
  participants,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [postTypeState, setPostTypeState] = useState<MediaGroupKindT[]>([]);
  const [dateState, setDateState] = useState<DateStateT | undefined>();
  const [participantsState, setParticipantsState] = useState<string[]>([]);

  const { t } = useTranslate("brands.reportings.participants.index");

  const isActive = filterState && Object.keys(filterState).length > 0;

  useEffect(() => {
    setFiliterState({
      ...(dateState?.startDate && { from: getStringDate(dateState.startDate) }),
      ...(dateState?.endDate && { to: getStringDate(dateState.endDate) }),
      ...(postTypeState.length > 0 && { kinds: postTypeState }),
      ...(participantsState.length > 0 && {
        participant_ids: participantsState,
      }),
    });
  }, [postTypeState, dateState, participantsState]);

  const setSortingStateHandle = (): void => {
    setSortingState((prevState) => {
      return {
        sort:
          prevState.sort === "created_at:desc"
            ? "created_at:asc"
            : "created_at:desc",
      };
    });
  };

  return (
    <>
      {isFilterOpen && <Overlay onClick={() => setIsFilterOpen(false)} />}
      <FlexBox customWidth="max-content">
        <Button
          onClick={setSortingStateHandle}
          size={ButtonSizeE.small}
          type={ButtonTypeE.clearWithHover}
        >
          <Icon
            icon={IconTypeE.sort}
            size="small"
            rotate={sortingState.sort === "created_at:desc" ? "0deg" : "180deg"}
          />
          <Spacer size="micro" />
          {sortingState.sort === "created_at:desc"
            ? t("sort_desc")
            : t("sort_asc")}
        </Button>
        <Devider />
        <Button
          onClick={() => setIsFilterOpen((prevState) => !prevState)}
          size={ButtonSizeE.small}
          type={ButtonTypeE.clearWithHover}
        >
          <Icon
            icon={isActive ? IconTypeE.filterFilled : IconTypeE.filter}
            size="small"
          />
          <Spacer size="micro" />
          {t("filter")}
        </Button>
        <FilterWrapper visible={isFilterOpen}>
          <Panel
            withShadow={true}
            flexDirection="column"
            marginBottom={0}
            justifyContent="flex-start"
            position="relative"
          >
            <Close
              onClick={() => setIsFilterOpen(false)}
              icon={IconTypeE.cross}
              size="mini"
            />
            <FilterPostType
              postTypeState={postTypeState}
              setPostTypeState={setPostTypeState}
            />
            <FilterDate dateState={dateState} setDateState={setDateState} />
            {participants?.reporting_participants && (
              <FilterParticipants
                participants={participants.reporting_participants}
                participantsState={participantsState}
                setParticipantsState={setParticipantsState}
              />
            )}
          </Panel>
        </FilterWrapper>
      </FlexBox>
    </>
  );
};

export default SortAndFilter;
