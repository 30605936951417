import { _o } from "../helpers";

export const minimizeCampaignsFilter = (): void => {
  const filterWrapper = document.getElementById("list-filter-wrapper");

  if (filterWrapper) {
    const { clientWidth, children } = filterWrapper;

    let filterElement;
    let actionsElement;

    _o(children).map((item) => {
      const itemHtml = item as HTMLElement;

      if (itemHtml.className.includes("list-filter")) {
        filterElement = itemHtml;
      }

      if (itemHtml.className.includes("list-actions")) {
        actionsElement = itemHtml;
      }
    });

    if (filterElement && actionsElement) {
      const filterWidth = (filterElement as HTMLElement).clientWidth;
      const actionsWidth = (actionsElement as HTMLElement).clientWidth;
      const minimized = (filterElement as HTMLElement).className.includes(
        "minimized"
      );

      if (clientWidth - actionsWidth - 20 < filterWidth && !minimized) {
        (filterElement as HTMLElement).classList.add("minimized");
      }

      if (clientWidth > 1249 && minimized) {
        (filterElement as HTMLElement).classList.remove("minimized");
      }
    }
  }
};
