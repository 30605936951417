import { NetworkProviderT } from "../types";

export const getProviderFromUrl = (): NetworkProviderT => {
  const regExp = /(provider=).*/g;
  const regExpResult = regExp.exec(window.location.href);
  if (regExpResult) {
    return regExpResult[0].replace("provider=", "") as NetworkProviderT;
  }

  return "instagram";
};
