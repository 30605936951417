import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Input from "../../../components/Input";
import Spacer from "../../../components/Spacer";
import { openExternalModal } from "../../../helpers/crossPacks";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import Dropdown from "../../../ui/Dropdown";

const Wrapper = styled(FlexBox)({
  marginTop: rem(16),

  [theme.media.maxSm]: {
    marginBottom: rem(16),
    alignSelf: "flex-end",
  },
});

const ExportContent = styled(FlexBox)({
  padding: rem(4),
});

const StyledInput = styled(Input)({
  width: "100%",
  border: `1px solid ${theme.color.greyColor}`,
  borderRadius: rem(8),
});

type ActionsT = {
  setExportType: Dispatch<SetStateAction<"copy" | "save" | null>>;
  exportPrice: string;
  setExportPrice: Dispatch<SetStateAction<string>>;
  exportDescription: string;
  setExportDescription: Dispatch<SetStateAction<string>>;
  adminRole: boolean;
};

const Actions: FC<ActionsT> = ({
  setExportType,
  exportPrice,
  setExportPrice,
  exportDescription,
  setExportDescription,
  adminRole,
}) => {
  const [exportHover, setExportHover] = useState(false);

  const exportHandle = (type: "copy" | "save"): void => {
    setExportType(type);
    setExportHover(false);
  };

  const { t } = useTranslate("crm");

  return (
    <Wrapper
      flexDirection="row"
      fullHeight={true}
      justifyContent="flex-end"
      alignItems="flex-start"
    >
      <Button
        size={ButtonSizeE.small}
        onClick={() => openExternalModal("modal-trigger-invite")}
      >
        <Icon icon={IconTypeE.newOffer} />
        <Spacer size="mini" />
        {t("filter.selected.add_to_campaign")}
      </Button>
      <Spacer size="mini" />
      <Button
        type={ButtonTypeE.grey}
        size={ButtonSizeE.small}
        onClick={() => openExternalModal("modal-trigger-list")}
        tooltip={t("filter.selected.add_to_list")}
      >
        <Icon icon={IconTypeE.folder} />
      </Button>
      <Spacer size="mini" />
      <Button
        type={ButtonTypeE.grey}
        size={ButtonSizeE.small}
        onClick={() => openExternalModal("modal-trigger-note")}
        tooltip={t("detail.add_note")}
      >
        <Icon icon={IconTypeE.note} />
      </Button>
      <Spacer size="mini" />
      {adminRole ? (
        <FlexBox
          position="relative"
          onMouseEnter={() => setExportHover(true)}
          onMouseLeave={() => setExportHover(false)}
        >
          <Button type={ButtonTypeE.grey} size={ButtonSizeE.small}>
            <Icon icon={IconTypeE.download} />
          </Button>
          <Dropdown active={exportHover} width={rem(210)}>
            <ExportContent
              flexDirection="column"
              customWidth={CustomWidthE.full}
            >
              <StyledInput
                value={exportPrice}
                setValue={setExportPrice}
                placeholder={t("detail.export.price")}
              />
              <Spacer direction="vertical" size="micro" />
              <StyledInput
                value={exportDescription}
                setValue={setExportDescription}
                placeholder={t("detail.export.description")}
              />
              <Spacer direction="vertical" size="micro" />
              <FlexBox customWidth={CustomWidthE.full}>
                <Button
                  customWidth={CustomWidthE.half}
                  size={ButtonSizeE.small}
                  onClick={() => exportHandle("save")}
                >
                  {t("detail.export.save")}
                </Button>
                <Spacer size="micro" />
                <Button
                  customWidth={CustomWidthE.half}
                  size={ButtonSizeE.small}
                  onClick={() => exportHandle("copy")}
                >
                  {t("detail.export.copy")}
                </Button>
              </FlexBox>
            </ExportContent>
          </Dropdown>
        </FlexBox>
      ) : (
        <Button
          type={ButtonTypeE.grey}
          size={ButtonSizeE.small}
          onClick={() => exportHandle("save")}
          tooltip={t("detail.export.save")}
        >
          <Icon icon={IconTypeE.download} />
        </Button>
      )}
      <Spacer />
    </Wrapper>
  );
};

export default Actions;
