import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../components/Button";
import FlexBox from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { useFetch } from "../../../../hooks/useFetch";
import { rem, theme } from "../../../../styling/theme";
import { OfferPayloadT } from "../../types";

type ParagraphWrapperT = {
  visible: boolean;
};

const ParagraphWrapper = styled.div<ParagraphWrapperT>(({ visible }) => ({
  maxWidth: rem(500),
  maxHeight: visible ? rem(80) : 0,
  marginTop: visible ? rem(16) : 0,
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,
  overflow: "hidden",
}));

type ApproveReportT = {
  offerId: number;
};

const ApproveReport: FC<ApproveReportT> = ({ offerId }) => {
  const [alert, setAlert] = useState(false);

  const { call, pending } = useFetch<OfferPayloadT>(
    getApiUrl(ApiRoutesE.OFFER_ACCEPT, offerId),
    undefined,
    "POST"
  );

  return (
    <FlexBox flexDirection="column" alignItems="flex-start">
      <ParagraphWrapper visible={alert}>
        <Paragraph
          fontWeight={600}
          paragraphSize="small"
          paragraph={`Prosím zkontrolujte finální odkazy a potvrďte je, pokud vše proběhlo dle dohody.
            Následně influencrovi zašleme odměnu za spolupráci.`}
        />
      </ParagraphWrapper>
      <Spacer direction="vertical" />
      {alert ? (
        <FlexBox>
          <Button
            size={ButtonSizeE.micro}
            type={ButtonTypeE.default}
            loading={pending}
            disabled={pending}
            onClick={call}
          >
            {"Ano"}
          </Button>
          <Spacer size="mini" />
          <FlexBox>
            <Button
              size={ButtonSizeE.micro}
              type={ButtonTypeE.outline}
              onClick={() => setAlert(false)}
            >
              {"Zatím ne"}
            </Button>
          </FlexBox>
        </FlexBox>
      ) : (
        <Button
          size={ButtonSizeE.micro}
          type={ButtonTypeE.default}
          loading={pending}
          disabled={pending}
          onClick={() => setAlert(true)}
        >
          {"Potvrdit platbu influencerovi"}
        </Button>
      )}
    </FlexBox>
  );
};

export default ApproveReport;
