import { IconTypeE } from "../../../assets/icons/Icons";
import { CardOriginT, FieldTypeT, MediaGroupKindT, MediaKindT } from "./types";

export const mediaGroupLabelsIcons: Record<MediaGroupKindT, IconTypeE> = {
  story_set: IconTypeE.igStory,
  igtv: IconTypeE.igVideo,
  post: IconTypeE.igPost,
  reels: IconTypeE.igVideo,
};

export const mediaGroupOriginsIcons: Record<CardOriginT, IconTypeE> = {
  public: IconTypeE.reportingPublic,
  private: IconTypeE.reportingPrivate,
  manual: IconTypeE.reportingManual,
};

export const contentTypes: MediaGroupKindT[] = [
  "post",
  "story_set",
  "igtv",
  "reels",
];

export const cardFields: Record<MediaKindT, FieldTypeT[]> = {
  post: ["likes", "comments", "reach", "impressions"],
  story: ["reach", "impressions", "stickertaps"],
  reels: ["impressions", "likes", "comments"],
  igtv: ["impressions", "likes", "comments"],
};

export const disabledFiledsMap: Record<CardOriginT, FieldTypeT[]> = {
  manual: [],
  public: ["likes", "comments"],
  private: ["likes", "comments", "reach", "impressions"],
};
