import styled from "@emotion/styled";
import * as React from "react";
import { FC, useRef } from "react";
import Avatar from "../../../components/Avatar";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Handlers from "../../../components/HorizontalScroll/Handlers";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../features/Placeholders/PlaceholderLoader";
import { useHoriznontalScroll } from "../../../hooks/useHorizontalScroll";
import { useResponsive } from "../../../hooks/useResponsive";
import { breakpoints, rem, theme } from "../../../styling/theme";
import { InboxItemT } from "../types";

const ImagesWrapper = styled(FlexBox)({
  padding: `${rem(10)} 0`,
});

const Wrapper = styled(FlexBox)({
  padding: `${rem(28)} 0 ${rem(28)} ${rem(28)}`,
  borderBottom: `1px solid ${theme.color.greyColor}`,

  ["&:last-of-type"]: {
    borderBottom: "none",
  },
});

const ScrollWrapper = styled(FlexBox)({
  overflow: "scroll",
  scrollbarWidth: "none",
  scrollBehavior: "smooth",

  ["&::-webkit-scrollbar"]: {
    display: "none",
  },
});

const PostImageWrapper = styled(FlexBox)({
  position: "relative",
  width: rem(180),
  minWidth: rem(180),
  height: rem(180),
  marginRight: rem(10),
  overflow: "hidden",
  borderRadius: rem(15),
});

const PostImage = styled.img({
  objectFit: "cover",
  width: "100%",
  height: rem(250),
  maxHeight: "100%",
});

type PostViewItemT = {
  item: InboxItemT;
  campaignId: number;
};

const PostViewItem: FC<PostViewItemT> = React.memo(({ item, campaignId }) => {
  const { id, handle, user, total_people_ig, price, post_urls } = item;

  const { breakePointCondition } = useResponsive(breakpoints.md);
  const scrollWrapper = useRef<HTMLDivElement>(null);

  const { onArrowClickHandle, onScrollHandle, leftArrow, rightArrow } =
    useHoriznontalScroll(scrollWrapper, post_urls);

  return (
    <Wrapper
      flexDirection={breakePointCondition ? "row" : "column"}
      customWidth={CustomWidthE.full}
    >
      <FlexBox
        customWidth={
          breakePointCondition ? CustomWidthE.custom28 : CustomWidthE.full
        }
        alignItems="stretch"
        justifyContent="stretch"
      >
        <Avatar src={user.avatar_url} name={user.nick_or_name} size={85} />
        <Spacer />
        <FlexBox
          customWidth={CustomWidthE.full}
          alignItems="stretch"
          flexDirection="column"
        >
          <Spacer size="micro" direction="vertical" />
          <Heading
            spaceBottom="micro"
            headingType={HeadingTypeT.h3}
            heading={user.nick_or_name}
          />
          <Paragraph color={theme.color.textGreyColor} paragraph={handle} />
          <Spacer direction="vertical" />
          <FlexBox customWidth={CustomWidthE.full}>
            <FlexBox
              customWidth={CustomWidthE.half}
              alignItems="flex-start"
              flexDirection="column"
            >
              <Paragraph paragraph={`${total_people_ig}`} fontWeight={600} />
              <Paragraph
                paragraph={`sledujících`}
                color={theme.color.textGreyColor}
              />
            </FlexBox>
            {!price && (
              <FlexBox
                customWidth={CustomWidthE.half}
                alignItems="flex-start"
                flexDirection="column"
              >
                <Paragraph paragraph={`${price}`} fontWeight={600} />
                <Paragraph
                  paragraph={`nabídka`}
                  color={theme.color.textGreyColor}
                />
              </FlexBox>
            )}
          </FlexBox>
          <Spacer direction="vertical" />
          <FlexBox customWidth={CustomWidthE.full} justifyContent="stretch">
            <Button
              size={ButtonSizeE.small}
              type={ButtonTypeE.outline}
              href={`/brands/campaigns/${campaignId}/offers/${id}`}
            >
              {"Detail"}
            </Button>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <ImagesWrapper
        customWidth={
          breakePointCondition ? CustomWidthE.custom72 : CustomWidthE.full
        }
        position="relative"
      >
        {post_urls && post_urls.length > 0 ? (
          <>
            <ScrollWrapper
              customWidth="100%"
              onScroll={_.debounce((e) => onScrollHandle(e), 500)}
              ref={scrollWrapper}
              justifyContent="stretch"
            >
              {post_urls.map((url, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index}>
                    <PostImageWrapper>
                      <PostImage src={url} />
                    </PostImageWrapper>
                  </div>
                );
              })}
              <Handlers
                leftArrow={leftArrow}
                rightArrow={rightArrow}
                onArrowClickHandle={onArrowClickHandle}
                scrollWrapper={scrollWrapper}
              />
            </ScrollWrapper>
          </>
        ) : (
          <PlaceholderLoader type={PlaceholdersTypeE.post_gallery} count={5} />
        )}
      </ImagesWrapper>
    </Wrapper>
  );
});

export default PostViewItem;
