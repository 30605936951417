import noUiSlider from "nouislider";
import { setBudgetToLabel } from "./campaigns/helpers";
import { _o } from "./helpers";
import wNumb from "wnumb";

const getPips = (slider) => {
  return _o($(slider).find(".noUi-value"));
};

const getSliderEdgeValue = (slider, edgeType) => {
  const edgeValue = slider.range[edgeType];

  if (typeof edgeValue === "object") {
    return edgeValue[0];
  }

  return edgeValue;
};

const getStartValues = (slider) => {
  const values = [];
  const fromInput = document.getElementById(`${slider.id}-from`);
  const maxValue = getSliderEdgeValue(slider, "max");
  const minValue = getSliderEdgeValue(slider, "min");

  if (fromInput) {
    values.push(fromInput.value < maxValue ? fromInput.value : minValue);
  }

  if (slider.connect && typeof slider.connect === "boolean") {
    const toInput = document.getElementById(`${slider.id}-to`);

    if (toInput) {
      values.push(toInput.value ? toInput.value : maxValue);
    }
  }

  return values;
};

const setChangedValues = (slider, values) => {
  const changeEvent = new Event("input");
  const fromInput = document.getElementById(`${slider.id}-from`);
  const toInput = document.getElementById(`${slider.id}-to`);

  fromInput.value = Math.floor(values[0]);
  fromInput.dispatchEvent(changeEvent);

  if (slider.connect && typeof slider.connect === "boolean" && toInput) {
    toInput.dispatchEvent(changeEvent);

    if (parseFloat(values[1]) === getSliderEdgeValue(slider, "max")) {
      toInput.value = "";

      return;
    }

    toInput.value = Math.floor(values[1]);
  }
};

export const slidersInit = (sliders) => {
  sliders.map((slider) => {
    const sliderElement = document.getElementById(`slider-${slider.id}`);
    const startedValues = getStartValues(slider);

    const tooltips = slider.tooltips
      ? startedValues.map(() => wNumb({ decimals: 0, thousand: " " }))
      : false;

    if (!sliderElement) {
      return;
    }

    const existSlider = sliderElement && sliderElement.noUiSlider;
    if (existSlider) {
      existSlider.destroy();
    }

    noUiSlider.create(sliderElement, {
      start: startedValues,
      connect: slider.connect,
      range: slider.range,
      tooltips: tooltips,
      step: slider.step,
      pips: slider.pipsValues && {
        mode: "values",
        values: slider.pipsValues,
        density: 4,
        stepped: true,
      },
    });

    if (slider.pipsValues) {
      const pips = getPips(sliderElement);

      pips.map((pip) => {
        if (pip instanceof HTMLElement) {
          const pipContent = pip.innerHTML;

          if (!pipContent) {
            console.error(`${pip} - pip has no content!`);
          }

          const pipSymbol =
            slider.pipsSymbols && slider.pipsSymbols[pipContent];
          const pipAlias = slider.pipsAlias && slider.pipsAlias[pipContent];

          if (pipContent) {
            pip.dataset.content = pipAlias ? pipAlias : pipContent;
          }

          if (pipSymbol) {
            pip.dataset.symbol = pipSymbol;
          }
        }
      });
    }

    sliderElement.noUiSlider.on("change", (values) => {
      setChangedValues(slider, values);

      if (slider.updateLabel === "change") {
        setBudgetToLabel(slider.id, Math.round(values[0]));
      }
    });

    sliderElement.noUiSlider.on("update", (values) => {
      const pips = getPips(sliderElement);
      pips.forEach((pip) => {
        if (pip && pip.outerText) {
          const check = !!values.find(
            (value) => pip.outerText === Math.floor(value).toString()
          );

          if (check) {
            pip.classList.add("checked");

            return;
          }

          pip.classList.remove("checked");
        }
      });

      if (slider.updateLabel === "update") {
        setBudgetToLabel(slider.id, Math.round(values[0]));
      }
    });
  });
};
