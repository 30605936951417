import { _o } from "./helpers";

const ACTIVE_CLASS = "tab--active";
const ACTIVE_CONTENT_CLASS = "tab-content--active";

const clearClasses = (): void => {
  const activeTabs = document.getElementsByClassName(ACTIVE_CLASS);
  const activeTabsContent =
    document.getElementsByClassName(ACTIVE_CONTENT_CLASS);

  _o(activeTabs).forEach((tab) => {
    tab.classList.remove(ACTIVE_CLASS);
  });

  _o(activeTabsContent).forEach((tabContent) => {
    tabContent.classList.remove(ACTIVE_CONTENT_CLASS);
  });
};

type setActiveClassesT = (tab: HTMLElement, content: HTMLElement) => void;
const setActiveClasses: setActiveClassesT = (tab, content) => {
  tab.classList.add(ACTIVE_CLASS);
  content.classList.add(ACTIVE_CONTENT_CLASS);
};

export const tabsInit = (): void => {
  const tabsWrappers = document.getElementsByClassName("tabs");

  if (tabsWrappers) {
    _o(tabsWrappers).forEach((wrapper) => {
      const tabsElements = wrapper.getElementsByClassName("tab");

      if (tabsElements) {
        _o(tabsElements).forEach((tab, index) => {
          const {
            dataset: { content },
          } = tab as HTMLElement;

          const contentElement = content && document.getElementById(content);

          if (contentElement) {
            if (index === 0) {
              setActiveClasses(tab as HTMLElement, contentElement);
            }

            tab.addEventListener("click", () => {
              clearClasses();
              setActiveClasses(tab as HTMLElement, contentElement);
            });
          }
        });
      }
    });
  }
};
