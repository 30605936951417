import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import { useResponsive } from "../hooks/useResponsive";
import { rem, theme } from "../styling/theme";
import FlexBox, { CustomWidthE } from "./FlexBox";

type WrapperStyleT = {
  visible: boolean;
  customHeight: number | "default";
  transitionTime?: number;
  overflow: OverflowOptionsT;
};

const RollWrapper = styled(FlexBox)<WrapperStyleT>(
  ({ visible, transitionTime, customHeight, overflow }) => ({
    position: "relative",
    height: 0,
    paddingBottom:
      visible && typeof customHeight === "number" ? rem(customHeight) : 0,
    overflow,
    transition: `padding-bottom ${transitionTime}s`,
    transitionTimingFunction: theme.timing.default,
    transform: "translateZ(0)", // solution for safari "overflow-hidden" problem
  })
);

const FadeWrapper = styled(FlexBox)<WrapperStyleT>(
  ({ visible, transitionTime, customHeight }) => ({
    position: "relative",
    height:
      customHeight && typeof customHeight === "number"
        ? rem(customHeight)
        : "100%",
    visibility: visible ? "visible" : "hidden",
    opacity: visible ? 1 : 0,
    transition: `all ${transitionTime}s`,
    transitionTimingFunction: theme.timing.default,
  })
);

const Content = styled(FlexBox)({
  position: "absolute",
  top: 0,
  left: 0,
});

type OverflowOptionsT = "visible" | "hidden";

type VisibleWrapperT = {
  visible: boolean;
  type?: "roll" | "fade";
  onClick?: () => any;
  transitionTime?: number;
  className?: string;
};

const VisibleWrapper: FC<VisibleWrapperT> = ({
  visible,
  type = "fade",
  transitionTime = 0.4,
  children,
  onClick,
  className,
}) => {
  const [height, setHeight] = useState<number | "default">("default");
  const [overflow, setOverflow] = useState<OverflowOptionsT>(
    visible ? "visible" : "hidden"
  );
  const contentElement = useRef<HTMLDivElement>(null);
  const { windowWidth } = useResponsive();

  useEffect(() => {
    if (type === "fade") {
      return;
    }

    const { current } = contentElement;

    if (!!current) {
      setHeight(current.clientHeight);
    }
  }, [contentElement, windowWidth]);

  useEffect(() => {
    if (visible) {
      setTimeout(() => {
        setOverflow("visible");
      }, transitionTime * 1000);

      return;
    }

    setOverflow("hidden");
  }, [visible]);

  return (
    <>
      {type === "roll" ? (
        <RollWrapper
          customWidth={CustomWidthE.full}
          visible={visible}
          transitionTime={transitionTime}
          customHeight={height}
          onClick={onClick}
          className={className}
          overflow={overflow}
        >
          <Content ref={contentElement} customWidth={CustomWidthE.full}>
            {children}
          </Content>
        </RollWrapper>
      ) : (
        <FadeWrapper
          customWidth={CustomWidthE.full}
          visible={visible}
          customHeight={height}
          transitionTime={transitionTime}
          onClick={onClick}
          className={className}
          overflow={overflow}
        >
          {children}
        </FadeWrapper>
      )}
    </>
  );
};

export default VisibleWrapper;
