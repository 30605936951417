import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import CheckBox from "../../../components/CheckBox";
import FlexBox from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import SocialIcon from "../../../components/SocialIcon";
import Spacer from "../../../components/Spacer";
import { rem, theme } from "../../../styling/theme";
import TableHeadWrapper from "../../../ui/table/TableHeadWrapper";
import { FilterSortingOptionT, InboxItemT, InboxSortingByT } from "../types";

const TableFlexBox = styled(FlexBox)({
  padding: `${rem(10)} ${rem(16)}`,
  justifyContent: "flex-start",
});

export const ActionsColumn = styled(FlexBox)({
  paddingLeft: rem(16),
  width: "8%",
});

export const Select = styled(FlexBox)({
  width: "50%",
});

export const InfoColumn = styled.a({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "92%",
});

export const InfoColumnAnchor = styled.a({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "92%",
});

export const UserColumn = styled(TableFlexBox)<{ withBorder?: boolean }>(
  ({ withBorder = true }) => ({
    minWidth: "23%",
    width: "23%",
    height: "100%",
    borderRight: withBorder ? `1px solid ${theme.color.greyColor}` : "none",
  })
);

export const MessageColumn = styled(TableFlexBox)({
  minWidth: "35%",
  width: "35%",
  paddingRight: rem(24),
  paddingLeft: rem(24),
});

export const FollowsColumn = styled(FlexBox)({
  width: "7%",
  justifyContent: "center",
});

export const PriceColumn = styled(FlexBox)({
  width: "16%",
  justifyContent: "flex-end",
  paddingRight: rem(24),
});

const PointerWrapper = styled(FlexBox)({
  cursor: "pointer",
});

const StyledParagraph = styled(Paragraph)({
  paddingTop: rem(8),
  paddingBottom: rem(8),
});

type TableHeadT = {
  sortingState: FilterSortingOptionT | null;
  setSortingState: Dispatch<SetStateAction<FilterSortingOptionT | null>>;
  inboxItems: InboxItemT[] | null;
  selectedItems: InboxItemT[];
  setSelectedItems: Dispatch<SetStateAction<InboxItemT[]>>;
};

const TableHead: FC<TableHeadT> = ({
  sortingState,
  setSortingState,
  selectedItems,
  setSelectedItems,
  inboxItems,
}) => {
  type SocialNetworkProviderTemporaryT =
    | "instagram"
    | "youtube"
    | "facebook"
    | "blog";
  const socialNetworks: SocialNetworkProviderTemporaryT[] = [
    "instagram",
    "youtube",
    "facebook",
    "blog",
  ];

  type FilterKeyT = SocialNetworkProviderTemporaryT | "price";
  const filterKeys: Record<FilterKeyT, InboxSortingByT> = {
    instagram: "total_people_ig",
    youtube: "total_people_yt",
    facebook: "total_people_fb",
    blog: "total_people_blog",
    price: "proposed_price",
  };

  const setSortingHandle = (key: FilterKeyT): void => {
    setSortingState(() => {
      return {
        sortBy: filterKeys[key],
        sortOption: sortingState?.sortOption === "asc" ? "desc" : "asc",
      };
    });
  };

  const selectedAll = !!(
    inboxItems && selectedItems.length === inboxItems.length
  );

  return (
    <TableHeadWrapper justifyContent="flex-start" alignItems="stretch">
      <ActionsColumn>
        <Select>
          {inboxItems && (
            <CheckBox
              active={selectedAll}
              onClickHandle={() =>
                setSelectedItems(() => (selectedAll ? [] : inboxItems))
              }
            />
          )}
        </Select>
        <Select />
      </ActionsColumn>
      <InfoColumn>
        <UserColumn withBorder={false}>
          <StyledParagraph
            paragraph={"Influencer"}
            color={theme.color.textGreyColor}
            paragraphSize="small"
          />
        </UserColumn>
        <MessageColumn>
          <StyledParagraph
            paragraph={"Zpráva"}
            color={theme.color.textGreyColor}
            paragraphSize="small"
          />
        </MessageColumn>
        {socialNetworks.map((network) => {
          return (
            <FollowsColumn
              key={network}
              onClick={() => setSortingHandle(network)}
            >
              <PointerWrapper>
                <SocialIcon size="mini" socialNetwork={network} />
                <Spacer size="micro" />
                <Icon
                  icon={IconTypeE.sort}
                  size="small"
                  color={theme.color.textGreyColor}
                  rotate={
                    sortingState?.sortBy === filterKeys[network] &&
                    sortingState.sortOption === "asc"
                      ? "180deg"
                      : "0deg"
                  }
                />
              </PointerWrapper>
            </FollowsColumn>
          );
        })}
        <PriceColumn onClick={() => setSortingHandle("price")}>
          <PointerWrapper>
            <StyledParagraph
              paragraph={"Finální cena"}
              color={theme.color.textGreyColor}
              paragraphSize="small"
            />
            <Spacer size="micro" />
            <Icon
              icon={IconTypeE.sort}
              size="small"
              color={theme.color.textGreyColor}
              rotate={
                sortingState?.sortBy === filterKeys["price"] &&
                sortingState.sortOption === "asc"
                  ? "180deg"
                  : "0deg"
              }
            />
          </PointerWrapper>
        </PriceColumn>
      </InfoColumn>
    </TableHeadWrapper>
  );
};

export default TableHead;
