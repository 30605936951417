import * as React from "react";
import { createContext, Dispatch, FC, SetStateAction, useState } from "react";
import { NotificationT } from "../types";

type NotificationsStateT = {
  notificationState: {
    notifications: NotificationT[] | null;
    setNotifications: Dispatch<SetStateAction<NotificationT[] | null>>;
  };
  liveNotificationsState: {
    liveNotifications: NotificationT[];
    setLiveNotifications: Dispatch<SetStateAction<NotificationT[]>>;
  };
};

export const NotificationsContext = createContext<NotificationsStateT>({
  notificationState: {
    notifications: null,
    setNotifications: () => {},
  },
  liveNotificationsState: {
    liveNotifications: [],
    setLiveNotifications: () => {},
  },
});

const NotificationsContextProvider: FC<{}> = ({ children }) => {
  const [notifications, setNotifications] = useState<NotificationT[] | null>(
    null
  );
  const [liveNotifications, setLiveNotifications] = useState<NotificationT[]>(
    []
  );
  const initState = {
    notificationState: {
      notifications,
      setNotifications,
    },
    liveNotificationsState: {
      liveNotifications,
      setLiveNotifications,
    },
  };

  return (
    <NotificationsContext.Provider value={initState}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContextProvider;
