import styled from "@emotion/styled";
import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { ActionCableContext } from "../";
import { IconTypeE } from "../../../assets/icons/Icons";
import CircleButton from "../../../components/CircleButton";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import VisibleWrapper from "../../../components/VisibleWrapper";
import { CrossPacksConts } from "../../../helpers/crossPacks";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useFetch } from "../../../hooks/useFetch";
import { useResponsive } from "../../../hooks/useResponsive";
import { rem, theme } from "../../../styling/theme";
import { NotificationsDataPropsT } from "../../types";
import { NotificationsContext } from "../context-providers/NotificationsContextProvider";
import { NotificationsChannelDataT } from "../types";
import PopupBox from "./PopupBox";

type IconWrapperStyleT = {
  active: boolean;
};

const BellWrapper = styled.div({
  position: "relative",
});

const CountIndicatorWrapper = styled(FlexBox)({
  position: "absolute",
  right: rem(2),
  top: rem(2),
  zIndex: 1,
});

const CountIndicator = styled(FlexBox)({
  width: rem(15),
  height: rem(15),
  backgroundColor: theme.color.yellowColor,
  borderRadius: "50%",
  zIndex: -1,
  fontSize: rem(9),
  fontWeight: 500,
});

const BellWrapperMobile = styled(FlexBox)({
  marginBottom: rem(16),
  cursor: "pointer",
});

export const IconWrapper = styled.div<IconWrapperStyleT>(({ active }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: rem(52),
  height: rem(52),
  marginRight: rem(8),
  backgroundColor: active ? theme.color.yellowColor : "transparent",
  borderRadius: "50%",
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,
  cursor: "pointer",
}));

const Notifications: FC<NotificationsDataPropsT> = ({ data }) => {
  const { unread_count } = data;
  const { tabletVersion } = useResponsive();

  const notificationsListener = new Event(
    CrossPacksConts.NOTIFICATIONS_LISTENER
  );

  const [unreadCount, setUnreadCount] = useState<number | null>(unread_count);
  const [firstBoxOpen, setFirstBoxOpen] = useState(false);
  const [boxOpen, setBoxOpen] = useState(false);

  const cable = useContext<any>(ActionCableContext);
  const {
    liveNotificationsState: { setLiveNotifications },
  } = useContext(NotificationsContext);

  const { call } = useFetch<Response>(
    getApiUrl(ApiRoutesE.NOTIFICATION_READ_ALL),
    undefined,
    "POST"
  );

  const handleOnBellClick = (): void => {
    if (!firstBoxOpen) {
      setFirstBoxOpen(true);
    }

    if (boxOpen) {
      setBoxOpen(false);
      return;
    }

    setUnreadCount(null);
    call();
    setBoxOpen(true);
  };

  useEffect(() => {
    if (firstBoxOpen) {
      setBoxOpen(true);
    }
  }, [firstBoxOpen]);

  useEffect(() => {
    if (boxOpen) {
      setBoxOpen(false);
    }
  }, [tabletVersion]);

  useEffect(() => {
    const channelOptions = {
      channel: "NotificationsChannel",
    };

    const channel = cable.subscriptions.create(channelOptions, {
      received: (data: NotificationsChannelDataT) => {
        const { message, unread } = data;

        document.dispatchEvent(notificationsListener);

        if (message && message.json) {
          setLiveNotifications((prevState) => [...prevState, message.json]);
        }

        if (!isNaN(unread)) {
          setUnreadCount(unread);
        }
      },
    });

    return () => {
      channel.unsubscribe();
    };
  }, []);

  return (
    <FlexBox position="relative">
      {tabletVersion ? (
        <BellWrapperMobile
          onClick={handleOnBellClick}
          customWidth={CustomWidthE.full}
          justifyContent="space-between"
        >
          <FlexBox justifyContent="flex-start">
            <IconWrapper active={!!unreadCount}>
              <Icon icon={IconTypeE.bell} />
            </IconWrapper>
            <FlexBox flexDirection="column" alignItems="flex-start">
              <Paragraph paragraph="Notifikace" fontWeight={700} />
              {unreadCount && (
                <Paragraph
                  paragraph={`nepřečtených: ${unreadCount}`}
                  color={theme.color.textGreyColor}
                  paragraphSize="small"
                />
              )}
            </FlexBox>
          </FlexBox>
          <Icon icon={IconTypeE.arrowRight} size={10} />
        </BellWrapperMobile>
      ) : (
        <BellWrapper>
          <CountIndicatorWrapper>
            <VisibleWrapper visible={!!unreadCount && unreadCount > 0}>
              <CountIndicator>{unreadCount}</CountIndicator>
            </VisibleWrapper>
          </CountIndicatorWrapper>
          <CircleButton
            onClickHandle={handleOnBellClick}
            background={boxOpen ? theme.color.backgroundColor : "transparent"}
            hoverBackground={theme.color.backgroundColor}
            icon={IconTypeE.bell}
          />
        </BellWrapper>
      )}
      {firstBoxOpen && <PopupBox boxOpen={boxOpen} setBoxOpen={setBoxOpen} />}
    </FlexBox>
  );
};

export default Notifications;
