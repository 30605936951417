import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../components/FlexBox";
import { rem, theme } from "../../../styling/theme";
import { CONVERSATION_CONTAINER_WIDTH } from "../partials/ConversationsContainer";
import { CHAT_HEADER_HEIGHT } from "./CampaignDetail";

type WrapperStyleT = {
  visible: boolean;
};

const Wrapper = styled(FlexBox)<WrapperStyleT>(({ visible }) => ({
  flexGrow: 1,
  maxWidth: `calc(100% - ${CONVERSATION_CONTAINER_WIDTH}px)`,
  height: "100%",
  paddingBottom: rem(16),

  [theme.media.maxSm]: {
    paddingBottom: rem(8),
  },

  [theme.media.maxMd]: {
    position: "fixed",
    width: "100%",
    maxWidth: "none",
    left: visible ? "0" : "100%",
    top: 0,
    bottom: 0,
    transition: "all 0.4s",
    transitionTimingFunction: theme.timing.default,
    paddingTop: rem(CHAT_HEADER_HEIGHT),
    paddingBottom: rem(32),
    backgroundColor: theme.color.whiteColor,
    zIndex: 1,
  },
}));

const FullWidthWrapper = styled(FlexBox)({
  flexGrow: 1,
  maxWidth: "100%",
  height: "100%",
  paddingBottom: rem(16),

  [theme.media.maxSm]: {
    paddingBottom: rem(8),
  },
});

type MessagesContainerWrapperT = {
  fullWidth?: boolean;
  offerDetailVisible?: boolean;
};

const MessagesContainerWrapper: FC<MessagesContainerWrapperT> = React.memo(
  ({ fullWidth = false, children, offerDetailVisible = false }) => {
    if (fullWidth) {
      return (
        <FullWidthWrapper justifyContent="space-between" flexDirection="column">
          {children}
        </FullWidthWrapper>
      );
    }

    return (
      <Wrapper
        visible={offerDetailVisible}
        justifyContent="space-between"
        flexDirection="column"
      >
        {children}
      </Wrapper>
    );
  }
);

export default MessagesContainerWrapper;
