import { _o } from "../helpers";

type scrollToErrorsT = () => void;
export const scrollToErrors: scrollToErrorsT = () => {
  const errors = document.getElementsByClassName("has-error");

  if (errors && errors.length > 0) {
    const firstError = _o(errors)[0] as HTMLElement;

    setTimeout(() => {
      window.scrollTo(0, firstError.offsetTop - 100);
    }, 10);
  }
};
