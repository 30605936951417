import { QueryClient } from "react-query";
import { MediaGroupT, MediaT, QueryStackT } from "../types";

type createMediaMutationT = (
  queryClient: QueryClient,
  data: { media: MediaT },
  mediaGroupId: number,
  queryStack?: QueryStackT
) => void;

export const createMediaMutation: createMediaMutationT = (
  queryClient,
  data,
  mediaGroupId,
  queryStack = "mediaGroups"
) => {
  queryClient.setQueryData<MediaGroupT[] | undefined>(
    queryStack,
    (prevState) => {
      if (prevState) {
        const currentMediaGroupIndex = prevState.findIndex(
          (mediaGroup) => mediaGroup.id === mediaGroupId
        );

        if (currentMediaGroupIndex > -1) {
          const currentMediaGroup = prevState[currentMediaGroupIndex];

          const newCurrentMediaGroup = {
            ...currentMediaGroup,
            medias: [...currentMediaGroup.medias, data.media],
          };
          const newState = prevState;
          newState[currentMediaGroupIndex] = newCurrentMediaGroup;

          return newState;
        }
      }

      return prevState;
    }
  );
};
