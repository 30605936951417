import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import FlexBox from "../../../../components/FlexBox";
import Icon from "../../../../components/Icon";
import { getDateTime } from "../../../../helpers/date";
import {
  FileTypeE,
  getFileExtension,
  getFileType,
} from "../../../../helpers/fileType";
import { rem, theme } from "../../../../styling/theme";
import { StoryT } from "../../types";

type WrapperStyleT = {
  hover: boolean;
};

type BulletStyleT = {
  checked: boolean;
};

const RelativeWrapper = styled(FlexBox)<WrapperStyleT>(({ hover }) => ({
  position: "relative",
  width: "20%",
  transform: `scale(${hover ? 1 : 0.97})`,
  transition: "transform 0.4s",
  transitionTimingFunction: theme.timing.default,

  [theme.media.maxMd]: {
    transform: `scale(1)`,
  },

  [theme.media.maxSm]: {
    width: "33.3%",
  },
}));

const Wrapper = styled(FlexBox)({
  flexWrap: "wrap",
  width: "100%",
  maxHeight: rem(170),
  margin: `${rem(10)} ${rem(10)} 0 0`,
  borderRadius: rem(4),
  overflow: "hidden",
});

const FileWrapper = styled.div({
  width: "100%",
  height: "100%",
  padding: "60% 0",
  backgroundColor: theme.color.yellowColor,
});

const FileIcon = styled(Icon)({
  margin: "0 auto",
});

const Image = styled.img({
  width: "100%",
});

const Video = styled.video({
  width: "100%",
  height: "100%",
  borderRadius: rem(4),
  overflow: "hidden",
});

const CheckboxOverlay = styled.input({
  position: "absolute",
  userSelect: "none",
  margin: 0,
  right: rem(0),
  top: rem(0),
  height: "100%",
  width: "100%",
  opacity: 0,
  zIndex: 2,
  cursor: "pointer",
});

const Bullet = styled(FlexBox)<BulletStyleT>(({ checked }) => ({
  position: "absolute",
  userSelect: "none",
  margin: 0,
  right: rem(15),
  top: rem(15),
  height: rem(25),
  width: rem(25),
  borderRadius: "50%",
  backgroundColor: checked ? theme.color.primaryColor : "transparent",
  border: `2px solid ${theme.color.whiteColor}`,
}));

const Time = styled(FlexBox)({
  position: "absolute",
  bottom: rem(5),
  left: rem(5),
  fontSize: rem(13),
  fontWeight: 600,
  color: theme.color.whiteColor,
  backgroundColor: theme.color.textColor,
  borderRadius: rem(4),
  padding: `${rem(1)} ${rem(3)}`,
});

type StoryComponentT = {
  story: StoryT;
  onStoryCheck: (state: boolean, id: string) => void;
  forceUncheck?: boolean;
};

const Story: FC<StoryComponentT> = ({ story, onStoryCheck }) => {
  const [checkState, setCheckState] = useState(false);
  const [hoverState, setHoverState] = useState(false);

  const { id, media_url, timestamp } = story;

  const fileType = !!media_url
    ? getFileType(getFileExtension(media_url))
    : FileTypeE.file;

  type handleOnStoryCheckT = (state: boolean) => void;
  const handleOnStoryCheck: handleOnStoryCheckT = (state) => {
    setCheckState(state);
    onStoryCheck(state, id);
  };

  const getFileContent = (): JSX.Element => {
    if (media_url !== null && fileType === FileTypeE.image) {
      return <Image src={media_url} />;
    }

    if (media_url !== null && fileType === FileTypeE.video) {
      return <Video src={media_url + "#t=0.1"} />;
    }

    return (
      <FileWrapper>
        <FileIcon icon={IconTypeE.sadDoc} size="large" />
      </FileWrapper>
    );
  };

  return (
    <RelativeWrapper
      hover={hoverState}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      <CheckboxOverlay
        type="checkbox"
        onChange={(e) => handleOnStoryCheck(e.target.checked)}
      />
      <Bullet checked={checkState}>
        <Icon
          visible={checkState}
          icon={IconTypeE.check}
          color={theme.color.whiteColor}
          size={11}
        />
      </Bullet>
      <Time>{getDateTime(timestamp, "shortDate")}</Time>
      <Wrapper>{getFileContent()}</Wrapper>
    </RelativeWrapper>
  );
};

export default Story;
