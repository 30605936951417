import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import FlexBox from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Spacer from "../../../components/Spacer";
import { CrossPacksConts } from "../../../helpers/crossPacks";
import { rem, theme } from "../../../styling/theme";
import ActionModal, { ActionModalTypeT } from "../components/ActionModal";
import { InboxItemT, ItemsActionT } from "../types";

const TRANSITION_TIME = 300;

const Wrapper = styled(FlexBox)<{
  isVisible: boolean;
  overflow: "visible" | "hidden";
}>(({ isVisible, overflow }) => ({
  position: "relative",
  padding: `${rem(10)} 0`,
  maxWidth: isVisible ? rem(300) : 0,
  overflow,
  transition: `all ${TRANSITION_TIME}ms cubic-bezier(0.22, 1, 0.36, 1)`,
}));

const DataForPayment = styled.div({
  display: "none",
});

type MassActionsT = {
  firstLoad: boolean;
  campaignId: number;
  selectedItems: InboxItemT[];
  setReFetchHelper: Dispatch<SetStateAction<number>>;
  modalOpen: ActionModalTypeT | undefined;
  setModalOpen: Dispatch<SetStateAction<ActionModalTypeT | undefined>>;
  barter: boolean;
};

const MassActions: FC<MassActionsT> = ({
  firstLoad,
  selectedItems,
  campaignId,
  setReFetchHelper,
  modalOpen,
  setModalOpen,
  barter,
}) => {
  const [overflow, setOverflow] = useState<"visible" | "hidden">("hidden");
  const reFetchHandle = (): void => {
    if (!firstLoad) {
      setReFetchHelper((prevState) => prevState + 1);
    }
  };

  useEffect(() => {
    if (!modalOpen) {
      reFetchHandle();
    }
  }, [modalOpen]);

  useEffect(() => {
    document.addEventListener(CrossPacksConts.REFETCH_OFFERS, () => {
      reFetchHandle();
    });
  }, []);

  useEffect(() => {
    if (selectedItems.length > 0) {
      setTimeout(() => {
        setOverflow("visible");
      }, TRANSITION_TIME);

      return;
    }

    setOverflow("hidden");
  }, [selectedItems]);

  type getAllowActionT = (action: ItemsActionT) => boolean;
  const getActionPermission: getAllowActionT = (action) =>
    !(
      selectedItems.filter((item) => !item.actions.includes(action)).length > 0
    );

  const disableMessage = !getActionPermission("message");
  const disablePay = !getActionPermission("pay");
  const disableDecline = !getActionPermission("decline");
  const disableCheck = !getActionPermission("approve");

  type getModalTitleT = (type?: ActionModalTypeT) => string;
  const getModalTitle: getModalTitleT = (type?) => {
    if (type == "approve") {
      return "Hromadné schválení";
    }
    if (type == "message") {
      return "Hromadná správa";
    }
    if (type == "decline") {
      return "Hromadné odmietnutie";
    }

    return "";
  };

  const changeEvent = new Event(CrossPacksConts.EXTERNAL_MODAL);
  const offerIds = `offer_ids=${selectedItems
    .map((item) => item.id)
    .join("%2C")}`;

  return (
    <>
      <Wrapper isVisible={selectedItems.length > 0} overflow={overflow}>
        <Button
          onClick={() => setModalOpen("message")}
          type={ButtonTypeE.grey}
          size={ButtonSizeE.small}
          disabled={disableMessage}
          tooltip={
            disableMessage
              ? "Některým z označených nabídek nelze poslat hromadnou zprávu"
              : "Hromadná zpráva"
          }
        >
          <Icon
            icon={IconTypeE.chatBubble}
            size="small"
            color={
              disableMessage ? theme.color.greyColor : theme.color.textColor
            }
          />
        </Button>
        <Spacer size="mini" />
        <Button
          onClick={() => setModalOpen("decline")}
          type={ButtonTypeE.grey}
          size={ButtonSizeE.small}
          disabled={disableDecline}
          tooltip={
            disableDecline
              ? "Některé z označených nabídek nelze odmítnout"
              : "Odmítnout nabídku"
          }
        >
          <Icon
            icon={IconTypeE.stop}
            size="small"
            color={
              disableDecline ? theme.color.greyColor : theme.color.textColor
            }
          />
        </Button>
        <Spacer size="mini" />
        {barter ? (
          <Button
            onClick={() => setModalOpen("approve")}
            type={ButtonTypeE.grey}
            size={ButtonSizeE.small}
            disabled={disableCheck}
            tooltip={
              disableCheck
                ? "Některé z označených nabídek nelze schválit"
                : "Schválit nabídku"
            }
          >
            <Icon
              icon={IconTypeE.checkCircle}
              size="small"
              color={
                disableCheck ? theme.color.greyColor : theme.color.textColor
              }
            />
          </Button>
        ) : (
          <Button
            onClick={() => document.dispatchEvent(changeEvent)}
            type={ButtonTypeE.grey}
            size={ButtonSizeE.small}
            disabled={disablePay}
            tooltip={
              disablePay
                ? "Některým z označených nabídek nemůžete zaplatit"
                : "Zaplatit"
            }
          >
            <Icon
              icon={IconTypeE.wallet}
              size="small"
              color={disablePay ? theme.color.greyColor : theme.color.textColor}
            />
          </Button>
        )}
      </Wrapper>
      <Modal
        visible={!!modalOpen}
        close={() => setModalOpen(undefined)}
        heading={getModalTitle(modalOpen)}
        renderChildren={(visible, close) => (
          <ActionModal
            campaignId={campaignId}
            visible={visible}
            selectedItems={selectedItems}
            closeModal={close}
            type={modalOpen}
          />
        )}
      />
      <DataForPayment id="OFFER_IDS" data-params={offerIds} />
    </>
  );
};

export default MassActions;
