import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import { UserRoleT } from "../../types";
import AutoMessage from "./AutoMessage";
import AutoModal from "./AutoModal";
import { HeaderDataT } from "./Header";

type AutoContainerT = {
  headerData: HeaderDataT;
  autoState: boolean;
  setAutoState: Dispatch<SetStateAction<boolean | null>>;
  role: UserRoleT | null;
};

const AutoContainer: FC<AutoContainerT> = ({
  headerData,
  autoState,
  setAutoState,
  role,
}) => {
  const { reporting } = headerData;

  const [showModal, setShowModal] = useState(false);
  const [showMessage, setShowMessagge] = useState(false);

  const onCloseModalHandle = (): void => {
    setShowModal(false);
    setShowMessagge(true);
  };

  useEffect(() => {
    if ((reporting.auto && !autoState && role === "influencer") || !role) {
      setShowModal(true);
      return;
    }

    setShowMessagge(true);
  }, []);

  return (
    <>
      <AutoMessage
        role={role}
        noteKey={reporting.name}
        auto={!!autoState}
        setShowModal={setShowModal}
        visible={showMessage}
      />

      <Modal
        visible={showModal}
        close={onCloseModalHandle}
        slim={false}
        contentPadding={"0"}
        renderChildren={(_, close) => {
          return (
            <AutoModal
              participantData={headerData}
              userExist={!!role}
              setAutoState={setAutoState}
              close={close}
            />
          );
        }}
      />
    </>
  );
};

export default AutoContainer;
