import { allowScroll, disableScroll, _o } from "../helpers";

const _ = window;

const finishWizard = (): void => {
  allowScroll();
  const wizard = document.getElementById("wizard");

  if (wizard) {
    wizard.classList.remove("visible");
    localStorage.setItem("lafluence-market-wizard", "finished");
  }
};

const goToNextStep = (wizard: HTMLElement): void => {
  const stepsContent = _o(document.getElementsByClassName("wizard-step"));

  if (stepsContent) {
    const currentStepElement = stepsContent.find((step) =>
      step.className.includes("visible")
    ) as HTMLElement;

    if (currentStepElement) {
      const stepIndex = currentStepElement.dataset.stepIndex;
      const currentStep = stepIndex && parseInt(stepIndex);
      const stepIndicator = document.getElementById("step-indicator");
      currentStepElement.classList.remove("visible");

      if (currentStep == 2) {
        finishWizard();

        return;
      }

      stepsContent.forEach((step, i) => {
        if (typeof currentStep === "number") {
          const toStep = currentStep + 1;

          if (i == toStep && stepIndicator) {
            step.classList.add("visible");
            stepIndicator.classList.add(`s${toStep}`);
          }

          if (toStep == 2) {
            const button = wizard.getElementsByClassName(
              "button"
            )[0] as HTMLElement;

            if (button) {
              const finishText = button.dataset.finish;

              if (finishText) {
                button.innerText = finishText;
              }
            }
          }
        }
      });
    }
  }
};

const displayWizard = (wizard: HTMLElement): void => {
  disableScroll();

  if (wizard) {
    wizard.classList.add("visible");

    const button = wizard.getElementsByClassName("button")[0];
    button.addEventListener("click", () => goToNextStep(wizard));
  }
};

export const wizardInit = (): void => {
  const wizard = document.getElementById("wizard") as HTMLElement;
  const wizardSession = localStorage.getItem("lafluence-market-wizard");

  if (!wizardSession) {
    displayWizard(wizard);

    return;
  }

  if (wizard) {
    wizard.remove();
  }
};
