import styled from "@emotion/styled";
import * as React from "react";
import { useEffect, useState } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import Button, { ButtonSizeE } from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Icon from "../../../../components/Icon";
import Input from "../../../../components/Input";
import Paragraph from "../../../../components/Paragraph";
import Popover from "../../../../components/Popover";
import Spacer from "../../../../components/Spacer";
import Tooltip from "../../../../components/Tooltip";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { CloseOverlay } from "../../../../ui/CloseOverlay";
import { useMutateMedia } from "../../hooks/useMutateMedia";
import InsightItemWrapper from "../../ui/InsightItemWrapper";

const TriggerWrapper = styled(FlexBox)({
  cursor: "pointer",
});

const StyledInput = styled(Input)({
  width: "100%",
  border: `1px solid ${theme.color.yellowColor}`,
  borderRadius: rem(8),
  padding: rem(10),
});

type InputUrlT = {
  id: number;
  label: string;
  participantId: string;
  url: string | null;
  permanentDisabled?: boolean;
  liveAddedMedia?: boolean;
};

const InputUrl: React.FC<InputUrlT> = ({
  id,
  label,
  participantId,
  url,
  permanentDisabled = false,
  liveAddedMedia,
}) => {
  const [urlState, setUrlState] = useState<string>(url || "");
  const [openPopover, setOpenPopover] = useState<"open" | "close">("close");
  const [hover, setHover] = useState<boolean>(false);

  const { disabled, mutateMedia } = useMutateMedia(
    id,
    "url",
    participantId,
    undefined,
    liveAddedMedia ? "addedMediaGroups" : "mediaGroups"
  );
  const { t } = useTranslate("brands.reportings.media_groups.form");

  const updateUrlHandle = (): void => {
    mutateMedia(urlState);
    setOpenPopover("close");
  };

  useEffect(() => {
    if (openPopover === "close") {
      setTimeout(() => setUrlState(url || ""), 250);
    }
  }, [openPopover, url]);

  return (
    <InsightItemWrapper
      label={label}
      disabled={false}
      clear={true}
      width="100%"
    >
      <FlexBox alignItems="stretch" justifyContent="stretch">
        {openPopover === "open" && (
          <CloseOverlay onClick={() => setOpenPopover("close")} />
        )}
        <FlexBox
          position="relative"
          zIndex={openPopover === "open" ? 3 : "auto"}
        >
          {!url ? (
            <Paragraph paragraph={"-"} fontWeight={500} />
          ) : (
            <a href={url} target="_blank" rel="noreferrer">
              <Paragraph
                paragraph={t("open_url")}
                color={theme.color.primaryColor}
                fontWeight={500}
              />
            </a>
          )}
          {!permanentDisabled && (
            <Popover
              forceActive={openPopover}
              padding={4}
              width={290}
              renderTrigger={() => (
                <TriggerWrapper
                  onClick={() => setOpenPopover("open")}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  position="relative"
                >
                  <Spacer size={"mini"} />
                  <Icon icon={IconTypeE.edit} size="small" />
                  <Tooltip
                    visible={openPopover === "close" && hover}
                    position={"top"}
                    tooltip={t("edit_url")}
                  />
                </TriggerWrapper>
              )}
              content={
                <FlexBox customWidth={CustomWidthE.full}>
                  <StyledInput
                    value={urlState || ""}
                    setValue={setUrlState}
                    type="text"
                  />
                  <Spacer size="micro" />
                  <Button
                    size={ButtonSizeE.small}
                    onClick={() => updateUrlHandle()}
                    disabled={disabled}
                  >
                    <Icon icon={IconTypeE.check} size="small" />
                  </Button>
                </FlexBox>
              }
            />
          )}
        </FlexBox>
      </FlexBox>
    </InsightItemWrapper>
  );
};

export default InputUrl;
