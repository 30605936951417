import I18n, { Scope, TranslateOptions } from "i18n-js";
import * as React from "react";
import { createContext, FC, ReactNode, useEffect, useState } from "react";

export type TranslationsLocaleT = "en" | "cs";

export type TranslationsT = {
  renderChildren: (ready: boolean) => ReactNode;
  translations: Record<TranslationsLocaleT, JSON> | null;
};

export type TranslationsContextT = {
  t: (scope: Scope, options?: TranslateOptions) => string;
};

export const TranslationsContext = createContext<TranslationsContextT>({
  t: () => "",
});

const TranslationsContextProvider: FC<TranslationsT> = ({
  translations,
  renderChildren,
}) => {
  // prepare for change language option
  const [locale, _] = useState<TranslationsLocaleT>("cs");
  const [ready, setReady] = useState(false);

  useEffect(() => {
    I18n.translations = translations || {};
    I18n.defaultLocale = locale;
    I18n.locale = locale;
    I18n.currentLocale();
    setReady(true);
  }, [locale]);

  return (
    <TranslationsContext.Provider value={I18n}>
      {renderChildren(ready)}
    </TranslationsContext.Provider>
  );
};

export default TranslationsContextProvider;
