import { _o } from "../helpers";

const getStep = () => {
  const stepsInputElement = document.getElementById("campaign_step");

  if (stepsInputElement) {
    return parseInt(stepsInputElement.value);
  }

  return 1;
};

const setStep = (value) => {
  const stepsInputElement = document.getElementById("campaign_step");

  if (stepsInputElement) {
    stepsInputElement.value = value;
  }
};

const getClass = (to, current) => {
  if (to < current) {
    return "enabled";
  }

  if (to == current) {
    return "active";
  }

  if (to == current + 1) {
    return "submit";
  }

  return "disabled";
};

const setClasses = () => {
  const stepItems = document.getElementsByClassName("step-item");
  const stepContents = document.getElementsByClassName("step-content");
  const currentStep = getStep();

  if (stepItems && stepContents) {
    _o(stepItems).forEach((item) => {
      const toStep = parseInt(item.dataset.toStep);

      if (toStep) {
        item.classList.remove("active", "disabled", "enabled", "submit");
        item.classList.add(getClass(toStep, currentStep));
      }
    });

    _o(stepContents).forEach((content) => {
      const forStep = parseInt(content.dataset.forStep);

      if (forStep) {
        content.classList.remove("selected");
        if (forStep == currentStep) {
          content.classList.add("selected");
        }
      }
    });
  }
};

const submitForm = () => {
  const form = document.getElementById("create_campaign");

  if (form) {
    form.submit();
  }
};

const goToStepHandle = (stepElement) => {
  const className = stepElement.className;
  const toStep = stepElement.dataset.toStep;

  if (className.includes("submit")) {
    submitForm();

    return;
  }

  if (toStep && className.includes("enabled")) {
    setStep(toStep);
    setClasses();

    return;
  }

  return;
};

export const campaignFormStepsInit = () => {
  const stepItems = document.getElementsByClassName("step-item");

  if (stepItems) {
    _o(stepItems).forEach((item) => {
      item.addEventListener("click", () => goToStepHandle(item));
    });

    setClasses();
  }
};
