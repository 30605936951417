import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { theme } from "../styling/theme";
import Icon from "./Icon";
import VisibleWrapper from "./VisibleWrapper";

const Wrapper = styled(VisibleWrapper)({
  cursor: "pointer",
});

type CheckHeartT = {
  active: boolean;
  visible: boolean;
  onClickHandle: () => void;
  lightColor?: string;
};

const CheckHeart: FC<CheckHeartT> = ({
  active,
  visible,
  onClickHandle,
  lightColor = theme.color.greyLightColor,
}) => {
  return (
    <Wrapper visible={visible} onClick={onClickHandle}>
      <Icon
        icon={IconTypeE.heart}
        size="small"
        color={active ? theme.color.textGreyDarkColor : lightColor}
      />
    </Wrapper>
  );
};

export default CheckHeart;
