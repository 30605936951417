import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { rem, theme } from "../styling/theme";
import FlexBox from "./FlexBox";
import Icon from "./Icon";
import Popover from "./Popover";

type IconWrapperStyleT = {
  hover: boolean;
  margin?: "left" | "right";
};

const Wrapper = styled(FlexBox)({
  marginBottom: rem(12),
});

//TODO: [FE/TODO] - Unify CircleButton
export const IconWrapper = styled(FlexBox)<IconWrapperStyleT>(
  ({ hover, margin = "left" }) => ({
    marginLeft: margin === "left" ? rem(8) : 0,
    marginRight: margin === "right" ? rem(8) : 0,
    padding: rem(4),
    borderRadius: "50%",
    backgroundColor: hover
      ? theme.color.yellowHoverColor
      : theme.color.yellowColor,
    transition: "all 0.4s",
    transitionTimingFunction: theme.timing.default,
    cursor: "pointer",
  })
);

const Label = styled.div({
  fontWeight: 600,
  cursor: "default",
});

type SectionLabelT = {
  title: string;

  popoverText?: string;
  popoverTitle?: string;
};

const SectionLabel: FC<SectionLabelT> = ({
  title,
  popoverText,
  popoverTitle,
}) => {
  return (
    <Wrapper>
      <Label>{title}</Label>
      {popoverText && (
        <Popover
          renderTrigger={(active) => (
            <IconWrapper hover={active}>
              <Icon icon={IconTypeE.info} size="mini" />
            </IconWrapper>
          )}
          content={popoverText}
          title={popoverTitle}
        />
      )}
    </Wrapper>
  );
};

export default SectionLabel;
