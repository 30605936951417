import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import Avatar from "../components/Avatar";
import FlexBox from "../components/FlexBox";
import Icon, { IconSizeT } from "../components/Icon";
import { NetworkProviderT } from "../entry-files/database-detail/types";
import { devideStringToTwo, getColorFromString } from "../helpers/formaters";
import { rem, theme } from "../styling/theme";

const Wrapper = styled(FlexBox)<{ circlesSize: number }>(({ circlesSize }) => ({
  padding: `${rem(15)} ${rem(circlesSize * 0.5)} ${rem(15)} ${rem(
    circlesSize * 0.5
  )}`,
  minWidth: "fit-content",
  overflow: "hidden",
  zIndex: 1,
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,
}));

const SocialIconWrapper = styled(FlexBox)({
  position: "absolute",
  borderRadius: "50%",
  bottom: rem(0),
  right: rem(0),
  padding: rem(2),
  backgroundColor: theme.color.whiteColor,
  zIndex: 2,
});

const ColorCircle = styled(FlexBox)<{
  color: string;
  index: number;
  size: number;
  avatarSize: number;
}>(({ color, index, size, avatarSize }) => ({
  position: "absolute",
  left: index === 1 ? rem(size * 0.1) : rem(size * 0.6 + avatarSize * 0.5),
  top: index === 1 ? "-15%" : "50%",
  backgroundColor: color,
  width: rem(size),
  height: rem(size),
  borderRadius: "50%",
  zIndex: -1,
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,
}));

type AvatarWithCirclesSizesT = "big" | "default" | "mini";
type AvatarWithCirclesT = {
  handle: string;
  profileImageUrl: string | null;
  avatarSize?: AvatarWithCirclesSizesT;
  disabled?: boolean;
  provider?: NetworkProviderT;
};

const AvatarWithCircles: FC<AvatarWithCirclesT> = ({
  handle,
  profileImageUrl,
  disabled = false,
  avatarSize = "default",
  provider,
}) => {
  const getProviderIcon = (provider: NetworkProviderT): IconTypeE => {
    switch (provider) {
      case "instagram":
        return IconTypeE.socInstagram;
      case "tiktok":
        return IconTypeE.socTiktok;
      case "youtube":
        return IconTypeE.socYoutube;
    }
  };

  const getAvatarSizeDimensions = (): {
    size: number;
    circlesSize: number;
    iconSize: IconSizeT;
  } => {
    switch (avatarSize) {
      case "big":
        return {
          size: 240,
          circlesSize: 200,
          iconSize: "monster",
        };
      case "mini":
        return {
          size: 90,
          circlesSize: 70,
          iconSize: "big",
        };
      default:
        return {
          size: 140,
          circlesSize: 100,
          iconSize: "large",
        };
    }
  };

  const { size, circlesSize, iconSize } = getAvatarSizeDimensions();

  return (
    <Wrapper
      position="relative"
      flexDirection="column"
      circlesSize={circlesSize}
    >
      {devideStringToTwo(`${handle}`).map((item, index) => (
        <ColorCircle
          key={item}
          color={disabled ? theme.color.greyColor : getColorFromString(item)}
          index={index}
          size={circlesSize}
          avatarSize={size}
        />
      ))}
      <FlexBox position="relative">
        <Avatar
          size={size}
          name={handle}
          src={profileImageUrl}
          borderWidth={4}
          withShadow={true}
        />
        {provider && (
          <SocialIconWrapper>
            <Icon icon={getProviderIcon(provider)} size={iconSize} />
          </SocialIconWrapper>
        )}
      </FlexBox>
    </Wrapper>
  );
};

export default AvatarWithCircles;
