import { DataToQueryT, getQuery } from "../../../data-access/getQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

type getUpdatedUrlT = (reportId: number, data?: DataToQueryT) => string;
export const getUpdatedUrl: getUpdatedUrlT = (reportId, data) => {
  const API_URL_OFFERS = getApiUrl(ApiRoutesE.REPORTING_MEDIA_GROUPS, reportId);

  if (data && Object.keys(data).length > 0) {
    const query = getQuery(data);

    return `${API_URL_OFFERS}?${query}`;
  }
  return API_URL_OFFERS;
};
