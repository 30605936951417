import * as React from "react";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import uniqid from "uniqid";
import { IconTypeE } from "../../../assets/icons/Icons";
import Button, { ButtonSizeE } from "../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Spacer from "../../../components/Spacer";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem } from "../../../styling/theme";
import Dropdown, { DropdownItem } from "../../../ui/Dropdown";
import { createMediaGroup } from "../data-access/createMediaGroup";
import { contentTypes } from "../data-access/dataConstants";
import { createMediaGroupMutation } from "../data-access/mutations/createMediaGroupMutation";
import { MediaGroupKindT, MediaKindT } from "../data-access/types";
import { getParticipantId } from "../utils/helpers";

type HeaderActionsT = {
  isAbsolutePosition: boolean;
};

const HeaderActions: FC<HeaderActionsT> = ({ isAbsolutePosition }) => {
  const [buttonHover, setButtonHover] = useState(false);
  const { t } = useTranslate("brands.reportings.media_groups.buttons");

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(createMediaGroup, {
    onMutate: () => {
      setButtonHover(false);
    },
    onSuccess: (data) => {
      createMediaGroupMutation(queryClient, data);
    },
  });

  type addNewContentHandleT = (kind: MediaGroupKindT) => void;
  const addNewContentHandle: addNewContentHandleT = (kind) => {
    if (!isLoading) {
      const mediaKind: MediaKindT = kind === "story_set" ? "story" : kind;
      const data = {
        participantId: getParticipantId(),
        payload: {
          media_group: {
            kind,
            temporary_id: uniqid(),
            medias_attributes: [{ kind: mediaKind }],
          },
        },
      };

      mutate(data);
    }
  };

  return (
    <FlexBox
      position="relative"
      onMouseEnter={() => setButtonHover(true)}
      onMouseLeave={() => setButtonHover(false)}
      customWidth={isAbsolutePosition ? CustomWidthE.full : undefined}
    >
      <Button
        size={ButtonSizeE.small}
        customWidth={isAbsolutePosition ? CustomWidthE.full : undefined}
      >
        <Icon icon={IconTypeE.arrowBottom} size="mini" />
        <Spacer size={"mini"} />
        {t("add_post")}
      </Button>
      <Dropdown
        active={buttonHover}
        width={isAbsolutePosition ? "100%" : rem(150)}
      >
        <FlexBox flexDirection="column" customWidth={CustomWidthE.full}>
          {contentTypes.map((item) => (
            <DropdownItem onClick={() => addNewContentHandle(item)} key={item}>
              {t(item)}
            </DropdownItem>
          ))}
        </FlexBox>
      </Dropdown>
    </FlexBox>
  );
};

export default HeaderActions;
