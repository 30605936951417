import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { rem, theme } from "../styling/theme";
import FlexBox from "./FlexBox";
import Paragraph from "./Paragraph";

const Wrapper = styled(FlexBox)({
  position: "relative",
  borderRadius: rem(8),
  minWidth: "fit-content",
  border: `1px solid ${theme.color.greyColor}`,
  overflow: "hidden",
});

const Background = styled.div<{ leftSide: boolean }>(({ leftSide }) => ({
  position: "absolute",
  height: "calc(100% - 2px)",
  width: "calc(50% - 2px)",
  borderTopLeftRadius: leftSide ? rem(6) : 0,
  borderBottomLeftRadius: leftSide ? rem(6) : 0,
  borderTopRightRadius: leftSide ? 0 : rem(6),
  borderBottomRightRadius: leftSide ? 0 : rem(6),
  right: leftSide ? "calc(50% + 1px)" : "1px",
  background: theme.color.yellowColor,
  zIndex: 1,
  transition: "all 0.2s",
  transitionTimingFunction: theme.timing.default,
}));

const Side = styled(FlexBox)({
  height: rem(30),
  cursor: "pointer",
  zIndex: 1,
});

type SwitchT = {
  state: boolean;
  setState: Dispatch<SetStateAction<boolean>>;
  leftOption: JSX.Element | string;
  rightOption: JSX.Element | string;
  sideWidth?: number;
  disable?: boolean;
};

const Switch: FC<SwitchT> = ({
  state,
  setState,
  leftOption,
  rightOption,
  sideWidth = 40,
  disable,
}) => {
  const onClickHandle = (state: boolean): void => {
    if (disable) {
      return;
    }

    setState(state);
  };

  return (
    <Wrapper>
      <Background leftSide={state} />
      <Side customWidth={rem(sideWidth)} onClick={() => onClickHandle(true)}>
        {typeof leftOption === "string" ? (
          <Paragraph
            align="center"
            fontWeight={600}
            wordBreak="normal"
            paragraph={leftOption}
          />
        ) : (
          leftOption
        )}
      </Side>
      <Side customWidth={rem(sideWidth)} onClick={() => onClickHandle(false)}>
        {typeof rightOption === "string" ? (
          <Paragraph
            align="center"
            fontWeight={600}
            wordBreak="normal"
            paragraph={rightOption}
          />
        ) : (
          rightOption
        )}
      </Side>
    </Wrapper>
  );
};

export default Switch;
