import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import FlexBox from "../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../components/Heading";
import Icon from "../../../../components/Icon";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import FilesUploadArea from "../../../../features/files-upload/FilesUploadArea";
import { PendingFileT } from "../../../../hooks/usePendingFile";
import { rem, theme } from "../../../../styling/theme";
import { FileStatusTypeE } from "../../types";
import PendingFile from "../files-update/PendingFile";

const UploadButtonWrapper = styled(FlexBox)({
  position: "relative",
  width: rem(100),
  height: rem(100),
  backgroundColor: theme.color.backgroundColor,
  borderRadius: rem(4),
  border: `1px solid ${theme.color.backgroundColor}`,
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,

  ["&:hover"]: {
    borderColor: theme.color.greyColor,
  },
});

const FilesWrapper = styled(FlexBox)({
  flexWrap: "wrap",
});

type FilesT = {
  offerId: number;
  fileURLs: string[];
  setFileURLs: Dispatch<SetStateAction<string[]>>;
};

const Files: FC<FilesT> = ({ setFileURLs, fileURLs }) => {
  const [pendingFiles, setPendingFiles] = useState<PendingFileT[]>([]);

  useEffect(() => {
    if (pendingFiles) {
      const uploadedFiles = pendingFiles.filter(
        (file) => file.status === FileStatusTypeE.uploaded
      );

      if (uploadedFiles.length > 0) {
        setFileURLs(() => {
          return uploadedFiles.map((file) => (file.link ? file.link : "none"));
        });
      }
    }
  }, [pendingFiles]);

  useEffect(() => {
    if (fileURLs.length === 0) {
      setPendingFiles([]);
    }
  }, [fileURLs]);

  return (
    <FlexBox flexDirection="column" alignItems="stretch">
      <Heading heading={"Soubory"} headingType={HeadingTypeT.h3} />
      <FilesWrapper justifyContent="stretch">
        {pendingFiles &&
          pendingFiles.length > 0 &&
          pendingFiles.map((file) => {
            return (
              <PendingFile
                key={file.id}
                pendingFile={file}
                setPendingFiles={setPendingFiles}
                background={theme.color.backgroundColor}
              />
            );
          })}
        <UploadButtonWrapper flexDirection="column">
          <Icon icon={IconTypeE.plus} size="large" />
          <Spacer direction="vertical" size="mini" />
          <Paragraph
            paragraph={"Přidat soubor"}
            paragraphSize="small"
            fontWeight={700}
          />
          <FilesUploadArea setPendingFiles={setPendingFiles} />
        </UploadButtonWrapper>
      </FilesWrapper>
    </FlexBox>
  );
};

export default Files;
