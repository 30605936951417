import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Spacer from "../../../components/Spacer";
import { useTranslate } from "../../../hooks/useTranslate";
import { hexToRgb, rem, theme } from "../../../styling/theme";
import { CampaignStateE } from "../../chat/types";

type ColorT = {
  color: string;
};

const Indicator = styled(FlexBox)<ColorT>(({ color }) => ({
  width: rem(10),
  height: rem(10),
  borderRadius: "50%",
  backgroundColor: color,
  boxShadow: `0px 4px 12px ${hexToRgb(color, 0.2)}`,
}));

type CampaignStatusT = {
  status: CampaignStateE;
};

const CampaignStatus: FC<CampaignStatusT> = ({ status }) => {
  const statusColors = {
    draft: theme.color.textGreyColor,
    published: theme.color.yellowPressedColor,
    approved: theme.color.successColor,
    disapproved: theme.color.errorColor,
    finished: theme.color.errorColor,
    expired: theme.color.errorColor,
  };

  const { t } = useTranslate("brands.campaigns.status");

  return (
    <FlexBox>
      <Indicator color={statusColors[status]} />
      <Spacer size="micro" />
      <Heading
        heading={t(status)}
        spaceBottom={0}
        headingType={HeadingTypeT.h4}
      />
    </FlexBox>
  );
};

export default CampaignStatus;
