import * as React from "react";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { GridWrapper } from "../../../components/GridWrapper";
import Modal from "../../../components/Modal";
import NoResults from "../../../components/NoResults";
import Spacer from "../../../components/Spacer";
import ReferencialCard from "../../../features/card/ReferencialCard";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../features/Placeholders/PlaceholderLoader";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem } from "../../../styling/theme";
import { TRANSITION_TIME } from "../../../ui/cards-ui/TransitionWrapper";
import TableContentWrapper from "../../campaign-detail/components/TableContentWrapper";
import { MediaGroupsHelperContextProvider } from "../context-providers/MediaGroupsHelperContextProvider";
import { AddedMediaGroupsStateT } from "../data-access/createMediaGroup";
import { removeMediaGroupMutation } from "../data-access/mutations/removeMediaGroupMutation";
import { removeMediaMutation } from "../data-access/mutations/removeMediaMutation";
import { removeMedia } from "../data-access/removeMedia";
import { MediaGroupT, MediaT, QueryStackT } from "../data-access/types";
import CalendarContainer, { DateCalendarData } from "./CalendarContainer";
import LightBoxModal, { LightBoxStateT } from "./card-cover/LightBoxModal";
import RemoveMediaModal from "./card/RemoveMediaModal";
import MediaGroup from "./MediaGroup";

export type MediaToRemoveStateT = {
  media: MediaT;
  participantId: string;
  removed: boolean;
  story: boolean;
  queryStack: QueryStackT;
};

type MediaGroupsContainerT = {
  mediaGroups?: MediaGroupT[];
  addedMediaGroups?: AddedMediaGroupsStateT;
  loading?: boolean;
  withParticipant?: boolean;
};

const MediaGroupsContainer: FC<MediaGroupsContainerT> = ({
  mediaGroups,
  addedMediaGroups,
  loading,
  withParticipant,
}) => {
  const [removeMediaDisable, setRemoveMediaDisable] = useState<boolean>(false);
  const [referencialCardWidth, setReferencialCardWidth] = useState<number>(0);

  const [mediaToRemove, setMediaToRemove] = useState<
    MediaToRemoveStateT | undefined
  >();
  const [lightBoxState, setLightBoxState] = useState<
    LightBoxStateT | undefined
  >();
  const [dateCalendar, setDateCalendar] = useState<
    DateCalendarData | undefined
  >();

  const { t } = useTranslate("brands.reportings.participants.index");

  const queryClient = useQueryClient();
  const { mutate } = useMutation(removeMedia, {
    onMutate: () => {
      setRemoveMediaDisable(true);
    },
    onSuccess: () => {
      if (mediaToRemove) {
        setMediaToRemove((prevState) => {
          if (prevState) {
            return { ...prevState, removed: true };
          }

          return prevState;
        });

        setTimeout(() => {
          if (mediaToRemove.story) {
            removeMediaMutation(
              queryClient,
              mediaToRemove,
              clearStatesAfterRemoving,
              mediaToRemove.queryStack
            );

            return;
          }

          removeMediaGroupMutation(
            queryClient,
            mediaToRemove,
            clearStatesAfterRemoving,
            mediaToRemove.queryStack
          );
        }, TRANSITION_TIME);
      }
    },

    onError: () => {
      clearStatesAfterRemoving();
    },
  });

  const clearStatesAfterRemoving = (): void => {
    setMediaToRemove(undefined);
    setRemoveMediaDisable(false);
  };

  const removeMediaHandle = (): void => {
    if (mediaToRemove) {
      const data = {
        id: mediaToRemove.media.id,
        participantId: mediaToRemove.participantId,
      };

      mutate(data);
    }
  };

  const getContent = (): JSX.Element => {
    if (loading) {
      return (
        <PlaceholderLoader
          type={PlaceholdersTypeE.camapign_card}
          count={8}
          direction="row"
          justifyContent="flex-start"
        />
      );
    }

    if (
      (addedMediaGroups && addedMediaGroups.length > 1) ||
      (mediaGroups && mediaGroups.length > 0)
    ) {
      return (
        <>
          {addedMediaGroups &&
            addedMediaGroups.map((item) => (
              <MediaGroup
                key={item.temporary_id ? item.temporary_id : item.id}
                mediaGroup={item}
                mediaToRemove={mediaToRemove}
                liveAddedMediaGroup={true}
              />
            ))}

          {mediaGroups &&
            mediaGroups.map((item) => (
              <MediaGroup
                key={item.id}
                mediaGroup={item}
                mediaToRemove={mediaToRemove}
              />
            ))}
        </>
      );
    }

    return (
      <TableContentWrapper isWhite={true}>
        <NoResults illustrationSize={350} message={t("feed.empty_title")} />
        <Spacer direction="vertical" />
      </TableContentWrapper>
    );
  };

  return (
    <MediaGroupsHelperContextProvider
      cardWidth={rem(referencialCardWidth)}
      withParticipant={!!withParticipant}
      setMediaToRemove={setMediaToRemove}
      setLightBoxState={setLightBoxState}
      dateCalendar={dateCalendar}
      setDateCalendar={setDateCalendar}
    >
      <GridWrapper>
        <ReferencialCard setReferencialCardWidth={setReferencialCardWidth} />

        {getContent()}

        <Modal
          visible={!!mediaToRemove}
          close={() => setMediaToRemove(undefined)}
          slim={true}
          renderChildren={() => (
            <RemoveMediaModal
              disabled={removeMediaDisable}
              removeMediaHandle={removeMediaHandle}
            />
          )}
        />

        <Modal
          visible={!!lightBoxState}
          close={() => setLightBoxState(undefined)}
          contentPadding={rem(4)}
          showCloseButton={false}
          renderChildren={(_, close) => (
            <>
              {!!lightBoxState && (
                <LightBoxModal lightBoxState={lightBoxState} close={close} />
              )}
            </>
          )}
        />
      </GridWrapper>
      <CalendarContainer />
    </MediaGroupsHelperContextProvider>
  );
};

export default MediaGroupsContainer;
