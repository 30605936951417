import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import Button from "../../../../components/Button";
import FlexBox from "../../../../components/FlexBox";
import Icon from "../../../../components/Icon";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { rem, theme } from "../../../../styling/theme";
import { RatingStateT } from "./Rating";

const StarWrapper = styled(FlexBox)({
  margin: `${rem(32)} ${rem(4)} ${rem(52)}`,
  cursor: "pointer",
});

type SetRatingModal = {
  rating: RatingStateT;
  closeModal: () => void;
};

const SetRatingModal: FC<SetRatingModal> = React.memo(
  ({ rating, closeModal }) => {
    const { feedback, stars } = rating;

    return (
      <FlexBox
        flexDirection="column"
        justifyContent="stretch"
        alignItems="stretch"
      >
        <FlexBox>
          {[...Array(5)].map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <StarWrapper key={index}>
              <Icon
                icon={IconTypeE.star}
                size="monster"
                color={
                  stars > index
                    ? theme.color.yellowColor
                    : theme.color.greyColor
                }
              />
            </StarWrapper>
          ))}
        </FlexBox>
        <Paragraph paragraph={feedback} align="center" />
        <Spacer size="big" direction="vertical" />
        <FlexBox justifyContent="center">
          <Button onClick={closeModal}>{"Zavřít"}</Button>
        </FlexBox>
      </FlexBox>
    );
  }
);

export default SetRatingModal;
