import * as React from "react";
import { FC, useContext } from "react";
import TransitionWrapper from "../../../ui/cards-ui/TransitionWrapper";
import { MediaGroupsHelperContext } from "../context-providers/MediaGroupsHelperContextProvider";
import { MediaGroupEmptyPossibleT } from "../data-access/createMediaGroup";
import { MediaGroupT } from "../data-access/types";
import MediaCard from "./card/MediaCard";
import { MediaToRemoveStateT } from "./MediaGoupsContainer";
import MediaGroupStorySet from "./MediaGroupStorySet";

type MediaGroupWrapperT = {
  mediaGroup: MediaGroupEmptyPossibleT | MediaGroupT;
  mediaToRemove: MediaToRemoveStateT | undefined;
  liveAddedMediaGroup?: boolean;
};

const MediaGroupWrapper: FC<MediaGroupWrapperT> = ({
  mediaGroup,
  mediaToRemove,
  liveAddedMediaGroup,
}) => {
  const emptyMediaGroup = !mediaGroup.id;
  const mediaToRemoveId = mediaToRemove?.media.reporting_media_group_id;
  const mediaToRemoveRemoved = mediaToRemove?.removed;
  const mediaToRemoveIsNotStory = !mediaToRemove?.story;

  const shrinkMediaGroup =
    (!emptyMediaGroup &&
      mediaToRemoveRemoved &&
      mediaToRemoveIsNotStory &&
      mediaToRemoveId === mediaGroup.id) ||
    emptyMediaGroup;

  const { cardWidth } = useContext(MediaGroupsHelperContext);

  return (
    <TransitionWrapper customWidth={shrinkMediaGroup ? "0" : cardWidth}>
      {emptyMediaGroup ? (
        <div />
      ) : (
        <>
          {mediaGroup.kind === "story_set" ? (
            <MediaGroupStorySet
              mediaGroup={mediaGroup}
              mediaToRemove={mediaToRemove}
              liveAddedMediaGroup={liveAddedMediaGroup}
            />
          ) : (
            <MediaCard
              media={mediaGroup.medias[0]}
              mediaGroup={mediaGroup}
              liveAddedMedia={liveAddedMediaGroup}
            />
          )}
        </>
      )}
    </TransitionWrapper>
  );
};

export default MediaGroupWrapper;
