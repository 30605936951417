import * as React from "react";
import { FC, useContext, useEffect, useState } from "react";
import { animateScroll } from "react-scroll";
import { ActionCableContext } from "../";
import { UserRoleT, UserT } from "../../types";
import {
  CampaignT,
  LinkCheckT,
  MessageT,
  OfferStateE,
  RatingsT,
  RecivedDataT,
} from "../types";
import CreateMessageBar from "./CreateMessageBar";
import MessagesList, { SCROLL_WRAPPER_ID } from "./MessagesList";

type MessagesContainerT = {
  activeOfferId: number;
  activeOfferState?: OfferStateE;
  activeOfferRating?: RatingsT[];
  campaign?: CampaignT;
  userId: number;
  userRole: UserRoleT;
  userData: UserT;
};

const MessagesContainer: FC<MessagesContainerT> = ({
  activeOfferId,
  activeOfferState,
  activeOfferRating,
  campaign,
  userId,
  userRole,
  userData,
}) => {
  const [showedMessages, setShowedMessages] = useState<MessageT[] | null>([]);
  const [linksCheckState, setLinksCheckState] = useState<LinkCheckT[]>([]);
  const [offerState, setOfferState] = useState<OfferStateE | null>(null);

  const cable = useContext<any>(ActionCableContext);

  type scrollToBottomT = () => void;
  const scrollToBottom: scrollToBottomT = () => {
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: SCROLL_WRAPPER_ID,
        duration: 450,
        smooth: "easeInOutQuint",
      });
    }, 250);
  };

  type handleReceivedDataT = (data: RecivedDataT) => void;
  const handleReceivedData: handleReceivedDataT = (data) => {
    if (data.message) {
      const json = data.message.json;

      if (json && json.is_system) {
        // With every new syste message is state of offer
        // force pushed to the local state.

        const { offer } = json;
        handleReceivedMessage(json);

        if (!!offer) {
          setOfferState(() => offer.state);
        }
      }

      if (json.sender.id !== userId && !json.is_system) {
        // A websocket handle only messages from other side
        // own messages are hendled by api call.
        handleReceivedMessage(json);
        return;
      }
    }

    if (data.link_check && data.link) {
      handleReceivedCheckLink(data.link);
    }
  };

  type handleReceivedMessageT = (message: MessageT) => void;
  const handleReceivedMessage: handleReceivedMessageT = (message) => {
    setShowedMessages((prevState) => {
      if (prevState) {
        const existMessageIndex = prevState.findIndex(
          (item) => item.id === message.id
        );

        // handling for existed messages - f.e.: system messages with report
        if (existMessageIndex > -1) {
          prevState.splice(existMessageIndex, 1);
        }

        return [message, ...prevState];
      }

      return [message];
    });
    scrollToBottom();
  };

  type handleReceivedCheckLinkT = (link: LinkCheckT) => void;
  const handleReceivedCheckLink: handleReceivedCheckLinkT = (link) => {
    setLinksCheckState((prevState) => {
      if (prevState) {
        const filteredPrevState = prevState.filter(
          (item) => item.object_id !== link.object_id
        );
        return [...filteredPrevState, link];
      }

      return [link];
    });
  };

  useEffect(() => {
    if (activeOfferState) {
      setOfferState(() => activeOfferState);
    }
  }, [activeOfferState]);

  useEffect(() => {
    const channelOptions = {
      channel: "MessagesChannel",
      data: { offer_id: activeOfferId },
    };

    const channel = cable.subscriptions.create(channelOptions, {
      received: (data: RecivedDataT) => {
        handleReceivedData(data);
      },
    });

    return () => {
      channel.unsubscribe();
    };
  }, [activeOfferId]);

  return (
    <>
      <MessagesList
        userId={userId}
        userRole={userRole}
        offerId={activeOfferId}
        offerState={offerState}
        showedMessages={showedMessages}
        setShowedMessages={setShowedMessages}
        scrollToBottom={scrollToBottom}
      />

      <CreateMessageBar
        offerId={activeOfferId}
        offerState={offerState}
        offerRating={activeOfferRating}
        setShowedMessages={setShowedMessages}
        scrollToBottom={scrollToBottom}
        userData={userData}
        campaignName={campaign && campaign.name}
        userRole={userRole}
        linksCheckState={linksCheckState}
      />
    </>
  );
};

export default MessagesContainer;
