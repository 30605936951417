import styled from "@emotion/styled";
import FlexBox from "../../components/FlexBox";
import { rem, theme } from "../../styling/theme";

type PositionWrapperStyleT = {
  cardWidth: string;
  storySizing?: boolean;
};

const SIDE_PADDING = 12;
const HANDLES_TOP_SPACE = 28;

const PositionWrapper = styled(FlexBox)<PositionWrapperStyleT>(
  ({ cardWidth, storySizing = false }) => ({
    width: storySizing
      ? `calc(${cardWidth} - ${rem(SIDE_PADDING * 2)})`
      : cardWidth,
    minWidth: storySizing
      ? `calc(${cardWidth} - ${rem(SIDE_PADDING * 2)})`
      : cardWidth,
    paddingTop: storySizing ? 0 : rem(HANDLES_TOP_SPACE),
    paddingLeft: storySizing ? 0 : rem(SIDE_PADDING),
    paddingRight: storySizing ? 0 : rem(SIDE_PADDING),
    paddingBottom: 0,
    marginBottom: storySizing ? 0 : rem(24),
    transition: "all 0.5s",
    transitionTimingFunction: theme.timing.default,
    overflow: "hidden",
    transform: "translate3d(0,0,0)",

    ["@media screen and (max-width: 600px)"]: {
      width: cardWidth,
      minWidth: cardWidth,
      paddingTop: storySizing ? 0 : rem(SIDE_PADDING + HANDLES_TOP_SPACE),
      paddingLeft: 0,
      paddingRight: 0,
      marginBottom: storySizing ? 0 : rem(32),
    },
  })
);

export default PositionWrapper;
