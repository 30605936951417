import { Dispatch, SetStateAction } from "react";
import { GroupsOffsetsT } from "../components/Marketplace";
import { CampaignGroupKeyT } from "../types";

export type setScrolledGroupT = Dispatch<SetStateAction<CampaignGroupKeyT>>;

type handleSetScrolledGroupT = (
  groups: GroupsOffsetsT,
  currentOffset: number,
  setScrolledGroup: setScrolledGroupT,
  windowHeight: number
) => void;
export const handleSetScrolledGroup: handleSetScrolledGroupT = (
  groups,
  currentOffset,
  setScrolledGroup,
  windowHeight
) => {
  const SAFE_ZONE = windowHeight / 4;

  const scrolledSection = Object.values(groups).findIndex((group) => {
    return (
      currentOffset >= group.offset - SAFE_ZONE &&
      currentOffset < group.offset + group.height - SAFE_ZONE
    );
  });

  if (scrolledSection !== -1) {
    setScrolledGroup(Object.keys(groups)[scrolledSection] as CampaignGroupKeyT);
  }
};
