import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { IconTypeE } from "../../assets/icons/Icons";
import { rem, theme } from "../../styling/theme";
import FlexBox from "../FlexBox";
import Icon from "../Icon";
import VisibleWrapper from "../VisibleWrapper";

type ArrowSideT = {
  side: "left" | "right";
};

type HoverStyleT = {
  hover: boolean;
};

const Wrapper = styled(VisibleWrapper)<ArrowSideT>(({ side }) => ({
  position: "absolute",
  top: 0,
  height: "100%",
  width: "auto",
  right: side === "right" ? 0 : "auto",
  left: side === "left" ? 0 : "auto",
  zIndex: 1,
  cursor: "pointer",
}));

const ArrowWrapper = styled(FlexBox)<ArrowSideT>(({ side }) => ({
  paddingRight: side === "right" ? rem(16) : 0,
  paddingLeft: side === "left" ? rem(16) : 0,
}));

const IconWrapper = styled(FlexBox)<HoverStyleT>(({ hover }) => ({
  height: rem(36),
  minWidth: rem(36),
  borderRadius: "50%",
  background: hover ? theme.color.yellowColor : theme.color.whiteColor,
  transition: "background 0.4s",
  transitionTimingFunction: theme.timing.default,
  boxShadow: `0px 2px 9px rgb(0 0 0 / 10%)`,
}));

type ArrowT = {
  visible: boolean;
  onClickHandle: () => void;
} & ArrowSideT;

const Arrow: FC<ArrowT> = ({ side, visible, onClickHandle }) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <Wrapper side={side} visible={visible} onClick={onClickHandle}>
      <ArrowWrapper
        fullHeight={true}
        side={side}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <IconWrapper hover={hover}>
          <Icon
            size="small"
            icon={side === "right" ? IconTypeE.arrowRight : IconTypeE.arrowLeft}
          />
        </IconWrapper>
      </ArrowWrapper>
    </Wrapper>
  );
};

export default Arrow;
