import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { ReportingParticipant } from "./types";

export type GetParticipantsReponseT = {
  reporting_participants: ReportingParticipant[];
};

type getParticipantsT = (reportId: number) => Promise<GetParticipantsReponseT>;
export const getPartcipants: getParticipantsT = async (reportId) =>
  axios
    .get(getApiUrl(ApiRoutesE.REPORTING_PARTICIPANTS, reportId))
    .then((res) => res.data);
