import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import { rem, theme } from "../../../styling/theme";
import { AnimationOverlay } from "../PlaceholderLoader";

const Wrapper = styled(FlexBox)<{ count: number }>(({ count }) => ({
  position: "relative",
  width: `${100 / count}%`,
  height: rem(180),
  borderRadius: rem(16),
  overflow: "hidden",
}));

const IgPost = styled(FlexBox)({
  marginRight: rem(5),
  borderRadius: rem(15),
  backgroundColor: theme.color.greyLightColor,
});

const PostGalleryPlaceholder: FC<{ count: number }> = ({ count }) => (
  <Wrapper count={count}>
    <FlexBox
      justifyContent="flex-start"
      customWidth={CustomWidthE.full}
      fullHeight={true}
    >
      <AnimationOverlay />
      <IgPost
        customWidth={CustomWidthE.full}
        fullHeight={true}
        alignItems="center"
      />
    </FlexBox>
  </Wrapper>
);

export default PostGalleryPlaceholder;
