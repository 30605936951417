import { useEffect, useState } from "react";
import { useResponsive } from "./useResponsive";

type useCardCoverHeightT = (
  wrapperWidth?: number,
  defaultHeight?: number,
  aspectRatio?: number[]
) => {
  coverHeight: number;
};

export const useCardCoverHeight: useCardCoverHeightT = (
  wrapperWidth,
  defaultHeight = 280,
  aspectRatio = [4, 3]
) => {
  const { windowWidth } = useResponsive();
  const [coverHeight, setCoverHeight] = useState<number>(defaultHeight);

  useEffect(() => {
    if (wrapperWidth) {
      setCoverHeight(() => (wrapperWidth * aspectRatio[1]) / aspectRatio[0]);
    }
  }, [windowWidth]);

  return {
    coverHeight: coverHeight > 360 ? 360 : coverHeight,
  };
};
