export const optionsCredibleFollowers = {
  responsive: true,
  cutout: 65,
  layout: {
    padding: {
      top: 30,
      bottom: 35,
    },
  },
  plugins: {
    legend: {
      position: "bottom" as const,
      labels: {
        padding: 20,
      },
    },
    tooltip: {
      callbacks: {
        label: ({ formattedValue, label }: any) =>
          `${label}: ${formattedValue}%`,
      },
    },
  },
  scales: {
    y: {
      ticks: {
        display: false,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
    x: {
      ticks: {
        display: false,
      },
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
};
