import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import FlexBox from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import { rem, theme } from "../../../styling/theme";

type ArrowStyleT = {
  side: "left" | "right";
  disable: boolean;
  visible: boolean;
};

const ArrowWrapper = styled(FlexBox)<ArrowStyleT>(
  ({ side, visible, disable }) => ({
    position: "absolute",
    padding: rem(10),
    top: "50%",
    transform: "translateY(-50%)",
    opacity: disable ? 1 : 0.3,
    left: side === "left" ? rem(5) : "auto",
    right: side === "right" ? rem(5) : "auto",
    transition: "all 0.4s",
    transitionTimingFunction: theme.timing.default,
    cursor: disable ? "pointer" : "auto",
    visibility: visible ? "visible" : "hidden",
  })
);

type CarouselLightBoxArrowT = {
  side: "left" | "right";
  onClickHandle: () => void;
  disable: boolean;
  visible: boolean;
};

const CarouselLightBoxArrow: FC<CarouselLightBoxArrowT> = ({
  side,
  visible,
  disable,
  onClickHandle,
}) => {
  return (
    <ArrowWrapper visible={visible} disable={disable} side={side} zIndex={1}>
      <Button
        onClick={visible ? onClickHandle : undefined}
        type={ButtonTypeE.grey}
        size={ButtonSizeE.small}
      >
        <Icon
          icon={side === "left" ? IconTypeE.arrowLeft : IconTypeE.arrowRight}
          size="small"
        />
      </Button>
    </ArrowWrapper>
  );
};

export default CarouselLightBoxArrow;
