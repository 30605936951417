import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import Turbolinks from "turbolinks";
import { IconTypeE } from "../../../assets/icons/Icons";
import Avatar from "../../../components/Avatar";
import CircleButton from "../../../components/CircleButton";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { CrossPacksConts } from "../../../helpers/crossPacks";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useFetch } from "../../../hooks/useFetch";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import CardCover from "../../../ui/cards-ui/CardCover";
import CardWrapper from "../../../ui/cards-ui/CardWrapper";
import { ActionWrapper, Content } from "../../../ui/cards-ui/styles";
import { CampaignT } from "../../chat/types";
import { CampignHidePayloadT } from "../types";

type ExpirationStyleT = {
  hover: boolean;
};

const ActionButton = styled(FlexBox)({
  cursor: "pointer",
});

const Expiration = styled(FlexBox)<ExpirationStyleT>(({ hover }) => ({
  position: "absolute",
  bottom: hover ? rem(48) : rem(24),
  fontSize: hover ? rem(13) : rem(14),
  color: hover ? theme.color.textGreyColor : theme.color.textLightGreyColor,
  left: rem(16),
  transition: "all 0.3s",
  transitionTimingFunction: theme.timing.default,
}));

type CampaignCardT = {
  campaign: CampaignT;
  cardWidth: string;
  isHidden?: boolean;
  hideOption?: boolean;
};

const CampaignCard: FC<CampaignCardT> = ({
  campaign,
  cardWidth,
  isHidden = false,
  hideOption = true,
}) => {
  const {
    id,
    image_url,
    barter,
    short_description,
    name,
    invited,
    expiration_text,
    offer_path,
    campaign_brand,
  } = campaign;

  const brandLogo = campaign_brand?.logo_url;
  const DETAIL_PATH = `/influencers/campaigns/${id}`;

  const { t } = useTranslate("influencer.market");

  const [localHidden, setLocalHidden] = useState(isHidden);

  const expiration = `${t("will_expire")} ${expiration_text}`;

  const apiUrl = getApiUrl(ApiRoutesE.CAMPAIGN_HIDE, id);
  const body = {
    hidden: !localHidden,
  };

  const { pending, call, payload } = useFetch<CampignHidePayloadT>(
    apiUrl,
    undefined,
    "PUT",
    body
  );

  const onClickHandle = (): void => {
    if (offer_path) {
      Turbolinks.visit(offer_path);
      return;
    }

    const modal = document.getElementById(
      "external-modal-trigger-preview-modal"
    );

    if (modal) {
      const detailUrl = `${window.location.pathname}?campaign-detail=${id}`;
      window.history.pushState({}, "", detailUrl);

      modal.dataset.url = DETAIL_PATH;
      const changeEvent = new Event(CrossPacksConts.EXTERNAL_MODAL);

      document.dispatchEvent(changeEvent);
    }
  };

  const onEyeClickHandle = (): void => {
    if (!pending) {
      call();
    }
  };

  useEffect(() => {
    if (payload) {
      const { hidden } = payload;

      setLocalHidden(hidden);
    }
  }, [payload]);

  const getLabelsArray = (): string[] => {
    const arr = [];

    if (invited) {
      arr.push(t("labels.invited"));
    }

    if (offer_path) {
      arr.push(t("labels.offered"));
    }

    if (barter) {
      arr.push(t("labels.barter"));
    }

    return arr;
  };

  return (
    <CardWrapper
      cardWidth={cardWidth}
      renderChildren={(wrapperWidth, actionsVisible) => (
        <>
          <CardCover
            onClick={onClickHandle}
            image={image_url}
            wrapperWidth={wrapperWidth}
            labels={getLabelsArray()}
            defaultHeight={280}
          />
          <Content
            onClick={onClickHandle}
            flexDirection="column"
            justifyContent="space-between"
            customWidth={CustomWidthE.full}
          >
            <FlexBox
              flexDirection="column"
              alignItems="flex-start"
              customWidth={CustomWidthE.full}
            >
              <FlexBox
                justifyContent="flex-start"
                customWidth={CustomWidthE.full}
              >
                {brandLogo && (
                  <>
                    <Avatar name={name} src={brandLogo} size={48} />
                    <Spacer size="mini" />
                  </>
                )}
                <FlexBox>
                  <Paragraph paragraph={name} truncate={50} fontWeight={600} />
                </FlexBox>
              </FlexBox>

              <Spacer size="mini" direction="vertical" />
              <Paragraph
                truncate={100}
                color={theme.color.textGreyColor}
                paragraphSize="small"
                paragraph={short_description}
              />
            </FlexBox>
            <Spacer direction="vertical" size={20} />
          </Content>
          <Expiration hover={!!actionsVisible}>{expiration}</Expiration>
          <ActionWrapper visible={!!actionsVisible}>
            <FlexBox
              flexDirection="column"
              customWidth={CustomWidthE.full}
              alignItems="stretch"
            >
              <FlexBox
                flexDirection="row"
                customWidth={CustomWidthE.full}
                justifyContent="space-between"
              >
                {hideOption && (
                  <ActionButton onClick={onEyeClickHandle}>
                    <Icon
                      icon={localHidden ? IconTypeE.eye : IconTypeE.eyeStrike}
                      color={
                        localHidden
                          ? theme.color.textGreyDarkColor
                          : theme.color.textGreyColor
                      }
                    />
                    <Spacer size="micro" />
                    <Paragraph
                      paragraph={localHidden ? t("restore") : t("hide")}
                      fontWeight={500}
                      color={
                        localHidden
                          ? theme.color.textGreyDarkColor
                          : theme.color.textGreyColor
                      }
                    />
                  </ActionButton>
                )}
                <CircleButton
                  onClickHandle={onClickHandle}
                  hoverBackground={theme.color.yellowHoverColor}
                  iconSize="small"
                  icon={offer_path ? IconTypeE.note : IconTypeE.arrowRight}
                />
              </FlexBox>
            </FlexBox>
          </ActionWrapper>
        </>
      )}
    />
  );
};

export default CampaignCard;
