export enum CrossPacksConts {
  EXTERNAL_MODAL = "EXTERNAL_MODAL",
  REFETCH_OFFERS = "REFETCH_OFFERS",
  NOTIFICATIONS_LISTENER = "NOTIFICATIONS_LISTENER",
  NOTIFICATIONS_CLOSE_LISTENER = "NOTIFICATIONS_CLOSE_LISTENER",
  REPORTING_MONITORING = "REPORTING_MONITORING",
}

export const openExternalModal = (key: string): void => {
  const modalTrigger = document.getElementById(key);

  if (modalTrigger) {
    const clickEvenet = new Event("click");
    modalTrigger.dispatchEvent(clickEvenet);
  }
};
