import * as React from "react";
import { FC } from "react";
import FlexBox from "../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../components/Heading";
import Paragraph from "../../components/Paragraph";
import Popover from "../../components/Popover";
import { theme } from "../../styling/theme";
import { Column } from "./Column";

type TruncateColumnT = {
  string: string;
  label: string;
  border?: boolean;
  mobile?: boolean;
};

const TruncateColumn: FC<TruncateColumnT> = ({
  string,
  label,
  border = false,
  mobile = false,
}) => {
  return (
    <Column
      fullHeight={true}
      border={border}
      mobile={mobile}
      paddingLeft={false}
    >
      <FlexBox flexDirection="column" alignItems="stretch">
        <Heading
          headingType={HeadingTypeT.h4}
          heading={label}
          spaceBottom="mini"
        />
        {string.length > 15 ? (
          <Popover
            renderTrigger={() => (
              <Paragraph
                paragraph={_.truncate(string, { length: 15 })}
                paragraphSize="small"
                color={theme.color.textGreyColor}
              />
            )}
            content={string}
          />
        ) : (
          <Paragraph
            paragraph={string}
            paragraphSize="small"
            color={theme.color.textGreyColor}
          />
        )}
      </FlexBox>
    </Column>
  );
};

export default TruncateColumn;
