import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import Button, { ButtonSizeE } from "../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import LightBoxModal, {
  LightBoxStateT,
} from "../../../components/LightBoxModal";
import Modal from "../../../components/Modal";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { LightBoxContextProvider } from "../../../context-providers/LightBoxContextProvider";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useFetch } from "../../../hooks/useFetch";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import MediaModal from "../../database-detail/partials/MediaModal";
import { CompetitiveAnalysisT, RecordMediaT } from "../../types";
import OutputCards from "./OutputCards";
import Table from "./Table";

const Controls = styled(FlexBox)({
  marginBottom: rem(20),
});

const Tabs = styled(FlexBox)({
  gap: rem(10),
});

const Tab = styled(FlexBox)<{ active: boolean }>(({ active }) => ({
  borderBottom: `2px solid ${active ? theme.color.yellowColor : "transparent"}`,

  ["p"]: {
    fontWeight: active ? 600 : "normal",
    cursor: "pointer",
  },
}));

const SortButtonWrapper = styled(FlexBox)<{ visible: boolean }>(
  ({ visible }) => ({
    visibility: visible ? "visible" : "hidden",
    gap: rem(5),
  })
);

type SortT = "timestamp:desc" | "owner:asc";

type MainContainerT = {
  competitiveAnalysis: CompetitiveAnalysisT;
};

const MainContainer: FC<MainContainerT> = ({ competitiveAnalysis }) => {
  const { results } = competitiveAnalysis;

  const [openModal, setOpenModal] = useState<RecordMediaT[] | null>(null);
  const [handle, setHandle] = useState<string | undefined>();
  const [outputs, setOutputs] = useState<RecordMediaT[] | undefined>();
  const [sort, setSort] = useState<SortT>("timestamp:desc");
  const [activeTab, setActiveTab] = useState<"influencers" | "outputs">(
    "influencers"
  );
  const [lightBoxState, setLightBoxState] = useState<
    LightBoxStateT | undefined
  >(undefined);

  const { t } = useTranslate("brands.competitive_analyses.show");

  const { payload, call } = useFetch<{ media: RecordMediaT[] }>(
    `${getApiUrl(
      ApiRoutesE.COMPETITIVE_ANALYSIS_MEDIA,
      competitiveAnalysis.id
    )}?owner=${handle}&per_page=100`,
    undefined,
    "GET"
  );

  const { payload: allOutputs, call: allCall } = useFetch<{
    media: RecordMediaT[];
  }>(
    `${getApiUrl(
      ApiRoutesE.COMPETITIVE_ANALYSIS_MEDIA,
      competitiveAnalysis.id
    )}?sort=${sort}&per_page=1000`,
    undefined,
    "GET"
  );

  useEffect(() => {
    allCall();
  }, [sort]);

  useEffect(() => {
    if (allOutputs) {
      setOutputs(allOutputs.media);
    }
  }, [allOutputs]);

  useEffect(() => {
    if (payload) {
      setOpenModal(payload.media);
    }
  }, [payload]);

  useEffect(() => {
    if (handle) {
      call();
    }

    setHandle(undefined);
  }, [handle]);

  return (
    <LightBoxContextProvider setLightBoxState={setLightBoxState}>
      <FlexBox flexDirection="column" alignItems="flex-start">
        <Controls
          justifyContent="space-between"
          customWidth={CustomWidthE.full}
        >
          <Tabs>
            <Tab
              active={activeTab == "influencers"}
              onClick={() => setActiveTab("influencers")}
            >
              <Paragraph paragraph={t("influencers")} paragraphSize="large" />
            </Tab>
            <Tab
              active={activeTab == "outputs"}
              onClick={() => setActiveTab("outputs")}
            >
              <Paragraph paragraph={t("outputs")} paragraphSize="large" />
            </Tab>
          </Tabs>
          <SortButtonWrapper visible={activeTab === "outputs"}>
            <Paragraph paragraph={t("sort_by")} />
            <Button
              size={ButtonSizeE.small}
              onClick={() =>
                setSort((value) =>
                  value === "timestamp:desc" ? "owner:asc" : "timestamp:desc"
                )
              }
            >
              <Icon icon={IconTypeE.sort} />
              <Spacer size="mini" />
              {sort === "timestamp:desc" ? t("owner") : t("timestamp")}
            </Button>
          </SortButtonWrapper>
        </Controls>
        {activeTab === "outputs" && outputs && results && (
          <OutputCards outputs={outputs} results={results} />
        )}
        {activeTab === "influencers" && (
          <Table results={results} setHandle={setHandle} />
        )}
      </FlexBox>

      <Modal
        visible={!!openModal}
        close={() => setOpenModal(null)}
        heading={t("table.outputs")}
        side="right"
        renderChildren={() => {
          return <MediaModal medias={openModal} />;
        }}
      />

      <Modal
        visible={!!lightBoxState}
        close={() => setLightBoxState(undefined)}
        showCloseButton={false}
        renderChildren={(_, close) => (
          <>
            {!!lightBoxState && (
              <LightBoxModal lightBoxState={lightBoxState} close={close} />
            )}
          </>
        )}
      />
    </LightBoxContextProvider>
  );
};

export default MainContainer;
