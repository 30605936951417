export enum ApiRoutesE {
  SESSION = "SESSION",
  UPLOADS_NEW = "UPLOADS_NEW",
  STORIES = "STORIES",
  OFFERS = "OFFERS",
  OFFER = "OFFER",
  OFFER_MESSAGES = "OFFER_MESSAGES",
  OFFER_ACCEPT = "OFFER_ACCEPT",
  OFFER_LINKS = "OFFER_LINKS",
  OFFER_LINKS_VALIDATE = "OFFER_LINKS_VALIDATE",
  OFFER_RATINGS = "OFFER_RATINGS",
  INBOX_ITEMS = "INBOX_ITEMS",
  MASS_MESSAGE = "MASS_MESSAGE",
  MASS_DECLINE = "MASS_DECLINE",
  MASS_APPROVE = "MASS_APPROVE",
  NOTIFICATIONS = "NOTIFICATIONS",
  NOTIFICATION_READ = "NOTIFICATION_READ",
  NOTIFICATION_READ_ALL = "NOTIFICATION_READ_ALL",
  CAMPAIGN = "CAMPAIGN",
  CAMPAIGNS = "CAMPAIGNS",
  CAMPAIGN_HIDE = "CAMPAIGN_HIDE",
  CAMPAIGN_FINISH = "CAMPAIGN_FINISH",
  CAMPAIGN_ACCESSIBLE = "CAMPAIGN_ACCESSIBLE",
  COMPETITIVE_ANALYSIS_MEDIA = "COMPETITIVE_ANALYSIS_MEDIA",
  BRAND_AFFINITY = "BRAND_AFFINITY",
  IG_HANDLE = "IG_HANDLE",
  NETWORK_PROFILE = "NETWORK_PROFILE",
  REPORTING_PARTICIPANTS = "REPORTING_PARTICIPANTS",
  REPORTING_PARTICIPANTS_ITEM = "REPORTING_PARTICIPANTS_ITEM",
  REPORTING_MEDIAS = "REPORTING_MEDIAS",
  REPORTING_MEDIAS_POST = "REPORTING_MEDIAS_GET",
  REPORTING_MEDIAS_ITEM = "REPORTING_MEDIAS_ITEM",
  REPORTING_MEDIA_GROUPS = "REPORTING_MEDIA_GROUPS",
  REPORTING_MEDIA_GROUPS_POST = "REPORTING_MEDIA_GROUPS_POST",
  REPORTING_MEDIA_GROUPS_PARTICIPANT = "REPORTING_MEDIA_GROUPS_PARTICIPANT",
  REPORTING_MEDIA_GROUPS_ITEM = "REPORTING_MEDIA_GROUPS_ITEM",
  REPORTING_MEDIA_FILE = "REPORTING_MEDIA_FILE",
}

type getApiUrlT = (
  url: ApiRoutesE,
  id?: number | string,
  parentId?: number | string
) => string;
export const getApiUrl: getApiUrlT = (url, id, parentId) => {
  if (url === ApiRoutesE.SESSION) {
    return "/api/v1/session";
  }

  if (url === ApiRoutesE.UPLOADS_NEW) {
    return "/api/v1/uploads/new/";
  }

  if (url === ApiRoutesE.STORIES) {
    return "/api/v1/stories";
  }

  if (url === ApiRoutesE.OFFERS) {
    return "/api/v1/offers";
  }

  if (url === ApiRoutesE.OFFER && id) {
    return `/api/v1/offers/${id}`;
  }

  if (url === ApiRoutesE.OFFER_MESSAGES && id) {
    return `/api/v1/offers/${id}/messages`;
  }

  if (url === ApiRoutesE.OFFER_ACCEPT && id) {
    return `/api/v1/offers/${id}/accept`;
  }

  if (url === ApiRoutesE.OFFER_LINKS && id) {
    return `/api/v1/offers/${id}/links`;
  }

  if (url === ApiRoutesE.OFFER_LINKS_VALIDATE && id) {
    return `/api/v1/offers/${id}/links/validate`;
  }

  if (url === ApiRoutesE.OFFER_RATINGS && id) {
    return `/api/v1/offers/${id}/ratings`;
  }

  if (url === ApiRoutesE.INBOX_ITEMS && id) {
    return `/api/v1/campaigns/${id}/inbox_items`;
  }

  if (url === ApiRoutesE.MASS_MESSAGE && id) {
    return `/api/v1/campaigns/${id}/offers/message`;
  }

  if (url === ApiRoutesE.MASS_DECLINE && id) {
    return `/api/v1/campaigns/${id}/offers/decline`;
  }

  if (url === ApiRoutesE.MASS_APPROVE && id) {
    return `/api/v1/campaigns/${id}/offers/approve`;
  }

  if (url === ApiRoutesE.NOTIFICATIONS) {
    return `/api/v1/notifications`;
  }

  if (url === ApiRoutesE.NOTIFICATION_READ && id) {
    return `/api/v1/notifications/${id}/read`;
  }

  if (url === ApiRoutesE.NOTIFICATION_READ_ALL) {
    return `/api/v1/notifications/read_all`;
  }

  if (url === ApiRoutesE.CAMPAIGN && id) {
    return `/api/v1/campaigns/${id}`;
  }

  if (url === ApiRoutesE.CAMPAIGNS) {
    return `/api/v1/campaigns`;
  }

  if (url === ApiRoutesE.CAMPAIGN_HIDE && id) {
    return `/api/v1/campaigns/${id}/hide`;
  }

  if (url === ApiRoutesE.CAMPAIGN_FINISH && id) {
    return `/api/v1/campaigns/${id}/finish`;
  }

  if (url === ApiRoutesE.CAMPAIGN_ACCESSIBLE && id) {
    return `/api/v1/campaigns/${id}/accessible`;
  }

  if (url === ApiRoutesE.COMPETITIVE_ANALYSIS_MEDIA && id) {
    return `/api/v1/brands/competitive_analyses/${id}/media`;
  }

  if (url === ApiRoutesE.BRAND_AFFINITY && id) {
    return `/api/v1/influencers/${id}/brand_affinity`;
  }

  if (url === ApiRoutesE.IG_HANDLE) {
    return `/api/v1/ig_handles`;
  }

  if (url === ApiRoutesE.CAMPAIGN && id) {
    return `/api/v1/campaigns/${id}`;
  }

  if (url === ApiRoutesE.NETWORK_PROFILE && id) {
    return `/api/v1/crm/influencers/${id}/network_profile`;
  }

  if (url === ApiRoutesE.REPORTING_PARTICIPANTS && id) {
    return `/api/v1/reportings/${id}/participants`;
  }

  if (url === ApiRoutesE.REPORTING_PARTICIPANTS_ITEM && id && parentId) {
    return `/api/v1/reportings/${parentId}/participants/${id}`;
  }

  if (url === ApiRoutesE.REPORTING_MEDIAS && id) {
    return `/api/v1/reportings/${id}/medias`;
  }

  if (url === ApiRoutesE.REPORTING_MEDIAS_POST && id) {
    return `/api/v1/reportings/participants/${id}/medias`;
  }

  if (url === ApiRoutesE.REPORTING_MEDIAS_ITEM && id && parentId) {
    return `/api/v1/reportings/participants/${parentId}/medias/${id}`;
  }

  if (url === ApiRoutesE.REPORTING_MEDIA_GROUPS && id) {
    return `/api/v1/reportings/${id}/media_groups`;
  }

  if (url === ApiRoutesE.REPORTING_MEDIA_GROUPS_POST && id) {
    return `/api/v1/reportings/participants/${id}/media_groups`;
  }

  if (url === ApiRoutesE.REPORTING_MEDIA_GROUPS_PARTICIPANT && id) {
    return `/api/v1/reportings/participants/${id}/media_groups`;
  }

  if (url === ApiRoutesE.REPORTING_MEDIA_GROUPS_ITEM && id && parentId) {
    return `/api/v1/reportings/participants/${parentId}/media_groups/${id}`;
  }

  if (url === ApiRoutesE.REPORTING_MEDIA_FILE && id && parentId) {
    return `/api/v1/reportings/participants/${parentId}/media_files/${id}`;
  }

  return "/";
};
