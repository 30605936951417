import { RefObject, useEffect, useState } from "react";

type ArrowSideT = "left" | "right";

export type onArrowClickHandleT = (
  scrollWrapper: RefObject<HTMLDivElement>,
  side: ArrowSideT
) => void;

type onScrollHandleT = (scrollEvent: Event) => void;

type useHoriznontalScrollT = (
  scrollWrapper?: RefObject<HTMLDivElement>,
  reInit?: any,
  moveOffset?: number
) => {
  onArrowClickHandle: onArrowClickHandleT;
  onScrollHandle: onScrollHandleT;
  leftArrow: boolean;
  rightArrow: boolean;
};

export const useHoriznontalScroll: useHoriznontalScrollT = (
  scrollWrapper,
  reInit,
  moveOffset = 190
) => {
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(false);

  useEffect(() => {
    const wrapper = scrollWrapper?.current;

    if (wrapper) {
      updateArrows(wrapper as HTMLDivElement);
    }
  }, [reInit]);

  const onArrowClickHandle: onArrowClickHandleT = (scrollWrapper, side) => {
    if (scrollWrapper.current) {
      const scrollLeft = scrollWrapper.current.scrollLeft;
      scrollWrapper.current.scrollLeft =
        scrollLeft + (side === "left" ? -moveOffset : moveOffset);
    }
  };

  type updateArrowsT = (target: HTMLDivElement) => void;
  const updateArrows: updateArrowsT = (target) => {
    const { scrollWidth, scrollLeft, clientWidth } = target as HTMLDivElement;

    if (scrollLeft + clientWidth === scrollWidth && rightArrow) {
      setRightArrow(false);
    }

    if (scrollLeft + clientWidth !== scrollWidth && !rightArrow) {
      setRightArrow(true);
    }

    if (scrollLeft === 0 && leftArrow) {
      setLeftArrow(false);
    }

    if (scrollLeft !== 0 && !leftArrow) {
      setLeftArrow(true);
    }
  };

  const onScrollHandle: onScrollHandleT = (scrollEvent) => {
    const { target } = scrollEvent;

    if (target) {
      updateArrows(target as HTMLDivElement);
    }
  };

  return {
    onArrowClickHandle,
    onScrollHandle,
    leftArrow,
    rightArrow,
  };
};
