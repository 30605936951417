import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Icon from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import ColumnsBar from "../../../features/columns-bar/ColumnsBar";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../features/Placeholders/PlaceholderLoader";
import { getDateTime } from "../../../helpers/date";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { Column } from "../../../ui/columns-bar/Column";
import { CampaignStateE, CampaignT } from "../../chat/types";
import CampaignStatus from "../components/CampaignStatus";
import ActionButtons from "./ActionButtons";

const ImageWrapper = styled(FlexBox)({
  height: rem(60),
  width: rem(60),
  borderRadius: rem(4),
  overflow: "hidden",
});

const Image = styled.img({
  minHeight: "100%",
  minWidth: "100%",
  objectFit: "cover",
});

type CampaignHeaderT = {
  campaign: CampaignT | null;
};

const CampaignHeader: FC<CampaignHeaderT> = ({ campaign }) => {
  const { t } = useTranslate("brands.campaigns.detail");

  const onBackButtonClick = (): void => {
    window.location = "/brands/campaigns" as any as Location;
  };

  type getContentT = (campaign: CampaignT) => JSX.Element;
  const getContent: getContentT = (campaign) => {
    const { image_url, name, launch_date_from, state, budget, budget_spent } =
      campaign;

    return (
      <>
        <Column
          onClick={onBackButtonClick}
          cursor="pointer"
          border={false}
          fullHeight={true}
        >
          <Icon icon={IconTypeE.arrowLeft} size="small" />
        </Column>
        <Column fullHeight={true} paddingLeft={false}>
          <ImageWrapper>
            <Image src={image_url} />
          </ImageWrapper>
          <Spacer />
          <FlexBox flexDirection="column" alignItems="stretch">
            <Heading
              headingType={HeadingTypeT.h4}
              heading={name}
              spaceBottom="mini"
            />
            <Paragraph
              paragraph={`${t("launch_date")}: ${getDateTime(
                launch_date_from,
                "shortDate"
              )}`}
              paragraphSize="small"
              color={theme.color.textGreyColor}
            />
          </FlexBox>
        </Column>
        <Column fullHeight={true}>
          <FlexBox flexDirection="column" alignItems="stretch">
            <Heading
              headingType={HeadingTypeT.h4}
              heading={`${t("budget")}: ${budget}`}
              spaceBottom="mini"
            />
            <Paragraph
              paragraph={`${t("budget_spent")}: ${budget_spent}`}
              paragraphSize="small"
              color={theme.color.textGreyColor}
            />
          </FlexBox>
        </Column>
        <Column fullHeight={true} border={false}>
          <FlexBox flexDirection="column" alignItems="stretch">
            <CampaignStatus status={state} />
            <Spacer direction="vertical" size="mini" />
            <Paragraph
              paragraph={t("camaign_status")}
              paragraphSize="small"
              color={theme.color.textGreyColor}
            />
          </FlexBox>
        </Column>
      </>
    );
  };

  return (
    <ColumnsBar
      renderActions={
        campaign
          ? (isAbsolutePosition) => (
              <ActionButtons
                campaignId={campaign.id}
                isAbsolutePosition={isAbsolutePosition}
                hideFinish={
                  campaign.state === CampaignStateE.finished ||
                  campaign.state === CampaignStateE.expired
                }
              />
            )
          : undefined
      }
    >
      {!campaign ? (
        <PlaceholderLoader
          type={PlaceholdersTypeE.camapign_detail_header}
          count={1}
          direction="column"
        />
      ) : (
        getContent(campaign)
      )}
    </ColumnsBar>
  );
};

export default CampaignHeader;
