import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../features/Placeholders/PlaceholderLoader";
import { rem, theme } from "../../../styling/theme";
import GraphCard from "../components/GraphCard";
import { getCardsMap } from "../providersCards/cardsMap";
import { CardsTypeT, CardTypeNameT, NetworkProviderT } from "../types";

const CardsWrapper = styled(FlexBox)({
  margin: `0 ${rem(-8)} ${rem(16)}`,

  [theme.media.maxSm]: {
    margin: `0 0 ${rem(16)}`,
    width: "100%",
  },
});

type CardsT = {
  provider: NetworkProviderT;
  cardsData: Record<CardTypeNameT, CardsTypeT> | null;
};

const Cards: FC<CardsT> = ({ provider, cardsData }) => {
  const cards = provider && getCardsMap(provider);

  return (
    <FlexBox
      customWidth={CustomWidthE.full}
      flexDirection="column"
      alignItems="stretch"
    >
      <CardsWrapper
        flexWrap="wrap"
        alignItems="stretch"
        justifyContent="flex-start"
      >
        {!!cards && !!cardsData ? (
          cards.map((item) => {
            const dataSource = cardsData[item.type];

            if (!dataSource) {
              return null;
            }

            return (
              <GraphCard
                key={item.type}
                cardChartData={item}
                card={dataSource}
                provider={provider}
              />
            );
          })
        ) : (
          <PlaceholderLoader
            type={PlaceholdersTypeE.chart_card}
            count={9}
            direction="row"
            justifyContent="flex-start"
          />
        )}
      </CardsWrapper>
    </FlexBox>
  );
};

export default Cards;
