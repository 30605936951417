import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../components/Button";
import CircleButton from "../../../../components/CircleButton";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Icon from "../../../../components/Icon";
import Input from "../../../../components/Input";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import VisibleWrapper from "../../../../components/VisibleWrapper";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { useFetch } from "../../../../hooks/useFetch";
import { rem, theme } from "../../../../styling/theme";
import { LinkCheckT, LinkValidationPayloadT } from "../../types";
import { LinkItemT } from "./Links";

type VisibleStyleT = {
  visible: boolean;
};

const Wrapper = styled(FlexBox)({
  width: "100%",
});

const ButtonWrapper = styled(FlexBox)({
  width: rem(100),
});

const AlreadyExist = styled.div<VisibleStyleT>(({ visible }) => ({
  marginTop: rem(4),
  marginLeft: rem(4),
  height: visible ? rem(30) : 0,
  overflow: "hidden",
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,
}));

const RemoveWrapper = styled(FlexBox)({
  position: "absolute",
  left: rem(0),
  width: rem(40),
  transform: "translateX(-100%)",
});

const StyledInput = styled(Input)({
  position: "absolute",
  width: "100%",
  height: rem(45),
  backgroundColor: theme.color.backgroundColor,
  borderRadius: rem(4),
  border: `1px solid ${theme.color.backgroundColor}`,

  ["&:hover"]: {
    borderColor: theme.color.greyColor,
  },

  ["&:active"]: {
    borderColor: theme.color.yellowColor,
  },

  ["&:focus"]: {
    borderColor: theme.color.yellowColor,
  },
});

type getButtonTypeT = (state: LinkStateT) => ButtonTypeE;
const getButtonType: getButtonTypeT = (state) => {
  switch (state) {
    case "success":
      return ButtonTypeE.dummy;
    case "error":
      return ButtonTypeE.error;
    case "disable":
      return ButtonTypeE.disabled;
    case "async_waiting":
      return ButtonTypeE.disabled;
    default:
      return ButtonTypeE.outline;
  }
};

type LinkT = {
  offerId: number;
  id: string;
  links: LinkItemT[];
  checkLink?: LinkCheckT;
  setLinks: Dispatch<SetStateAction<LinkItemT[]>>;
  hover?: boolean;
};

type LinkStateT = "success" | "error" | "disable" | "init" | "async_waiting";

const Link: FC<LinkT> = ({
  offerId,
  setLinks,
  id,
  hover,
  links,
  checkLink,
}) => {
  const [url, setUrl] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [linkState, setLinkState] = useState<LinkStateT>("init");
  const [timeOutCheck, setTimeOutCheck] = useState<boolean>(false);

  const body = {
    link: {
      url: url,
      view_object_id: id,
    },
  };

  const { call, payload, pending, error } = useFetch<LinkValidationPayloadT>(
    getApiUrl(ApiRoutesE.OFFER_LINKS_VALIDATE, offerId),
    undefined,
    "POST",
    body
  );

  type removeLinkT = (id: string) => void;
  const removeLink: removeLinkT = (id) => {
    setLinks((prevState) => {
      if (prevState.length === 1) {
        prevState[0].link = "";
        setUrl(() => "");

        return [...prevState];
      }

      const index = prevState.findIndex((link) => link.id === id);

      if (index > -1) {
        prevState.splice(index, 1);
      }

      return [...prevState];
    });
  };

  type updateLinkT = (id: string) => void;
  const updateLink: updateLinkT = (id) => {
    setLinks((prevState) => {
      const index = prevState.findIndex((link) => link.id === id);

      if (index > -1) {
        prevState[index].link = url;
      }

      return [...prevState];
    });
  };

  const addLinkToReport = (): void => {
    updateLink(id);
    setErrorMessage(() => null);
    setLinkState(() => "success");
  };

  const verifyLink = (): void => {
    call();
  };

  useEffect(() => {
    if (payload) {
      if (typeof payload.success == "string" && payload.success === "async") {
        setLinkState(() => "async_waiting");
        return;
      }

      if (payload.success) {
        addLinkToReport();

        return;
      }

      if (!payload.success && payload.errors && payload.errors.length > 0) {
        setErrorMessage(() => null);

        payload.errors.forEach((error) => {
          setErrorMessage((prevState) =>
            prevState ? prevState + ` ${error}` : error
          );
        });
      }

      setLinkState(() => "error");
    }
  }, [payload, error]);

  useEffect(() => {
    if (pending) {
      setLinkState(() => "disable");
    }
  }, [pending]);

  useEffect(() => {
    if (url.length < 1) {
      setLinkState(() => "init");
    }

    if (errorMessage) {
      setErrorMessage(() => null);
    }
  }, [url]);

  useEffect(() => {
    // Set default false for times up usecase and clear state for new validation
    if (timeOutCheck && linkState === "async_waiting") {
      setErrorMessage(() => "Odkaz se nepodařilo ověřit.");
      setLinkState(() => "error");
      setTimeOutCheck(() => false);
    }
  }, [timeOutCheck]);

  useEffect(() => {
    // Time remaining for Apify call - 90 seconds
    if (linkState === "async_waiting") {
      const timeout = setTimeout(() => {
        setTimeOutCheck(() => true);
      }, 90000);

      return () => clearTimeout(timeout);
    }

    return;
  }, [linkState]);

  useEffect(() => {
    // Set state depended on Apify response
    if (linkState === "async_waiting") {
      if (checkLink && checkLink.json.valid_class === "has-ok") {
        addLinkToReport();

        return;
      }

      setErrorMessage(() => "Odkaz se nenašel pod vašim účtem.");
      setLinkState(() => "error");
    }
  }, [checkLink]);

  const alreadyExist =
    links.filter((link) => link.link === url && link.id !== id).length > 0;

  const inputDisabled =
    linkState === "async_waiting" ||
    linkState === "disable" ||
    linkState === "success";
  const buttonDisabled = inputDisabled || url.length < 1 || alreadyExist;

  return (
    <Wrapper flexDirection="column" alignItems="stretch">
      <RemoveWrapper>
        <VisibleWrapper visible={!!hover}>
          <CircleButton
            onClickHandle={() => removeLink(id)}
            background={theme.color.errorColor}
            hoverBackground={theme.color.errorHoverColor}
            icon={IconTypeE.minus}
            iconSize="mini"
            iconColor={theme.color.whiteColor}
          />
        </VisibleWrapper>
      </RemoveWrapper>
      <Wrapper>
        <StyledInput
          name="message"
          value={url}
          setValue={setUrl}
          clearButton={true}
          placeholder="Vlože URL k ověření"
          disabled={inputDisabled}
        />
        <Spacer size="mini" />
        <ButtonWrapper>
          <Button
            size={ButtonSizeE.small}
            type={getButtonType(linkState)}
            loading={linkState === "async_waiting" || linkState === "disable"}
            disabled={buttonDisabled}
            onClick={verifyLink}
            customWidth={CustomWidthE.full}
            withoutDisableStyle={linkState === "success"}
          >
            {linkState === "success" ? (
              <Icon
                icon={IconTypeE.check}
                color={theme.color.whiteColor}
                size="default"
              />
            ) : (
              "Ověřit"
            )}
          </Button>
        </ButtonWrapper>
      </Wrapper>
      <AlreadyExist visible={alreadyExist || !!errorMessage}>
        <Paragraph
          paragraphSize="small"
          color={theme.color.errorColor}
          fontWeight={600}
          paragraph={
            !!errorMessage && !alreadyExist ? errorMessage : "Link už existuje"
          }
        />
      </AlreadyExist>
    </Wrapper>
  );
};

export default Link;
