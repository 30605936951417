import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { rem, theme } from "../../../styling/theme";

const Section = styled(FlexBox)<{ withBorder?: boolean }>(
  ({ withBorder = true }) => ({
    padding: rem(16),
    borderBottom: withBorder ? `1px solid ${theme.color.greyColor}` : "none",
  })
);

const Header = styled(FlexBox)({
  marginBottom: rem(16),
});

type FilterSectionT = {
  label: string;
  labelDescription?: string;
  withBorder?: boolean;
};

const FilterSection: FC<FilterSectionT> = ({
  label,
  labelDescription,
  withBorder,
  children,
}) => {
  return (
    <Section
      withBorder={withBorder}
      alignItems="flex-start"
      customWidth={CustomWidthE.full}
      flexDirection="column"
    >
      <Header>
        <Paragraph fontWeight={600} paragraphSize="small" paragraph={label} />
        {labelDescription && (
          <>
            <Spacer size="micro" />
            <Paragraph
              fontWeight={600}
              paragraphSize="small"
              color={theme.color.textLightGreyColor}
              paragraph={labelDescription}
            />
          </>
        )}
      </Header>
      {children}
    </Section>
  );
};

export default FilterSection;
