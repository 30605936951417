import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

export type UpdateParticipantPropsT = {
  id: number;
  reportId: number;
  body: {
    reporting_participant: {
      auto: boolean;
    };
  };
};

type updateParticipantT = (props: UpdateParticipantPropsT) => Promise<any>;
export const updateParticipant: updateParticipantT = async ({
  id,
  reportId,
  body,
}) =>
  axios
    .put(getApiUrl(ApiRoutesE.REPORTING_PARTICIPANTS_ITEM, id, reportId), body)
    .then((res) => res.data);
