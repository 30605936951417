import { _o } from "../helpers";

require("bootstrap-tagsinput/dist/bootstrap-tagsinput");

export const addButtonToTagsInputs = (): void => {
  const tags = _o(document.getElementsByClassName("tags-with-button"));

  const insertAfter = (referenceNode: Element, newNode: Element): void => {
    const { parentNode } = referenceNode;

    if (!!parentNode) {
      parentNode.insertBefore(newNode, referenceNode.nextSibling);
    }
  };

  if (tags && tags.length > 0) {
    tags.map((item) => {
      const button = document.createElement("div");
      button.innerHTML = "Přidat";
      button.classList.add("button", "button--small", "tags-added-button");

      insertAfter(item, button);
    });
  }
};

export const autoSwitcherOptions = (): void => {
  const autoreportingModal = document.getElementById(
    "reportings-modal"
  ) as Node;
  const participantModal = document.getElementById(
    "participants-modal"
  ) as Node;
  const options = {
    attributes: true,
  };

  const callback = (mutationList: any): void => {
    (mutationList as any[]).forEach((mutation) => {
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "class"
      ) {
        const switchInput = document.getElementById(
          "autoreporting-switch"
        ) as HTMLInputElement;
        const switchWrapper = document.getElementById(
          "reporting-switcher-wrapper"
        );
        const optionsWrapper = document.getElementById(
          "reporting-options-wrapper"
        );

        type handleOptionsVisibilityT = (item: HTMLInputElement) => void;
        const handleOptionsVisibility: handleOptionsVisibilityT = (item) => {
          if (item && item.checked && optionsWrapper) {
            optionsWrapper.classList.remove("hidden");

            $("#reporting-options-wrapper :input").each(function () {
              ($(this) as any).tagsinput("destroy");
              ($(this) as any).tagsinput("refresh");
            });
            return;
          }

          optionsWrapper?.classList.add("hidden");
        };

        if (switchWrapper && switchInput) {
          handleOptionsVisibility(switchInput);

          switchWrapper.classList.remove("hidden");
          switchInput.addEventListener("change", (item) => {
            const input = item.target as HTMLInputElement;
            handleOptionsVisibility(input);
          });
        }
      }
    });
    addButtonToTagsInputs();
  };

  if (autoreportingModal) {
    const observer = new MutationObserver(callback);
    observer.observe(autoreportingModal, options);
  }

  if (participantModal) {
    const observer = new MutationObserver(callback);
    observer.observe(participantModal, options);
  }
};
