import styled from "@emotion/styled";
import FlexBox from "../../components/FlexBox";
import { theme } from "../../styling/theme";

const ReferencialWrapper = styled(FlexBox)({
  position: "absolute",
  top: 0,
  left: 0,
  width: "20%",

  [theme.media.maxXl]: {
    width: "25%",
  },

  [theme.media.maxLg]: {
    width: "33.33%",
  },

  [theme.media.maxMd]: {
    width: "50%",
  },

  ["@media screen and (max-width: 600px)"]: {
    width: "100%",
  },
});

export default ReferencialWrapper;
