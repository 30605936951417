import { _o } from "../helpers";

export const productTypeOnChange = () => {
  const productTypeWrapper = document.getElementById("product-type");

  const moveAddonsToWrapper = (wrapper) => {
    const productAddons = document.getElementById('product-type-addons');
    const productAddonsHTML = productAddons.outerHTML;
    productAddons.remove();

    wrapper.innerHTML = wrapper.innerHTML + productAddonsHTML
  }

  const setOrderOfOptions = (wrapper) => {
    const optionWrappers = wrapper.getElementsByClassName('radio-wrapper-inline');
    _o(optionWrappers).forEach((option, index) => {
      const order = (index + 1) * 2;
      option.style.order = order; 
      option.dataset.order = order;
    })
  }

  const setOrderOfAddons = (input) => {
    const productAddons = document.getElementById('product-type-addons');
    const order = parseInt(input.parentNode.dataset.order);

    if (order && productAddons) {
      productAddons.style.order = order + 1;
    }
  }
  
  const setClassesToAddons = (input) => {
    setOrderOfAddons(input);

    const value = input.value;
    const productNeededID = "campaign-product-needed";
    const productPriceID = "campaign-product-price";
    const needed = document.getElementById(productNeededID);
    const price = document.getElementById(productPriceID);

    if (needed && price) {
      needed.classList.remove("hidden");
      price.classList.remove("hidden");


      if (value === "no_product" || value === "get_own") {
        needed.classList.add("hidden");
        price.classList.add("hidden");
      }
  
      if (value === "send_back") {
        price.classList.add("hidden");
      }

      return
    }

    console.warn(`Inputs /${productNeededID}/ or /${productPriceID}/ (or both) was not found!`);
  }

  const optionsVisibilityHandle = (input) => {
    // init call without specific input (not called by event listener)
    if (input.length) {
      const checkedInput = input.filter((element) => element.checked)[0];
      setClassesToAddons(checkedInput);
      return
    }

    setClassesToAddons(input);
  };

  if (productTypeWrapper) {
    moveAddonsToWrapper(productTypeWrapper);
    setOrderOfOptions(productTypeWrapper);

    const inputs = productTypeWrapper.getElementsByTagName("INPUT");
    const inputsArray = _o(inputs).filter(input => input.type === "radio");

    if (inputsArray) {
      inputsArray.forEach((input) => {
        input.addEventListener("click", () => optionsVisibilityHandle(input));
      });

      optionsVisibilityHandle(inputsArray);
      return
    }

    console.warn(`Inputs for /product-type/ was not found!`);
  }
};
