import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import CircleButton from "../../../../components/CircleButton";
import Modal from "../../../../components/Modal";
import Tooltip from "../../../../components/Tooltip";
import { rem, theme } from "../../../../styling/theme";
import { OfferStateE, RatingsT } from "../../types";
import SetRatingModal from "./SetRatingModal";
import ShowRatingModal from "./ShowRatingModal";

type WrapperStyleT = {
  visible: boolean;
};

const Wrapper = styled.div<WrapperStyleT>(({ visible }) => ({
  position: "relative",
  marginRight: visible ? rem(8) : 0,
  maxWidth: visible ? rem(100) : 0,
  overflow: visible ? "visible" : "hidden",
  transition: "max-width 0.4s",
  transitionTimingFunction: theme.timing.default,
  [theme.media.maxSm]: {
    marginRight: visible ? rem(2) : 0,
  },
}));

type RatingT = {
  offerId: number;
  offerState: OfferStateE | null;
  offerRating?: RatingsT[];
  influencerRole: boolean;
};

export type RatingStateT = {
  stars: number;
  feedback: string;
};

const Rating: FC<RatingT> = React.memo(
  ({ offerId, offerState, offerRating, influencerRole }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [localTmpRating, setLocalTmpRating] = useState<RatingStateT | null>(
      null
    );

    const RATING_LOCAL_STORAGE_KEY = "RATING_TOOLTIP";
    const reportTooltipStorage = localStorage.getItem(RATING_LOCAL_STORAGE_KEY);

    const allowRating =
      offerState && offerState === OfferStateE.accepted && !influencerRole

    type handleOpenReportT = () => void;
    const handleOpenReport: handleOpenReportT = () => {
      if (!reportTooltipStorage) {
        localStorage.setItem(RATING_LOCAL_STORAGE_KEY, "true");
      }
      setModalOpen(true);
    };

    return (
      <Wrapper visible={!!allowRating || !!localTmpRating}>
        <CircleButton
          onClickHandle={handleOpenReport}
          background={theme.color.yellowColor}
          iconColor={theme.color.textColor}
          hoverBackground={theme.color.yellowHoverColor}
          disabled={!allowRating}
          icon={IconTypeE.starRating}
          tooltip={
            <Tooltip
              visible={!reportTooltipStorage}
              bouncing={true}
              position="top"
              tooltip={"Hodnocení spolupráci s influencerem"}
            />
          }
        />
        <Modal
          visible={modalOpen}
          close={() => setModalOpen(false)}
          heading={"Hodnocení spolupráci s influencerem"}
          renderChildren={(visible, close) => {
            if (offerRating && offerRating.length > 0) {
              const { feedback, stars } =
                offerRating && offerRating[offerRating?.length - 1];
              const rating = { stars: stars, feedback: feedback };

              return <ShowRatingModal closeModal={close} rating={rating} />;
            }

            if (localTmpRating) {
              return (
                <ShowRatingModal closeModal={close} rating={localTmpRating} />
              );
            }

            return (
              <SetRatingModal
                offerId={offerId}
                visible={visible}
                closeModal={close}
                setLocalTmpRating={setLocalTmpRating}
              />
            );
          }}
        />
      </Wrapper>
    );
  }
);

export default Rating;
