import { _o } from "../helpers";

const moveHelpElement = (array) => {
  const helpElement = document.getElementById('campaign-tracking-individual');
  const input = _o(array).filter(item => item.value === "individual");

  if (input.length > 0 && helpElement) {
    const children = input[0].parentNode.children;
    const label = _o(children).filter(child => child.nodeName == "LABEL")[0];
    label.outerHTML = label.outerHTML + helpElement.outerHTML
    helpElement.remove();
  }
}

export const trackingTypeOnChange = () => {
  const trackingTypeWrapper = document.getElementById("tracking-type");

  const optionsVisibility = (inputsArray, link) => {
    const input = inputsArray.filter((input) => input.checked)[0];

    const checked = input !== undefined ? input.value : undefined;
    if (checked !== "one") {
      link.classList.add("hidden");
    }
  };

  if (trackingTypeWrapper) {
    const inputs = trackingTypeWrapper.getElementsByTagName("INPUT");
    const inputsArray = _o(inputs);
    
    
    const trackingLinkID = "campaign-tracking-link";

    const linkInput = document.getElementById(trackingLinkID);

    if (linkInput) {
      inputsArray.forEach((input) => {
        input.addEventListener("click", () => {
          linkInput.classList.remove("hidden");

          optionsVisibility(inputsArray, linkInput);
        });
      });

      optionsVisibility(inputsArray, linkInput);
      moveHelpElement(inputsArray);

      return
    }

    console.warn(`Input /${trackingLinkID}/ was not found!`);
  }
};
