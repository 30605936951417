import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import AvatarTile from "../../../components/AvatarTile";
import FlexBox from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Icon from "../../../components/Icon";
import OfferLabel from "../../../components/OfferLabel";
import Paragraph from "../../../components/Paragraph";
import ColumnsBar from "../../../features/columns-bar/ColumnsBar";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { Column } from "../../../ui/columns-bar/Column";
import { OfferStateE } from "../../chat/types";
import { ParticipantsHeadingDataT } from "../../types";
import HeaderActions from "./HeaderActions";

type MediaCountsT = {
  posts: number;
  story_sets: number;
  igtvs: number;
  reels: number;
};

export type HeaderDataT = {
  id: number;
  handle: string;
  back_url: string;
  avatar_url: string;
  auto: boolean | null;
  token: string;
  reporting: ParticipantsHeadingDataT;
  ig_profile: boolean;
  medias: MediaCountsT;
};

type HeaderT = {
  headerData: HeaderDataT;
  autoState: boolean;
};

const Header: FC<HeaderT> = ({ headerData, autoState }) => {
  const { back_url, avatar_url, handle, medias, reporting } = headerData;

  const { t } = useTranslate("brands.reportings");

  const onBackButtonClick = (): void => {
    window.location = back_url as any as Location;
  };

  return (
    <ColumnsBar
      renderActions={(isAbsolutePosition) => (
        <HeaderActions isAbsolutePosition={isAbsolutePosition} />
      )}
      renderContent={(mobilePosition) => (
        <>
          <Column
            onClick={onBackButtonClick}
            cursor="pointer"
            border={false}
            mobile={mobilePosition}
            fullHeight={true}
          >
            <Icon icon={IconTypeE.arrowLeft} size="small" />
          </Column>
          <Column fullHeight={true} mobile={mobilePosition} paddingLeft={false}>
            <AvatarTile imageUrl={avatar_url} name={`@${handle}`} />
          </Column>
          <Column fullHeight={true} mobile={mobilePosition} maxWidth={rem(180)}>
            <FlexBox flexDirection="column" alignItems="stretch">
              <Heading
                headingType={HeadingTypeT.h4}
                heading={reporting.name}
                spaceBottom={0}
              />
            </FlexBox>
          </Column>
          {Object.keys(medias).map((item) => {
            return (
              <Column key={item} mobile={mobilePosition} fullHeight={true}>
                <FlexBox flexDirection="column" alignItems="stretch">
                  <Heading
                    headingType={HeadingTypeT.h4}
                    heading={t(`labels.${item}`)}
                    spaceBottom="mini"
                  />
                  <Paragraph
                    paragraph={medias[item]}
                    paragraphSize="small"
                    color={theme.color.textGreyColor}
                  />
                </FlexBox>
              </Column>
            );
          })}
          {reporting.auto && (
            <Column mobile={mobilePosition} fullHeight={true}>
              <FlexBox flexDirection="column" alignItems="stretch">
                <Heading
                  headingType={HeadingTypeT.h4}
                  heading={t("labels.autoreporting")}
                  spaceBottom="mini"
                />
                <OfferLabel
                  state={
                    autoState ? OfferStateE.accepted : OfferStateE.declined
                  }
                />
              </FlexBox>
            </Column>
          )}
        </>
      )}
    ></ColumnsBar>
  );
};

export default Header;
