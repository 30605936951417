import { Scope, TranslateOptions } from "i18n-js";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { SortingOptionsT, SortingOrderT } from "../hooks/useLocalSort";
import BlueLink from "./BlueLink";

type SortLinkT = {
  attribute: string;
  sortingOptions: SortingOptionsT;
  setSortingOptions: Dispatch<SetStateAction<SortingOptionsT>>;
  t: (scope: Scope, options?: TranslateOptions | undefined) => string;
};

const SortLink: FC<SortLinkT> = ({
  attribute,
  sortingOptions,
  setSortingOptions,
  t,
}) => {
  const orderOption = (property: string): SortingOrderT => {
    if (
      sortingOptions.attribute === property &&
      sortingOptions.order !== "asc"
    ) {
      return "asc";
    }
    return "desc";
  };

  return (
    <BlueLink
      text={t(attribute)}
      icon={IconTypeE.arrowBottom}
      rotate={sortingOptions.order === "asc" ? "180deg" : "0deg"}
      iconPosition={"after"}
      iconSize="mini"
      iconColor={
        sortingOptions.attribute !== attribute ? "transparent" : undefined
      }
      paragraphSize="small"
      hideBorder={true}
      marginTop="0"
      onClick={() =>
        setSortingOptions({
          order: orderOption(attribute),
          attribute: attribute,
        })
      }
    />
  );
};

export default SortLink;
