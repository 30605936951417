import styled from "@emotion/styled";
import * as React from "react";
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { AntiShake } from "../../../components/GeneralStyles";
import { rem, theme } from "../../../styling/theme";

export const MESSAGE_INPUT_HEIGHT = 25;
export const MESSAGE_INPUT_MARGIN = 7.5;
const MAX_MESSAGE_INPUT = 100;

type TextAreaStyleT = {
  height: number;
};

const StyledText = styled.textarea<TextAreaStyleT>(({ height }) => ({
  width: "100%",
  height: rem(height),
  fontSize: rem(15),
  margin: 0,
  padding: 0,
  paddingTop: rem(2),
  backgroundColor: "transparent",
  border: "none",
  outline: "none",
  resize: "none",
  transition: "height 0.4s",
  transitionTimingFunction: theme.timing.default,
  ...AntiShake,
}));

type MessageInputT = {
  newMessage: string;
  setNewMessage: Dispatch<SetStateAction<string>>;
  height: number;
  setHeight: Dispatch<SetStateAction<number>>;
  className?: string;
};

const MessageInput: FC<MessageInputT> = React.memo(
  ({ newMessage, setNewMessage, height, setHeight, className }) => {
    const [initHeight] = useState(height);
    const messageInput = useRef<HTMLTextAreaElement>(null);

    type handleHeightT = (textArea: HTMLTextAreaElement) => void;
    const handleHeight: handleHeightT = (textArea) => {
      const { clientHeight, scrollHeight, value } = textArea;

      if (value.length < 1) {
        setHeight(() => initHeight);
        return;
      }

      if (clientHeight < scrollHeight) {
        if (scrollHeight > MAX_MESSAGE_INPUT) {
          setHeight(() => MAX_MESSAGE_INPUT);
          return;
        }

        setHeight(() => scrollHeight);
      }
    };

    type handleOnChangeT = (e: ChangeEvent<HTMLTextAreaElement>) => void;
    const handleOnChange: handleOnChangeT = (e) => {
      const eventTarget = e.target as HTMLTextAreaElement;

      handleHeight(eventTarget);
      setNewMessage(eventTarget.value);
    };

    useEffect(() => {
      if (newMessage.length === 0 && messageInput !== null) {
        const { current } = messageInput;

        if (current !== null) {
          handleHeight(current);
        }
      }
    }, [newMessage]);

    return (
      <StyledText
        height={height}
        name="message"
        value={newMessage}
        onChange={(e) => handleOnChange(e)}
        ref={messageInput}
        className={className}
      />
    );
  }
);

export default MessageInput;
