import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { CrossPacksConts } from "../../../helpers/crossPacks";
import { getCampaignAccessible } from "../data-access/getCampaignAccessible";

const OpenDetailOnLoad: FC = () => {
  const [idFromUrl, setIdFromUrl] = useState<string>("");

  const { data: accessibleResponse, refetch: accessibleCall } = useQuery(
    "approval",
    () => getCampaignAccessible(idFromUrl),
    { enabled: false }
  );

  useEffect(() => {
    const regExp = /[^=]*$/;
    const urlSearch = window.location.search;
    const exec = regExp.exec(urlSearch);

    if (urlSearch.length > 0 && !!exec) {
      if (exec[0]) {
        setIdFromUrl(exec[0]);
      }
    }
  }, []);

  useEffect(() => {
    if (idFromUrl.length > 0) {
      accessibleCall();
    }
  }, [idFromUrl]);

  useEffect(() => {
    if (accessibleResponse?.accessible && idFromUrl.length > 0) {
      const DETAIL_PATH_BASE = `/influencers/campaigns/`;
      const modal = document.getElementById(
        "external-modal-trigger-preview-modal"
      );

      if (modal) {
        modal.dataset.url = DETAIL_PATH_BASE + idFromUrl;
        const changeEvent = new Event(CrossPacksConts.EXTERNAL_MODAL);

        document.dispatchEvent(changeEvent);
        setIdFromUrl("");
      }
    }
  }, [accessibleResponse]);

  return null;
};

export default OpenDetailOnLoad;
