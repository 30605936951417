import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../styling/theme";

const Wrapper = styled.div<{ size: number; borderSize: number; color: string }>`
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: ${({ size }) => rem(size)};
    height: ${({ size }) => rem(size)};
  }
  .loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ size, borderSize }) => rem(size - borderSize)};
    height: ${({ size, borderSize }) => rem(size - borderSize)};
    border: ${({ color, borderSize }) => `${borderSize}px solid ${color}`};
    border-radius: 50%;
    animation: loader-animation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ color }) =>
      `${color} transparent transparent transparent`};
  }
  .loader div:nth-of-type(1) {
    animation-delay: -0.45s;
  }
  .loader div:nth-of-type(2) {
    animation-delay: -0.3s;
  }
  .loader div:nth-of-type(3) {
    animation-delay: -0.15s;
  }
  @keyframes loader-animation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

type SpinLoaderT = {
  size?: number;
  borderSize?: number;
  color?: string;
};

const SpinLoader: FC<SpinLoaderT> = ({
  size = 18,
  borderSize = 2,
  color = theme.color.textColor,
}) => (
  <Wrapper size={size} borderSize={borderSize} color={color}>
    <div className="loader">
      <div />
      <div />
      <div />
      <div />
    </div>
  </Wrapper>
);

export default SpinLoader;
