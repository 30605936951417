import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useMutation } from "react-query";
import { IconTypeE } from "../../../assets/icons/Icons";
import {
  getIllustration,
  IllustrationTypeE,
} from "../../../assets/illustrations/Illustrations";
import BlueLink from "../../../components/BlueLink";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import CheckBox from "../../../components/CheckBox";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Icon from "../../../components/Icon";
import { IllustrationWrapper } from "../../../components/NoResults";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { getDateTime } from "../../../helpers/date";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { updateParticipant } from "../data-access/updateParticipant";
import { HeaderDataT } from "./Header";

const WarningWrapper = styled(FlexBox)({
  padding: rem(16),
  borderRadius: rem(8),
  border: `1px solid ${theme.color.greyColor}`,
});

const Side = styled(FlexBox)({
  width: "50%",
});

const ModalContent = styled(Side)({
  padding: rem(32),
});

const ModalImage = styled(Side)({
  backgroundColor: theme.color.yellowLightHoverColor,
  borderTopRightRadius: rem(8),
  borderBottomRightRadius: rem(8),
  overflow: "hidden",
});

type AutoModalT = {
  close: () => void;
  setAutoState: Dispatch<SetStateAction<boolean | null>>;
  participantData: HeaderDataT;
  userExist: boolean;
};

const AutoModal: FC<AutoModalT> = ({
  setAutoState,
  close,
  participantData,
  userExist,
}) => {
  const [tac, setTac] = useState(false);

  const { t } = useTranslate(
    "brands.reportings.media_groups.autoreporting.modal"
  );
  const { reporting, handle, id, ig_profile } = participantData;
  const { hashtags, mentions, duration_from, duration_to, name } = reporting;

  const body = {
    reporting_participant: {
      auto: true,
    },
  };

  const { mutate, isLoading } = useMutation(updateParticipant, {
    onSuccess: () => {
      setAutoState(true);
      close();
    },
  });

  const mutateHandle = (): void => {
    mutate({ id, reportId: reporting.id, body });
  };

  const getModalActionContent = (): JSX.Element => {
    if (!userExist) {
      return (
        <WarningWrapper customWidth={CustomWidthE.full} flexDirection="column">
          <Paragraph
            paragraph={t("register")}
            paragraphSize="small"
            align="center"
            fontWeight={600}
          />
          <Spacer direction="vertical" />
          <FlexBox>
            <Button
              href="/signup/email"
              target="_blank"
              size={ButtonSizeE.micro}
            >
              {t("register_button")}
            </Button>
            <Spacer size="mini" />
            <Button
              onClick={() => window.location.reload()}
              type={ButtonTypeE.grey}
              size={ButtonSizeE.micro}
              tooltip="Reload"
            >
              <Icon icon={IconTypeE.loadArrow} size="small" />
            </Button>
          </FlexBox>
        </WarningWrapper>
      );
    }

    if (!ig_profile) {
      return (
        <WarningWrapper customWidth={CustomWidthE.full} flexDirection="column">
          <Paragraph
            paragraph={`${t("bussiness")} - @${handle}`}
            paragraphSize="small"
            align="center"
            fontWeight={600}
          />
          <Spacer direction="vertical" />
          <FlexBox>
            <Button
              href="/profile/networks"
              target="_blank"
              size={ButtonSizeE.small}
            >
              {t("bussiness_button")}
            </Button>
            <Spacer size="mini" />
            <Button
              onClick={() => window.location.reload()}
              type={ButtonTypeE.grey}
              size={ButtonSizeE.micro}
              tooltip="Reload"
            >
              <Icon icon={IconTypeE.loadArrow} size="small" />
            </Button>
          </FlexBox>
        </WarningWrapper>
      );
    }

    return (
      <>
        <FlexBox>
          <CheckBox
            active={tac}
            onClickHandle={() => setTac((prevState) => !prevState)}
          />
          <Spacer size="small" />
          <FlexBox>
            <Paragraph paragraph={t("agree")} />
            <Spacer size="micro" />
            <BlueLink
              text={t("tac")}
              href="/tac"
              target="_blank"
              marginTop={"0"}
            />
          </FlexBox>
        </FlexBox>
        <Spacer direction="vertical" size="big" />
      </>
    );
  };

  return (
    <FlexBox alignItems="stretch" justifyContent="flex-start">
      <ModalContent
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <FlexBox
          customWidth={CustomWidthE.full}
          flexDirection="column"
          alignItems="flex-start"
        >
          <Heading
            heading={t("title")}
            headingType={HeadingTypeT.h2}
            spaceBottom="small"
          />
          <Paragraph
            paragraph={`@${handle}`}
            color={theme.color.textGreyDarkColor}
            fontWeight={600}
          />
          <Spacer size="big" direction="vertical" />
          <Paragraph paragraph={`${t("text")} “${name}”.`} />
          <Spacer size="small" direction="vertical" />
          {(hashtags || mentions) && (
            <Paragraph
              paragraph={
                <>
                  {t("collect.sentence") + " "}
                  <b>{hashtags && t("collect.hashtags") + `: ${hashtags}`}</b>
                  {hashtags && mentions && " a "}
                  <b>{mentions && t("collect.mentions") + `: ${mentions}`}</b>
                </>
              }
            />
          )}

          <Spacer direction="vertical" />
          <Paragraph
            paragraph={`${t("date")} ${getDateTime(
              duration_from,
              "shortDate"
            )} - ${getDateTime(duration_to, "shortDate")}`}
            fontWeight={600}
          />
          <Spacer direction="vertical" size="big" />
          {getModalActionContent()}
        </FlexBox>
        <Spacer direction="vertical" size="big" />
        <FlexBox customWidth={CustomWidthE.full} justifyContent="flex-end">
          <Button
            onClick={close}
            type={ButtonTypeE.clear}
            size={ButtonSizeE.micro}
          >
            {t("skip")}
          </Button>
          <Spacer size="default" />
          <Button
            onClick={mutateHandle}
            disabled={isLoading || !userExist || (userExist && !tac)}
            size={ButtonSizeE.micro}
            type={ButtonTypeE.default}
          >
            {t("continue")}
          </Button>
        </FlexBox>
      </ModalContent>
      <ModalImage>
        <IllustrationWrapper size={rem(250)}>
          {getIllustration(IllustrationTypeE.mobile_device)}
        </IllustrationWrapper>
      </ModalImage>
    </FlexBox>
  );
};

export default AutoModal;
