import { isStringEmpty } from "../helpers";

const buttonVisibility = (desc, shortDesc, button) => {
  if (button && isStringEmpty(shortDesc.value) && !isStringEmpty(desc.value)) {
    button.classList.add("visible");
    return;
  }

  button.classList.remove("visible");
};

const copyDescription = (desc, shortDesc) => {
  if (isStringEmpty(desc.value)) {
    return;
  }

  const inputEvent = new Event("input");
  shortDesc.value =
    desc.value.length >= 150 ? desc.value.slice(0, 147) + "..." : desc.value;
  shortDesc.dispatchEvent(inputEvent);
};

export const copyDescriptionsOnChange = () => {
  const description = document.getElementById("campaign_description");
  const shortDescription = document.getElementById(
    "campaign_short_description"
  );
  const copyDescriptonButton = document.getElementById("description-copy");

  if (description && shortDescription && copyDescriptonButton) {
    const inputs = [description, shortDescription];

    inputs.forEach((input) => {
      input.addEventListener("input", () =>
        buttonVisibility(description, shortDescription, copyDescriptonButton)
      );
    });

    copyDescriptonButton.addEventListener("click", () =>
      copyDescription(description, shortDescription)
    );

    buttonVisibility(description, shortDescription, copyDescriptonButton);
  }
};
