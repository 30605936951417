export type DataToQueryT = {
  [key: string]: string[] | string;
};

export const getQuery = (data: DataToQueryT): string => {
  const arrayData = {};

  Object.keys(data).forEach((k) => {
    const item = data[k];
    arrayData[k] = Array.isArray(item) ? item : [item];
  });

  return Object.keys(arrayData)
    .filter((k) => arrayData[k])
    .map(
      (k) =>
        `${encodeURIComponent(k)}=${encodeURIComponent(arrayData[k].join(","))}`
    )
    .join("&");
};
