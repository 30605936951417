import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useContext, useEffect } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import { IllustrationTypeE } from "../../../assets/illustrations/Illustrations";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Icon from "../../../components/Icon";
import NoResults from "../../../components/NoResults";
import PaginationScrollWrapper from "../../../components/PaginationScrollWrapper";
import VisibleWrapper from "../../../components/VisibleWrapper";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../features/Placeholders/PlaceholderLoader";
import { CrossPacksConts } from "../../../helpers/crossPacks";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { usePagination } from "../../../hooks/usePagination";
import { useResponsive } from "../../../hooks/useResponsive";
import { rem, theme } from "../../../styling/theme";
import { NotificationsContext } from "../context-providers/NotificationsContextProvider";
import { NotificationT } from "../types";
import NotificationCard from "./NotificationCard";
import { IconWrapper } from "./Notifications";

type BoxWrapperStyleT = {
  active: boolean;
};

const CloseBoxOverlay = styled.div({
  position: "fixed",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 0,
});

const BoxWrapper = styled(VisibleWrapper)<BoxWrapperStyleT>(({ active }) => ({
  position: "absolute",
  top: "calc(100% + 3px)",
  right: 0,
  width: rem(360),
  backgroundColor: theme.color.whiteColor,
  boxShadow: `0px 2px 9px rgb(0 0 0 / 10%)`,
  borderRadius: rem(8),
  overflow: "hidden",

  [theme.media.maxMd]: {
    position: "fixed",
    width: rem(285),
    height: "100%",
    top: 0,
    left: active ? 0 : rem(-285),
    paddingTop: rem(72),
    borderRadius: 0,
    boxShadow: "none",
    backgroundColor: theme.color.backgroundColor,
    transition: "all 0.4s",
    transitionTimingFunction: theme.timing.default,
    zIndex: 10,
  },
}));

const ScrollWrapper = styled(PaginationScrollWrapper)({
  borderBottomLeftRadius: rem(8),
  borderBottomRightRadius: rem(8),

  [theme.media.maxMd]: {
    borderRadius: 0,
  },
});

const BoxNotch = styled.div({
  position: "absolute",
  top: 0,
  right: rem(16),
  width: rem(16),
  height: rem(16),
  transform: "rotate(45deg)",
  backgroundColor: theme.color.whiteColor,
  transformOrigin: "right",
});

const HeadingWrapper = styled(FlexBox)({
  padding: `${rem(12)} ${rem(16)}`,

  [theme.media.maxMd]: {
    paddingBottom: rem(24),
    borderBottom: `1px solid ${theme.color.greyColor}`,
  },
});

type PopupBoxT = {
  boxOpen: boolean;
  setBoxOpen: Dispatch<SetStateAction<boolean>>;
};

const PopupBox: FC<PopupBoxT> = ({ boxOpen, setBoxOpen }) => {
  const ITEMS_PER_PAGE = 15;
  const { tabletVersion } = useResponsive();

  const {
    notificationState: { notifications, setNotifications },
    liveNotificationsState: { liveNotifications },
  } = useContext(NotificationsContext);

  const { firstLoad, pending, queuedItems, loadItems } =
    usePagination<NotificationT>(
      getApiUrl(ApiRoutesE.NOTIFICATIONS),
      ITEMS_PER_PAGE,
      setNotifications,
      "notifications"
    );

  useEffect(() => {
    // EventListener for refreshing the offers list in the case when new notification came
    document.addEventListener(
      CrossPacksConts.NOTIFICATIONS_CLOSE_LISTENER,
      () => {
        if (boxOpen) {
          setBoxOpen(false);
        }
      }
    );
  }, []);

  const getContent = (): JSX.Element => {
    if (!firstLoad && !pending && !notifications && queuedItems.length === 0) {
      return (
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          <NoResults
            illustrationSize={200}
            illustration={IllustrationTypeE.scissors}
            message={"Zatím žádné notifikace"}
          />
        </FlexBox>
      );
    }

    if (notifications && notifications.length > 0) {
      const allNotifications = liveNotifications
        .reverse()
        .concat(notifications);

      return (
        <>
          {allNotifications.map((item) => (
            <NotificationCard key={item.id} notification={item} />
          ))}
        </>
      );
    }

    return (
      <PlaceholderLoader
        type={PlaceholdersTypeE.notification_card}
        count={5}
        direction="column"
      />
    );
  };

  return (
    <>
      {boxOpen && <CloseBoxOverlay onClick={() => setBoxOpen(false)} />}
      <BoxWrapper active={boxOpen} visible={boxOpen} type="roll">
        {!tabletVersion && <BoxNotch />}
        <FlexBox
          alignItems="flex-start"
          flexDirection="column"
          customWidth={CustomWidthE.full}
        >
          <HeadingWrapper justifyContent="flex-start">
            {tabletVersion && (
              <IconWrapper active={true} onClick={() => setBoxOpen(false)}>
                <Icon icon={IconTypeE.arrowLeft} size={10} />
              </IconWrapper>
            )}
            <Heading heading="Notifikace" headingType={HeadingTypeT.h2} />
          </HeadingWrapper>
          <ScrollWrapper
            loadItems={loadItems}
            queuedItems={queuedItems}
            showedItems={notifications}
            pending={pending}
            height={tabletVersion ? "100%" : rem(380)}
          >
            {getContent()}
          </ScrollWrapper>
        </FlexBox>
      </BoxWrapper>
    </>
  );
};

export default PopupBox;
