import * as React from "react";
import { FC, useEffect, useState } from "react";
import Container from "../../../components/Container";
import FlexBox from "../../../components/FlexBox";
import { useResponsive } from "../../../hooks/useResponsive";
import { useScroll } from "../../../hooks/useScroll";
import { useTranslate } from "../../../hooks/useTranslate";
import { MarketplaceDataPropsT } from "../../types";
import OpenDetailOnLoad from "../features/OpenDetailOnLoad";
import { handleSetScrolledGroup } from "../helpers/scrolledGroup";
import { CampaignGroupKeyT } from "../types";
import CampaignsGroup from "./CampaignsGroup";
import CampaignsHeader from "./CampaignsHeader";

export type GroupScrollDataT = {
  height: number;
  offset: number;
  exists: boolean;
};
export type GroupsOffsetsT = Record<CampaignGroupKeyT, GroupScrollDataT>;

const initGroup: GroupScrollDataT = {
  height: 0,
  offset: 0,
  exists: false,
};

const initGroupsOffsets: GroupsOffsetsT = {
  new: initGroup,
  targeted: initGroup,
  other: initGroup,
  hidden: initGroup,
};

const Marketplace: FC<MarketplaceDataPropsT> = ({ data }) => {
  const { t } = useTranslate("influencer.market");
  const { campaigns } = data;
  const { windowHeight, tabletVersion } = useResponsive();
  const { currentOffset } = useScroll(500);

  const [groups, setGroups] = useState<GroupsOffsetsT>(initGroupsOffsets);
  const [scrolledGroup, setScrolledGroup] =
    useState<CampaignGroupKeyT>("targeted");

  useEffect(() => {
    handleSetScrolledGroup(
      groups,
      currentOffset,
      setScrolledGroup,
      windowHeight
    );
  }, [currentOffset, scrolledGroup, groups, windowHeight]);

  return (
    <>
      {tabletVersion && (
        <CampaignsHeader groups={groups} scrolledGroup={scrolledGroup} />
      )}

      <Container>
        <FlexBox position="relative" flexDirection="column">
          <CampaignsGroup
            campaignGroupKey={"new"}
            setGroups={setGroups}
            title={t("new.title")}
            horizontal={true}
            hideAfterLoad={true}
          />
          <CampaignsGroup
            campaignGroupKey={"targeted"}
            setGroups={setGroups}
            initCampaigns={campaigns}
            title={t("targeted.title")}
            popoverTitle={t("targeted.info_title")}
            popoverText={t("targeted.info_text")}
          />
          <CampaignsGroup
            campaignGroupKey="other"
            setGroups={setGroups}
            perPage={4}
            title={t("other.title")}
            popoverTitle={t("other.info_title")}
            popoverText={t("other.info_text")}
          />
          <CampaignsGroup
            campaignGroupKey="hidden"
            setGroups={setGroups}
            perPage={4}
            title={t("hidden.title")}
            renderAfterLoad={true}
          />
        </FlexBox>
      </Container>
      <OpenDetailOnLoad />
    </>
  );
};

export default Marketplace;
