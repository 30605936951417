import styled from "@emotion/styled";
import React, { FC } from "react";
import { AlignT, CustomWidthE } from "../../components/FlexBox";
import TableColumn from "./TableColumn";

const Anchor = styled.a<{ justifyContent?: AlignT }>(({ justifyContent }) => ({
  display: "flex",
  width: "100%",
  justifyContent,
  alignContent: "center",
}));

type TableColumnLinkT = {
  url: string;
  columnWidth?: CustomWidthE | string;
  justifyContent?: AlignT;
};

const TableColumnLink: FC<TableColumnLinkT> = ({
  url,
  columnWidth,
  justifyContent,
  children,
}) => {
  return (
    <TableColumn columnWidth={columnWidth}>
      <Anchor href={url} justifyContent={justifyContent}>
        {children}
      </Anchor>
    </TableColumn>
  );
};

export default TableColumnLink;
