import styled from "@emotion/styled";
import { rem, theme } from "../styling/theme";

type ContainerT = {
  noMargin?: boolean;
};

const Container = styled.div<ContainerT>(({ noMargin = false }) => ({
  width: "100%",
  maxWidth: rem(theme.containerSizes.monsterContainerWidth),
  margin: noMargin ? "0 auto" : `${rem(32)} auto ${rem(52)}`,
  padding: `0 ${rem(16)}`,

  [theme.media.maxXl]: {
    maxWidth: rem(theme.containerSizes.bigContainerWidth),
  },

  [theme.media.maxLg]: {
    maxWidth: rem(theme.containerSizes.baseContainerWidth),
  },
}));

export default Container;
