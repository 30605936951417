import styled from "@emotion/styled";
import * as React from "react";
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import Button from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Icon from "../../../../components/Icon";
import { getAfterActionModalContent } from "../../../../components/Modal";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { useFetch } from "../../../../hooks/useFetch";
import { rem, theme } from "../../../../styling/theme";
import { RatingStateT } from "./Rating";

const StarsWrapper = styled(FlexBox)({
  marginTop: rem(16),
});

const StarWrapper = styled(FlexBox)({
  margin: `${rem(32)} ${rem(4)} ${rem(52)}`,
  cursor: "pointer",
});

const StyledText = styled.textarea({
  width: "100%",
  height: rem(100),
  fontSize: rem(15),
  margin: 0,
  padding: rem(16),
  backgroundColor: theme.color.backgroundColor,
  borderRadius: rem(4),
  border: `1px solid ${theme.color.greyColor}`,
  outline: "none",
  resize: "none",
  transition: "height 0.4s",
  transitionTimingFunction: theme.timing.default,
  "-webkit-appearance": "none",
});

type SetRatingModal = {
  offerId: number;
  visible: boolean;
  closeModal: () => void;
  setLocalTmpRating: Dispatch<SetStateAction<RatingStateT | null>>;
};

const SetRatingModal: FC<SetRatingModal> = React.memo(
  ({ offerId, closeModal, visible, setLocalTmpRating }) => {
    const [feedback, setFeedback] = useState<string>("");
    const [rating, setRating] = useState<number>(0);
    const [reportSent, setReportSend] = useState(false);

    const body = {
      rating: {
        stars: rating,
        feedback: feedback,
      },
    };

    const { call, pending, payload, error } = useFetch<Response>(
      getApiUrl(ApiRoutesE.OFFER_RATINGS, offerId),
      undefined,
      "POST",
      body
    );

    useEffect(() => {
      if (payload || error) {
        setTimeout(() => {
          closeModal();
          setLocalTmpRating(body.rating);
        }, 3000);
      }
    }, [payload, error]);

    useEffect(() => {
      // removing modal state after modal reopen
      if (!visible) {
        setFeedback("");
        setRating(0);
        setReportSend(false);
      }
    }, [visible]);

    const sendReport = (): void => {
      call();
      setReportSend(true);
    };

    const handlePress = (index: number) => () => {
      setRating(index);
    };

    type handleOnChangeT = (e: ChangeEvent<HTMLTextAreaElement>) => void;
    const handleOnChange: handleOnChangeT = (e) => {
      const eventTarget = e.target as HTMLTextAreaElement;

      setFeedback(eventTarget.value);
    };

    const sendDisable = feedback.length < 1 || rating < 1;

    return (
      <FlexBox
        flexDirection="column"
        justifyContent="stretch"
        alignItems="stretch"
      >
        {reportSent ? (
          getAfterActionModalContent(
            payload,
            error,
            "Hodnocení bylo úspěšně odesláno",
            "Vyskytla se chyba, skuste to pozdějc prosím znovu."
          )
        ) : (
          <>
            <StarsWrapper>
              {[...Array(5)].map((_, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <StarWrapper onClick={handlePress(index + 1)} key={index}>
                  <Icon
                    icon={IconTypeE.star}
                    size="monster"
                    color={
                      rating > index
                        ? theme.color.yellowColor
                        : theme.color.greyColor
                    }
                  />
                </StarWrapper>
              ))}
            </StarsWrapper>
            <Paragraph
              paragraph={"Stručný popis spolupráce"}
              color={theme.color.textGreyColor}
            />
            <Spacer size="mini" direction="vertical" />
            <StyledText
              name="feedback"
              value={feedback}
              maxLength={170}
              onChange={(e) => handleOnChange(e)}
            />
            <Spacer size="big" direction="vertical" />
            <Button
              customWidth={CustomWidthE.full}
              disabled={pending || sendDisable}
              onClick={sendReport}
            >
              {"Ohodnotit spolupráci"}
            </Button>
          </>
        )}
      </FlexBox>
    );
  }
);

export default SetRatingModal;
