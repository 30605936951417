import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import FlexBox from "../../../../components/FlexBox";
import { PendingFileT } from "../../../../hooks/usePendingFile";
import { rem, theme } from "../../../../styling/theme";
import { FileStatusTypeE } from "../../types";
import PendingFile from "./PendingFile";

type FilesWrapperStyleT = {
  customHeight: string;
};

const Files = styled(FlexBox)<FilesWrapperStyleT>(({ customHeight }) => ({
  flex: "100%",
  height: customHeight,
  maxHeight: customHeight,
  paddingTop: rem(3),
  boxSizing: "content-box",
  transition: "min-height 0.4s",
  transitionTimingFunction: theme.timing.default,
  overflowX: "scroll",
  overflowY: "hidden",
  scrollbarWidth: "none",
  paddingLeft: rem(4),
  marginLeft: rem(-4),
}));

type FilesUploadT = {
  customHeight: number;
  pendingFiles: PendingFileT[];
  setPendingFiles: Dispatch<SetStateAction<PendingFileT[]>>;
  setFileURLs: Dispatch<SetStateAction<string[]>>;
};

const FilesUpload: FC<FilesUploadT> = React.memo(
  ({ customHeight, pendingFiles, setPendingFiles, setFileURLs }) => {
    useEffect(() => {
      if (pendingFiles) {
        const uploadedFiles = pendingFiles.filter(
          (file) => file.status === FileStatusTypeE.uploaded
        );

        if (uploadedFiles.length > 0) {
          setFileURLs(() => {
            return uploadedFiles.map((file) =>
              file.link ? file.link : "none"
            );
          });
        }
      }
    }, [pendingFiles]);

    return (
      <Files
        customHeight={rem(customHeight)}
        flexDirection="row"
        justifyContent="stretch"
      >
        {pendingFiles &&
          pendingFiles.map((file) => {
            return (
              <PendingFile
                key={file.id}
                pendingFile={file}
                setPendingFiles={setPendingFiles}
                isLast={pendingFiles[pendingFiles.length - 1].id === file.id}
              />
            );
          })}
      </Files>
    );
  }
);

export default FilesUpload;
