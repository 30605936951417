import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { rem, theme } from "../styling/theme";
import FlexBox from "./FlexBox";
import Icon, { getIconSize, IconSizeT } from "./Icon";

type CircleButtonStyleT = {
  size: number;
  background: string;
  disabled: boolean;
  withShadow: boolean;
  disabledBackground?: string;
};

const StyledCircleButton = styled(FlexBox)<CircleButtonStyleT>(
  ({ background, size, disabled, withShadow, disabledBackground }) => ({
    position: "relative",
    height: rem(size),
    minWidth: rem(size),
    background:
      disabled && disabledBackground ? disabledBackground : background,
    borderRadius: "50%",
    transition: "background 0.4s",
    transitionTimingFunction: theme.timing.default,
    boxShadow: withShadow ? `0px 2px 9px rgb(0 0 0 / 10%)` : "none",
    cursor: disabled ? "default" : "pointer",
  })
);

type CircleButtonT = {
  onClickHandle?: () => void;
  background?: string;
  hoverBackground?: string;
  disabledBackground?: string;
  icon: IconTypeE;
  iconSize?: IconSizeT;
  iconColor?: string;
  disableIconColor?: string;
  className?: string;
  disabled?: boolean;
  withShadow?: boolean;
  tooltip?: JSX.Element;
};

const CircleButton: FC<CircleButtonT> = ({
  onClickHandle,
  background = theme.color.yellowColor,
  hoverBackground = "transparent",
  disabledBackground,
  icon,
  iconSize = "default",
  iconColor = theme.color.textColor,
  disableIconColor = theme.color.textColor,
  className,
  disabled = false,
  withShadow = false,
  tooltip,
}) => {
  const [hover, setHover] = useState<boolean>(false);
  const disabledButton = disabled || !onClickHandle;
  const iconSizeInt = getIconSize(iconSize);

  return (
    <StyledCircleButton
      onClick={disabledButton ? () => {} : onClickHandle}
      background={hover && !disabledButton ? hoverBackground : background}
      disabledBackground={disabledBackground}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      size={iconSizeInt * 2}
      className={className}
      disabled={disabledButton}
      withShadow={withShadow}
    >
      <Icon
        icon={icon}
        size={iconSize}
        color={disabled ? disableIconColor : iconColor}
      />
      {tooltip && tooltip}
    </StyledCircleButton>
  );
};

export default CircleButton;
