import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import AvatarTile from "../../../../components/AvatarTile";
import Button, {
  ButtonSizeE,
  ButtonTypeE,
} from "../../../../components/Button";
import FlexBox from "../../../../components/FlexBox";
import Icon from "../../../../components/Icon";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import TableColumn from "../../../../ui/table/TableColumn";
import TableColumnLink from "../../../../ui/table/TableColumnLink";
import TableItemWrapper from "../../../../ui/table/TableItemWrapper";
import { ReportingParticipant } from "../../../reporting-media-groups/data-access/types";
import { ParticipantToRemove } from "./Table";

type TableRowT = {
  participant: ReportingParticipant;
  last: boolean;
  reportingId: number;
  hide: boolean;
  setShareLink: Dispatch<SetStateAction<string | undefined>>;
  setParticipantToRemove: Dispatch<
    SetStateAction<ParticipantToRemove | undefined>
  >;
};

const TableRow: FC<TableRowT> = ({
  participant,
  last,
  reportingId,
  hide,
  setShareLink,
  setParticipantToRemove,
}) => {
  const { id, medias, handle, profile_url, display_name } = participant;
  const { posts, story_sets, igtvs, reels } = medias;
  const { t } = useTranslate("brands.reportings.participants.index.table");

  return (
    <TableItemWrapper
      last={last}
      temporaryHide={hide}
      renderChildren={() => (
        <>
          <TableColumnLink url={`/media_groups/${id}`} columnWidth="35%">
            <AvatarTile
              handle={handle}
              name={display_name ? display_name : handle}
              imageUrl={profile_url}
            />
          </TableColumnLink>
          <TableColumnLink
            url={`/media_groups/${id}`}
            columnWidth="10%"
            justifyContent="center"
          >
            {posts || "0"}
          </TableColumnLink>
          <TableColumnLink
            url={`/media_groups/${id}`}
            columnWidth="10%"
            justifyContent="center"
          >
            {story_sets || "0"}
          </TableColumnLink>
          <TableColumnLink
            url={`/media_groups/${id}`}
            columnWidth="10%"
            justifyContent="center"
          >
            {igtvs || "0"}
          </TableColumnLink>
          <TableColumnLink
            url={`/media_groups/${id}`}
            columnWidth="10%"
            justifyContent="center"
          >
            {reels || "0"}
          </TableColumnLink>
          <TableColumn columnWidth="25%" justifyContent="flex-end">
            <FlexBox>
              <Button
                onClick={() => setShareLink(`${origin}/media_groups/${id}`)}
                type={ButtonTypeE.grey}
                size={ButtonSizeE.micro}
              >
                <Icon icon={IconTypeE.socialNetwork} size="small" />
                <Spacer size="mini" />
                {t("share")}
              </Button>
              <Spacer size={"micro"} />
              <Button
                onClick={() =>
                  setParticipantToRemove({
                    id,
                    reportingId,
                    removed: false,
                  })
                }
                type={ButtonTypeE.grey}
                size={ButtonSizeE.micro}
              >
                <Icon icon={IconTypeE.bin} size="small" />
              </Button>
            </FlexBox>
          </TableColumn>
        </>
      )}
    />
  );
};

export default TableRow;
