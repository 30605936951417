import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { getParticipantId } from "../utils/helpers";
import { MediaGroupT } from "./types";

type getMediaGroupsParticipantT = () => Promise<MediaGroupT[]>;
export const getMediaGroupsParticipant: getMediaGroupsParticipantT =
  async () => {
    return axios
      .get(
        getApiUrl(
          ApiRoutesE.REPORTING_MEDIA_GROUPS_PARTICIPANT,
          getParticipantId()
        )
      )
      .then((res) => res.data.media_groups);
  };
