import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../styling/theme";
import FlexBox from "./FlexBox";

const Wrapper = styled(FlexBox)<{
  size: number;
  borderWidth: number;
  borderColor: string;
  withShadow: boolean;
}>(({ size, borderWidth, borderColor, withShadow }) => ({
  minWidth: rem(size),
  width: rem(size),
  height: rem(size),
  backgroundColor: theme.color.yellowColor,
  borderWidth,
  borderColor,
  borderStyle: "solid",
  borderRadius: "50%",
  boxShadow: withShadow ? `0px 2px 20px rgb(0 0 0 / 10%)` : "none",
  overflow: "hidden",
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,
}));

const Image = styled.img({
  objectFit: "cover",
  width: "100%",
  height: "100%",
});

const Letter = styled.div({
  fontSize: rem(20),
  fontWeight: "bold",
  userSelect: "none",
});

type AvatarT = {
  name: string;
  src: string | null;
  size?: number;
  borderWidth?: number;
  borderColor?: string;
  withShadow?: boolean;
};

const Avatar: FC<AvatarT> = ({
  src,
  name,
  size = 28,
  borderWidth = 0,
  borderColor = theme.color.whiteColor,
  withShadow = false,
}) => {
  return (
    <Wrapper
      title={name}
      size={size}
      borderColor={borderColor}
      borderWidth={borderWidth}
      withShadow={withShadow}
    >
      {src ? <Image src={src} /> : <Letter>{name[0].toUpperCase()}</Letter>}
    </Wrapper>
  );
};

export default Avatar;
