import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Panel from "../../../components/Panel";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useFetch } from "../../../hooks/useFetch";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { BrandAffinityPayloadT, BrandRecordT } from "../types";
import AffintyTable from "./AffinityTable";

const PanelHead = styled(FlexBox)({
  padding: `${rem(24)} ${rem(16)} ${rem(20)}`,
  borderBottom: `1px solid ${theme.color.greyColor}`,
});

const Divider = styled.div({
  width: rem(2),
  height: rem(30),
  backgroundColor: theme.color.greyColor,
});

type PastDaysT = 90 | 180 | 365;

type BrandAffinityT = {
  influencerId: number;
};

const BrandAffinity: FC<BrandAffinityT> = ({ influencerId }) => {
  const [brands, setBrands] = useState<BrandRecordT[] | null>(null);
  const [filteredBrands, setFilteredBrands] = useState<BrandRecordT[] | null>(
    null
  );
  const [pastDays, setPastDays] = useState<PastDaysT>(365);
  const [loaded, setLoaded] = useState(false);
  const [paidPartnership, setPaidPartnership] = useState(false);

  const { t } = useTranslate("crm.detail.brand_affinity");

  const { payload, call } = useFetch<BrandAffinityPayloadT>(
    `${getApiUrl(
      ApiRoutesE.BRAND_AFFINITY,
      influencerId
    )}?past_days=${pastDays}`,
    [],
    "GET"
  );

  const filterPaidPartnership = (
    paidPartnership: boolean,
    brand_affinities: BrandRecordT[]
  ): BrandRecordT[] => {
    if (paidPartnership) {
      const newAffinities = brand_affinities.map((item) => {
        const affinity: BrandRecordT = {
          ...item,
          medias: item.medias.filter((media) => media.paid_partnership),
        };

        return affinity;
      });

      return newAffinities.filter((item) => item.medias.length > 0);
    }

    return brand_affinities;
  };

  useEffect(() => {
    if (payload) {
      const { brand_affinity } = payload;
      setBrands(brand_affinity);

      setFilteredBrands(filterPaidPartnership(paidPartnership, brand_affinity));

      setLoaded(true);
    }
  }, [payload]);

  useEffect(() => {
    if (loaded) {
      call();
    }
  }, [pastDays]);

  const pastDaysData: Record<PastDaysT, string> = {
    90: t("last_90"),
    180: t("last_180"),
    365: t("last_year"),
  };

  const handlePaidPartnership = () => {
    const paid = !paidPartnership;
    setPaidPartnership(paid);
    brands && setFilteredBrands(filterPaidPartnership(paid, brands));
  };

  return (
    <Panel flexDirection="column">
      <PanelHead justifyContent="space-between" customWidth={CustomWidthE.full}>
        <FlexBox flexDirection="column" alignItems="flex-start">
          <Heading
            headingType={HeadingTypeT.h4}
            heading={t("label")}
            spaceBottom="micro"
          />
          <Spacer size="micro" direction="vertical" />
          <Paragraph
            paragraph={t("popover")}
            color={theme.color.textGreyColor}
          />
        </FlexBox>
        <FlexBox>
          <Button
            size={ButtonSizeE.micro}
            type={paidPartnership ? ButtonTypeE.default : ButtonTypeE.outline}
            onClick={handlePaidPartnership}
          >
            {t("paid")}
          </Button>
          <Spacer size="default" />
          <Divider />
          <Spacer size="small" />
          {Object.keys(pastDaysData).map((item, index) => {
            const current = item as any as PastDaysT;

            return (
              // eslint-disable-next-line react/no-array-index-key
              <FlexBox key={index}>
                <Spacer size="mini" />
                <Button
                  size={ButtonSizeE.micro}
                  type={
                    pastDays == current
                      ? ButtonTypeE.default
                      : ButtonTypeE.outline
                  }
                  onClick={() => setPastDays(current)}
                >
                  {pastDaysData[item]}
                </Button>
              </FlexBox>
            );
          })}
        </FlexBox>
      </PanelHead>
      <AffintyTable brands={filteredBrands} />
    </Panel>
  );
};

export default BrandAffinity;
