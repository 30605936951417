import * as React from "react";
import { FC } from "react";
import { Bar } from "react-chartjs-2";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import { AgeGenderCardDataT } from "../../types";
import { Tab } from "../engagement/Engagement";
import { customTicksAgeGender, getAgeGenderOptions } from "./options";

type AgeGenderT = {
  data: AgeGenderCardDataT;
};

const AgeGender: FC<AgeGenderT> = ({ data }) => {
  const { t } = useTranslate("crm.detail.card");

  const { gender_age_groups, total } = data;
  const { male, female } = gender_age_groups;
  const unknown = 100 - (total.female + total.male);

  const dataFemale = Object.entries(female).map((item) => ({
    [item[0]]: item[1],
  }));

  const dataMale = Object.entries(male).map((item) => ({
    [item[0]]: item[1],
  }));

  const valuesFemale = dataFemale
    .map((s) => Object.values(s))
    .map((a) => a.join());
  const valuesMale = dataMale.map((s) => Object.values(s)).map((a) => a.join());
  const labels = dataFemale.map((s) => Object.keys(s)).map((a) => a.join());

  const labelsExtended = labels.map((s, i) =>
    labels[i + 1] ? `${s} - ${labels[i + 1]}` : `${s}+`
  );

  const chartData = {
    labels: labelsExtended,
    datasets: [
      {
        label: "Female",
        data: valuesFemale,
        backgroundColor: "#BB6BD9",
        barThickness: 18,
        borderRadius: 6,
        borderWidth: 2,
        borderColor: "transparent",
      },
      {
        label: "Male",
        data: valuesMale,
        backgroundColor: "#2D9CDB",
        barThickness: 18,
        borderRadius: 6,
        borderWidth: 2,
        borderColor: "transparent",
      },
    ],
  };

  return (
    <FlexBox
      customWidth={CustomWidthE.full}
      fullHeight={true}
      flexDirection="column"
      justifyContent="space-between"
    >
      <FlexBox customWidth={CustomWidthE.full} justifyContent="flex-start">
        <Tab active={false} dotColor="#BB6BD9">{`${t("others.female")} ${
          total.female
        }%`}</Tab>
        <Spacer size="big" />
        <Tab active={false} dotColor="#2D9CDB">{`${t("others.male")} ${
          total.male
        }%`}</Tab>
        {unknown > 0.09 && (
          <>
            <Spacer size="big" />
            <Tab active={false} dotColor="#BDBDBD">{`${t(
              "others.unknown"
            )} ${unknown.toFixed(1)}%`}</Tab>
          </>
        )}
      </FlexBox>
      <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
        <Bar
          data={chartData}
          options={getAgeGenderOptions(valuesMale, valuesFemale)}
          plugins={[customTicksAgeGender]}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default AgeGender;
