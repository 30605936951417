import * as ActionCable from "@rails/actioncable";
import * as React from "react";
import { createContext } from "react";
import { NotificationsDataPropsT } from "../types";
import Notifications from "./components/Notifications";
import NotificationsContextProvider from "./context-providers/NotificationsContextProvider";

const CableApp = { cable: null };
CableApp.cable = ActionCable.createConsumer();
export const ActionCableContext = createContext(null);

export default ({ data }: NotificationsDataPropsT): JSX.Element => (
  <NotificationsContextProvider>
    <ActionCableContext.Provider value={CableApp.cable}>
      <Notifications data={data} />
    </ActionCableContext.Provider>
  </NotificationsContextProvider>
);
