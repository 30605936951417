import { _o } from "./helpers";

type counterOnChangeT = (counter: HTMLElement, e: Event) => void;
const counterOnChange: counterOnChangeT = (counter, e) => {
  e.stopPropagation();

  const { className } = e.target as HTMLElement;
  const counterTrigger =
    className.includes("button-minus") || className.includes("button-plus");

  if (counterTrigger) {
    const inputsArray = counter.getElementsByTagName("INPUT");
    const input = inputsArray[0] as HTMLInputElement;
    const dispatch = input.dataset.dispatch;
    const numberValue = parseInt(input.value);

    if (className === "button-plus") {
      input.value = (numberValue + 1).toString();
    }

    if (className === "button-minus") {
      input.value = (numberValue === 0 ? 0 : numberValue - 1).toString();
    }

    if (dispatch) {
      const changeEvent = new Event(dispatch);
      input.dispatchEvent(changeEvent);
    }
  }
};

type componentInitT = () => void;
export const countersInit: componentInitT = () => {
  const counters = document.getElementsByClassName("counter-input");

  _o(counters).map((counter) => {
    counter.addEventListener("click", (e) =>
      counterOnChange(counter as HTMLElement, e)
    );
  });
};
