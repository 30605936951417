import pptxgen from "pptxgenjs";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect } from "react";
import FlexBox from "../../../components/FlexBox";
import { useFetch } from "../../../hooks/useFetch";
import {
  createCoverSlide,
  createInfluencerMediaSlides,
  createInfluencerSummarySlide,
  createSummarySlide,
} from "./slides";
import { ExportDataResponseT, InfluencerT, SummaryDataT } from "./types";

type PPTExportT = {
  reportingId: number;
  setPptxIsGenerating: Dispatch<SetStateAction<boolean>>;
};

const PPTExport: FC<PPTExportT> = ({
  reportingId,
  children,
  setPptxIsGenerating,
}) => {
  const { payload, call, pending } = useFetch<ExportDataResponseT>(
    `/brands/reportings/${reportingId}.json`,
    undefined,
    "GET"
  );

  useEffect(() => {
    if (payload) {
      const { influencers, summary } = payload;

      if (influencers && summary) {
        setPptxIsGenerating(false);
        generatePPT(influencers, summary);
      }
    }
  }, [payload]);

  const generatePPT = (
    influencers: InfluencerT[],
    summary: SummaryDataT
  ): void => {
    const pptx = new pptxgen();

    createCoverSlide(pptx, summary);
    createSummarySlide(pptx, summary);

    influencers.forEach((item) => {
      createInfluencerSummarySlide(pptx, item, summary.name);

      if (item.medias.length > 0) {
        createInfluencerMediaSlides(pptx, item.medias, item);
      }
    });

    pptx.writeFile({ fileName: `${summary.name}.pptx` });
  };

  const onClickHandle = (): void => {
    if (!pending) {
      setPptxIsGenerating(true);
      call();
    }
  };

  return <FlexBox onClick={onClickHandle}>{children}</FlexBox>;
};

export default PPTExport;
