import { _o } from "../helpers.ts";
import { productPriceOnChange } from "./productPrice";
import { slidersInit } from "../inputSlider";
import { getListOfNetworks, hotUpdate, setCurrency } from "./helpers";
import { BUDGET_SLIDER } from "./sliders";

const _ = window;

export const reInitBudgetSlider = () => {
  const currency = _.CAMPAIGN_CURRENCY;
  const updatedSlider = BUDGET_SLIDER[currency];

  slidersInit(updatedSlider);
};

export const currencyOnChange = () => {
  const campaignCurrency = document.getElementById("campaign-currency");

  if (campaignCurrency) {
    campaignCurrency.addEventListener("change", () => {
      setCurrency();
      reInitBudgetSlider();
      productPriceOnChange();
    });
  }
};

export const networksOnChange = () => {
  const campaignNetworks = document.getElementById("campaigns-networks");
  const networks = getListOfNetworks();

  if (networks.length < 1) {
    return;
  }

  if (campaignNetworks) {
    campaignNetworks.addEventListener("click", (e) => {
      const trigger = e.target;
      const triggerId = trigger.id;
      const clickToTrigger =
        triggerId &&
        networks.filter((network) => triggerId.includes(`trigger-${network}`))
          .length !== 0;

      if (clickToTrigger) {
        const input = document.getElementById(triggerId).previousElementSibling;
        const inputEvent = new Event("input");

        if (input.value == 1) {
          input.value = 0;
          input.dispatchEvent(inputEvent);
          return;
        }

        input.value = 1;
        input.dispatchEvent(inputEvent);
      }
    });
  }
};

export const nameOnChange = () => {
  const campaingNameInput = document.getElementById("campaign_name");
  const sourceNameElement = document.getElementById("thumb-heading");

  inputOnChange(campaingNameInput, sourceNameElement);
};

export const shortDesctOnChange = () => {
  const campaingShortDescInput = document.getElementById(
    "campaign_short_description"
  );
  const sourceShortDescElement = document.getElementById("thumb-description");

  inputOnChange(campaingShortDescInput, sourceShortDescElement);
};

export const inputOnChange = (target, source) => {
  const updateName = (e) => {
    hotUpdate(source, e.target.value);
  };

  if (target && source) {
    target.addEventListener("input", (e) => updateName(e));
  }
};

const updatePreview = (imageURL, targetID, tableID) => {
  const campaingImage = document.getElementById(targetID);
  const table = document.getElementById(tableID);
  const removeImage = table.getElementsByClassName("cropper-remove")[0];

  removeImage.addEventListener("click", () => {
    campaingImage.style.backgroundImage = "";
    campaingImage.style.backgroundSize = "";
    campaingImage.classList.remove("exist");
  });

  campaingImage.style.backgroundImage = `url(${imageURL})`;
  campaingImage.style.backgroundSize = "cover";
  campaingImage.classList.add("exist");
};

export const cardImageOnChange = () => {
  const fileInputsID = "file-upload-wrapper";
  const campaignUploadWrapper = _o(
    document.getElementsByClassName(fileInputsID)
  );

  function wrapperUpdateHandler() {
    const tableElement = this.getElementsByTagName("TABLE")[0];

    if (tableElement) {
      const { autoUpdateId } = tableElement.dataset;

      const interval = setInterval(() => {
        if (tableElement.children) {
          const tableElementInputs = tableElement.getElementsByTagName("INPUT");

          _o(tableElementInputs).forEach((input) => {
            if (input.className.includes("file_hidden_field")) {
              updatePreview(input.value, autoUpdateId, tableElement.id);
              clearInterval(interval);
            }
          });
        }
      }, 1000);
    }
  }

  if (campaignUploadWrapper) {
    campaignUploadWrapper.forEach((item) => {
      item.addEventListener("click", wrapperUpdateHandler);
    });
  }
};
