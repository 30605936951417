import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import CheckBox from "../../../components/CheckBox";
import CircleButton from "../../../components/CircleButton";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Spacer from "../../../components/Spacer";
import { rem, theme } from "../../../styling/theme";
import Dropdown from "../../../ui/Dropdown";
import { OfferStateE } from "../types";

export type FilterOptionT = {
  id: OfferStateE;
  icon: IconTypeE;
  label: string;
};

export const allFilterOptions = [
  OfferStateE.accepted,
  OfferStateE.created,
  OfferStateE.declined,
  OfferStateE.in_progress,
];

export const filterOptionsData: FilterOptionT[] = [
  {
    id: OfferStateE.created,
    icon: IconTypeE.newOffer,
    label: "návrh odeslán",
  },
  {
    id: OfferStateE.in_progress,
    icon: IconTypeE.time,
    label: "v procesu",
  },
  {
    id: OfferStateE.accepted,
    icon: IconTypeE.checkCircle,
    label: "dokončeno",
  },
  {
    id: OfferStateE.declined,
    icon: IconTypeE.crossCircle,
    label: "odmítnuto",
  },
];

const Wrapper = styled(FlexBox)({
  position: "relative",
  flex: 1,
});

const PickWrapper = styled(FlexBox)({
  position: "relative",
  width: "100%",
  cursor: "pointer",
});

const Label = styled.span<{ bold?: boolean }>(({ bold }) => ({
  fontWeight: bold ? 500 : 400,
  zIndex: 0,

  ["&::first-letter"]: {
    textTransform: "capitalize",
  },
}));

const FilterContent = styled(FlexBox)({
  padding: `${rem(6)} 0`,
});

const Option = styled(FlexBox)({
  padding: rem(12),
  cursor: "pointer",

  ["&:hover"]: {
    backgroundColor: theme.color.backgroundColor,
  },
});

type FilterT = {
  filteredState: OfferStateE[];
  setFilteredState: Dispatch<SetStateAction<OfferStateE[]>>;
};

const Filter: FC<FilterT> = ({ filteredState, setFilteredState }) => {
  const [openOptions, setOpenOptions] = useState(false);

  type optionOnClickHandleT = (filteredOption: FilterOptionT) => void;
  const optionOnClickHandle: optionOnClickHandleT = (filteredOption) => {
    setFilteredState((prevState) => {
      const exist = prevState.find((item) => item === filteredOption.id);

      if (exist) {
        return prevState.filter((item) => item !== filteredOption.id);
      }

      return [...prevState, filteredOption.id];
    });
  };

  return (
    <Wrapper>
      <PickWrapper justifyContent="flex-end">
        <Label onClick={() => setOpenOptions(!openOptions)} bold={true}>
          {"Filter"}
        </Label>
        <Spacer size="micro" />
        <CircleButton
          onClickHandle={() => setOpenOptions(!openOptions)}
          icon={IconTypeE.filter}
          iconColor={theme.color.textColor}
          hoverBackground={theme.color.yellowColor}
          background={
            (filteredState.length > 0 && filteredState.length < 4) ||
            openOptions
              ? theme.color.yellowColor
              : theme.color.greyLightColor
          }
        />
      </PickWrapper>
      <Dropdown
        width={rem(200)}
        active={openOptions}
        setInactive={() => setOpenOptions(false)}
      >
        <FilterContent flexDirection="column" customWidth={CustomWidthE.full}>
          {filterOptionsData.map((option) => {
            const exist = filteredState.find((item) => item === option.id);

            return (
              <Option
                key={option.id}
                justifyContent="space-between"
                customWidth={CustomWidthE.full}
                onClick={() => optionOnClickHandle(option)}
              >
                <FlexBox>
                  <Icon icon={option.icon} size="small" />
                  <Spacer size="micro" />
                  <Label>{option.label}</Label>
                </FlexBox>
                <Spacer size="mini" />
                <CheckBox
                  active={!!exist}
                  onClickHandle={() => {}}
                  size="micro"
                />
              </Option>
            );
          })}
        </FilterContent>
      </Dropdown>
    </Wrapper>
  );
};

export default Filter;
