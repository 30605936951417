import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

type RemoveParticipantPropsT = {
  id: string;
  reportingId: number;
};

type removeParticipantT = (props: RemoveParticipantPropsT) => Promise<{}>;
export const removeParticipant: removeParticipantT = async ({
  id,
  reportingId,
}) =>
  axios
    .delete(getApiUrl(ApiRoutesE.REPORTING_PARTICIPANTS_ITEM, id, reportingId))
    .then((res) => res.data);
