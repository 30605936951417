import { putSpacesToThousand } from "../../../helpers/formaters";

type formatedRowsDataItemT = { text: string; options: {} };
type formatTableRowsT = (rowsData: string[][]) => formatedRowsDataItemT[][];
export const formatTableRows: formatTableRowsT = (rowsData) => {
  return rowsData.map((row) => {
    return [
      { text: row[0], options: { align: "left" } },
      { text: row[1], options: { align: "right", bold: true } },
    ];
  });
};

type createNumberColumnT = (
  slide: any,
  item: string,
  label: string,
  x: number,
  y: number,
  yGap?: number,
  w?: number,
  fontSize?: number
) => void;
export const createNumberColumn: createNumberColumnT = (
  slide,
  item,
  label,
  x,
  y,
  yGap = 0.4,
  w = 1.5,
  fontSize = 18
) => {
  slide.addText(item, {
    x,
    y,
    w,
    h: 0.3,
    fontSize,
    bold: true,
    color: "333333",
    align: "center",
  });

  slide.addText(label, {
    x,
    y: y + yGap,
    w,
    h: 0.25,
    fontSize: fontSize / 1.5,
    color: "BDBDBD",
    align: "center",
  });
};

type createTableT = (
  slide: any,
  items: formatedRowsDataItemT[][],
  x: number,
  y: number,
  w: number,
  h: number,
  rowStartY: number,
  rowGap: number,
  color: string,
  fontSize?: number
) => void;
export const createTable: createTableT = (
  slide,
  items,
  x,
  y,
  w,
  h,
  rowStartY,
  rowGap,
  color,
  fontSize = 12
) => {
  slide.addTable(items, {
    x,
    y,
    w,
    h,
    fontSize,
    color,
  });

  if (rowGap > 0 && rowStartY > 0) {
    [...Array(items.length - 1)].forEach((_, index) => {
      slide.addShape("rect", {
        x: x + 0.05,
        y: rowStartY + index * rowGap,
        w: w - 0.05,
        h: 0.01,
        fill: { color, transparency: 65 },
      });
    });
  }
};

type addPBLTextT = (slide: any, x?: number) => void;
export const addPBLText: addPBLTextT = (slide, x = 0.2) => {
  slide.addText("Powered by Lafluence", {
    x: x,
    y: 5,
    w: 2,
    h: 0.4,
    fontSize: 12,
    color: "BDBDBD",
    align: "left",
  });
};

type getStringValueT = (number: number | null) => string;
export const getStringValue: getStringValueT = (value) =>
  value ? `${putSpacesToThousand(value)}` : "";

type getAudienceT = (
  age: string | null,
  gender: string | null,
  location: string | null
) => string;
export const getAudience: getAudienceT = (age, gender, location) => {
  return `${age ? age + " | " : ""}${gender ? gender + " | " : ""}${
    location ? location : ""
  }`;
};
