import styled from "@emotion/styled";
import html2canvas from "html2canvas";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useRef } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Panel from "../../../components/Panel";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { createFileName, useScreenshot } from "../../../hooks/useScreenshot";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import AvatarWithCircles from "../../../ui/AvatarWithCircles";
import GraphCardForExport from "../components/GraphCardForExport";
import { getCardsForExportMap } from "../providersCards/cardsForExportMap";
import { InfluencerProfileT } from "../types";
import InfluencerThumbnails from "../ui/InfluencerThumbnails";

const Wrapper = styled(FlexBox)<{ isVisible: boolean }>(({ isVisible }) => {
  const CONTAINER_WIDTH = 1550;

  return {
    position: "fixed",
    top: "0px",
    minWidth: `${CONTAINER_WIDTH}px`,
    maxWidth: `${CONTAINER_WIDTH}px`,
    left: `-${CONTAINER_WIDTH}px`,
    zIndex: -999999,
    opacity: isVisible ? 1 : 0,
  };
});

const Header = styled(Panel)({
  margin: rem(4),
  width: `calc(100% - ${rem(8)})`,
});

const Profile = styled(FlexBox)({
  marginLeft: rem(8),
});

const CardsWrapper = styled(FlexBox)({
  backgroundColor: theme.color.whiteColor,
  margin: `0 ${rem(-8)} ${rem(16)}`,
});

type ExportContainerT = {
  influencerData: InfluencerProfileT;
  exportType: "copy" | "save" | null;
  setExportType: Dispatch<SetStateAction<"copy" | "save" | null>>;
  exportPrice: string;
  exportDescription: string;
};

const ExportContainer: FC<ExportContainerT> = ({
  influencerData,
  exportType,
  setExportType,
  exportPrice,
  exportDescription,
}) => {
  const { provider, cards, handle, avatar_url, name, top_posts } =
    influencerData;

  const { t } = useTranslate("crm");

  const { image, takeScreenshot } = useScreenshot("image/png", 1.0);

  const cardsData = provider && getCardsForExportMap(provider);
  const capture = useRef<HTMLDivElement>(null);

  const createExport = (node: HTMLElement): void => {
    html2canvas(node, {
      logging: true,
      allowTaint: false,
      useCORS: true,
    }).then((canvas) => {
      if (exportType === "save") {
        const source = document.createElement("a");
        source.href = canvas.toDataURL("image/png");
        source.download = createFileName("png", `@${handle}-export-lafluence`);
        source.click();
      }

      if (exportType === "copy") {
        canvas.toBlob((blob) =>
          navigator.clipboard.write([
            new ClipboardItem({ "image/png": blob as Blob }),
          ])
        );
      }

      setExportType(null);
    });
  };

  useEffect(() => {
    if (exportType && capture.current) {
      takeScreenshot(capture.current);
    }
  }, [exportType]);

  useEffect(() => {
    if (image && capture.current) {
      createExport(capture.current);
    }
  }, [image]);

  return (
    <Wrapper isVisible={!!exportType} flexDirection="column">
      <CardsWrapper
        customWidth={CustomWidthE.full}
        flexWrap="wrap"
        alignItems="stretch"
        justifyContent="flex-start"
        ref={capture}
      >
        <Header padding={rem(4)} justifyContent="flex-start">
          <FlexBox
            justifyContent="space-between"
            customWidth={CustomWidthE.full}
          >
            <Profile alignItems="flex-start">
              <FlexBox flexDirection="column" alignItems="flex-start">
                <AvatarWithCircles
                  handle={handle}
                  profileImageUrl={avatar_url}
                  provider={provider}
                />
                <Heading
                  spaceBottom="micro"
                  heading={name ? name : handle}
                  headingType={HeadingTypeT.h2}
                />
                {name && (
                  <Paragraph
                    paragraph={`@${handle}`}
                    paragraphSize="large"
                    color={theme.color.textGreyColor}
                  />
                )}
              </FlexBox>
              <Spacer size="big" />
              <FlexBox
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
              >
                <Spacer direction="vertical" />
                {exportPrice.length > 0 && (
                  <Heading
                    heading={`${t("detail.export.price")}: ${exportPrice}`}
                    headingType={HeadingTypeT.h3}
                    spaceBottom="micro"
                    color={theme.color.textGreyColor}
                  />
                )}
                {exportDescription.length > 0 && (
                  <>
                    <Heading
                      heading={`${t("detail.export.description")}:`}
                      headingType={HeadingTypeT.h3}
                      spaceBottom="micro"
                      color={theme.color.textGreyColor}
                    />
                    <Paragraph
                      color={theme.color.textGreyColor}
                      paragraph={exportDescription}
                      truncate={250}
                    />
                  </>
                )}
              </FlexBox>
            </Profile>
            <InfluencerThumbnails items={top_posts} />
          </FlexBox>
        </Header>
        {cardsData &&
          cardsData.map((item) => {
            const dataSource = cards[item.type];

            if (!dataSource) {
              return null;
            }

            return (
              <GraphCardForExport
                key={item.type}
                cardChartData={item}
                card={dataSource}
                provider={provider}
              />
            );
          })}
      </CardsWrapper>
    </Wrapper>
  );
};
export default ExportContainer;
