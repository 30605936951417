import styled from "@emotion/styled";
import { rem, theme } from "../styling/theme";

export const UnreadIndicatior = styled.div({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  right: 0,
  width: rem(8),
  height: rem(8),
  borderRadius: "50%",
  backgroundColor: theme.color.yellowColor,
});
