export const getParticipantId = (): string => {
  const pathName = window.location.pathname;
  const regExp = /media_groups\/([\w|-]*)/;
  const regExpResult = regExp.exec(pathName);

  if (regExpResult) {
    return regExpResult[1];
  }

  return window.location.pathname;
};
