import * as React from "react";
import { FC, useContext } from "react";
import Button, { ButtonTypeE } from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../components/Heading";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import { MediaGroupsHelperContext } from "../../context-providers/MediaGroupsHelperContextProvider";

type RemoveMediaModalT = {
  disabled: boolean;
  removeMediaHandle: () => void;
};

const RemoveMediaModal: FC<RemoveMediaModalT> = ({
  disabled,
  removeMediaHandle,
}) => {
  const { t } = useTranslate("brands.reportings.media_groups");
  const { setMediaToRemove } = useContext(MediaGroupsHelperContext);

  return (
    <FlexBox flexDirection="column" alignItems="stretch">
      <Heading
        headingType={HeadingTypeT.h3}
        heading={t("modal_remove_title")}
        spaceBottom={10}
      />
      <Paragraph paragraph={t("modal_remove_question")} />
      <Spacer size="big" direction="vertical" />
      <FlexBox customWidth={CustomWidthE.full}>
        <Button
          type={ButtonTypeE.darkGrey}
          customWidth={CustomWidthE.full}
          onClick={() => setMediaToRemove(undefined)}
        >
          {t("buttons.modal_close")}
        </Button>
        <Spacer />
        <Button
          type={ButtonTypeE.error}
          customWidth={CustomWidthE.full}
          disabled={disabled}
          onClick={removeMediaHandle}
        >
          {t("buttons.confrim_remove_post")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default RemoveMediaModal;
