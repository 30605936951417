import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import Panel from "../../../components/Panel";
import { SocialNetworkProviderT } from "../../../helpers/socialMediaProviders";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import RawDataBoxes from "../charts-components/RawDataBoxes";
import { CardsMapT, CardsTypeT, EngagementCardDatatT } from "../types";

const Card = styled(Panel)({
  margin: rem(4),
  width: `calc(25% - ${rem(8)})`,
});

const CardPart = styled(FlexBox)({
  padding: rem(12),
});

const ChartPart = styled(CardPart)({
  paddingTop: rem(0),
});

type GraphCardForExportT = {
  cardChartData: CardsMapT;
  card: CardsTypeT;
  provider: SocialNetworkProviderT;
};

const GraphCardForExport: FC<GraphCardForExportT> = ({
  cardChartData,
  card,
  provider,
}) => {
  const { t } = useTranslate("crm.detail");
  const { type, element, graphOptions } = cardChartData;

  const { alternative_value, value, data } = card;

  const Graph = element !== "without" ? element : false;

  const getChart = (Element: React.FC<any> | "raw"): JSX.Element | null => {
    if (Element === "raw") {
      const getData = (): Record<string, string | number>[] | false => {
        if (type === "accounts_reached") {
          return (
            !!alternative_value &&
            !!value && [{ Posts: value }, { Stories: alternative_value }]
          );
        }

        if (type === "audience_reachability") {
          return data as Record<string, number>[];
        }

        if (type === "engagement") {
          const engagementData = data as EngagementCardDatatT;

          return [
            {
              [t("card.others.avg_likes")]:
                engagementData && engagementData.avg_likes?.total,
            },
            {
              [t("card.others.avg_comments")]:
                engagementData && engagementData.avg_comments?.total,
            },
          ];
        }

        return false;
      };

      const rawData = getData();
      if (rawData) {
        return (
          <ChartPart
            customWidth={CustomWidthE.full}
            alignItems="flex-start"
            justifyContent="space-between"
            fullHeight={true}
          >
            <RawDataBoxes
              data={rawData}
              half={type === "engagement" || type === "audience_reachability"}
              withPercentage={type === "audience_reachability"}
            />
          </ChartPart>
        );
      }

      return null;
    }

    if (data) {
      return (
        <ChartPart customWidth={CustomWidthE.full} alignItems="flex-end">
          <Element
            callBackForYTicks={graphOptions.callBackForYTicks}
            callBackForTooltipLabel={graphOptions.callBackForTooltipLabel}
            data={data}
          />
        </ChartPart>
      );
    }

    return null;
  };

  const cardName =
    type === "interests"
      ? t(`metrics.${provider}.${type}.title`) +
        ` ${t("card.others.audience").toLowerCase()}`
      : t(`metrics.${provider}.${type}.title`);

  return (
    <Card key={type} flexDirection="column" justifyContent="space-between">
      <CardPart
        flexDirection="column"
        justifyContent="center"
        alignItems="stretch"
        customWidth={CustomWidthE.full}
      >
        <FlexBox justifyContent="flex-start">
          <Heading
            headingType={HeadingTypeT.h2}
            heading={cardName}
            spaceBottom={0}
            fontWeight={600}
            color={theme.color.textGreyColor}
          />
        </FlexBox>
        <FlexBox
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          {value && !alternative_value && (
            <Heading
              headingType={
                alternative_value ? HeadingTypeT.h2 : HeadingTypeT.h1
              }
              heading={`${value}`}
              spaceBottom={0}
            />
          )}
        </FlexBox>
      </CardPart>
      {Graph && getChart(Graph)}
    </Card>
  );
};

export default GraphCardForExport;
