import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import Turbolinks from "turbolinks";
import Avatar from "../../../components/Avatar";
import FlexBox from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { getDateTimeDynamicOption } from "../../../helpers/date";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useFetch } from "../../../hooks/useFetch";
import { rem, theme } from "../../../styling/theme";
import { UnreadIndicatior } from "../../../ui/UnreadIndicator";
import { NotificationT } from "../types";

type WrapperStyleT = {
  read: boolean;
};

const Wrapper = styled(FlexBox)<WrapperStyleT>(({ read }) => ({
  width: "100%",
  padding: `${rem(10)} ${rem(16)}`,
  borderBottom: `1px solid ${theme.color.greyColor}`,
  backgroundColor: read ? theme.color.backgroundColor : theme.color.whiteColor,
  cursor: "pointer",

  [theme.media.maxSm]: {
    padding: `${rem(8)} ${rem(16)}`,
  },
}));

const InfoWrapper = styled(FlexBox)({
  width: "100%",
  marginTop: rem(15),
});

const ContentWrapper = styled(FlexBox)({
  position: "relative",
  flexGrow: 1,
  paddingLeft: rem(16),

  [theme.media.maxSm]: {
    paddingLeft: rem(8),
  },
});

const Header = styled.div({
  position: "relative",
  width: "100%",
  paddingRight: rem(14),
  marginBottom: rem(4),
});

type NotificationCardT = {
  notification: NotificationT;
};

const NotificationCard: FC<NotificationCardT> = ({ notification }) => {
  const { id, text, campaign, created_at, read, owner, path } = notification;
  const { name, campaign_brand } = campaign;

  const { call, payload, pending } = useFetch<Response>(
    getApiUrl(ApiRoutesE.NOTIFICATION_READ, id),
    undefined,
    "POST"
  );

  const [unread, setUnread] = useState<boolean>(!read);

  const getCardTitle = (): string => {
    const information: string[] = [];
    information.push(
      _.truncate(name, {
        length: 55,
      })
    );

    if (campaign_brand) {
      information.push(campaign_brand.name);
    }

    return information.join(" • ");
  };

  const handleOnClick = (): void => {
    if (!pending || unread) {
      call();
    }

    Turbolinks.visit(path);
  };

  React.useEffect(() => {
    if (payload && unread) {
      setUnread(false);
    }
  }, [payload]);

  return (
    <Wrapper onClick={handleOnClick} alignItems="flex-start" read={!unread}>
      <Avatar src={campaign.image_url} name={campaign.name} size={32} />
      <ContentWrapper flexDirection="column" alignItems="flex-start">
        <Header>
          <Paragraph
            paragraph={`Zpráva od: ${owner.nick_or_name}`}
            color={unread ? theme.color.textColor : theme.color.textGreyColor}
            fontWeight={unread ? 600 : 400}
            paragraphSize="small"
          />
          <Spacer size="micro" direction="vertical" />
          <Paragraph
            paragraph={`Kampaň: ${getCardTitle()}`}
            color={unread ? theme.color.textColor : theme.color.textGreyColor}
            fontWeight={unread ? 600 : 400}
            paragraphSize="small"
          />
        </Header>
        <Paragraph
          paragraph={text}
          color={unread ? theme.color.textColor : theme.color.textGreyColor}
          truncate={90}
        />
        {unread && <UnreadIndicatior />}
        <InfoWrapper justifyContent="flex-end">
          {created_at && (
            <Paragraph
              paragraph={getDateTimeDynamicOption(created_at)}
              paragraphSize="small"
              color={theme.color.textGreyColor}
            />
          )}
        </InfoWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

export default NotificationCard;
