import * as React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { MediaGroupsDataPropsT } from "../types";
import MainContainer from "./features/MainContainer";

export default ({ props, session }: MediaGroupsDataPropsT): JSX.Element => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <MainContainer headerData={props} role={session.role} />
    </QueryClientProvider>
  );
};
