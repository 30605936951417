import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import BlueLink from "../../../components/BlueLink";
import Button, { ButtonSizeE, ButtonTypeE } from "../../../components/Button";
import CheckBox from "../../../components/CheckBox";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Panel from "../../../components/Panel";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { getDateTime } from "../../../helpers/date";
import {
  FileTypeE,
  getFileExtension,
  getFileType,
} from "../../../helpers/fileType";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { RecordMediaT } from "../../types";

const ImageWrapper = styled(FlexBox)({
  minHeight: rem(350),
  borderRadius: rem(6),
  backgroundColor: theme.color.greyLightColor,
  overflow: "hidden",
});

const ButtonWrapper = styled(FlexBox)({
  gap: rem(10),
  marginBottom: rem(10),
});

const CheckBoxWrapper = styled(FlexBox)({
  gap: rem(8),
});

const NoPreview = styled(FlexBox)({
  padding: rem(24),
});

const Image = styled.img({
  width: "100%",
});

const Video = styled.video({
  width: "100%",
});

const ImageBox = styled.div({
  position: "relative",
});

const PaidPartnership = styled.div({
  backgroundColor: theme.color.yellowColor,
  padding: rem(5),
  borderRadius: rem(5),
  width: "fit-content",
  position: "absolute",
  bottom: rem(10),
  left: rem(10),
});

type MediaModalT = {
  medias: RecordMediaT[] | null;
};

const MediaModal: FC<MediaModalT> = ({ medias }) => {
  const [allPosts, setAllPosts] = useState<boolean>(true);
  const [allStories, setAllStories] = useState<boolean>(true);
  const [filteredMedias, setFilteredMedias] = useState<
    RecordMediaT[] | null | undefined
  >(medias);
  const { t } = useTranslate("crm.detail.brand_affinity");

  type getImagePreviewT = (url: string) => JSX.Element;
  const getImagePreview: getImagePreviewT = (url) => {
    const fileType = getFileType(getFileExtension(url));

    if (fileType === FileTypeE.video) {
      return (
        <Video src={`${url.replace("development", "production")}#t=0.3`} />
      );
    }

    return <Image src={`${url.replace("development", "production")}`} />;
  };

  const filterMedias = () => {
    if (allPosts && allStories) {
      setFilteredMedias(medias);
    } else if (!allPosts && !allStories) {
      setFilteredMedias(null);
    } else {
      const filtered = medias?.filter(
        (media) => media.kind === (allPosts ? "IgPost" : "IgStory")
      );
      setFilteredMedias(filtered);
    }
  };

  useEffect(() => {
    filterMedias();
  }, [allPosts, allStories, medias]);

  return (
    <>
      <ButtonWrapper justifyContent="stretch">
        <Button
          type={ButtonTypeE.grey}
          size={ButtonSizeE.small}
          customWidth={CustomWidthE.full}
          onClick={() => setAllPosts((prev) => !prev)}
        >
          <CheckBoxWrapper>
            <CheckBox hover={true} active={allPosts} onClickHandle={() => {}} />
            Posts
          </CheckBoxWrapper>
        </Button>
        <Button
          type={ButtonTypeE.grey}
          size={ButtonSizeE.small}
          customWidth={CustomWidthE.full}
          onClick={() => setAllStories((prev) => !prev)}
        >
          <CheckBoxWrapper>
            <CheckBox
              hover={true}
              active={allStories}
              onClickHandle={() => {}}
            />
            Stories
          </CheckBoxWrapper>
        </Button>
      </ButtonWrapper>
      {filteredMedias?.map((media) => {
        const { permalink, media_url, caption, timestamp, paid_partnership } =
          media;

        return (
          <FlexBox
            key={media.id}
            flexDirection="column"
            alignItems="flex-start"
          >
            <Panel marginBottom="default" padding={rem(4)} withShadow={true}>
              <ImageBox>
                {paid_partnership && (
                  <PaidPartnership>{t("paid")}</PaidPartnership>
                )}
                <ImageWrapper customWidth={CustomWidthE.full}>
                  {media_url ? (
                    getImagePreview(media_url)
                  ) : (
                    <NoPreview flexDirection="column">
                      <Icon
                        icon={IconTypeE.eyeStrike}
                        color={theme.color.textLightGreyColor}
                        size="big"
                      />
                      <Spacer direction="vertical" size="small" />
                      <Paragraph
                        paragraph={t(
                          permalink ? "missing_image" : "missing_image_short"
                        )}
                        color={theme.color.textLightGreyColor}
                        fontWeight={500}
                        align="center"
                      />
                    </NoPreview>
                  )}
                </ImageWrapper>
              </ImageBox>
            </Panel>
            <Paragraph paragraph={caption} align="left" />
            <Spacer direction="vertical" size="small" />
            <FlexBox
              customWidth={CustomWidthE.full}
              justifyContent="space-between"
            >
              <Paragraph
                paragraph={`${t("posted")}: ${getDateTime(
                  timestamp,
                  "shortDate"
                )}`}
                color={theme.color.textGreyColor}
              />
              {permalink && (
                <BlueLink
                  href={permalink}
                  text={t("link")}
                  target="_blank"
                  icon={IconTypeE.link}
                />
              )}
            </FlexBox>
            <Spacer direction="vertical" size={65} />
          </FlexBox>
        );
      })}
    </>
  );
};

export default MediaModal;
