import { QueryClient } from "react-query";
import { MediaToRemoveStateT } from "../../features/MediaGoupsContainer";
import { MediaGroupT, QueryStackT } from "../types";

type removeMediaGroupMutationT = (
  queryClient: QueryClient,
  mediaToRemove: MediaToRemoveStateT,
  afterMutationHandle: () => void,
  queryStack?: QueryStackT
) => void;

export const removeMediaGroupMutation: removeMediaGroupMutationT = (
  queryClient,
  mediaToRemove,
  afterMutationHandle,
  queryStack = "mediaGroups"
) => {
  queryClient.setQueryData<MediaGroupT[] | undefined>(
    queryStack,
    (prevState) => {
      if (prevState) {
        return [
          ...prevState.filter(
            (item) => item.id !== mediaToRemove?.media.reporting_media_group_id
          ),
        ];
      }
      return prevState;
    }
  );
  afterMutationHandle();
};
