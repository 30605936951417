import styled from "@emotion/styled";
import FlexBox, { AlignT, CustomWidthE } from "../../components/FlexBox";
import { rem } from "../../styling/theme";

const TableColumn = styled(FlexBox)<{
  columnWidth?: CustomWidthE | string;
  justifyContent?: AlignT;
}>(({ columnWidth = "40%", justifyContent = "flex-start" }) => ({
  padding: `${rem(10)} ${rem(16)}`,
  justifyContent,
  minWidth: columnWidth,
  width: columnWidth,
}));

export default TableColumn;
