import {
  SessionEmptyPossibleT,
  SessionPayloadT,
  SessionT,
} from "../entry-files/types";
import { ApiRoutesE, getApiUrl } from "../helpers/routes";

type fetchSessionInfoT = () => Promise<SessionT | SessionEmptyPossibleT>;
export const fetchSessionInfo: fetchSessionInfoT = async () => {
  const response = await fetch(getApiUrl(ApiRoutesE.SESSION));
  const data = (await (response.json() as Promise<SessionPayloadT>)).session;

  return data;
};
