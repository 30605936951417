import * as React from "react";
import { createContext, Dispatch, FC, SetStateAction } from "react";
import { LightBoxStateT } from "../components/LightBoxModal";

type LightBoxContextT = {
  setLightBoxState: Dispatch<SetStateAction<LightBoxStateT | undefined>>;
};

export const LightBoxContext = createContext<LightBoxContextT>({
  setLightBoxState: () => {},
});

export const LightBoxContextProvider: FC<LightBoxContextT> = ({
  setLightBoxState,
  children,
}) => (
  <LightBoxContext.Provider
    value={{
      setLightBoxState,
    }}
  >
    {children}
  </LightBoxContext.Provider>
);
