import {
  addButtonToTagsInputs,
  autoSwitcherOptions,
} from "./autoSwitcherOptions";

$(document).on("turbolinks:load", () => {
  const reportings = document.getElementById("reportings");

  if (reportings) {
    autoSwitcherOptions();

    window["fireTagsButton"] = function () {
      addButtonToTagsInputs();
    };
  }
});
