import * as React from "react";
import { FC } from "react";
import { useQuery } from "react-query";
import Container from "../../../components/Container";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { SessionT } from "../../types";
import { getInfluencerProfile } from "../data-access/getInfluencerProfile";
import { getProviderFromUrl } from "../utils/helpers";
import BrandAffinity from "./BrandAffinity";
import Cards from "./Cards";
import InfluencerProfile from "./InfluencerProfile";

type MainContainerT = {
  influencerId: number;
  session: SessionT;
};

const MainContainer: FC<MainContainerT> = ({ influencerId, session }) => {
  const provider = getProviderFromUrl();
  const apiUrl =
    getApiUrl(ApiRoutesE.NETWORK_PROFILE, influencerId) +
    `?provider=${provider}`;

  const { data } = useQuery("database-detail", () =>
    getInfluencerProfile(apiUrl)
  );

  const showBrandAffinity = provider === "instagram";
  const adminRole = session.roles.filter((role) => role === "admin").length > 0;

  return (
    <Container>
      <InfluencerProfile
        data={data}
        provider={provider}
        adminRole={adminRole}
      />
      <Cards provider={provider} cardsData={data ? data.cards : null} />
      {showBrandAffinity && <BrandAffinity influencerId={influencerId} />}
    </Container>
  );
};

export default MainContainer;
