import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import FlexBox from "../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../components/Heading";
import Icon from "../../../../components/Icon";
import LoaderComponent from "../../../../components/Loader";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import VisibleWrapper from "../../../../components/VisibleWrapper";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { usePagination } from "../../../../hooks/usePagination";
import { rem, theme } from "../../../../styling/theme";
import { StoryT } from "../../types";
import Story from "./Story";

const ItemsWrapper = styled(FlexBox)({
  position: "relative",
  maxHeight: rem(450),
  flexWrap: "wrap",
  overflow: "scroll",
  scrollbarWidth: "none",
  scrollBehavior: "smooth",

  ["&::-webkit-scrollbar"]: {
    display: "none",
  },

  [theme.media.maxSm]: {
    maxHeight: rem(250),
  },
});

const LoadMoreButton = styled(FlexBox)({
  padding: rem(25),
  color: theme.color.textGreyDarkColor,
  fontWeight: 600,
  cursor: "pointer",
});

const StyledVisibleWrapper = styled(VisibleWrapper)({
  width: "100%",
});

const NoContentWrapper = styled(FlexBox)({
  width: "100%",
  height: rem(70),
});

type StoriesT = {
  setStories: Dispatch<SetStateAction<string[]>>;
};

const Stories: FC<StoriesT> = ({ setStories }) => {
  const [showedStories, setShowedStories] = useState<StoryT[] | null>([]);

  const { queuedItems, loadItems } = usePagination<StoryT>(
    getApiUrl(ApiRoutesE.STORIES),
    15,
    setShowedStories,
    "stories"
  );

  const loadMoreStories = (): void => {
    loadItems();
  };

  type onStoryCheckT = (state: boolean, id: string) => void;
  const onStoryCheck: onStoryCheckT = (state, id) => {
    setStories((prevState) => {
      if (prevState) {
        if (state) {
          return [...prevState, id];
        }

        const index = prevState.findIndex((storyId) => storyId === id);
        if (index > -1) {
          prevState.splice(index, 1);
        }

        return [...prevState];
      }

      return [];
    });
  };

  const getContent = (): JSX.Element => {
    if (!!showedStories) {
      if (showedStories.length < 1) {
        return (
          <NoContentWrapper>
            <LoaderComponent size={65} />
          </NoContentWrapper>
        );
      }

      return (
        <>
          {showedStories.map((story) => {
            return (
              <Story key={story.id} story={story} onStoryCheck={onStoryCheck} />
            );
          })}

          <StyledVisibleWrapper visible={queuedItems.length > 0}>
            <LoadMoreButton onClick={loadMoreStories}>
              <Icon
                icon={IconTypeE.arrowDoubleDown}
                size="small"
                color={theme.color.textGreyDarkColor}
              />
              <Spacer size="micro" />
              <FlexBox>{"Načíst více"}</FlexBox>
            </LoadMoreButton>
          </StyledVisibleWrapper>
        </>
      );
    }

    return (
      <NoContentWrapper flexDirection="column">
        <Paragraph paragraph={"Žiadne stories"} color={theme.color.textColor} />
      </NoContentWrapper>
    );
  };

  return (
    <FlexBox position="relative" flexDirection="column" alignItems="stretch">
      <Heading
        heading={"Stories"}
        headingType={HeadingTypeT.h3}
        spaceBottom={0}
      />
      <ItemsWrapper justifyContent="stretch">{getContent()}</ItemsWrapper>
    </FlexBox>
  );
};

export default Stories;
