import * as React from "react";
import { FC } from "react";
import Turbolinks from "turbolinks";
import Avatar from "../../../components/Avatar";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { CrossPacksConts } from "../../../helpers/crossPacks";
import { useTranslate } from "../../../hooks/useTranslate";
import CardCover from "../../../ui/cards-ui/CardCover";
import CardSmallWrapper from "../../../ui/cards-ui/CardSmallWrapper";
import { Content } from "../../../ui/cards-ui/styles";
import { CampaignT } from "../../chat/types";

type CampaignCardSmallT = {
  campaign: CampaignT;
};

const CampaignCardSmall: FC<CampaignCardSmallT> = ({ campaign }) => {
  const { id, image_url, name, offer_path, campaign_brand } = campaign;

  const brandLogo = campaign_brand?.logo_url;
  const DETAIL_PATH = `/influencers/campaigns/${id}`;

  const { t } = useTranslate("influencer.market");

  const onClickHandle = (): void => {
    if (offer_path) {
      Turbolinks.visit(offer_path);
      return;
    }

    const modal = document.getElementById(
      "external-modal-trigger-preview-modal"
    );

    if (modal) {
      modal.dataset.url = DETAIL_PATH;
      const changeEvent = new Event(CrossPacksConts.EXTERNAL_MODAL);

      document.dispatchEvent(changeEvent);
    }
  };

  return (
    <CardSmallWrapper
      renderChildren={(wrapperWidth) => (
        <>
          <CardCover
            onClick={onClickHandle}
            image={image_url}
            wrapperWidth={wrapperWidth}
            labels={[t("labels.new")]}
          />
          <Content
            onClick={onClickHandle}
            flexDirection="column"
            justifyContent="space-between"
            customWidth={CustomWidthE.full}
          >
            <FlexBox
              flexDirection="column"
              alignItems="flex-start"
              customWidth={CustomWidthE.full}
            >
              <FlexBox
                justifyContent="flex-start"
                customWidth={CustomWidthE.full}
              >
                {brandLogo && (
                  <>
                    <Avatar name={name} src={brandLogo} size={48} />
                    <Spacer size="mini" />
                  </>
                )}
                <FlexBox>
                  <Paragraph paragraph={name} truncate={50} fontWeight={600} />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <Spacer direction="vertical" size={20} />
          </Content>
        </>
      )}
    />
  );
};

export default CampaignCardSmall;
