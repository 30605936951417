import { numberToText, roundNumber } from "../../../helpers/formaters";
import { SocialNetworkProviderT } from "../../../helpers/socialMediaProviders";
import AgeGender from "../charts-components/age-gender/AgeGender";
import AudienceReachibility from "../charts-components/audience-reachibility/AudienceReachibility";
import CreditableFollowers from "../charts-components/creditable-followers/CreditableFollowers";
import EngagementHistogram from "../charts-components/engagement-histogram/EngagementHistogram";
import Engagement from "../charts-components/engagement/Engagement";
import Geo from "../charts-components/geo/Geo";
import InterestsData from "../charts-components/interests/Interests";
import LineChart from "../charts-components/LineChart";
import { CardsMapT } from "../types";

const followersCard: CardsMapT = {
  type: "followers",
  element: LineChart,
  graphOptions: {
    callBackForYTicks: (v: any, i: any) =>
      i % 2 == 0 ? numberToText(Math.floor(v / 1000) * 1000) : "",
  },
};

const creditableFollowersCard: CardsMapT = {
  type: "credible_followers",
  element: CreditableFollowers,
  graphOptions: {},
};

const audienceReachabilityCard: CardsMapT = {
  type: "audience_reachability",
  element: AudienceReachibility,
  graphOptions: {},
};

const engagementHistogramCard: CardsMapT = {
  type: "engagement_histogram",
  element: EngagementHistogram,
  graphOptions: {},
};

const engagementRateCard: CardsMapT = {
  type: "engagement_rate",
  element: LineChart,
  graphOptions: {
    callBackForYTicks: (v) => `${roundNumber(v, 2)}%`,
    callBackForTooltipLabel: ({ formattedValue }) => `${formattedValue}%`,
  },
};

const engagementCard: CardsMapT = {
  type: "engagement",
  element: Engagement,
  graphOptions: {
    callBackForYTicks: (v: any, i: any) => (i % 2 == 0 ? numberToText(v) : ""),
  },
};

const accountsReachedCard: CardsMapT = {
  type: "accounts_reached",
  element: "without",
  graphOptions: {},
};

const impressionsCard: CardsMapT = {
  type: "impressions",
  element: "without",
  graphOptions: {},
};

const avgViewsCard: CardsMapT = {
  type: "avg_views",
  element: "without",
  graphOptions: {},
};

const avgReelsPlaysCard: CardsMapT = {
  type: "avg_reels_plays",
  element: "without",
  graphOptions: {},
};

const interestsCard: CardsMapT = {
  type: "interests",
  element: InterestsData,
  graphOptions: {},
};

const ageGenderCard: CardsMapT = {
  type: "age_gender",
  element: AgeGender,
  graphOptions: {},
};

const geoCard: CardsMapT = {
  type: "geo",
  element: Geo,
  graphOptions: {},
};

type getCardsMapT = (provider: SocialNetworkProviderT) => CardsMapT[] | null;
export const getCardsMap: getCardsMapT = (provider) => {
  switch (provider) {
    case "instagram":
      return [
        followersCard,
        creditableFollowersCard,
        audienceReachabilityCard,
        engagementRateCard,
        engagementHistogramCard,
        engagementCard,
        accountsReachedCard,
        impressionsCard,
        avgReelsPlaysCard,
        interestsCard,
        ageGenderCard,
        geoCard,
      ];

    case "youtube":
      return [
        followersCard,
        engagementRateCard,
        engagementHistogramCard,
        engagementCard,
        ageGenderCard,
        geoCard,
        impressionsCard,
        accountsReachedCard,
        avgViewsCard,
      ];

    case "facebook":
      return [
        followersCard,
        engagementRateCard,
        accountsReachedCard,
        engagementCard,
        ageGenderCard,
        geoCard,
      ];

    case "tiktok":
      return [
        followersCard,
        creditableFollowersCard,
        audienceReachabilityCard,
        engagementRateCard,
        engagementHistogramCard,
        engagementCard,
        accountsReachedCard,
        impressionsCard,
        avgViewsCard,
        interestsCard,
        ageGenderCard,
        geoCard,
      ];

    default:
      return null;
  }
};
