import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import Button, { ButtonTypeE } from "../../../../components/Button";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../components/Heading";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { useTranslate } from "../../../../hooks/useTranslate";
import { ParticipantToRemove } from "./Table";

type RemoveMediaModalT = {
  disabled: boolean;
  removeParticipantHandle: () => void;
  setParticipantToRemove: Dispatch<
    SetStateAction<ParticipantToRemove | undefined>
  >;
};

const RemoveMediaModal: FC<RemoveMediaModalT> = ({
  disabled,
  removeParticipantHandle,
  setParticipantToRemove,
}) => {
  const { t } = useTranslate("brands.reportings.participants");

  return (
    <FlexBox flexDirection="column" alignItems="stretch">
      <Heading
        headingType={HeadingTypeT.h3}
        heading={t("modal_remove_title")}
        spaceBottom={10}
      />
      <Paragraph paragraph={t("modal_remove_question")} />
      <Spacer size="big" direction="vertical" />
      <FlexBox customWidth={CustomWidthE.full}>
        <Button
          type={ButtonTypeE.darkGrey}
          customWidth={CustomWidthE.full}
          onClick={() => setParticipantToRemove(undefined)}
        >
          {t("buttons.modal_close")}
        </Button>
        <Spacer />
        <Button
          type={ButtonTypeE.error}
          customWidth={CustomWidthE.full}
          disabled={disabled}
          onClick={removeParticipantHandle}
        >
          {t("buttons.confrim_remove_influencer")}
        </Button>
      </FlexBox>
    </FlexBox>
  );
};

export default RemoveMediaModal;
