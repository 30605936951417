import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox from "../components/FlexBox";
import VisibleWrapper from "../components/VisibleWrapper";
import { rem, theme } from "../styling/theme";
import { CloseOverlay } from "./CloseOverlay";

type WrapperStyleT = {
  width: string;
};

export const DropdownAnchorWrapper = styled.a({
  width: "100%",
});

export const DropdownItem = styled(FlexBox)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  padding: `${rem(8)} ${rem(12)}`,
  backgroundColor: theme.color.whiteColor,
  transition: "all 0.3s",
  transitionTimingFunction: theme.timing.default,
  cursor: "pointer",

  ["&:hover"]: {
    backgroundColor: theme.color.backgroundColor,
  },
});

const Wrapper = styled(VisibleWrapper)<WrapperStyleT>(({ width }) => ({
  position: "absolute",
  top: "calc(100% + 3px)",
  right: 0,
  width,
  borderRadius: rem(8),
  background: theme.color.whiteColor,
  boxShadow: `0px 2px 9px rgb(0 0 0 / 10%)`,
  zIndex: 4,
  overflow: "hidden",
}));

type DropdownT = {
  active: boolean;
  width: string;
  setInactive?: () => void;
};

const Dropdown: FC<DropdownT> = ({ children, active, width, setInactive }) => (
  <>
    {active && setInactive && <CloseOverlay onClick={setInactive} />}
    <Wrapper visible={active} width={width} type="roll">
      {children}
    </Wrapper>
  </>
);

export default Dropdown;
