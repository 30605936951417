import styled from "@emotion/styled";
import * as React from "react";
import { FC, useState } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { useResponsive } from "../hooks/useResponsive";
import { rem, theme } from "../styling/theme";
import CircleButton from "./CircleButton";
import FlexBox from "./FlexBox";
import Icon from "./Icon";
import Panel from "./Panel";
import Paragraph from "./Paragraph";
import Spacer, { SpacerSizeT } from "./Spacer";
import VisibleWrapper from "./VisibleWrapper";

export const NoteContent = styled(FlexBox)({
  padding: `${rem(16)} ${rem(24)}`,
});

const CloseWrapper = styled(FlexBox)({
  position: "absolute",
  top: rem(15),
  right: rem(15),
  width: "min-width",
  cursor: "pointer",
});

type NoteT = {
  storageKey: string;
  closeText?: string;
  withIcon?: IconTypeE;
  iconBackground?: string;
  iconColor?: string;
  marginBottom?: SpacerSizeT;
};

const Note: FC<NoteT> = ({
  children,
  withIcon = undefined,
  iconBackground = theme.color.yellowColor,
  iconColor = theme.color.textColor,
  closeText,
  storageKey,
  marginBottom,
}) => {
  const noteStorageStatus = localStorage.getItem(storageKey);
  const [tmpHide, setTmpHide] = useState(!!noteStorageStatus);

  const { mobileVersion } = useResponsive();

  const onClickHandle = (): void => {
    localStorage.setItem(storageKey, "true");
    setTmpHide(true);
  };

  return (
    <VisibleWrapper visible={!noteStorageStatus || !tmpHide} type="roll">
      <Panel justifyContent="flex-start" marginBottom={marginBottom}>
        <NoteContent alignItems={mobileVersion ? "center" : "flex-start"}>
          {withIcon && (
            <>
              <CircleButton
                icon={withIcon}
                iconColor={iconColor}
                background={iconBackground}
                iconSize="small"
              />
              <Spacer />
            </>
          )}
          {children}
          {closeText ? (
            <>
              <Spacer />
              <CloseWrapper onClick={onClickHandle}>
                <Icon icon={IconTypeE.thumbs} />
                <Spacer size="mini" />
                <Paragraph
                  paragraph={closeText}
                  paragraphSize="small"
                  wordBreak="normal"
                  cursor="inherit"
                />
              </CloseWrapper>
            </>
          ) : (
            <CloseWrapper onClick={onClickHandle}>
              <Icon
                icon={IconTypeE.cross}
                color={theme.color.greyColor}
                size="mini"
              />
            </CloseWrapper>
          )}
        </NoteContent>
      </Panel>
    </VisibleWrapper>
  );
};

export default Note;
