import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import CheckBigIcon from "../../../components/CheckBigIcon";
import FlexBox from "../../../components/FlexBox";
import { useTranslate } from "../../../hooks/useTranslate";
import {
  contentTypes,
  mediaGroupLabelsIcons,
} from "../../reporting-media-groups/data-access/dataConstants";
import { MediaGroupKindT } from "../../reporting-media-groups/data-access/types";
import FilterSection from "../ui/FilterSection";

type FilterPostTypeT = {
  postTypeState: MediaGroupKindT[];
  setPostTypeState: Dispatch<SetStateAction<MediaGroupKindT[]>>;
};

const FilterPostType: FC<FilterPostTypeT> = ({
  postTypeState,
  setPostTypeState,
}) => {
  const { t } = useTranslate("brands.reportings");

  const setStateHandle = (key: MediaGroupKindT): void => {
    setPostTypeState((prevState) => {
      if (prevState) {
        const exists = prevState.some((item) => item === key);

        if (exists) {
          return prevState.filter((item) => item !== key);
        }

        return [...prevState, key];
      }

      return prevState;
    });
  };

  return (
    <FilterSection label={t("participants.filter.post_type")}>
      <FlexBox customWidth="100%" justifyContent="space-between">
        {contentTypes.map((type) => (
          <CheckBigIcon
            key={type}
            icon={mediaGroupLabelsIcons[type]}
            active={postTypeState.some((item) => item === type)}
            label={t(`labels.${type}`)}
            onClickHandle={() => setStateHandle(type)}
          />
        ))}
      </FlexBox>
    </FilterSection>
  );
};

export default FilterPostType;
