import { _o } from "./helpers";

type hideMessageT = (message: Element) => void;
const hideMessage: hideMessageT = (message) => {
  setTimeout(() => {
    message.classList.add("flash-message--hidden");
  }, 3000);

  setTimeout(() => {
    message.remove();
  }, 3700);
};

export const flashMessagesInit = (): void => {
  const flashMessages = _o(document.getElementsByClassName("flash-message"));

  if (flashMessages) {
    flashMessages.forEach((message) => {
      hideMessage(message);
    });
  }
};
