import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { IconTypeE } from "../../../../assets/icons/Icons";
import BlueLink from "../../../../components/BlueLink";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Icon from "../../../../components/Icon";
import Spacer from "../../../../components/Spacer";
import VisibleWrapper from "../../../../components/VisibleWrapper";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import { createMedia } from "../../data-access/createMedia";
import { createMediaMutation } from "../../data-access/mutations/createMediaMutation";
import { MediaT } from "../../data-access/types";

const HANDLES_HEIGHT = 24;

type LinePartStyleT = {
  precentageWidth: number;
  active: boolean;
};

const Wrapper = styled(FlexBox)({
  position: "absolute",
  width: `calc(100% - ${rem(24)})`,
  top: 0,
});

const HandlesWrapper = styled(FlexBox)({
  height: rem(HANDLES_HEIGHT),
  flex: 1,
});

const LinePart = styled(FlexBox)<LinePartStyleT>(
  ({ precentageWidth, active }) => ({
    width: `calc(${precentageWidth}% - ${rem(3)})`,
    margin: `0 ${rem(3)} 0 0`,
    height: rem(4),
    borderRadius: rem(2),
    backgroundColor: active
      ? theme.color.textGreyDarkColor
      : theme.color.greyColor,
    transition: "all 0.25s",
    transitionTimingFunction: theme.timing.default,
  })
);

const ArrowWrapper = styled(VisibleWrapper)({
  position: "absolute",
  top: 0,
  width: "100%",
  justifyContent: "space-between",
});

const Arrow = styled(FlexBox)({
  padding: rem(5),
  borderRadius: rem(4),
  backgroundColor: theme.color.textLightGreyColor,
  boxShadow: "0px 2px 9px rgb(0 0 0 / 10%)",
  transition: "all 0.25s",
  transitionTimingFunction: theme.timing.default,
  cursor: "pointer",
  ["&:hover"]: {
    backgroundColor: theme.color.textGreyColor,
  },
});

type StorySetHandlesT = {
  medias: MediaT[];
  mediaGroupId: number;
  mediaGroupParticipantId: string;
  currentCard: { index: number; id: number };
  setCurrentCard: Dispatch<SetStateAction<{ index: number; id: number }>>;
  addNewStoryAllow: boolean;
  liveAddedMediaGroup?: boolean;
};

const StorySetHandles: FC<StorySetHandlesT> = ({
  medias,
  mediaGroupId,
  mediaGroupParticipantId,
  currentCard,
  setCurrentCard,
  addNewStoryAllow,
  liveAddedMediaGroup,
}) => {
  const [hover, setHover] = useState<boolean>(false);
  const [mediasCount, setMediasCount] = useState<number>(medias.length);

  const { t } = useTranslate("brands.reportings.media_groups.buttons");

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(createMedia, {
    onSuccess: (data) => {
      createMediaMutation(
        queryClient,
        data,
        mediaGroupId,
        liveAddedMediaGroup ? "addedMediaGroups" : "mediaGroups"
      );
    },
  });

  const addStoryHandle = (): void => {
    if (!isLoading) {
      mutate({
        participantId: mediaGroupParticipantId,
        payload: {
          media: {
            kind: "story",
            reporting_media_group_id: mediaGroupId,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (mediasCount < medias.length) {
      setCurrentCard({
        index: medias.length - 1,
        id: medias[medias.length - 1].id,
      });
    }

    setMediasCount(medias.length);
  }, [medias]);

  return (
    <Wrapper
      justifyContent="space-between"
      flexDirection="row"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <HandlesWrapper position="relative">
        <ArrowWrapper visible={hover && mediasCount > 1}>
          <Arrow
            onClick={() =>
              setCurrentCard((prevState) =>
                prevState.index === 0
                  ? prevState
                  : {
                      index: prevState.index - 1,
                      id: medias[prevState.index - 1].id,
                    }
              )
            }
          >
            <Icon
              icon={IconTypeE.arrowLeft}
              size="mini"
              color={theme.color.whiteColor}
            />
          </Arrow>
          <Arrow
            onClick={() =>
              setCurrentCard((prevState) =>
                prevState.index + 1 === mediasCount
                  ? prevState
                  : {
                      index: prevState.index + 1,
                      id: medias[prevState.index + 1].id,
                    }
              )
            }
          >
            <Icon
              icon={IconTypeE.arrowRight}
              size="mini"
              color={theme.color.whiteColor}
            />
          </Arrow>
        </ArrowWrapper>
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          {medias.length > 1 &&
            medias.map((_, index) => (
              <LinePart
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                precentageWidth={100 / medias.length}
                active={currentCard.index === index}
              />
            ))}
        </FlexBox>
      </HandlesWrapper>
      {addNewStoryAllow && (
        <>
          <Spacer size="mini" />
          <BlueLink
            icon={IconTypeE.plus}
            onClick={addStoryHandle}
            text={t("add_story")}
            iconSize="mini"
            marginTop={rem(0)}
            paragraphSize="small"
          />
        </>
      )}
    </Wrapper>
  );
};

export default StorySetHandles;
