import * as React from "react";
import { FC } from "react";
import Paragraph from "../../../../components/Paragraph";
import { useTranslate } from "../../../../hooks/useTranslate";
import { theme } from "../../../../styling/theme";
import TableColumn from "../../../../ui/table/TableColumn";
import TableHeadWrapper from "../../../../ui/table/TableHeadWrapper";

const TableHead: FC<{}> = () => {
  const { t } = useTranslate("brands.reportings.participants.index");

  return (
    <TableHeadWrapper>
      <TableColumn columnWidth="35%">
        <Paragraph
          paragraph={t("table.influencer")}
          color={theme.color.textGreyColor}
          paragraphSize="small"
        />
      </TableColumn>
      <TableColumn columnWidth="10%" justifyContent="center">
        <Paragraph
          paragraph={t("table.ig_posts")}
          color={theme.color.textGreyColor}
          paragraphSize="small"
        />
      </TableColumn>
      <TableColumn columnWidth="10%" justifyContent="center">
        <Paragraph
          paragraph={t("table.ig_stories")}
          color={theme.color.textGreyColor}
          paragraphSize="small"
        />
      </TableColumn>
      <TableColumn columnWidth="10%" justifyContent="center">
        <Paragraph
          paragraph={t("table.ig_reels")}
          color={theme.color.textGreyColor}
          paragraphSize="small"
        />
      </TableColumn>
      <TableColumn columnWidth="10%" justifyContent="center">
        <Paragraph
          paragraph={t("table.ig_igtvs")}
          color={theme.color.textGreyColor}
          paragraphSize="small"
        />
      </TableColumn>
      <TableColumn columnWidth="25%" justifyContent="flex-end">
        <Paragraph
          paragraph={t("table.actions")}
          color={theme.color.textGreyColor}
          paragraphSize="small"
        />
      </TableColumn>
    </TableHeadWrapper>
  );
};

export default TableHead;
