import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

export type RemoveMediaImagePropsT = {
  id: number;
  participantId: string;
};

type removeMediaImageT = (props: RemoveMediaImagePropsT) => Promise<{}>;
export const removeMediaImage: removeMediaImageT = async ({
  id,
  participantId,
}) =>
  axios
    .delete(getApiUrl(ApiRoutesE.REPORTING_MEDIA_FILE, id, participantId))
    .then((res) => res.data);
