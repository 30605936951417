import styled from "@emotion/styled";
import * as React from "react";
import { FC, useEffect, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import NoResults from "../../../components/NoResults";
import Note from "../../../components/Note";
import Panel from "../../../components/Panel";
import Paragraph from "../../../components/Paragraph";
import ShowMore from "../../../components/ShowMore";
import Spacer from "../../../components/Spacer";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../features/Placeholders/PlaceholderLoader";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { usePagination } from "../../../hooks/usePagination";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem } from "../../../styling/theme";
import PostViewItem from "../components/PostViewItem";
import TableContentWrapper from "../components/TableContentWrapper";
import TableHead from "../components/TableHead";
import TableItem from "../components/TableItem";
import {
  FilterCountsT,
  FilterItemT,
  FilterSortingOptionT,
  InboxItemMetaT,
  InboxItemT,
} from "../types";
import EmptyInvited from "./EmptyInvited";
import OffersHeader from "./OffersHeader";
import TemporaryRow from "./TemporaryRow";

const Table = styled(Panel)({
  position: "relative",
  borderTop: "none",
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
});

const OverFlowScrollWrapper = styled(FlexBox)({
  overflow: "scroll",
});

const MinWidthWrapper = styled(FlexBox)({
  minWidth: rem(1200),
});

type OffersTableT = {
  campaignId: number;
  barter: boolean;
};

const OffersTable: FC<OffersTableT> = ({ campaignId, barter }) => {
  const API_URL_INBOX_ITEMS_BASE = getApiUrl(
    ApiRoutesE.INBOX_ITEMS,
    campaignId
  );
  const ITEMS_PER_PAGE = 25;

  const [reFetchHelper, setReFetchHelper] = useState(0);
  const [inboxItems, setInboxItems] = useState<InboxItemT[] | null>(null);
  const [selectedItems, setSelectedItems] = useState<InboxItemT[]>([]);
  const [filterCounts, setFilterCounts] = useState<FilterCountsT | null>(null);
  const [currentFilter, setCurrentFilter] = useState<FilterItemT>("choose");
  const [tableView, setTableView] = useState(true);
  const [withIgPosts, setWithIgPosts] = useState("");
  const [sortingState, setSortingState] = useState<FilterSortingOptionT | null>(
    null
  );

  const emptyInvitedCondition =
    currentFilter === "invited" &&
    filterCounts &&
    filterCounts["invited"] === 0;

  const sortBy = sortingState
    ? `&s=${sortingState.sortBy}&dir=${sortingState.sortOption}`
    : "";

  const apiUrl = `${API_URL_INBOX_ITEMS_BASE}?filter=${currentFilter}${sortBy}${withIgPosts}`;

  const { t } = useTranslate("brands.campaigns.detail");

  const { firstLoad, metaPayload, queuedItems, loadItems, pending } =
    usePagination<InboxItemT, InboxItemMetaT>(
      apiUrl,
      ITEMS_PER_PAGE,
      setInboxItems,
      "inbox_items",
      reFetchHelper
    );

  useEffect(() => {
    if (metaPayload) {
      const { filter_counts } = metaPayload;

      if (filter_counts) {
        setFilterCounts(() => filter_counts);
      }
    }
  }, [metaPayload]);

  useEffect(() => {
    if (!firstLoad) {
      setReFetchHelper((prevState) => prevState + 1);
    }
  }, [apiUrl, withIgPosts]);

  useEffect(() => {
    if (!firstLoad) {
      if (tableView && withIgPosts.length > 0) {
        setWithIgPosts("");
      }
    }
  }, [currentFilter, sortBy]);

  useEffect(() => {
    if (!firstLoad && withIgPosts.length == 0) {
      setWithIgPosts("&ig_posts=true");
    }
  }, [tableView]);

  useEffect(() => {
    setSelectedItems(() => []);
  }, [reFetchHelper]);

  const getContent = (): JSX.Element => {
    if (emptyInvitedCondition) {
      return (
        <TableContentWrapper>
          <EmptyInvited />
        </TableContentWrapper>
      );
    }

    if (filterCounts && filterCounts[currentFilter] === 0) {
      return (
        <TableContentWrapper>
          <NoResults illustrationSize={350} message={t("empty_folder")} />
        </TableContentWrapper>
      );
    }

    if (inboxItems && inboxItems.length > 0) {
      if (tableView) {
        return (
          <OverFlowScrollWrapper customWidth="100%" justifyContent="stretch">
            <MinWidthWrapper
              flexDirection="column"
              customWidth={CustomWidthE.full}
            >
              <TableHead
                sortingState={sortingState}
                setSortingState={setSortingState}
                inboxItems={inboxItems}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
              />

              <FlexBox flexDirection="column" customWidth={CustomWidthE.full}>
                {inboxItems.map((item, index) => {
                  const select = !!selectedItems.find(
                    (selectedItem) => selectedItem.id == item.id
                  );

                  return (
                    <TableItem
                      key={item.id}
                      item={item}
                      last={index === inboxItems.length - 1}
                      campaignId={campaignId}
                      currentFilter={currentFilter}
                      updateFilterCounts={setFilterCounts}
                      select={select}
                      updateSelectedItems={setSelectedItems}
                      invited={item.type === "CampaignInvitation"}
                    />
                  );
                })}
              </FlexBox>
            </MinWidthWrapper>
          </OverFlowScrollWrapper>
        );
      }

      return (
        <>
          {inboxItems.map((item) => {
            return (
              <PostViewItem key={item.id} item={item} campaignId={campaignId} />
            );
          })}
        </>
      );
    }

    return (
      <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
        <PlaceholderLoader
          type={PlaceholdersTypeE.table_item}
          count={5}
          direction="column"
        />
      </FlexBox>
    );
  };

  return (
    <>
      <OffersHeader
        filterCounts={filterCounts}
        currentFilter={currentFilter}
        setCurrentFilter={setCurrentFilter}
      />

      <TemporaryRow
        selectedItems={selectedItems}
        firstLoad={firstLoad}
        pending={pending}
        campaignId={campaignId}
        tableView={tableView}
        setReFetchHelper={setReFetchHelper}
        setTableView={setTableView}
        barter={barter}
      />

      <Table
        customWidth={CustomWidthE.full}
        flexDirection="column"
        marginBottom="mini"
      >
        {getContent()}
      </Table>

      <ShowMore loadItems={loadItems} queuedItems={queuedItems} />
    </>
  );
};

export default OffersTable;
