import { useCallback, useEffect, useState } from "react";

type useFetchReturnT<T> = {
  payload?: T;
  error?: {};
  pending: boolean;
  call: () => void;
};

export const useFetch = <T>(
  apiUrl: string,
  dependencies?: Array<any>,
  type?: "GET" | "POST" | "PUT" | "DELETE",
  body?: {}
): useFetchReturnT<T> => {
  const [payload, setPayload] = useState<T>();
  const [pending, setPending] = useState(!!dependencies);
  const [error, setError] = useState();

  const options: RequestInit = {
    method: type,
    headers: { "Content-Type": "application/json" },
    body: body ? JSON.stringify(body) : null,
  };

  const call = useCallback(() => {
    const execute = async (): Promise<void> => {
      setPending(true);

      try {
        const response = await fetch(apiUrl, options);
        const data = await response.json();

        if (!response.ok) {
          const error = (data && data.message) || response.status;
          setPending(false);
          setError(error);

          return;
        }

        setPending(false);
        setPayload(data);
      } catch {
        setPending(false);
        setError(error);
      }
    };
    execute();
  }, [apiUrl, body]);

  useEffect(() => {
    if (dependencies !== undefined) {
      call();
    }
  }, dependencies || []);

  return {
    payload,
    pending,
    error,
    call,
  };
};
