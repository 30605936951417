import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { MediaKindT, MediaT } from "./types";

type CreateMediaPropsT = {
  participantId: string;
  payload: {
    media: {
      kind: MediaKindT;
      reporting_media_group_id: number;
    };
  };
};

type CreateMediaPayloadT = {
  media: MediaT;
};

type createMediaT = (props: CreateMediaPropsT) => Promise<CreateMediaPayloadT>;
export const createMedia: createMediaT = async ({ participantId, payload }) =>
  axios
    .post(getApiUrl(ApiRoutesE.REPORTING_MEDIAS_POST, participantId), payload)
    .then((res) => res.data);
