import { QueryClient } from "react-query";
import { MediaToRemoveStateT } from "../../features/MediaGoupsContainer";
import { MediaGroupT, QueryStackT } from "../types";

type removeMediaMutationT = (
  queryClient: QueryClient,
  mediaToRemove: MediaToRemoveStateT,
  afterMutationHandle: () => void,
  queryStack?: QueryStackT
) => void;

export const removeMediaMutation: removeMediaMutationT = (
  queryClient,
  mediaToRemove,
  afterMutationHandle,
  queryStack = "mediaGroups"
) => {
  queryClient.setQueryData<MediaGroupT[] | undefined>(
    queryStack,
    (prevState) => {
      if (prevState) {
        const currentMediaGroupIndex = prevState.findIndex(
          (mediaGroup) =>
            mediaGroup.id === mediaToRemove.media.reporting_media_group_id
        );

        if (currentMediaGroupIndex > -1) {
          const currentMediaGroup = prevState[currentMediaGroupIndex];

          const newCurrentMediaGroup = {
            ...currentMediaGroup,
            medias: [
              ...currentMediaGroup.medias.filter(
                (item) => item.id !== mediaToRemove.media.id
              ),
            ],
          };
          prevState[currentMediaGroupIndex] = newCurrentMediaGroup;
        }
      }

      return prevState;
    }
  );
  afterMutationHandle();
};
