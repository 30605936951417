import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { MediaT, UpdatedMediaDatatT } from "./types";

type UpdatedMediaPropsT = {
  id: number;
  participantId: string;
  updatedMediaData: {
    media: UpdatedMediaDatatT;
  };
};

type UpdateMediaPayloadT = {
  media: MediaT;
};

type updateMediaT = (props: UpdatedMediaPropsT) => Promise<UpdateMediaPayloadT>;
export const updateMedia: updateMediaT = async ({
  id,
  updatedMediaData,
  participantId,
}) =>
  axios
    .put(
      getApiUrl(ApiRoutesE.REPORTING_MEDIAS_ITEM, id, participantId),
      updatedMediaData
    )
    .then((res) => res.data);
