import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, {
  AlignT,
  CustomWidthE,
  FlexDirectionT,
  FlexWrapT,
} from "../../components/FlexBox";
import LoaderComponent from "../../components/Loader";
import { rem, theme } from "../../styling/theme";
import CampaignCardPlaceholder from "./Items/CampaignCardPlaceholder";
import CampaignCardSmallPlaceholder from "./Items/CampaignCardSmallPlaceholder";
import CampaignDetailHeader from "./Items/CampaignDetailHeaderPlaceholder";
import ChartCardPlaceholder from "./Items/ChartCardPlaceholder";
import ChatMessagePlaceholder from "./Items/ChatMessagePlaceholder";
import ConversationCardPlaceholder from "./Items/ConversationCardPlaceholder";
import HeaderPanelPlaceholder from "./Items/HeaderPanelPlaceholder";
import NocitifcationCardPlaceholder from "./Items/NocitifcationCardPlaceholder";
import PostGalleryPlaceholder from "./Items/PostGalleryPlaceholder";
import TableItemPlaceholder from "./Items/TableItemPlaceholder";

type LineStyleT = {
  last?: boolean;
};

type AvatarSizeStyleT = {
  size?: number;
};

type CardWrapperStyleT = {
  withBorder?: boolean;
};

export const CardWrapper = styled(FlexBox)<CardWrapperStyleT>(
  ({ withBorder = true }) => ({
    padding: `${rem(16)} ${rem(24)}`,
    borderBottom: withBorder
      ? `1px solid ${theme.color.greyLightColor}`
      : "none",
  })
);

export const AnimationOverlay = styled.div({
  position: "absolute",
  width: "100%",
  height: "200%",
  background:
    "linear-gradient(70deg, rgba(255,255,255,0) 25%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 75%)",
  backgroundSize: "100% 100%",
  animation: "moving-gradient 1.5s ease infinite",
});

export const Avatar = styled.div<AvatarSizeStyleT>(({ size = 50 }) => ({
  width: rem(size),
  minWidth: rem(size),
  height: rem(size),
  marginRight: rem(12),
  borderRadius: "50%",
  backgroundColor: theme.color.greyLightColor,
}));

export const Line = styled.div<LineStyleT>(({ last = false }) => ({
  width: last ? "70%" : "100%",
  height: rem(12),
  marginBottom: last ? 0 : rem(20),
  marginRight: rem(16),
  backgroundColor: theme.color.greyLightColor,
}));

export const Button = styled.div<{ isSquare?: boolean }>(
  ({ isSquare = false }) => ({
    width: isSquare ? rem(40) : rem(130),
    height: rem(40),
    marginLeft: rem(8),
    borderRadius: rem(4),
    backgroundColor: theme.color.greyLightColor,
  })
);

export enum PlaceholdersTypeE {
  conversation_card = "conversation_card",
  notification_card = "notification_card",
  table_item = "table_item",
  table_item_no_avatar = "table_item_no_avatar",
  chat_message = "chat_message",
  post_gallery = "post_gallery",
  camapign_card = "camapign_card",
  camapign_card_small = "camapign_card_small",
  camapign_detail_header = "camapign_detail_header",
  chart_card = "chart_card",
  header_panel = "header_panel",
}

type PlaceholderLoaderT = {
  type: PlaceholdersTypeE;
  direction?: FlexDirectionT;
  flexWrap?: FlexWrapT;
  justifyContent?: AlignT;
  count?: number;
  withBorder?: boolean;
};

const PlaceholderLoader: FC<PlaceholderLoaderT> = ({
  type,
  direction = "row",
  justifyContent = "center",
  flexWrap = "wrap",
  count = 3,
  withBorder,
}) => {
  type getPlaceholderTypeT = (
    type: PlaceholdersTypeE,
    index: number
  ) => JSX.Element;
  const getPlaceholderType: getPlaceholderTypeT = (type, index) => {
    if (type === PlaceholdersTypeE.conversation_card) {
      return <ConversationCardPlaceholder key={index} />;
    }

    if (type === PlaceholdersTypeE.notification_card) {
      return <NocitifcationCardPlaceholder key={index} />;
    }

    if (type === PlaceholdersTypeE.chat_message) {
      return <ChatMessagePlaceholder key={index} index={index} count={count} />;
    }

    if (type === PlaceholdersTypeE.table_item) {
      return <TableItemPlaceholder key={index} withBorder={withBorder} />;
    }

    if (type === PlaceholdersTypeE.table_item_no_avatar) {
      return <TableItemPlaceholder key={index} withAvatar={false} />;
    }

    if (type === PlaceholdersTypeE.post_gallery) {
      return <PostGalleryPlaceholder key={index} count={count} />;
    }

    if (type === PlaceholdersTypeE.camapign_card) {
      return <CampaignCardPlaceholder key={index} />;
    }

    if (type === PlaceholdersTypeE.camapign_card_small) {
      return <CampaignCardSmallPlaceholder key={index} />;
    }

    if (type === PlaceholdersTypeE.camapign_detail_header) {
      return <CampaignDetailHeader key={index} />;
    }

    if (type === PlaceholdersTypeE.chart_card) {
      return <ChartCardPlaceholder key={index} />;
    }

    if (type === PlaceholdersTypeE.header_panel) {
      return <HeaderPanelPlaceholder key={index} />;
    }

    return <LoaderComponent key={index} />;
  };

  return (
    <FlexBox
      flexDirection={direction}
      justifyContent={justifyContent}
      flexWrap={flexWrap}
      customWidth={CustomWidthE.full}
      fullHeight={true}
    >
      {[...Array(count)].map((_, index) => {
        return getPlaceholderType(type, index);
      })}
    </FlexBox>
  );
};

export default PlaceholderLoader;
