import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { rem, theme } from "../styling/theme";
import Avatar from "./Avatar";
import FlexBox from "./FlexBox";
import Paragraph from "./Paragraph";
import Spacer from "./Spacer";

export enum AvatarSizeE {
  small = 32,
  default = 40,
}

type AvatarTileT = {
  name: string;
  imageUrl: string | null;
  handle?: string;
  avatarSize?: number;
  maxSize?: number | "auto";
};

type HandlesWrapperStyleT = {
  maxSize: number | "auto";
};

const HandlesWrapper = styled(FlexBox)<HandlesWrapperStyleT>(({ maxSize }) => ({
  maxWidth: typeof maxSize === "string" ? "auto" : rem(maxSize),
}));

const StyledA = styled.a({
  ["&:hover p"]: {
    cursor: "pointer",
    textDecoration: "underline",
  },
});

const AvatarTile: FC<AvatarTileT> = ({
  imageUrl,
  handle,
  avatarSize = AvatarSizeE.default,
  maxSize = "auto",
  name,
}) => {
  return (
    <FlexBox>
      <Avatar src={imageUrl} name={name} size={avatarSize} />
      <Spacer />
      <HandlesWrapper
        maxSize={maxSize}
        flexDirection="column"
        alignItems="flex-start"
      >
        <Paragraph paragraph={name} truncate="css" fontWeight={600} />
        {handle && (
          <StyledA href={`https://instagram.com/${handle}`}>
            <Paragraph
              paragraph={`@${handle}`}
              truncate="css"
              color={theme.color.textGreyColor}
            />
          </StyledA>
        )}
      </HandlesWrapper>
    </FlexBox>
  );
};

export default AvatarTile;
