import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import FlexBox from "../../../components/FlexBox";
import Spacer from "../../../components/Spacer";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";

const Item = styled.p<{ active: boolean }>(({ active }) => ({
  display: "block",
  position: "relative",
  fontSize: rem(18),
  fontWeight: active ? 600 : 400,
  transition: "font-weight 0.05s",
  transitionTimingFunction: theme.timing.default,
  cursor: "pointer",

  ["&::after"]: {
    content: '" "',
    position: "absolute",
    display: "block",
    bottom: rem(-8),
    height: rem(2),
    width: active ? "100%" : 0,
    backgroundColor: theme.color.yellowColor,
    transition: "all 0.2s",
    transitionTimingFunction: theme.timing.default,
  },
}));

type SectionsSwitcherT = {
  section: "participants" | "outputs";
  setSection: Dispatch<SetStateAction<"participants" | "outputs">>;
};

const SectionsSwitcher: FC<SectionsSwitcherT> = ({ section, setSection }) => {
  const { t } = useTranslate("brands.reportings.participants.index");

  return (
    <FlexBox justifyContent="space-between" customWidth={rem(200)}>
      <Item
        onClick={() => setSection("participants")}
        active={section === "participants"}
      >
        {t("dashboard.title")}
      </Item>
      <Spacer />
      <Item
        onClick={() => setSection("outputs")}
        active={section === "outputs"}
      >
        {t("feed.title")}
      </Item>
    </FlexBox>
  );
};

export default SectionsSwitcher;
