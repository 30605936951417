import _ from "lodash";
import { isSafari } from "./browserCheck";
import { _o } from "./helpers";

const dropHandle = (e: DragEvent, element: HTMLLabelElement): void => {
  e.preventDefault();
  e.stopPropagation();

  element.classList.remove("highlighted");

  const { htmlFor } = element;
  const input = document.getElementById(htmlFor) as HTMLInputElement;
  const eventFiles = e.dataTransfer && e.dataTransfer.files;

  if (input.files && eventFiles) {
    setTimeout(() => {
      input.files = eventFiles;
      const changeEvent = new Event("change");
      input.dispatchEvent(changeEvent);
    }, 500);
  }
};

export const dragAndDropInit = (): void => {
  const dragDropAreas = _o(document.getElementsByClassName("drag-drop-area"));
  const isSafariBrowser = isSafari();

  if (!isSafariBrowser && dragDropAreas) {
    dragDropAreas.forEach((areaElement) => {
      const area = areaElement as HTMLLabelElement;

      area.addEventListener(
        "dragenter",
        () => area.classList.add("highlighted"),
        false
      );
      area.addEventListener(
        "dragleave",
        () => area.classList.remove("highlighted"),
        false
      );
      area.addEventListener(
        "dragover",
        _.throttle(() => {}, 1000),
        false
      );
      area.addEventListener("drop", (e) => dropHandle(e, area), false);
    });
  }
};
