import * as React from "react";
import { FC } from "react";
import {
  getIllustration,
  IllustrationTypeE,
} from "../../../assets/illustrations/Illustrations";
import Button from "../../../components/Button";
import Heading, { HeadingTypeT } from "../../../components/Heading";
import { IllustrationWrapper } from "../../../components/NoResults";
import Paragraph from "../../../components/Paragraph";
import Spacer from "../../../components/Spacer";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem } from "../../../styling/theme";

const EmptyInvited: FC<{}> = () => {
  const { t } = useTranslate("brands.campaigns.detail.invited");

  return (
    <>
      <IllustrationWrapper size={rem(300)}>
        {getIllustration(IllustrationTypeE.mobile_device)}
      </IllustrationWrapper>

      <Heading
        heading={t("title")}
        headingType={HeadingTypeT.h2}
        spaceBottom={"default"}
      />
      <Paragraph paragraph={t("paragraph")} align="center" />
      <Spacer direction="vertical" />
      <Button href="/crm/influencers">{t("button")}</Button>
    </>
  );
};

export default EmptyInvited;
