import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import { rem } from "../../../styling/theme";
import { AnimationOverlay, Line } from "../PlaceholderLoader";

const Wrapper = styled(FlexBox)({
  position: "relative",
  width: "100%",
  borderRadius: rem(16),
  overflow: "hidden",
});

const CampaignDetailHeaderPlaceholder: FC<{}> = () => (
  <Wrapper>
    <FlexBox customWidth={CustomWidthE.full}>
      <AnimationOverlay />
      <FlexBox customWidth={CustomWidthE.full} alignItems="center">
        <FlexBox customWidth={CustomWidthE.full} alignItems="flex-start">
          <Line last={true} />
          <Line last={true} />
          <Line last={true} />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  </Wrapper>
);

export default CampaignDetailHeaderPlaceholder;
