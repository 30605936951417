import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Input from "../../../components/Input";
import Spacer from "../../../components/Spacer";
import { getQuery } from "../../../data-access/getQuery";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { rem, theme } from "../../../styling/theme";
import LineLoadingIndicator from "../../reporting-media-groups/ui/LineLoadingIndicator";
import { OfferStateE } from "../types";
import { CHAT_HEADER_HEIGHT } from "./CampaignDetail";
import Filter from "./Filter";

export const OFFERS_SEARCH_STORAGE_KEY = "OFFERS_SEARCH";
export const OFFERS_FILTER_STORAGE_KEY = "OFFERS_FILTER";

const Wrapper = styled(FlexBox)({
  position: "relative",
  width: "100%",
  padding: `0 ${rem(16)}`,
  minHeight: rem(CHAT_HEADER_HEIGHT),
  borderBottom: `1px solid ${theme.color.greyColor}`,

  [theme.media.maxSm]: {
    padding: `0 ${rem(8)}`,
  },
});

const SearchWrapper = styled(FlexBox)({
  width: "100%",
  maxWidth: `calc(100% - ${140}px)`,
});

const DynamicIconWrapper = styled.label({
  position: "relative",
  display: "flex",
  width: "100%",
  margin: 0,
  padding: `${rem(8)} 0 ${rem(8)} ${rem(8)}`,
  cursor: "pointer",

  [theme.media.maxSm]: {
    width: "auto",
  },
});

const StyledIcon = styled(Icon)({
  zIndex: 1,
});

const SearchInput = styled(Input)({
  paddingBottom: 0,
  paddingTop: 0,
  paddingLeft: 0,
  flex: 1,
  cursor: "pointer",

  [theme.media.maxSm]: {
    width: rem(250),
  },

  [theme.media.maxMi]: {
    width: rem(135),
  },
});

const LoadingWrapper = styled(FlexBox)({
  position: "absolute",
  width: "100%",
  bottom: 0,
  left: 0,
});

type InputFocusedStyle = {
  searchFocus: boolean;
};

const Bubble = styled.div<InputFocusedStyle>(({ searchFocus }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: searchFocus ? "100%" : rem(36),
  height: rem(36),
  borderRadius: rem(16),
  backgroundColor: theme.color.greyLightColor,
  transition: "all 0.4s",
  transitionTimingFunction: theme.timing.default,
  zIndex: 0,
}));

type getUpdatedUrlT = (search: string, filter: OfferStateE[]) => string;
export const getUpdatedUrl: getUpdatedUrlT = (search, filter) => {
  const API_URL_OFFERS = getApiUrl(ApiRoutesE.OFFERS);

  if (search.length > 0 || filter.length > 0) {
    const query = getQuery({
      campaign_unacc_name_or_campaign_campaign_brand_name_cont: [search],
      state_matches_any: filter as string[],
    });

    return `${API_URL_OFFERS}?${query}`;
  }

  return API_URL_OFFERS;
};

type OffersFiltersBarT = {
  pendingIndicator: boolean;
  setApiUrl: Dispatch<SetStateAction<string>>;
  filteredState: OfferStateE[];
  setFilteredState: Dispatch<SetStateAction<OfferStateE[]>>;
  searchString: string;
  setSearchString: Dispatch<SetStateAction<string>>;
};

const OffersFiltersBar: FC<OffersFiltersBarT> = ({
  pendingIndicator,
  setApiUrl,
  filteredState,
  setFilteredState,
  searchString,
  setSearchString,
}) => {
  const SEARCH_INPUT_ID = "SEARCH_INPUT_ID";

  const [searchFocus, setSearchFocus] = useState(false);

  const getUpdatedUrlHandle = React.useCallback(
    _.debounce(
      (search, filter) => setApiUrl(() => getUpdatedUrl(search, filter)),
      1000
    ),
    []
  );

  useEffect(() => {
    getUpdatedUrlHandle(searchString, filteredState);
  }, [searchString, filteredState]);

  useEffect(() => {
    localStorage.setItem(OFFERS_FILTER_STORAGE_KEY, filteredState.join(","));
  }, [filteredState]);

  useEffect(() => {
    localStorage.setItem(OFFERS_SEARCH_STORAGE_KEY, searchString);
  }, [searchString]);

  return (
    <Wrapper justifyContent="space-between" alignItems="center">
      <LoadingWrapper>
        <LineLoadingIndicator visible={pendingIndicator} />
      </LoadingWrapper>

      <SearchWrapper justifyContent="flex-start">
        <DynamicIconWrapper htmlFor={SEARCH_INPUT_ID}>
          <Bubble searchFocus={searchFocus || searchString.length > 0} />
          <StyledIcon icon={IconTypeE.magnifyingGlass} />
          <Spacer size="small" />
          <SearchInput
            value={searchString}
            setValue={setSearchString}
            id={SEARCH_INPUT_ID}
            placeholder={"Hledat"}
            clearButton={true}
            onBlur={() => setSearchFocus(false)}
            onFocus={() => setSearchFocus(true)}
          />
        </DynamicIconWrapper>
      </SearchWrapper>

      <Filter
        filteredState={filteredState}
        setFilteredState={setFilteredState}
      />
    </Wrapper>
  );
};

export default OffersFiltersBar;
