import * as React from "react";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { numberToText } from "../../../../javascripts/v2/helpers";
import AvatarTile from "../../../components/AvatarTile";
import CheckBox from "../../../components/CheckBox";
import CheckHeart from "../../../components/CheckHeart";
import Paragraph from "../../../components/Paragraph";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";
import { useFetch } from "../../../hooks/useFetch";
import { theme } from "../../../styling/theme";
import TableItemWrapper from "../../../ui/table/TableItemWrapper";
import {
  FilterCountsT,
  FilterItemT,
  InboxItemPayloadT,
  InboxItemT,
} from "../types";
import {
  ActionsColumn,
  FollowsColumn,
  InfoColumn,
  InfoColumnAnchor,
  MessageColumn,
  PriceColumn,
  Select,
  UserColumn,
} from "./TableHead";

type TableItemT = {
  item: InboxItemT;
  campaignId: number;
  last: boolean;
  currentFilter: FilterItemT;
  invited: boolean;
  updateFilterCounts: Dispatch<SetStateAction<FilterCountsT | null>>;
  select: boolean;
  updateSelectedItems: Dispatch<SetStateAction<InboxItemT[]>>;
};

const TableItem: FC<TableItemT> = React.memo(
  ({
    item,
    campaignId,
    last,
    currentFilter,
    updateFilterCounts,
    select,
    updateSelectedItems,
    invited,
  }) => {
    const {
      id,
      handle,
      message,
      user,
      read,
      total_people_blog,
      total_people_yt,
      total_people_fb,
      total_people_ig,
      price,
      starred,
    } = item;

    const [localStarred, setLocalstarred] = useState(starred);

    const API_URL_INBOX_ITEMS = getApiUrl(ApiRoutesE.INBOX_ITEMS, campaignId);

    const body = {
      offer: {
        stars: +localStarred,
      },
    };

    const { payload, pending, call } = useFetch<InboxItemPayloadT>(
      `${API_URL_INBOX_ITEMS}/${id}`,
      undefined,
      "PUT",
      body
    );

    useEffect(() => {
      if (payload) {
        const {
          meta: { filter_counts },
        } = payload;

        updateFilterCounts(() => filter_counts);
      }
    }, [payload]);

    const didMount = useRef(false);
    useEffect(() => {
      if (didMount.current) {
        call();

        return;
      }

      didMount.current = true;
    }, [localStarred]);

    const updateFilterCountsHandle = (): void => {
      updateSelectedItems((prevState) => {
        if (prevState) {
          const itemIndex = prevState.findIndex((item) => item.id === id);

          if (itemIndex > -1) {
            prevState.splice(itemIndex, 1);

            return [...prevState];
          }

          return [...prevState, item];
        }

        return [item];
      });
    };

    const getColumns = (): JSX.Element => {
      return (
        <>
          <UserColumn>
            <AvatarTile
              imageUrl={user.avatar_url}
              name={user.nick_or_name}
              handle={handle}
              maxSize={190}
            />
          </UserColumn>
          <MessageColumn>
            <Paragraph
              paragraph={message}
              fontWeight={read ? 400 : 600}
              truncate="css"
            />
          </MessageColumn>
          <FollowsColumn>
            {total_people_ig > 10000
              ? numberToText(Math.floor(total_people_ig / 1000) * 1000)
              : total_people_ig}
          </FollowsColumn>
          <FollowsColumn>
            {total_people_yt > 10000
              ? numberToText(Math.floor(total_people_yt / 1000) * 1000)
              : total_people_yt}
          </FollowsColumn>
          <FollowsColumn>
            {total_people_fb > 10000
              ? numberToText(Math.floor(total_people_fb / 1000) * 1000)
              : total_people_fb}
          </FollowsColumn>
          <FollowsColumn>
            {total_people_blog > 10000
              ? numberToText(Math.floor(total_people_blog / 1000) * 1000)
              : total_people_blog}
          </FollowsColumn>
          <PriceColumn>{price}</PriceColumn>
        </>
      );
    };

    return (
      <TableItemWrapper
        selected={select}
        temporaryHide={currentFilter === "starred" && !localStarred}
        last={last}
        renderChildren={(visible) => (
          <>
            <ActionsColumn>
              <Select>
                <CheckBox
                  active={select}
                  onClickHandle={updateFilterCountsHandle}
                />
              </Select>
              <Select>
                <CheckHeart
                  visible={visible}
                  active={localStarred}
                  lightColor={theme.color.greyColor}
                  onClickHandle={() =>
                    setLocalstarred((prevState) =>
                      pending ? prevState : !prevState
                    )
                  }
                />
              </Select>
            </ActionsColumn>
            {!invited ? (
              <InfoColumnAnchor
                target="_blank"
                href={`/brands/campaigns/${campaignId}/offers/${id}`}
              >
                {getColumns()}
              </InfoColumnAnchor>
            ) : (
              <InfoColumn>{getColumns()}</InfoColumn>
            )}
          </>
        )}
      ></TableItemWrapper>
    );
  }
);

export default TableItem;
