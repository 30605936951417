require("jquery-ui/ui/widgets/datepicker");
require("jquery-ui/ui/widgets/autocomplete");
require("jquery-ui/ui/i18n/datepicker-cs");
require("../datepicker");

$(document).on("click", ".popup", function (e) {
  $(this).toggleClass("is-popup-visible");
});

$(document).on("click", ".js-tab-link", function (e) {
  e.preventDefault();
  var $this = $(this),
    $closest = $this.closest(".js-tabs"),
    $links = $closest.find(".js-tab-link"),
    $tabs = $closest.find(".js-tabs__content");

  $links.removeClass("is-link-active");
  $tabs.removeClass("is-tab-active");

  $this.addClass("is-link-active");
  $tabs.filter($this.attr("href")).addClass("is-tab-active");
  return false;
});

$(document).on("change", ".list_select", function (e) {
  e.preventDefault();
  if ($(this).val() == "") {
    $("#list_name_field").show();
  } else {
    $("#list_name_field").hide();
  }
});

$(document).on("click", ".crm-filter-trigger", function (e) {
  e.preventDefault();
  $(this).parent().toggleClass("is-filter-open");
  $(this).siblings().slideToggle();
});

$(document).on("click", "#open_filter", function (e) {
  e.preventDefault();
  $("body").addClass("side-panel-visible");
  return false;
});

$(document).on("click", "#close_filter", function (e) {
  e.preventDefault();
  $("body").removeClass("side-panel-visible");
  return false;
});

$(document).on("click", "#submit_crm_form", function (e) {
  e.preventDefault();
  var form = $(this).closest("form");
  var inf_ids = $('input[name="influencer_ids[]"]').serialize();

  $.ajax({
    url: form.attr("action"),
    type: form.attr("method"),
    dataType: "script",
    data: form.serialize() + "&" + inf_ids,
  });

  return false;
});

$(document).on("click", ".crm_checkbox", function (e) {
  var selected = $(".crm_checkbox:checked").length;
  if ($(this).is(":checked")) {
    $(this).closest("tr").addClass("js-selected");
  } else {
    $(this).closest("tr").removeClass("js-selected");
  }
  if ($(".crm_checkbox").is(":checked")) {
    $("#crm_form").show();
  } else {
    $("#crm_form").hide();
  }
  $("#crm_ch_selected").html(selected);
  if (selected > 0) {
    $(".crm-mass-actions").addClass("crm-mass-actions--open");
  } else {
    $(".crm-mass-actions").removeClass("crm-mass-actions--open");
  }
});

$(document).on("change", "input.checkbox-filter", function () {
  return $(this)
    .closest(".radio-group")
    .find(".checkbox-filter")
    .not(this)
    .prop("checked", false);
});

$(document).on("change", ".filter_countries .inp-select", function () {
  setOptions();
});

const showListView = (view) => {
  $(".view-switcher").removeClass("active");
  $(`.view-switcher-${view}`).addClass("active");
  $(".view-tab").removeClass("active");
  $(`.view-tab.profile-${view}`).addClass("active");
  $(".view-tab").addClass("hidden");
  $(`.view-tab.profile-${view}`).removeClass("hidden");
};

const localStorageViewKey = "CRM_LIST_VIEW";
$(document).on("click", ".crm-page .view-switcher", function () {
  const view = $(this).data("view");
  showListView(view);
  localStorage.setItem(localStorageViewKey, view);
});

jQuery(function () {
  if ($("#crm_search").length) {
    return ($("#crm_search")
      .autocomplete({
        source: $("#crm_search").data("autocomplete-source"),
        minLength: 3,
        open: function (event, ui) {
          $("#crm_search_div").addClass("is-suggestion-open");
        },
        response: function (event, ui) {
          $(".search-bar-suggestion-item").remove();
        },
        // close: function( event, ui ) {
        //     $('#crm_search_div').removeClass('is-suggestion-open');
        // }
      })
      .autocomplete("instance")._renderItem = function (ul, item) {
      var provider = $(".crm-page #provider-input").val();
      return $('<div class="search-bar-suggestion-item">')
        .append(
          '<a target="_blank" href="/crm/influencers/' +
            item.id +
            '?provider=' + provider +
            '" class="search-bar-suggestion-link"><div class="flex-box"><div class="profile-avatar small-margin-right">' +
            "<img src='" +
            item.avatar_url +
            '\'/></div><div class="profil-card-bio truncate"><span class="weight-500 truncate">' +
            item.name +
            "</span>" +
            "</div></div></a>"
        )
        .appendTo("#autocomplete_wrapper");
    });
  }
});

if ($(".crm-page").length > 0) {
  let view = localStorage.getItem(localStorageViewKey);
  if (window.MOBILE_VERSION) {
    view = "cards";
  }
  const activate = $(".view-tab.active").data("view");

  if (view != undefined && view != activate) {
    showListView(view);
  }
}
