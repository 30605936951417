import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { IconTypeE } from "../../../../assets/icons/Icons";
import CircleButton from "../../../../components/CircleButton";
import FlexBox from "../../../../components/FlexBox";
import Heading, { HeadingTypeT } from "../../../../components/Heading";
import Icon from "../../../../components/Icon";
import Paragraph from "../../../../components/Paragraph";
import Spacer from "../../../../components/Spacer";
import { rem, theme } from "../../../../styling/theme";
import { LinkCheckT } from "../../types";
import Link from "./Link";

const Wrapper = styled(FlexBox)({
  position: "relative",
  marginBottom: rem(4),

  [theme.media.maxMd]: {
    marginLeft: rem(10),
  },
});

const AddMoreButtonWrapper = styled.div({
  display: "inline-block",
  width: "max-content",
});

const AddMoreButton = styled(FlexBox)({
  position: "relative",
  cursor: "pointer",
});

export type LinkItemT = {
  id: string;
  link: string | null;
};

type LinksT = {
  offerId: number;
  links: LinkItemT[];
  setLinks: Dispatch<SetStateAction<LinkItemT[]>>;
  addLink: () => void;
  linksCheckState: LinkCheckT[];
};

const Links: FC<LinksT> = ({
  offerId,
  links,
  setLinks,
  addLink,
  linksCheckState,
}) => {
  const [hover, setHover] = useState<string>("");

  useEffect(() => {
    if (links.length < 1) {
      addLink();
    }
  }, [links]);

  return (
    <FlexBox flexDirection="column" alignItems="stretch">
      <Heading
        heading={"Odkazy"}
        headingType={HeadingTypeT.h3}
        spaceBottom="small"
      />
      <FlexBox alignItems="flex-start">
        <Icon
          icon={IconTypeE.infoCircle}
          size="default"
          color={theme.color.primaryColor}
        />
        <Spacer size="mini" />
        <Paragraph
          paragraph={
            "V případě Reels/IGTV zkopíruj odkaz ze záložky Reels/IGTV. Správný odkaz poznáš tak, že obsahuje část 'instagram.com/<b>reel</b>/ nebo /<b>tv</b>/'."
          }
          color={theme.color.textGreyColor}
        />
      </FlexBox>
      <Spacer size="small" direction="vertical" />
      {links.map((link) => {
        const checkLink = linksCheckState.find(
          (checkLink) => checkLink.object_id === link.id
        );

        return (
          <Wrapper
            key={link.id}
            onMouseEnter={() => setHover(link.id)}
            onMouseLeave={() => setHover("")}
          >
            <Link
              offerId={offerId}
              id={link.id}
              setLinks={setLinks}
              hover={hover === link.id}
              links={links}
              checkLink={checkLink}
            />
          </Wrapper>
        );
      })}

      <Spacer size="mini" direction="vertical" />
      <AddMoreButtonWrapper>
        <AddMoreButton onClick={addLink} justifyContent="stretch">
          <CircleButton
            icon={IconTypeE.plus}
            iconSize="small"
            background={theme.color.backgroundColor}
          />
          <Spacer size="micro" />
          <Paragraph paragraph={"Přidat další odkaz"} fontWeight={600} />
        </AddMoreButton>
      </AddMoreButtonWrapper>
    </FlexBox>
  );
};

export default Links;
