import * as React from "react";
import ReactDOM from "react-dom";
import { _o } from "../javascripts/v2/helpers";
import App from "../react/App";
import "../react/styling/globalStyles.css";

document.addEventListener("turbolinks:load", () => {
  const appElements = _o(document.getElementsByClassName("react-app"));

  appElements.forEach((app) => {
    if (app) {
      const { data, component, loader, translations } = app.dataset;

      if (process.env.NODE_ENV === "development" || component) {
        ReactDOM.render(
          <App
            id={component}
            props={data ? JSON.parse(data) : null}
            translations={translations ? JSON.parse(translations) : null}
            loader={loader}
          />,
          app
        );
      }

      app.removeAttribute("data-translations");
      app.removeAttribute("data-data");
      app.removeAttribute("data-no-loader");
      app.removeAttribute("data-component");
    }
  });
});
