import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { rem, theme } from "../styling/theme";
import FlexBox, { CustomWidthE } from "./FlexBox";
import { RemoveInputStyles } from "./GeneralStyles";
import Icon from "./Icon";

type InputStyleT = {
  disabled: boolean;
  rightBigPadding: boolean;
};

const StyledInput = styled.input<InputStyleT>(
  ({ disabled, rightBigPadding }) => ({
    ...RemoveInputStyles,
    fontSize: rem(15),
    margin: 0,
    padding: rem(12),
    paddingRight: rightBigPadding ? rem(12 + 20 + 8) : rem(12),
    color: disabled ? theme.color.textGreyColor : theme.color.textColor,
    transition: "all 0.4s",
    transitionTimingFunction: theme.timing.default,
    zIndex: 1,
  })
);

const ClearButton = styled(FlexBox)({
  position: "absolute",
  right: rem(12),
  zIndex: 1,
});

type InputT = {
  value: string | number;
  setValue?: Dispatch<SetStateAction<string>>;
  name?: string;
  type?: "text";
  id?: string;
  clearButton?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  onBlur?: () => void;
  onFocus?: () => void;
};

const Input: FC<InputT> = ({
  value,
  setValue = () => {},
  name,
  type = "text",
  id,
  clearButton = false,
  disabled = false,
  placeholder,
  className,
  onBlur,
  onFocus,
}) => {
  type clearValueT = () => void;
  const clearValue: clearValueT = () => {
    setValue(() => "");
  };

  type handleOnChangeT = (value: string) => void;
  const handleOnChange: handleOnChangeT = (value) => {
    setValue(() => value);
  };

  return (
    <FlexBox customWidth={CustomWidthE.full} position="relative">
      <StyledInput
        id={id}
        name={name}
        type={type}
        value={value}
        disabled={disabled}
        onChange={(e) => handleOnChange(e.target.value)}
        rightBigPadding={clearButton}
        placeholder={placeholder}
        className={className}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      {clearButton && !disabled && (
        <ClearButton onClick={clearValue}>
          <Icon
            visible={!!value}
            icon={IconTypeE.crossCircle}
            color={theme.color.textGreyColor}
          />
        </ClearButton>
      )}
    </FlexBox>
  );
};

export default Input;
