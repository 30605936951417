import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import { rem, theme } from "../../../styling/theme";
import { AnimationOverlay, Line } from "../PlaceholderLoader";

const CampaignCardCover = styled(FlexBox)({
  height: "55%",
  backgroundColor: theme.color.greyLightColor,
});

const CampaignCardContent = styled(FlexBox)({
  height: "45%",
  padding: rem(16),
});

const Wrapper = styled(FlexBox)({
  position: "relative",
  width: rem(180),
  height: rem(180),
  margin: "0.5%",
  border: `1px solid ${theme.color.greyLightColor}`,
  backgroundColor: theme.color.whiteColor,
  borderRadius: rem(16),
  overflow: "hidden",
});

const CampaignCardSmallPlaceholder: FC<{}> = () => (
  <Wrapper>
    <AnimationOverlay />
    <FlexBox
      customWidth={CustomWidthE.full}
      fullHeight={true}
      flexDirection="column"
    >
      <CampaignCardCover customWidth={CustomWidthE.full}>
        <Icon
          icon={IconTypeE.image}
          size={100}
          color={theme.color.whiteColor}
        />
      </CampaignCardCover>
      <CampaignCardContent
        customWidth={CustomWidthE.full}
        alignItems="flex-start"
        justifyContent="space-between"
        flexDirection="column"
      >
        <Line />
        <Line />
      </CampaignCardContent>
    </FlexBox>
  </Wrapper>
);

export default CampaignCardSmallPlaceholder;
