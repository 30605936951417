import * as React from "react";
import { Dispatch, FC, SetStateAction } from "react";
import { CustomWidthE } from "../../../components/FlexBox";
import { useTranslate } from "../../../hooks/useTranslate";
import { VerticalScrollView } from "../../../ui/VerticalScrollView";
import { ReportingParticipant } from "../../reporting-media-groups/data-access/types";
import FilterParticipant from "../ui/FilterParticipant";
import FilterSection from "../ui/FilterSection";
import FilterParticipantsHandle from "./FilterParticipantsHandle";

type FilterParticipantsT = {
  participants: ReportingParticipant[];
  participantsState: string[];
  setParticipantsState: Dispatch<SetStateAction<string[]>>;
};

const FilterParticipants: FC<FilterParticipantsT> = ({
  participants,
  participantsState,
  setParticipantsState,
}) => {
  const { t } = useTranslate("brands.reportings.participants.filter");

  const participantStateHandle = (id: string): void => {
    setParticipantsState((prevState) => {
      if (prevState) {
        if (prevState.some((item) => item === id)) {
          return prevState.filter((item) => item !== id);
        }

        return [...prevState, id];
      }

      return [id];
    });
  };

  return (
    <FilterSection label={t("influencers")} withBorder={false}>
      <VerticalScrollView
        customWidth={CustomWidthE.full}
        justifyContent="flex-start"
        flexDirection="column"
        customHeight={300}
      >
        <FilterParticipantsHandle
          participants={participants}
          participantsState={participantsState}
          setParticipantsState={setParticipantsState}
        />
        {participants.map((participant) => (
          <FilterParticipant
            key={participant.id}
            profileUrl={participant.profile_url}
            handle={participant.handle}
            active={participantsState.some((item) => item === participant.id)}
            onClickHandle={() => participantStateHandle(participant.id)}
          />
        ))}
      </VerticalScrollView>
    </FilterSection>
  );
};

export default FilterParticipants;
