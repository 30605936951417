import { _o } from "./helpers";

const EVENTS = {
  marketDisplayed: {
    event: "ga.event",
    eventCategory: "Campaign | Influencer - Displayed | DL",
    eventValue: undefined,
    eventNonInteraction: "true",
  },
};

type pushT = (gtmElement: HTMLElement) => void;
const push: pushT = (gtmElement) => {
  const { type, data, userid } = gtmElement.dataset;

  if (type == "marketDisplayed") {
    data.split(",").forEach((campaignId) => {
      window.dataLayer.push(
        Object.assign(EVENTS[type], {
          eventAction: userid,
          eventLabel: campaignId,
        })
      );
    });
  }
};

type gtmPushInitT = () => void;
export const gtmPushInit: gtmPushInitT = () => {
  const gtmElements = document.getElementsByClassName("gtm-push");

  if (gtmElements && window.dataLayer) {
    _o(gtmElements).forEach((gtmElement) => {
      push(gtmElement as HTMLElement);
    });
  }
};
