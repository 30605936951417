import _ from "lodash";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../assets/icons/Icons";
import { OfferStateE } from "../entry-files/chat/types";
import { theme } from "../styling/theme";
import FlexBox from "./FlexBox";
import Icon from "./Icon";
import Paragraph from "./Paragraph";
import Spacer from "./Spacer";

type getOfferLabelT = (state: OfferStateE) => {
  icon: IconTypeE;
  color: string;
  text: string;
};

const getOfferLabel: getOfferLabelT = (state) => {
  switch (state) {
    case OfferStateE.created:
      return {
        icon: IconTypeE.newOffer,
        color: theme.color.yellowColor,
        text: "návrh odeslán",
      };
    case OfferStateE.in_progress:
      return {
        icon: IconTypeE.time,
        color: theme.color.primaryColor,
        text: "v procesu tvorby",
      };
    case OfferStateE.accepted:
      return {
        icon: IconTypeE.checkCircle,
        color: theme.color.successColor,
        text: "dokončeno",
      };
    case OfferStateE.declined:
      return {
        icon: IconTypeE.crossCircle,
        color: theme.color.textGreyColor,
        text: "odmítnuto",
      };
  }
};

type OfferLabelT = {
  state: OfferStateE;
  customLabel?: string;
};

const OfferLabel: FC<OfferLabelT> = ({ state, customLabel }) => {
  const { color, text, icon } = getOfferLabel(state);

  return (
    <FlexBox justifyContent="flex-start">
      <Icon icon={icon} color={color} size="small" />
      <Spacer size="micro" />
      <Paragraph
        paragraph={_.upperFirst(customLabel ? customLabel : text)}
        color={color}
        paragraphSize="small"
        fontWeight={500}
      />
    </FlexBox>
  );
};

export default OfferLabel;
