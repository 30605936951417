import styled from "@emotion/styled";
import * as React from "react";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import NoResults from "../../../components/NoResults";
import PaginationScrollWrapper from "../../../components/PaginationScrollWrapper";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../features/Placeholders/PlaceholderLoader";
import { CrossPacksConts } from "../../../helpers/crossPacks";
import { usePagination } from "../../../hooks/usePagination";
import { rem, theme } from "../../../styling/theme";
import { UserRoleT } from "../../types";
import ConversationCard from "../components/ConversationCard";
import { allFilterOptions } from "../components/Filter";
import OffersFiltersBar, {
  getUpdatedUrl,
  OFFERS_FILTER_STORAGE_KEY,
  OFFERS_SEARCH_STORAGE_KEY,
} from "../components/OffersFiltersBar";
import { OfferStateE, OfferT } from "../types";

type ActiveStateStyleT = {
  active: boolean;
};

type LastStateStyleT = {
  last: boolean;
};

export const CONVERSATION_CONTAINER_WIDTH = 420;
const Wrapper = styled(FlexBox)({
  position: "relative",
  minWidth: rem(CONVERSATION_CONTAINER_WIDTH),
  flexBasis: rem(CONVERSATION_CONTAINER_WIDTH),
  height: "100%",

  [theme.media.maxMd]: {
    width: "100%",
    flexBasis: "auto",
    minWidth: 0,
  },
});

const OnClickWrapper = styled.div<ActiveStateStyleT & LastStateStyleT>(
  ({ active, last }) => ({
    position: "relative",
    width: "100%",
    backgroundColor: active ? theme.color.yellowLightHoverColor : "transparent",
    transition: "all 0.4s",
    transitionTimingFunction: theme.timing.default,
    cursor: "pointer",
    borderBottom: last ? "none" : `1px solid ${theme.color.greyColor}`,

    ["&:hover"]: {
      backgroundColor: theme.color.backgroundColor,
    },

    [theme.media.maxMd]: {
      backgroundColor: "transparent",

      ["&:hover"]: {
        backgroundColor: "transparent",
      },
    },
  })
);

const ScrollWrapper = styled(PaginationScrollWrapper)({
  borderBottomLeftRadius: rem(16),
});

const Stripe = styled.div<ActiveStateStyleT>(({ active }) => ({
  position: "absolute",
  width: active ? rem(3) : 0,
  bottom: 0,
  top: 0,
  right: 0,
  zIndex: 2,
  backgroundColor: theme.color.yellowColor,
  transition: "width 0.4s",
  transitionTimingFunction: theme.timing.default,

  [theme.media.maxMd]: {
    display: "none",
  },
}));

const BorderLine = styled.div({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  borderRight: `1px solid ${theme.color.greyColor}`,
  zIndex: 1,

  [theme.media.maxMd]: {
    display: "none",
  },
});

type ConversationsContainerT = {
  activeOffer: OfferT;
  setActiveOffer: Dispatch<SetStateAction<OfferT | null>>;
  setOfferDetailVisible: Dispatch<SetStateAction<boolean>>;
  userId: number;
  userRole: UserRoleT;
};

const ConversationsContainer: FC<ConversationsContainerT> = ({
  activeOffer,
  setActiveOffer,
  setOfferDetailVisible,
  userId,
  userRole,
}) => {
  const OFFERS_PER_PAGE = 15;

  const searchStringStorage = localStorage.getItem(OFFERS_SEARCH_STORAGE_KEY);
  const filterStateStorage = localStorage.getItem(OFFERS_FILTER_STORAGE_KEY);
  const filterStateStorageArray =
    filterStateStorage && (filterStateStorage.split(",") as OfferStateE[]);

  const [searchString, setSearchString] = useState<string>(
    searchStringStorage ? searchStringStorage : ""
  );
  const [filteredState, setFilteredState] = useState<OfferStateE[]>(
    filterStateStorageArray ? filterStateStorageArray : allFilterOptions
  );

  const [reFetchHelper, setReFetchHelper] = useState(0);
  const [showedOffers, setShowedOffers] = useState<OfferT[] | null>(null);

  const [apiUrl, setApiUrl] = useState<string>(
    getUpdatedUrl(searchString, filteredState)
  );

  const { firstLoad, pending, queuedItems, loadItems } = usePagination<OfferT>(
    apiUrl,
    OFFERS_PER_PAGE,
    setShowedOffers,
    "offers",
    reFetchHelper
  );

  useEffect(() => {
    if (activeOffer) {
      window.history.pushState({}, "", `/market/my?offer_id=${activeOffer.id}`);
    }
  }, [activeOffer]);

  useEffect(() => {
    // EventListener for refreshing the offers list in the case when new notification came
    document.addEventListener(CrossPacksConts.NOTIFICATIONS_LISTENER, () =>
      setReFetchHelper((prevState) => prevState + 1)
    );
  }, []);

  useEffect(() => {
    if (!firstLoad) {
      setReFetchHelper((prevState) => prevState + 1);
    }
  }, [apiUrl]);

  const getContent = (): JSX.Element => {
    if (firstLoad) {
      return (
        <PlaceholderLoader
          type={PlaceholdersTypeE.conversation_card}
          count={5}
          direction="column"
        />
      );
    }

    if (!firstLoad && !pending && !showedOffers && queuedItems.length === 0) {
      return (
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          <NoResults message={"Bohužel nebyly nalezeny žádné nabídky"} />
        </FlexBox>
      );
    }

    if (showedOffers && showedOffers.length > 0) {
      return (
        <>
          {showedOffers.map((offer) => {
            const active = activeOffer.id === offer.id;

            const onClickHandle = (): void => {
              setActiveOffer(offer);
              setOfferDetailVisible(true);
            };

            return (
              <OnClickWrapper
                onClick={onClickHandle}
                key={offer.id}
                active={active}
                last={showedOffers[showedOffers.length - 1].id === offer.id}
              >
                <ConversationCard
                  activeOfferId={activeOffer.id}
                  offer={offer}
                  userId={userId}
                  userRole={userRole}
                />
                <Stripe active={active} />
              </OnClickWrapper>
            );
          })}
        </>
      );
    }

    return (
      <PlaceholderLoader
        type={PlaceholdersTypeE.conversation_card}
        count={5}
        direction="column"
      />
    );
  };

  return (
    <Wrapper flexDirection="column" justifyContent="space-between">
      <BorderLine />
      <OffersFiltersBar
        setApiUrl={setApiUrl}
        searchString={searchString}
        setSearchString={setSearchString}
        filteredState={filteredState}
        setFilteredState={setFilteredState}
        pendingIndicator={pending}
      />
      <ScrollWrapper
        loadItems={loadItems}
        showedItems={showedOffers}
        queuedItems={queuedItems}
        pending={pending}
      >
        {getContent()}
      </ScrollWrapper>
    </Wrapper>
  );
};

export default ConversationsContainer;
