import styled from "@emotion/styled";
import * as React from "react";
import { FC } from "react";
import { IconTypeE } from "../../../assets/icons/Icons";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Icon from "../../../components/Icon";
import Paragraph from "../../../components/Paragraph";
import Popover from "../../../components/Popover";
import Spacer from "../../../components/Spacer";
import { SocialNetworkProviderT } from "../../../helpers/socialMediaProviders";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem, theme } from "../../../styling/theme";
import { DataOriginT } from "../types";

const Wrapper = styled(FlexBox)({
  position: "relative",
  padding: `${rem(16)} ${rem(24)}`,
  borderTop: `1px solid ${theme.color.greyLightColor}`,
  cursor: "default",
});

type CardFooterDataT = {
  icon: IconTypeE;
  label: string;
  description: string;
};

type CardFooterT = {
  dataOrigin: DataOriginT;
  provider: SocialNetworkProviderT;
};

const CardFooter: FC<CardFooterT> = ({ dataOrigin, provider }) => {
  const { t } = useTranslate(`crm.detail.metrics.${provider}.footer`);

  const footerData: { [key in DataOriginT]: CardFooterDataT } = {
    verified: {
      icon: IconTypeE.checkCircleFillded,
      label: t("verified"),
      description: t("verified_description"),
    },
    estimated: {
      icon: IconTypeE.infoCircle,
      label: t("estimated"),
      description: t("estimated_description"),
    },
    public: {
      icon: IconTypeE.eye,
      label: t("public"),
      description: t("public_description"),
    },
  };

  return (
    <Wrapper customWidth={CustomWidthE.full}>
      <Popover
        renderTrigger={(active) => (
          <FlexBox>
            <Icon
              icon={footerData[dataOrigin].icon}
              color={theme.color.textGreyColor}
              size="small"
            />
            <Spacer size="micro" />
            <Paragraph
              paragraph={footerData[dataOrigin].label}
              color={theme.color.textGreyColor}
            />
          </FlexBox>
        )}
        content={footerData[dataOrigin].description}
        width={250}
      />
    </Wrapper>
  );
};

export default CardFooter;
