import * as React from "react";
import { FC } from "react";
import { GridWrapper } from "../../../../components/GridWrapper";
import { useTranslate } from "../../../../hooks/useTranslate";
import { cardFields, disabledFiledsMap } from "../../data-access/dataConstants";
import { CardOriginT, MediaT } from "../../data-access/types";
import InputColumn from "./InputColumn";
import InputDate from "./InputDate";
import InputUrl from "./InputUrl";

type InsightsFormT = {
  media: MediaT;
  mediaGroupParticipantId: string;
  origin: CardOriginT;
  liveAddedMedia?: boolean;
};

const InsightsForm: FC<InsightsFormT> = ({
  media,
  origin,
  liveAddedMedia,
  mediaGroupParticipantId,
}) => {
  const { kind, id, published_at, url } = media;
  const { t } = useTranslate("brands.reportings.media_groups");

  return (
    <GridWrapper sidesMargin={2}>
      <InputUrl
        id={id}
        label={t(`form.labels.url`)}
        participantId={mediaGroupParticipantId}
        url={url}
        liveAddedMedia={liveAddedMedia}
        permanentDisabled={origin === "private" || origin === "public"}
      />
      <InputDate
        id={id}
        label={t(`form.labels.published_at`)}
        participantId={mediaGroupParticipantId}
        date={published_at}
        liveAddedMedia={liveAddedMedia}
        permanentDisabled={origin === "private" || origin === "public"}
      />
      {cardFields[kind].map((key) => {
        return (
          <InputColumn
            key={key}
            id={id}
            inputKey={key}
            label={t(`form.labels.${key}`)}
            participantId={mediaGroupParticipantId}
            value={media[key]}
            liveAddedMedia={liveAddedMedia}
            permanentDisabled={
              !!disabledFiledsMap[origin].find((item) => item === key)
            }
          />
        );
      })}
    </GridWrapper>
  );
};

export default InsightsForm;
