import axios from "axios";
import { ApiRoutesE, getApiUrl } from "../../../helpers/routes";

type RemoveMediaPropsT = {
  id: number;
  participantId: string;
};

type removeMediaT = (props: RemoveMediaPropsT) => Promise<{}>;
export const removeMedia: removeMediaT = async ({ id, participantId }) =>
  axios
    .delete(getApiUrl(ApiRoutesE.REPORTING_MEDIAS_ITEM, id, participantId))
    .then((res) => res.data);
