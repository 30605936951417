import html2canvas from "html2canvas";
import { useState } from "react";

const createFileName = (extension: string = "", names: string) => {
  if (!extension) {
    return "";
  }

  return `${names}.${extension}`;
};

type takeScreenshotT = (node: HTMLDivElement) => Promise<string | void>;
type useScreenshotReturnT = {
  image: string | null;
  takeScreenshot: takeScreenshotT;
  error: string | null;
};

const useScreenshot = (type: string, quality: number): useScreenshotReturnT => {
  const [image, setImage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const takeScreenshot: takeScreenshotT = async (node) =>
    html2canvas(node)
      .then((canvas) => {
        const croppedCanvas = document.createElement("canvas");
        const croppedCanvasContext = croppedCanvas.getContext("2d");

        const cropPositionTop = 0;
        const cropPositionLeft = 0;
        const cropWidth = canvas.width;
        const cropHeight = canvas.height;

        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;

        if (croppedCanvasContext) {
          croppedCanvasContext.drawImage(
            canvas,
            cropPositionLeft,
            cropPositionTop
          );
        }

        const base64Image = croppedCanvas.toDataURL(type, quality);

        setImage(base64Image);
        return base64Image;
      })
      .catch((e) => setError(e));

  return { image, takeScreenshot, error };
};

export { useScreenshot, createFileName };
