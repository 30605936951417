import styled from "@emotion/styled";
import * as React from "react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import FlexBox from "./FlexBox";

type WrapperStyleT = {
  height: string;
};

const Wrapper = styled(FlexBox)<WrapperStyleT>(({ height }) => ({
  position: "relative",
  height,
  width: "100%",
  overflowY: "scroll",
  overflowX: "hidden",
}));

type PaginationScrollWrapperT = {
  pending: boolean;
  queuedItems: Array<any>;
  showedItems: Array<any> | null;
  loadItems: () => void;
  height?: string;
  className?: string;
};

const PaginationScrollWrapper: FC<PaginationScrollWrapperT> = ({
  pending,
  queuedItems,
  showedItems,
  loadItems,
  children,
  height = "100%",
  className,
}) => {
  const [scrollTop, setScrollTop] = useState<number | null>(null);
  const [scrollHeight, setScrollHeight] = useState<number | null>(null);

  const setScrollTopHandle = useCallback((e): void => {
    const { target } = e;

    setScrollTop(() => target.scrollTop + target.clientHeight);
    setScrollHeight(() => target.scrollHeight);
  }, []);

  useEffect(() => {
    if (
      scrollTop === scrollHeight &&
      !pending &&
      queuedItems.length > 0 &&
      !!showedItems &&
      showedItems.length > 0
    ) {
      loadItems();
    }
  }, [scrollTop]);

  return (
    <Wrapper
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      onScroll={useRef(_.throttle((e) => setScrollTopHandle(e), 1000)).current}
      height={height}
      className={className}
    >
      {children}
    </Wrapper>
  );
};

export default PaginationScrollWrapper;
