import { _o } from "../helpers";

type setAspectRatioHeightT = () => void;
export const setAspectRatioHeight: setAspectRatioHeightT = () => {
  const aspectRatio = [4, 3];
  const ratioImages = document.getElementsByClassName("ratio-4-3");

  if (ratioImages) {
    _o(ratioImages).map((image: Element) => {
      const imageElement = image as HTMLElement;

      imageElement.style.height =
        (image.clientWidth * aspectRatio[1]) / aspectRatio[0] + "px";
    });
  }
};

type aspectRatioOnResizeT = () => void;
export const aspectRatioOnResize: aspectRatioOnResizeT = () => {
  window.addEventListener("resize", setAspectRatioHeight);
  setAspectRatioHeight();
};
