import { _o } from "../helpers.ts";
import { slidersInit } from "../inputSlider";
import { campaignFormStepsInit } from "./campaignFormSteps";
import { copyDescriptionsOnChange } from "./descriptionCopy";
import {
  cardImageOnChange,
  currencyOnChange,
  nameOnChange,
  networksOnChange,
  shortDesctOnChange,
} from "./events";
import { setCurrency, updateLabels } from "./helpers";
import { influencerTargetingInit } from "./influencersTargeting";
import { networkTabsInit } from "./networkTabs";
import { offerPriceCalcInit } from "./offerPriceCalc";
import { productPriceOnChange } from "./productPrice";
import { productTypeOnChange } from "./productType";
import { budgetOnChange } from "./barterBudget";
import { saveDraftAutomatically } from "./saveDraftAutomatically";
import { scrollToErrors } from "./scrollToErrors";
import {
  BUDGET_SLIDER,
  CAMPAIGNS_SLIDERS,
  getNetworksMinFansSliders,
} from "./sliders";
import { trackingTypeOnChange } from "./trackingType";

const _ = window;

const initGlobalVariables = () => {
  setCurrency(false);
};

$(document).on("turbolinks:load", () => {
  const campaingCreation = document.getElementById("create_campaign");
  const offerCreation = document.getElementById("create_offer");
  const brandOfferDetail = document.getElementById("brand_offer_detail");
  const networkConnections = document.getElementById("network-connections");

  if (campaingCreation) {
    initGlobalVariables();
    const currency = _.CAMPAIGN_CURRENCY;
    const campaingSliders = CAMPAIGNS_SLIDERS.concat(
      getNetworksMinFansSliders()
    ).concat(BUDGET_SLIDER[currency]);

    campaignFormStepsInit();
    updateLabels();

    slidersInit(campaingSliders);
    currencyOnChange();
    networksOnChange();

    productTypeOnChange();
    productPriceOnChange();
    budgetOnChange();
    trackingTypeOnChange();

    nameOnChange();
    cardImageOnChange();
    shortDesctOnChange();
    copyDescriptionsOnChange();
    saveDraftAutomatically();

    scrollToErrors();
    influencerTargetingInit();

    const readOnlyWrapers = document.getElementsByClassName(
      "read-only-wrapper"
    );

    _o(readOnlyWrapers).forEach((element) =>
      element.addEventListener("click", (e) => {
        const inputs = e.target.getElementsByTagName("INPUT");

        _o(inputs).forEach((input) => (input.readOnly = true));
      })
    );
  }

  if (offerCreation) {
    scrollToErrors();
    offerPriceCalcInit();
    networkTabsInit();
  }

  if (brandOfferDetail || networkConnections) {
    networkTabsInit();
  }
});
