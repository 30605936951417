import { _o } from "./helpers";

export const openCrmActionInit = (): void => {
  const wrapper = _o(document.getElementsByClassName("database-crm-wrapper"));

  if (wrapper.length) {
    const url = new URL(window.location.href);
    const kind = url.searchParams.get("kind");

    if (kind) {
      const element = _o(document.getElementsByClassName(kind))[0] as HTMLElement;
      element.click();
    }
  };
};
